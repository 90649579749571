define('tm-common/models/document-template-has-field', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        fieldName: attr('string'),
        weight: attr('number'),
        verticalPosition: attr('string'),
        horizontalPosition: attr('string'),

        // relationships
        documentTemplate: belongsTo('document-template', { async: true }),
        documentField: belongsTo('document-field', { async: true })
    });
});