define('tm-common/models/subject-workflow-v2', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        assignedOn: attr('estdate'),
        assignedTo: attr('string'),
        assignedToFullName: attr('string'),
        assignedToId: attr('number'),
        calcDeadline: attr('string'),
        calcNextDue: attr('string'),
        caseOwnerIds: attr(),
        comment: attr('string'),
        completedOn: attr('estdate'),
        createdBy: belongsTo('user', { async: true }),
        createdOn: attr('estdate'),
        deadline: attr('string'),
        enabled: attr('number'),
        hardDeadline: attr('number'),
        hours: attr('number'),
        lastReportDate: attr('string'),
        matterCode: attr('string'),
        matterCollectionId: attr('number'),
        matterId: attr('number'),
        matterName: attr('string'),
        matterProgressStatus: attr('string'),
        matterTypeId: attr('number'),
        matterWorkflowId: attr('number'),
        name: attr('string'),
        startOn: attr('string'),
        status: attr('string'),
        subjectId: attr('number'),
        subjectName: attr('string'),
        updatedOn: attr('estdate'),
        workflowId: attr('number'),

        showStatus: Ember.computed('enabled', function () {
            // eslint-disable-next-line no-bitwise
            return !!(this.get('enabled') & 1);
        }),
        showAssigned: Ember.computed('enabled', function () {
            // eslint-disable-next-line no-bitwise
            return !!(this.get('enabled') & 2);
        }),
        showComment: Ember.computed('enabled', function () {
            // eslint-disable-next-line no-bitwise
            return !!(this.get('enabled') & 4);
        }),
        showHours: Ember.computed('enabled', function () {
            // eslint-disable-next-line no-bitwise
            return !!(this.get('enabled') & 8);
        }),
        showDeadline: Ember.computed('enabled', function () {
            // eslint-disable-next-line no-bitwise
            return !!(this.get('enabled') & 16);
        }),

        events: hasMany('case-workflow-event', {
            async: false
        }),

        dotColor: Ember.computed('deadline', function () {
            if (Ember.isEmpty(this.get('deadline'))) {
                return 'white';
            }
            var today = (0, _moment.default)();
            var diff = (0, _moment.default)(this.get('deadline')).diff(today, 'days');
            if (diff < 0) {
                return 'gray';
            } else if (diff <= 1) {
                return 'red';
            } else if (diff <= 7) {
                return 'orange';
            } else {
                return 'green';
            }
        })
    });
});