define('tm-common/models/collection-field', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;


    var VIEWABLE_PORTAL = 1;
    var VIEWABLE_WORKFLOW = 2;
    var VIEWABLE_SUBJECT = 4;
    var VIEWABLE_REPORT = 8;

    exports.default = Model.extend({
        lists: Ember.inject.service(),
        value: attr('string'),
        display: attr('string'),
        weight: attr('number'),
        columnWidth: attr('number'),
        viewable: attr('number'),
        renderType: attr('string'),
        renderList: attr('string'),
        compiledFrom: attr('string'),
        dashboardViewable: attr('number'),

        collection: belongsTo('collection'),

        /**
         * BEGIN BOOLEAN CALCS
         *
         * a series of booleans to describe if a field is locked for a particular case
         * useful for template logic
         */
        isEnabledCase: Ember.computed('enabled', {
            get: function get(key) {
                return this.get('enabled') === 1;
            },
            set: function set(key, value) {
                this.set('enabled', value ? 1 : 0);
                return value;
            }
        }),
        isViewablePortal: Ember.computed('viewable', {
            get: function get(key) {
                return this.get('viewable') & VIEWABLE_PORTAL;
            },
            set: function set(key, value) {
                var viewable = this.get('viewable');
                if (value) {
                    viewable |= VIEWABLE_PORTAL;
                } else {
                    viewable &= ~VIEWABLE_PORTAL;
                }
                this.set('viewable', viewable);
                return value;
            }
        }),
        isViewableWorkflow: Ember.computed('viewable', {
            get: function get(key) {
                return this.get('viewable') & VIEWABLE_WORKFLOW;
            },
            set: function set(key, value) {
                var viewable = this.get('viewable');
                if (value) {
                    viewable |= VIEWABLE_WORKFLOW;
                } else {
                    viewable &= ~VIEWABLE_WORKFLOW;
                }
                this.set('viewable', viewable);
                return value;
            }
        }),
        isViewableSubject: Ember.computed('viewable', {
            get: function get(key) {
                return this.get('viewable') & VIEWABLE_SUBJECT;
            },
            set: function set(key, value) {
                var viewable = this.get('viewable');
                if (value) {
                    viewable |= VIEWABLE_SUBJECT;
                } else {
                    viewable &= ~VIEWABLE_SUBJECT;
                }
                this.set('viewable', viewable);
                return value;
            }
        }),
        isViewableReport: Ember.computed('viewable', {
            get: function get(key) {
                return this.get('viewable') & VIEWABLE_REPORT;
            },
            set: function set(key, value) {
                var viewable = this.get('viewable');
                if (value) {
                    viewable |= VIEWABLE_REPORT;
                } else {
                    viewable &= ~VIEWABLE_REPORT;
                }
                this.set('viewable', viewable);
                return value;
            }
        }),
        isLabelEditable: Ember.computed('value', 'isEnabledCase', function () {
            return this.get('value').indexOf('custom') >= 0 && this.get('isEnabledCase');
        }),

        /**
         * END BOOLEAN CALCS
         */

        /** this property gets the list from the lists service with the right listName */
        options: Ember.computed('renderType', function () {
            if (this.get('renderType') === 'enum' || this.get('renderType') === 'radio' || this.get('renderType') === 'checkboxes') {
                return this.get('lists').getListForGroup(this.get('renderList'));
            }
            return [];
        })
    });
});