define('tm-common/models/data-report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        name: attr('string'),
        description: attr('string'),
        category: attr('string'),
        url: attr('string'),

        // client defined properties
        displayName: '',
        // The name that will be shown on the table header
        perPage: 100,
        // The Ember route that the report is located at
        route: '',
        // An optional custom component for the table layout for the report
        component: 'report/default-table',
        // A boolean for whether report stands on its own (will not be on list page if true)
        subReport: false,
        // Optional - designates parent report of a sub report
        parentReport: ''
    });
});