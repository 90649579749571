define('tm-common/services/lists', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var PromiseArray = _emberData.default.PromiseArray;


    /**
     * @private
     * return a copy of the given object which contains only the given keys
     * e.g.:
     *    object = {a: 'asd', b: 'bnm', c: 'asdqw'}
     *    keys = ['a', 'b']
     *    retain(object, key) // -> {a: 'asd', b: 'bnm'}
     * @param object
     * @param keys
     */
    function retain(object, keys) {
        return Object.keys(object).filter(function (key) {
            return keys.includes(key);
        }).reduce(function (filtered, key) {
            filtered[key] = object[key];
            return filtered;
        }, {});
    }

    /**
     * @public
     * This service can be used to query the appl_lists endpoint
     * with some heuristics to avoid hitting the api multiple times
     * for the same static data.
     *
     * And also allow querying multiple groups with one request
     * and then just getting data from the store.
     *
     */
    exports.default = Ember.Service.extend({
        /**
         * @property
         * @private
         *
         * the service uses this variable to cache the results
         * of a specific group
         */
        itemsByListName: {},

        /**
         * @property
         * @private
         *
         * the service uses this variable to keep track of the current requests
         * of a specific set of lists
         */
        pendingRequestByListName: {},

        /**
         * @property
         * @private
         *
         * store used to query the API
         */
        store: Ember.inject.service(),

        /**
         * @public
         * @method
         *
         * This method is called with an array of group names
         * so we can hit the API only for the missing group names,
         * and when is ready return the
         * itemsByListName
         *
         * @return Promise
         */
        fetchMultipleLists: function fetchMultipleLists(listNames) {
            var _this = this;

            var itemsByListName = this.get('itemsByListName');
            var store = this.get('store');
            var listNamesCopy = Ember.copy(listNames);

            // Remove the groups already on the local cache
            Object.keys(itemsByListName).forEach(function (listName) {
                listNamesCopy.removeObject(listName);
            });

            // If there is no pending groups just return the current cache
            if (listNamesCopy.length === 0) {
                return Ember.RSVP.Promise.resolve(retain(this.get('itemsByListName'), listNames));
            }

            // before asking the store: check if there is a pending request for that:
            var pendingRequest = this.get('pendingRequestByListName.' + listNamesCopy.join('-'));
            if (Ember.isPresent(pendingRequest)) {
                return pendingRequest;
            }

            // Get all the groups at once, and then update the cache and return when all ready
            var request = store.query('appl-lists-category', {
                category_name: listNamesCopy,
                with: 'appl_lists'
            }).then(function () {
                var itemsByListName = _this.get('itemsByListName');
                listNamesCopy.forEach(function (listName) {
                    if (!Ember.isPresent(itemsByListName[listName])) {
                        itemsByListName[listName] = _this.peekFromStore(listName);
                        _this.set('itemsByListName', itemsByListName);
                    }
                });
                var pendingRequests = _this.get('pendingRequestByListName');
                delete pendingRequests[listNamesCopy.join('-')];
                _this.set('pendingRequestByListName', pendingRequests);
                return retain(_this.get('itemsByListName'), listNames);
            });

            this.set('pendingRequestByListName.' + listNamesCopy.join('-'), request);
            return request;
        },


        /**
         * @public
         * @method
         *
         * This method is called to get a list for one group name
         * if the data is ready on the cache return that,
         * if not fetch @using fetchMultipleLists and then return the data
         *
         * @return PromiseArray
         */
        getListForGroup: function getListForGroup(listName) {
            var _this2 = this;

            var itemsByListName = this.get('itemsByListName');

            var promise = new Ember.RSVP.Promise(function (resolve) {
                // Check the cache for the presence of that group
                if (Ember.isPresent(itemsByListName[listName])) {
                    resolve(itemsByListName[listName]);
                } else {
                    // if not present on cache go to API and then return the group asked
                    _this2.fetchMultipleLists([listName]).then(function (itemsByListName) {
                        resolve(itemsByListName[listName]);
                    });
                }
            });
            return PromiseArray.create({ promise: promise });
        },


        /**
         * @private
         * for a provided list category name as a string
         * pull all child list records for that parent category
         *
         * this function pulls from cache, not the api
         *
         * @param listName
         * @returns {*|Array}
         */
        peekFromStore: function peekFromStore(listName) {
            var result = Ember.A();
            // use peekAll to just get the data from the store (not the server)
            // and then just filter the group asked
            this.get('store').peekAll('appl-list').forEach(function (record) {
                if (Ember.isEmpty(record.get('applListsCategory'))) {
                    // do nothing relationship is broken
                    Ember.debug('Encountered a list record w/o a parent category, is the model broken?');
                } else {
                    if (record.get('applListsCategory.categoryName') && record.get('applListsCategory.categoryName').toLowerCase() === listName.toLowerCase()) {
                        result.pushObject(record);
                    }
                }
            });
            return result.sortBy('weight').sortBy('display');
        }
    });
});