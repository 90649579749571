define('tm-common/components/subjects/table/client-social-cell', ['exports', 'tm-common/components/subjects/table/social-cell'], function (exports, _socialCell) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _socialCell.default.extend({
        hideIcon: true,

        getCurrentHyperlink: function getCurrentHyperlink() {
            var socNetIndex = parseInt(this.get('column.label').split('#')[1], 10) - 1;
            var hyperlinks = [];
            var orderedHyperlinks = this.get('row.content.hyperlinks').rejectBy('type', 'Media').rejectBy('type', 'Monitoring').rejectBy('type', null).rejectBy('viewable', 0).rejectBy('viewable', 2).sortBy('weight');
            orderedHyperlinks.forEach(function (hyperlink) {
                hyperlinks.push(hyperlink);
            });
            if (socNetIndex < hyperlinks.length) {
                return hyperlinks[socNetIndex];
            }
            return null;
        }
    });
});