define('tm-common/components/sort-icon', ['exports', 'tm-common/templates/components/sort-icon'], function (exports, _sortIcon) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _sortIcon.default,

        tagName: '',

        /**
         * @property
         * @public
         *
         * what field is being sorted right now
         */
        currentSortField: '',

        /**
         * @property
         * @public
         *
         * what field this sort is representing
         */
        forField: '',

        fieldText: '',
        showDoubleArrows: true,

        simpleSortField: Ember.computed('currentSortField', function () {
            var parts = this.get('currentSortField').split(':');
            return parts[0];
        }),
        directionSortField: Ember.computed('currentSortField', function () {
            var parts = this.get('currentSortField').split(':');
            if (parts.length === 2) {
                return parts[1];
            } else {
                return 'asc';
            }
        }),

        contrary: function contrary(dir) {
            return dir === 'asc' ? ':desc' : ':asc';
        },


        actions: {
            sortChanged: function sortChanged() {
                if (this.get('simpleSortField') === this.get('forField')) {
                    this.sendAction('sortChanged', this.get('forField') + this.contrary(this.get('directionSortField')));
                } else {
                    this.sendAction('sortChanged', this.get('forField'));
                }
            }
        }
    });
});