define('tm-common/models/key-attribute-rule', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        matter: belongsTo('matter', { async: true }),
        field: attr('string'),
        operator: attr('string'),
        compareTo: attr('string'),
        fieldOrValue: attr('string'),
        resultIsFieldOrValue: attr('string'),
        resultFieldValue: attr('string'),
        icon: attr('string'),
        weight: attr('number')
    });
});