define('ember-cli-table-pagination/components/bs-table-pagination/table-tools', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-tools', 'ember-cli-table-pagination/utils/operators-list'], function (exports, _tableTools, _operatorsList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var hasFilter = function hasFilter(c) {
    var hasValues = false;
    switch (c.get('advFilterOperator.input')) {
      case 0:
        hasValues = true;
        break;
      case 1:
        hasValues = Ember.isPresent(c.get('advFilterValue'));
        break;
      case 2:
        hasValues = Ember.isPresent(c.get('advFilterValue')) && Ember.isPresent(c.get('advFilterValue2'));
        break;
    }
    return Ember.isPresent(c.get('advFilterOperator')) && hasValues;
  };

  exports.default = Ember.Component.extend({
    layout: _tableTools.default,
    classNames: ['col-xs-9', 'col-md-9', 'col-lg-10', 'table-tools'],

    // properties
    perPageOptions: [50, 100, 250],

    showAll: false,

    onInitComponent: Ember.on('init', function () {
      if (this.get('toolsParams')) {
        this.set('perPageFormat', this.get('toolsParams.perPageFormat') ? this.get('toolsParams.perPageFormat') : 'select');
        this.set('showAll', this.get('toolsParams.showAll') ? this.get('toolsParams.showAll') : this.get('isInfinite'));
      } else {
        this.set('showAll', this.get('isInfinite'));
      }
    }),

    allowAdvancedFilter: Ember.computed('allowQuickSearch', function () {
      return this.get('allColumns.length') > 0 && this.get('allowQuickSearch');
    }),

    filters: Ember.computed('allColumns.@each.{advFilterOperator,advFilterValue,advFilterValue2}', function () {
      return this.get('allColumns').filterBy('enableSearch').filter(hasFilter);
    }),

    perPageFormat: 'select',

    operators: _operatorsList.default,

    buttonLink: Ember.computed.reads('toolsParams.buttonLink'),
    buttonText: Ember.computed.reads('toolsParams.buttonText'),

    actions: {
      showAllRecords: function showAllRecords() {
        this.set('showAll', true);
        this.attrs.changePerPage(100000000); // 100 million records
        this.attrs.refresh();
      },
      showByPageRecords: function showByPageRecords() {
        this.set('showAll', false);
        this.attrs.changePerPage(this.get('perPageOptions.firstObject'));
        this.attrs.refresh();
      },
      applyFilter: function applyFilter() {
        var columnsWithoutFilter = this.get('allColumns').reject(hasFilter);
        columnsWithoutFilter.forEach(function (c) {
          c.setProperties({
            filterValue: null,
            advFilterValue: null,
            advFilterValue2: null,
            advFilterOperator: (0, _operatorsList.getOperator)(c.get('acceptedOperators'))
          });
        });
        var columnsWithFilter = this.get('allColumns').filter(hasFilter);

        var extraParams = {};
        columnsWithFilter.forEach(function (c) {
          var filter = '';
          var value = c.get('advFilterValue');
          var value2 = c.get('advFilterValue2');
          c.set('filterValue', value);
          switch (c.get('advFilterOperator.value')) {
            case 'contains':
              filter = '*' + value + '*';
              break;
            case 'not_contains':
              filter = '!' + value + '!';
              break;
            case 'equal':
              filter = value;
              break;
            case 'not_equal':
              filter = '!=' + value;
              break;
            case 'blank':
              filter = ['NULL', ''];
              break;
            case 'not_blank':
              filter = ['!NULL', '!='];
              break;
            case 'between':
              filter = '~' + value + '~' + value2;
              break;
            case 'not_between':
              filter = '~' + value + '~' + value2;
              extraParams[c.get('apiInteractionName') + '_operator'] = 'not_between';
              break;
            case 'in':
              if (Ember.typeOf(value) === 'array') {
                filter = value;
              } else {
                filter = value.split(' ');
              }
              break;
            case 'not_in':
              if (Ember.typeOf(value) === 'array') {
                filter = value.map(function (x) {
                  return '<>' + x;
                });
              } else {
                filter = value.split(' ').map(function (x) {
                  return '<>' + x;
                });
              }
              break;
            case 'all':
              filter = value;
              extraParams[c.get('apiInteractionName') + '_operator'] = 'all';
              break;
            case 'none':
              filter = value;
              extraParams[c.get('apiInteractionName') + '_operator'] = 'none';
              break;
          }
          extraParams[c.get('apiInteractionName')] = filter;
        });

        this.attrs.runAdvancedSearch(extraParams);

        this.set('showFilterDialog', false);
      },
      clearFilter: function clearFilter(column) {
        column.setProperties({
          filterValue: null,
          advFilterValue: null,
          advFilterValue2: null,
          advFilterOperator: (0, _operatorsList.getOperator)(column.get('acceptedOperators'))
        });
      },
      clearAllFilters: function clearAllFilters() {
        var columnsWithFilter = this.get('allColumns').filter(hasFilter);

        columnsWithFilter.setEach('filterValue', null);
        columnsWithFilter.setEach('advFilterValue', null);
        columnsWithFilter.setEach('advFilterValue2', null);
        columnsWithFilter.forEach(function (c) {
          c.set('advFilterOperator', (0, _operatorsList.getOperator)(c.get('acceptedOperators')));
        });

        this.send('applyFilter');
      },
      isInAccepted: function isInAccepted(accepted, value) {
        if (accepted.length === 0) {
          return true;
        }
        return accepted.includes(value);
      },
      hasFilterApplied: function hasFilterApplied(column) {
        return hasFilter(column);
      }
    }
  });
});