define('tm-common/models/billable-group', ['exports', 'ember-data', 'ember-api-actions', 'accounting/format-money', 'moment'], function (exports, _emberData, _emberApiActions, _formatMoney, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        start: attr('string'),
        end: attr('string'),
        total: attr('number'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        approvalStatus: attr('string'),
        type: attr('string'),
        includeForTravel: attr('boolean', { defaultValue: true }),
        reimbursedOn: attr('estdate'),
        includeForBilling: attr('boolean', { defaultValue: true }),

        // not really needed
        updatedById: attr('number'),
        canSeeAmounts: attr('boolean', { defaultValue: true }),

        // relationships
        billables: hasMany('billable', {
            async: false
        }),
        receipts: hasMany('receipt', {
            async: false
        }),
        charges: hasMany('charge', {
            async: false
        }),
        travelExpenseFiles: hasMany('travel-expense-has-files', {
            async: false
        }),
        deductions: hasMany('deduction', {
            async: false
        }),

        updatedBy: belongsTo('employee', { async: true, inverse: 'updatedBys' }),
        createdBy: belongsTo('employee', { async: true, inverse: 'createdBys' }),
        enteredBy: belongsTo('employee', { async: true }),
        approvedBy: belongsTo('employee', { async: true, inverse: 'approvedBys' }),

        // serialized from the api if manual details are solicitated:
        billablesCount: attr('number'),
        billType: attr('string'),

        // computed properties

        // custom actions
        updateBillables: (0, _emberApiActions.memberAction)({ path: 'update-billables', type: 'put' }),
        reimburse: (0, _emberApiActions.memberAction)({ path: 'reimburse', type: 'put' }),
        undoReimbursement: (0, _emberApiActions.memberAction)({ path: 'undo-reimbursement', type: 'put' }),
        unlinkFromInvoice: (0, _emberApiActions.memberAction)({ path: 'unlink-invoice', type: 'put' }),

        expenses: Ember.computed('billables.@each.billType', function () {
            return this.get('billables').filter(function (billable) {
                switch (billable.get('billType')) {
                    case 'Time:Expense':
                    case 'Piece:Expense':
                    case 'Expense':
                        return true;
                }
                return false;
            });
        }),
        timeBillables: Ember.computed.filterBy('billables', 'billType', 'Time'),
        pieceBillables: Ember.computed.filterBy('billables', 'billType', 'Piece'),
        mainBillables: Ember.computed('billables.[]', function () {
            return this.get('billables').filter(function (b) {
                return b.get('billType') === 'Time' || b.get('billType') === 'Piece';
            });
        }),
        formattedTotal: Ember.computed('total', function () {
            return (0, _formatMoney.default)(this.get('total'));
        }),
        totalService: Ember.computed('timeBillables.[]', function () {
            return this.get('timeBillables').reduce(function (acc, b) {
                return acc + b.get('total');
            }, 0);
        }),
        formattedTotalService: Ember.computed('totalService', function () {
            return (0, _formatMoney.default)(this.get('totalService'));
        }),
        totalExpense: Ember.computed('expenses.[]', function () {
            return this.get('expenses').reduce(function (acc, b) {
                return acc + b.get('total');
            }, 0);
        }),
        formattedTotalExpense: Ember.computed('totalExpense', function () {
            return (0, _formatMoney.default)(this.get('totalExpense'));
        }),
        formattedStart: Ember.computed('start', function () {
            return (0, _moment.default)(this.get('start')).format('MM-DD-YYYY');
        }),
        rangeDate: Ember.computed('start', 'end', function () {
            var _getProperties = this.getProperties('start', 'end'),
                start = _getProperties.start,
                end = _getProperties.end;

            var startFormatted = (0, _moment.default)(start).format('MM/DD/YY');
            var endFormatted = (0, _moment.default)(end).format('MM/DD/YY');
            return startFormatted + ' to ' + endFormatted;
        }),
        normalizeDates: function normalizeDates() {
            var startDate = this.get('start');
            var endDate = this.get('end');

            this.set('start', (0, _moment.default)(startDate).format('YYYY-MM-DD'));
            if (endDate) {
                this.set('end', (0, _moment.default)(endDate).format('YYYY-MM-DD'));
            } else {
                this.set('end', (0, _moment.default)(startDate).format('YYYY-MM-DD'));
            }
        },

        reimburseValue: Ember.computed('total', 'deductions.[]', function () {
            var total = this.get('total') || 0;
            var deduct = 0;
            this.get('deductions').forEach(function (deduction) {
                deduct += deduction.get('amount');
            });
            return parseFloat(total) - parseFloat(deduct);
        })
    });
});