define('tm-common/components/edit-subject-type', ['exports', 'tm-common/templates/components/edit-subject-type'], function (exports, _editSubjectType) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _editSubjectType.default,
        tagName: '',
        selectClass: '',

        // various subject types
        subjectTypeList: [Ember.Object.create({ value: 'juror_subjects', display: 'Juror' }), Ember.Object.create({ value: 'plaintiff_subjects', display: 'Plaintiff' }), Ember.Object.create({ value: 'witness_subjects', display: 'Witness' }), Ember.Object.create({ value: 'other_subjects', display: 'Other' }), Ember.Object.create({ value: 'decedent_subjects', display: 'Decedent' })],
        subject: null
    });
});