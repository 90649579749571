define('tm-common/helpers/status-label', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.statusLabel = statusLabel;


    /**
     * Replace billing statuses used in db (Preliminary, Lebel 1 Complete, etc) to more user friendly text
     *
     * @param params
     * expects an array with a single element as string
     * @returns {*}
     */
    function statusLabel(params /*, hash */) {
        if (Ember.$.isArray(params)) {
            var status = params[0];
            switch (status) {
                case 'Preliminary':
                    return 'Timekeeper Approval Required';
                case 'Level 1 Complete':
                    return 'Manager Approval Required';
                case 'Level 2 Complete':
                    return 'Accounting Approval Required';
                default:
                    return status;
            }
        }
        return '';
    }

    exports.default = Ember.Helper.helper(statusLabel);
});