define('tm-common/models/invoice-group-member', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        billSplit: attr('number'),

        // relationships
        invoiceGroup: belongsTo('invoice-group', {
            async: false
        }),
        firm: belongsTo('firm', { async: true })
    });
});