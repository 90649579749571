define('tm-common/models/table-setting', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        state: attr(),
        appName: attr(),
        route: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        user: belongsTo('user', { async: false })
    });
});