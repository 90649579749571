define('tm-common/services/relevant-content-updater', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        functionGetData: null,

        setFunction: function setFunction(functionGetData) {
            this.set('functionGetData', functionGetData);
        },
        refresh: function refresh() {
            if (this.get('functionGetData')) {
                this.get('functionGetData')();
            }
        }
    });
});