define('tm-common/models/document-download', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        code: attr('string'),
        note: attr('string'),
        url: attr('string'),
        matterId: attr('number'),
        requiresPassword: attr('boolean'),
        expiresOn: attr('estdate'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        user: belongsTo('user'),
        resource: belongsTo('resource'),

        expire: (0, _emberApiActions.memberAction)({ path: 'expire', type: 'put' })
    });
});