define('tm-common/models/list-template-group', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        matterType: belongsTo('matter-type', { async: true }),
        listTemplates: hasMany('list-template', { async: true }),

        matterTypeWritable: Ember.computed('matterType', {
            get: function get(key) {
                return this.get('matterType.content');
            },
            set: function set(key, value) {
                this.set('matterType', value);
                return value;
            }
        })
    });
});