define('tm-common/utils/get-app-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getAppUrl;
  /**
   * @function
   *
   * Takes the environment and a path to return
   * a full API Rest Endpoint URL to work with
   * this is used accross the system to avoid duplication
   */
  function getAppUrl(env, path) {
    return env.APP.restDestination + '/' + env.APP.restNameSpace + path;
  }
});