define('tm-common/helpers/slash-2br', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.slash2br = slash2br;


    /**
     * adds break tag after forward slash
     *
     * @param params
     * expects an array with a single element as string
     * @returns {*}
     */
    function slash2br(params /*, hash */) {
        if (Ember.$.isArray(params)) {
            var breakTag = '<br />';
            var str = params[0];
            if (str) {
                return Ember.String.htmlSafe((str + '').replace(/\//g, '/' + breakTag));
            }
            return '';
        }
    }

    exports.default = Ember.Helper.helper(slash2br);
});