define('tm-common/models/event', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        title: attr('string'),
        description: attr('string'),
        eventType: attr('string'),
        start: attr('string'),
        end: attr('string'),

        hardDeadline: attr('number', { defaultValue: 0 }),
        deadlineMet: attr('number', { defaultValue: 0 }),
        notifyEmployees: attr('number', { defaultValue: 0 }),
        selectedEmployees: attr('csv', { defaultValue: '' }),

        matter: belongsTo('matter'),
        report: belongsTo('report', { async: true }),

        getGlobalEvents: (0, _emberApiActions.collectionAction)({
            path: 'global-calendar',
            type: 'get',
            urlType: 'findRecord'
        }),

        getSchedulingEvents: (0, _emberApiActions.collectionAction)({
            path: 'scheduling-calendar',
            type: 'get',
            urlType: 'findRecord'
        }),

        hasReport: Ember.computed('isNew', 'eventType', 'newReport', function () {
            return this.get('isNew') && this.get('eventType') === 'Report Due Date' && this.get('newReport') && this.get('newReport.isNew');
        })
    });
});