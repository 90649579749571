define('tm-common/models/tax-rule', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr(),
        rate: attr('number'),
        qbMap: attr(),
        description: attr(),
        formattedRate: attr(),
        firmHasMatterHasTaxes: hasMany('firm-has-matter-has-tax', {
            async: true
        })
    });
});