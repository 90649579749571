define('tm-common/components/subjects/profile/widget-field-read', ['exports', 'tm-common/templates/components/subjects/profile/widget-field-read', 'tm-common/mixins/analytics-util', 'tm-common/mixins/profile-analytics-mixin'], function (exports, _widgetFieldRead, _analyticsUtil, _profileAnalyticsMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, _profileAnalyticsMixin.default, {
        layout: _widgetFieldRead.default,
        tagName: '',

        analyticsFormsData: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            this.set('analyticsFormsData.profileEditMode', false);
        },


        reRender: true,

        overviewSection: false,

        vitalsSection: false,

        isChild: false,

        isParent: true,

        isTable: false,

        actions: {
            singleFieldChange: function singleFieldChange() {
                if (this.get('isSingleAutoSaving')) {
                    this.debounceTask('saveData', 5000);
                }
            },
            save: function save() {
                this.normalizeAndSetAnalytics(this.get('data.subject'), this.get('data'));
                this.attrs.save('save', false, false);
                // this.sendAction('save', false, false)
                this.sendAction('openFieldChanged', null);
                this.toggleProperty('isSingleEdit');
                this.set('isSsanVisible', false);
                this.attrs.cancelEdit();
            },


            // expandAll () {
            //   $('.analytics-accordion .collapse').collapse('show')
            // },
            //
            // collapseAll () {
            //   $('.analytics-accordion .collapse').collapse('hide')
            // },

            usePhrase: function usePhrase(phrase, field) {
                var fieldPath = 'model.' + field.get('name');
                var breaks = '';
                for (var i = 0; i <= phrase.get('breaks'); i++) {
                    breaks += '\n';
                }
                this.set('reRender', false);
                var previousVal = this.get(fieldPath);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));
                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            }
        }
    });
});