define('tm-common/components/x-preview-document/result-preview-dropdown', ['exports', 'tm-common/templates/components/x-preview-document/result-preview-dropdown', 'ember-lifeline/mixins/run'], function (exports, _resultPreviewDropdown, _run) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        layout: _resultPreviewDropdown.default,
        classNames: ['btn-group'],

        pageSize: 25,
        currentPage: 1,
        showDropDown: false,

        pageOffset: Ember.computed('pageSize', 'currentPage', function () {
            var pageSize = this.get('pageSize');
            var currentPage = this.get('currentPage');
            return pageSize * (currentPage - 1);
        }),
        totalPages: Ember.computed('pageSize', 'matchTotal', function () {
            var pageSize = this.get('pageSize');
            var matchTotal = this.get('matchTotal');

            return Math.ceil(matchTotal / pageSize);
        }),
        currentPageReset: Ember.observer('pageSize', 'currentMatchIdx', function () {
            var pageSize = this.get('pageSize');
            var currentMatchIdx = this.get('currentMatchIdx');

            this.set('currentPage', Math.ceil(currentMatchIdx / pageSize));
        }),

        actions: {
            changePage: function changePage() {
                var _this = this;

                this.runTask(function () {
                    _this.$().addClass('open');
                });
            }
        }
    });
});