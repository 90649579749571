define('tm-common/services/sc-channels', ['exports', 'phoenix'], function (exports, _phoenix) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        listeningChannels: [],
        session: Ember.inject.service(),

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        init: function init() {
            var token = this.get('session.data.authenticated.channels_token');
            var restHost = this.get('ENV').APP.restDestination.replace('http', 'ws');
            var socket = new _phoenix.Socket(restHost + '/channels/socket', { params: { token: token } });
            socket.connect();
            this.set('socket', socket);
        },
        joinChannel: function joinChannel(channelName, callback) {
            var channel = this.socket.channel(channelName);
            channel.join().receive('ok', function (resp) {
                console.log('Joined successfully to ' + channelName, resp);
            }).receive('error', function (resp) {
                console.log('Unable to join to ' + channelName, resp);
            });

            channel.on('publish', callback);
            this.get('listeningChannels').pushObject({ topic: channelName, channel: channel });
        },
        leaveChannel: function leaveChannel(channelName) {
            var channelRecord = this.get('listeningChannels').findBy('topic', channelName);
            if (!channelRecord) {
                return;
            }
            channelRecord.channel.leave();
            console.log('Left successfully from ' + channelName);
            this.get('listeningChannels').removeObject(channelRecord);
        }
    });
});