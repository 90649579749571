define('tm-common/models/phrase-case-type-group-matter-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        // Relationships
        caseTypeGroupId: attr('number'),
        caseTypeGroup: belongsTo('phrase-case-type-group', { async: true }),

        matterTypeId: attr('number'),
        matterType: belongsTo('matter-type', { async: true })
    });
});