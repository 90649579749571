define('tm-common/models/file', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        session: Ember.inject.service(),

        name: attr('string'),
        fileName: attr('string'),
        mimetype: attr('string'),
        size: attr('number'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),

        /**
         * calculate a friendly file size for display
         */
        friendlySize: Ember.computed('size', function () {
            var bytes = this.get('size');
            var decimals = 0;

            if (bytes === 0) {
                return '0 Byte';
            }
            var k = 1024; // Or 1 kilo = 1000
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
        }),

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        /**
         * return the url to hit to download the file
         */
        downloadURL: Ember.computed('id', function () {
            var ENV = this.get('ENV');
            var download = ENV.APP.documentDownloadURL.replace('documents', 'files');
            return download + '/' + this.get('id') + '/download?token=' + this.get('session.data.authenticated.token');
        })
    });
});