define('tm-common/helpers/should-show-end-date', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.shouldShowEndDate = shouldShowEndDate;
    function shouldShowEndDate(params) {
        return false;
    }

    exports.default = Ember.Helper.helper(shouldShowEndDate);
});