define('tm-common/components/subjects/profile/modules/non-jury/default-with-notes-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/non-jury/default-with-notes-module'], function (exports, _defaultWithNotesModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _defaultWithNotesModule.default,

        classNames: ['inplace-edit-profile-data'],

        notesField: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            var notesField = void 0;

            switch (field.get('value')) {
                case 'bankruptcy':
                    notesField = sortedMatterFields.findBy('value', 'bankruptcy_notes');
                    break;

                case 'dob':
                    notesField = sortedMatterFields.findBy('value', 'birth');
                    break;

                case 'civil':
                    notesField = sortedMatterFields.findBy('value', 'civil_notes');
                    break;

                case 'counties':
                    notesField = sortedMatterFields.findBy('value', 'county_notes');
                    break;

                case 'criminal':
                    notesField = sortedMatterFields.findBy('value', 'criminal_history');
                    break;

                case 'death':
                    notesField = sortedMatterFields.findBy('value', 'death_information');
                    break;

                case 'education':
                    notesField = sortedMatterFields.findBy('value', 'education_notes');
                    break;

                case 'employment':
                    notesField = sortedMatterFields.findBy('value', 'employment_notes');
                    break;

                case 'family':
                    notesField = sortedMatterFields.findBy('value', 'family_notes');
                    break;

                case 'marital':
                    notesField = sortedMatterFields.findBy('value', 'marital_notes');
                    break;

                case 'medical':
                    notesField = sortedMatterFields.findBy('value', 'medical_notes');
                    break;

                case 'relationship':
                    notesField = sortedMatterFields.findBy('value', 'relationship_notes');
                    break;

                case 'residential':
                    notesField = sortedMatterFields.findBy('value', 'residential_notes');
                    break;

                case 'social_media':
                    notesField = sortedMatterFields.findBy('value', 'social_media_notes');
                    break;

                case 'ssan':
                    notesField = sortedMatterFields.findBy('value', 'ssan_notes');
                    break;

                case 'traffic':
                    notesField = sortedMatterFields.findBy('value', 'traffic_notess');
                    break;
            }

            return notesField;
        })
    });
});