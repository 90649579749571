define('tm-common/initializers/extend-dropdown-direction', ['exports', 'ember-bootstrap/components/bs-dropdown'], function (exports, _bsDropdown) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() {
        _bsDropdown.default.reopen({
            init: function init() {
                this._super.apply(this, arguments);

                if (this.get('direction') === 'auto') {
                    this._autoDirection = true;
                }
            },


            actions: {
                openDropdown: function openDropdown() {
                    var _this = this;

                    this._super();

                    if (this._autoDirection) {
                        Ember.run.scheduleOnce('afterRender', function () {
                            // now check if we're near the end of the screen
                            var $dropdown = _this.$('.dropdown-menu');
                            if ($dropdown.length === 0) {
                                return;
                            }
                            // but allow for some padding
                            var bottomPadding = 10;

                            var scrollTop = Ember.$(window).scrollTop();
                            var topOffset = $dropdown.offset().top;
                            var height = $dropdown.height();
                            var relativeOffset = topOffset + height - scrollTop;

                            // and if we've hit the end
                            if (relativeOffset + bottomPadding > Ember.$(window).height()) {
                                _this.set('direction', 'up');
                            }
                        });
                    }
                },
                closeDropdown: function closeDropdown() {
                    this._super();

                    if (this._autoDirection) {
                        this.set('direction', 'down');
                    }
                }
            }
        });
    }

    exports.default = {
        name: 'modify-bootstrap',
        initialize: initialize
    };
});