define('tm-common/components/pdf-js/pdf-js', ['exports', 'ember-pdf-js/components/pdf-js', 'ember-resize/mixins/resize-aware'], function (exports, _pdfJs, _resizeAware) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _pdfJs.default.extend(_resizeAware.default, {
        /** monitor the change of height **/
        resizeWidthSensitive: true,
        resizeHeightSensitive: true,

        resizeService: Ember.inject.service('resize'),

        debouncedDidResize: function debouncedDidResize(width, height, evt) {
            this.send('resize');
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.send('resize');
        },


        actions: {
            resize: function resize() {
                var container = this.$('.pdfViewerContainer');
                container.height(window.innerHeight - container.offset().top);
            }
        }
    });
});