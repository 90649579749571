define('tm-common/components/sc-button', ['exports', 'ember-bootstrap/components/bs-button', 'ember-bootstrap/templates/components/bs-button'], function (exports, _bsButton, _bsButton2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _bsButton.default.extend({
        classNames: ['sc-btn'],
        layout: _bsButton2.default
    });
});