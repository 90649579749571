define('tm-common/components/read-more', ['exports', 'tm-common/templates/components/read-more', 'tm-common/helpers/nl-2br'], function (exports, _readMore, _nl2br) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _readMore.default,
        classNames: 'read-more',
        classNameBindings: ['isOpen:is-open:is-closed'],

        isOpen: false,
        maxLines: 2, // max number of lines to show

        textToShow: Ember.computed('text', 'isOpen', 'maxLines', function () {
            var sanitizedText = Ember.Handlebars.Utils.escapeExpression(this.get('text'));

            if (this.get('isOpen')) {
                sanitizedText = (0, _nl2br.nl2br)([sanitizedText]);
            } else {
                sanitizedText = (0, _nl2br.nl2br)([sanitizedText.split('\n').slice(0, this.get('maxLines')).join('\n')]);
            }

            return sanitizedText;
        }),

        hasMore: Ember.computed('text', 'maxLines', function () {
            var text = this.get('text');
            if (Ember.isEmpty(text)) {
                return false;
            }
            return this.get('text').split('\n').length > this.get('maxLines');
        }),

        openText: 'Read more',
        closeText: 'Close',
        toggleText: Ember.computed('openText', 'closeText', 'isOpen', function () {
            return this.get('isOpen') ? this.get('closeText') : this.get('openText');
        }),

        actions: {
            toggle: function toggle() {
                this.toggleProperty('isOpen');

                if (this.get('isOpen')) {
                    this.sendAction('onOpen');
                } else {
                    this.sendAction('onClose');
                }
            }
        }
    });
});