define('tm-common/components/timelines/new-timeline-event', ['exports', 'tm-common/mixins/crud/error', 'tm-common/mixins/timeline-event-mixin', 'tm-common/mixins/timeline-date-fields-enablement-mixin', 'tm-common/templates/components/timelines/new-timeline-event'], function (exports, _error2, _timelineEventMixin, _timelineDateFieldsEnablementMixin, _newTimelineEvent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error2.default, _timelineEventMixin.default, _timelineDateFieldsEnablementMixin.default, {
        layout: _newTimelineEvent.default,
        timelineSentence: null,
        matter: null,
        firstScreen: true,

        didReceiveAttrs: function didReceiveAttrs() {
            this.initFrequentLocations();
            this.set('selectedCountry', this.get('countries').findBy('id', this.get('ENV.APP.tags.USACountry').toString()));
            this.set('documentSource', this.get('documentTypes'));
            this.initializeComponent();
            var selectedCategory = this.get('selectedCategory');
            if (selectedCategory) {
                this.send('selectEventCategory', selectedCategory);
            }
        },

        timelineEventParentsGrid: Ember.computed('timelineEventParents.[]', function () {
            // add Empty categories to align the item in the flex grid
            var tep = this.get('timelineEventParents').toArray();
            var results = [].concat(tep);
            var extraBlanks = tep.get('length') % 4;
            if (extraBlanks !== 0) {
                // add blank events at the end
                for (var i = 0; i < extraBlanks; i++) {
                    results.push({ blank: true });
                }
            }
            return results;
        }),

        actions: {
            saveNewEvent: function saveNewEvent() {
                var _this = this;

                // validate meta keys
                if (!this.validateEventRules()) {
                    return;
                }
                var event = this.get('newEvent');
                event.matter = this.get('matter');
                event.timelineEventType = this.get('currentEventType');

                event.startYear = this.getNumberValue(this.get('startYear'));
                event.startMonth = this.getNumberValue(this.get('startMonth'));
                event.startDay = this.getNumberValue(this.get('startDay'));
                event.startPrecision = this.getNumberValue(this.get('startPrecision'));

                if (this.get('hasDuration')) {
                    event.endYear = this.getNumberValue(this.get('endYear'));
                    event.endMonth = this.getNumberValue(this.get('endMonth'));
                    event.endDay = this.getNumberValue(this.get('endDay'));
                    event.endPrecision = this.getNumberValue(this.get('endPrecision'));
                }

                event.country = this.get('selectedCountryName');
                event.state = this.get('selectedStateName');
                event.county = this.get('selectedCountyName');
                event.city = this.get('selectedCityName');
                event.docType = this.get('documentSourceName');
                // save the custom description if the user has overwritten it
                if (this.get('isCustomDescription')) {
                    event.isCustomDescription = 1;
                    event.description = this.get('timelineSentence');
                } else {
                    event.isCustomDescription = 0;
                }

                var newEvent = this.get('store').createRecord('timeline-event', event);

                // create the meta resources objects

                var metaFields = this.get('metaFields');
                var rules = this.get('currentEventRules');
                var metaResources = [];
                if (rules && 'fields' in rules) {
                    rules.fields.forEach(function (field) {
                        // only create a resource meta if a value exists
                        if (metaFields.get(field.key)) {
                            metaResources.push({
                                key: field.key,
                                value: metaFields.get(field.key)
                            });
                        }
                    });
                }
                newEvent.set('metaResources', JSON.stringify(metaResources));
                // add subject id
                newEvent.set('subjectId', this.get('subject.id'));

                newEvent.save().then(function (result) {
                    _this.get('notify').success('Event has been saved successfully');
                    _this.closeThisForm();
                    _this.set('selectedCategory', null);
                    return _this.attrs.refresh();
                }, function (_error) {
                    _this.validationReport(newEvent);
                });
            },

            setDocType: function setDocType(docType) {
                this.set('documentSourceName', docType.get('value'));
            }
        }
    });
});