define('tm-common/components/timelines/multiple-event-row', ['exports', 'tm-common/mixins/crud/error', 'tm-common/mixins/timeline-event-mixin', 'tm-common/templates/components/timelines/multiple-event-row'], function (exports, _error, _timelineEventMixin, _multipleEventRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, _timelineEventMixin.default, {
        layout: _multipleEventRow.default,
        event: null,
        classNames: ['row', 'mb-10', 'ml-0', 'mr-0'],

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        selectedStateChanged: Ember.observer('selectedState', function () {
            var event = this.get('event');
            var selectedState = this.get('selectedState');
            if (selectedState) {
                event.set('state', selectedState.get('name'));
            } else {
                event.set('state', '');
            }
        })
    });
});