define('tm-common/models/user-viewed-resource', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        // relationships
        resource: belongsTo('resource', {
            async: false
        }),
        user: belongsTo('user', {
            async: false
        })
    });
});