define('tm-common/components/sc-sidebar', ['exports', 'tm-common/templates/components/sc-sidebar'], function (exports, _scSidebar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _scSidebar.default,
        tagName: '',
        sidebarExpanded: true,
        actions: {
            toggleSidebar: function toggleSidebar() {
                this.toggleProperty('sidebarExpanded');
            }
        }
    });
});