define('tm-common/mixins/random-color-generator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        goldenRatioConjugate: 0.618033988749895,
        convertHsvToRgb: function convertHsvToRgb(h, s, v) {
            var r = void 0,
                g = void 0,
                b = void 0;

            var i = Math.floor(h * 6);
            var f = h * 6 - i;
            var p = v * (1 - s);
            var q = v * (1 - f * s);
            var t = v * (1 - (1 - f) * s);

            switch (i % 6) {
                case 0:
                    r = v;
                    g = t;
                    b = p;
                    break;
                case 1:
                    r = q;
                    g = v;
                    b = p;
                    break;
                case 2:
                    r = p;
                    g = v;
                    b = t;
                    break;
                case 3:
                    r = p;
                    g = q;
                    b = v;
                    break;
                case 4:
                    r = t;
                    g = p;
                    b = v;
                    break;
                case 5:
                    r = v;
                    g = p;
                    b = q;
                    break;
            }

            return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
        },
        getRandomColor: function getRandomColor(seed, brighter) {
            var h = void 0;
            if (!seed) {
                h = Math.random();
            } else {
                h = seed;
            }
            h += this.get('goldenRatioConjugate');
            h %= 1;

            var s = 0.4;
            var v = 0.8;

            if (brighter) {
                s = 0.5;
                v = 0.95;
            }

            var color = this.convertHsvToRgb(h, s, v);
            return 'rgb(' + color[0] + ', ' + color[1] + ', ' + color[2] + ')';
        }
    });
});