define('tm-common/models/summary-case-statistic', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        jurorStat: attr(),
        researchCompleteResearcher: attr('number'),
        researchCompleteReviewer: attr('number'),
        totalJurors: attr('number'),
        totalPool: attr('number'),
        totalStarted: attr('number'),
        totalStruck: attr('number'),
        locations: attr(),
        selectedJurors: attr('number'),
        totalPublished: attr('number')
    });
});