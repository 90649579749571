define('tm-common/models/resource-has-resource', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        parentId: attr('string'),
        childId: attr('string'),

        // relationships
        documents: hasMany('document', { async: true }),
        subjects: hasMany('subject', { async: true }),
        hyperlinks: hasMany('hyperlink', { async: true })
    });
});