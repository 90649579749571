define('tm-common/models/task-has-note', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        resourceId: attr('number'),

        note: belongsTo('note', { async: false }),
        task: belongsTo('task', { async: false, inverse: 'notes' })
    });
});