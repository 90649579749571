define('tm-common/models/report-component', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        title: attr(),
        text: attr(),
        matter: belongsTo('matter', { async: true }),
        reportComponentType: belongsTo('report-component-type', { async: true })
    });
});