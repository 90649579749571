define('tm-common/components/pdf-js/toolbar', ['exports', 'ember-pdf-js/components/pdf-js-toolbar', 'tm-common/templates/components/pdf-js/toolbar'], function (exports, _pdfJsToolbar, _toolbar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _pdfJsToolbar.default.extend({
        layout: _toolbar.default,

        highlightAll: true,

        actions: {
            doNothing: function doNothing() {}
        }
    });
});