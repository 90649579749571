define('tm-common/components/x-upload', ['exports', 'ember-uploader/uploaders/uploader', 'tm-common/templates/components/x-upload', 'tm-common/helpers/show-filesize', 'ember-component-inbound-actions/inbound-actions'], function (exports, _uploader, _xUpload, _showFilesize, _inboundActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var MAX_UPLOAD_SIZE = 5120 * 1024 * 1024; // 5GB
    var MAX_IMAGE_SIZE = 3 * 1024 * 1024; // 3MB

    var allExtensions = ['pdf', 'png', 'jpeg', 'tiff', 'doc', 'docx', 'jpg', 'gif', 'bmp', 'jp2', 'tif', 'webp', 'ppt', 'pptx', 'pps', 'ppsx', 'key', 'psd', 'mp3', 'mp4', 'm4a', 'ogg', 'mov', 'wmv', 'avi', 'mpg', 'ogv', 'xls', 'xlsx', 'xlsm', 'swf', 'txt', 'wmf', 'wav', 'wav', 'xml', 'xpm', 'csv', 'odt', 'odc', 'odp', 'odg', 'mpp', 'zip', 'rar', '7z', 'gz', 'ptx', 'mhtml', 'webm', 'asf', 'wma'];
    var receiptExtensions = ['pdf', 'png', 'jpeg', 'gif', 'jpg'];
    var imageExtensions = ['png', 'jpeg', 'jpg'];
    /**
     * Component intended to replace document-uploader
     * it will have all the logic itself, without
     * the need of external dependencies like the mixin
     * or functions in controllers.
     *
     * To communicate with the outside world, it's just
     * going to send actions to be catched by the
     * controller or router where is used.
     *
     * It will have some options depending where is
     * used, in order to ask for more information
     *
     * i.e.
     *
     * - Automatically link uploads to the subject as argument
     *
     * {{x-upload matter=matter subject=subject documentTypes=listService.fileTypes
     *   cancelUpload='cancelUpload'
     *   beforeUpload='beforeUpload'
     *   afterUpload='afterUpload'
     *   errorUpload='errorUpload'}}
     *
     * - Ask for subject before upload
     *
     * {{x-upload matter=model documentTypes=listService.fileTypes
     *   cancelUpload='cancelUpload'
     *   beforeUpload='beforeUpload'
     *   afterUpload='afterUpload'
     *   errorUpload='errorUpload'}}
     *
     * - For billable group edits:
     *
     * {{x-upload matter=model documentTypes=false subject=false endPoint='receipts'
     *   cancelUpload='cancelUpload'
     *   beforeUpload='beforeUpload'
     *   afterUpload='afterUpload'
     *   errorUpload='errorUpload'}}
     */
    exports.default = Ember.Component.extend(_inboundActions.default, {
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        counts: Ember.inject.service(),
        notify: Ember.inject.service(),
        metrics: Ember.inject.service(),

        hideTitle: false,
        jsStyle: false,

        showCancelButton: true,
        showUploadSize: true,

        skipWarningCheck: false,

        isReceipt: false,
        isOnlyImages: false,

        pasted: false,
        /**
         * used to get configuration from the application
         *
         * @property
         */
        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        isClientPortal: Ember.computed('clientPortal', function () {
            if (this.get('clientPortal')) {
                return true;
            }

            return false;
        }),

        /** component linking properties */
        filesDisplayComponent: 'x-upload.body-list',

        /**
         * set the right layout to be rendered with this component
         */
        layout: _xUpload.default,

        /**
         * List of files ready to be uploaded
         *
         * @property
         * @type Array
         */
        filesToUpload: [],

        /**
         * @property
         * @type boolean
         *
         * allow hide the upload button to send the upload action from outside
         */
        hideUploadButton: false,

        /**
         * @property
         * @type boolean
         *
         * not show as box to show as a unit when replacing a file
         */
        noBox: false,

        /**
         * check if the upload button should be shown
         *
         * @property
         * @type boolean
         */
        showUploadButton: Ember.computed('uploader', 'filesToUpload.[]', 'hideUploadButton', function () {
            if (this.get('hideUploadButton')) {
                return false;
            } else {
                return Ember.isPresent(this.get('uploader')) && this.get('filesToUpload.length') > 0;
            }
        }),

        /**
         * @property
         *
         * check if documentTypes and subject is false to
         * not show any of that info like in billable/edit
         */
        shouldAskForInfo: Ember.computed('subject', 'documentTypes', function () {
            return !(this.get('subject') === false && this.get('documentTypes') === false);
        }),

        /**
         * case where document should be uploaded to
         *
         * @property
         * @type model/Matter
         */
        matter: null,

        /**
         * list of document types to show on the list
         *
         * @property
         */
        documentTypes: [],

        /**
         * subject that document should be linked to automatically
         * without asking, if not provided the component will show
         * a list of subjects on the provided case
         *
         * @property
         * @type model/Subject
         */
        subject: null,

        /**
         * if the user can upload multiple files
         */
        allowMultiple: true,

        /**
         * check if subject is set so we don't ask
         */
        askForSubject: true,

        /**
         * check if subject is false, so is not related to subject
         */
        notRelatedToSubject: Ember.computed('subject', function () {
            return this.get('subject') === false;
        }),

        /**
         * check if we need to ask for documentType
         */
        askForDocumentType: Ember.computed('documentTypes', function () {
            return this.get('documentTypes') !== false;
        }),

        /**
         * list of subjects to be show to select one
         * @property
         * @type Array
         */
        subjects: [],

        /**
         * fields to sort by the subjects
         */
        subjectsSortBy: ['lastName', 'firstName'],
        /**
         * list of subjectes sorted by subjectsSortBy
         */
        sortedSubjects: Ember.computed.sort('subjects', 'subjectsSortBy'),

        /**
         * object in charge of the upload details
         * communication with server, send of the form data, etc
         *
         * @property
         * @type Uploader
         */
        uploader: null,

        /**
         * progress bar size / 100%
         */
        progress: 0,

        isPublic: false,
        isPrivate: false,

        /**
         * computed for style the progressbar
         */
        progressStyle: Ember.computed('progress', function () {
            var style = 'width: ' + this.get('progress') + '%;';
            return Ember.String.htmlSafe(style);
        }),

        /**
         * Fetch subjects if there is no one provided
         *
         * @method
         */
        initializeComponent: Ember.on('init', function () {
            var _this = this;

            var self = this;
            var store = this.get('store');

            Ember.run.next(function () {
                _this.set('filesToUpload', []);
                _this.setupDropZone();
            });

            if (Ember.isEmpty(this.get('subject'))) {
                store.query('subject', {
                    matter_id: this.get('matter.id')
                }).then(function (subjects) {
                    _this.set('subjects', subjects);
                });
            }

            // Initialize the uploader component

            var uploader = _uploader.default.create({
                url: this.get('uploadURL')
            });

            uploader.on('didUpload', function () {
                Ember.run(function () {
                    if (!self.get('isDestroyed') && !self.get('isDestroying')) {
                        self.set('filesToUpload', []);
                        self.set('progress', 0);
                    }
                });
            });

            uploader.on('progress', function (e) {
                Ember.run(function () {
                    if (!self.get('isDestroyed') && !self.get('isDestroying')) {
                        self.set('progress', e.percent);
                    }
                });
            });
            this.set('uploader', uploader);

            uploader.set('ajaxSettings', this.ajaxSettings());
        }),

        setupDropZone: function setupDropZone() {
            var _this2 = this;

            var xupload = this;
            var hoverClassName = 'hover';

            var pasteListener = function pasteListener(event) {
                var found = false;
                if (xupload.isDestroying || xupload.isDestroyed) return;
                var clipboardData = event.clipboardData;
                Array.prototype.forEach.call(clipboardData.types, function (type, i) {
                    var file, reader;
                    if (found) {
                        return;
                    }
                    if (type.match(options.matchType) || clipboardData.items[i].type.match(options.matchType)) {
                        var fileInput = document.querySelector('input[type=file]');
                        fileInput.files = clipboardData.files;
                        options.callback(fileInput);
                        file = clipboardData.items[i].getAsFile();
                        reader = new FileReader();
                        reader.onload = function (evt) {
                            document.querySelector('#paste-container').src = evt.target.result;
                            return file;
                        };
                        reader.readAsDataURL(file);
                        return found = true;
                    }
                });
            };

            var dragEnterListener = function dragEnterListener(e) {
                e.preventDefault();
                dropZone.classList.add(hoverClassName);
            };
            var dragLeaveListener = function dragLeaveListener(e) {
                e.preventDefault();
                dropZone.classList.remove(hoverClassName);
            };
            var dropListener = function dropListener(e) {
                e.preventDefault();
                if (xupload.isDestroying || xupload.isDestroyed) return;
                dropZone.classList.remove(hoverClassName);

                var fileInput = document.querySelector('input[type=file]');
                fileInput.files = e.dataTransfer.files;

                if (isOneImage(e.dataTransfer)) {
                    // If only one image, show as if it was pasted
                    options.callback(fileInput);
                    var file = e.dataTransfer.files[0];
                    var reader = new FileReader();
                    reader.onload = function (evt) {
                        document.querySelector('#paste-container').src = evt.target.result;
                        return file;
                    };
                    reader.readAsDataURL(file);
                } else {
                    xupload.get('fileField').send('newFiles', { target: fileInput });
                    xupload.set('pasted', false);
                }
            };
            var options = {
                matchType: /image.*/,
                callback: function callback(fileInput) {
                    _this2.get('fileField').send('newFiles', { target: fileInput });
                    _this2.set('pasted', true);
                }
            };
            var isOneImage = function isOneImage(dataTransfer) {
                var files = dataTransfer.files;
                var first = dataTransfer.items[0];
                return files.length === 1 && first.type.match(options.matchType);
            };

            var dropZone = document.body;

            if (dropZone) {
                document.removeEventListener('paste', pasteListener);
                dropZone.removeEventListener('dragenter', dragEnterListener);
                dropZone.removeEventListener('dragover', dragEnterListener);
                dropZone.removeEventListener('dragleave', dragLeaveListener);
                dropZone.removeEventListener('drop', dropListener);

                // Handle drag* events to handle style
                // Add the css you want when the class "hover" is present
                document.addEventListener('paste', pasteListener);
                dropZone.addEventListener('dragenter', dragEnterListener);
                dropZone.addEventListener('dragover', dragEnterListener);
                dropZone.addEventListener('dragleave', dragLeaveListener);
                dropZone.addEventListener('drop', dropListener);
            }
        },


        ajaxSettings: function ajaxSettings(url, params, method) {
            var env = this.get('ENV.environment');
            var cikey = this.get('ENV.cikey');

            return function (url, params, method) {
                var self = this;
                return {
                    url: url,
                    type: method || 'POST',
                    contentType: false,
                    processData: false,
                    xhr: function xhr() {
                        var xhr = Ember.$.ajaxSettings.xhr();
                        xhr.upload.onprogress = function (e) {
                            self.didProgress(e);
                        };
                        self.one('isAborting', function () {
                            xhr.abort();
                        });
                        return xhr;
                    },
                    data: params,
                    beforeSend: function beforeSend(request) {
                        if (env === 'test') {
                            request.setRequestHeader('X-CI-KEY', cikey);
                        }
                    }
                };
            };
        },

        totalUploadSize: Ember.computed('filesToUpload.[]', function () {
            var filesToUpload = this.get('filesToUpload');
            return filesToUpload.reduce(function (accumulator, file) {
                return accumulator + file.size;
            }, 0);
        }),

        /**
         * action name used to notify the controller
         * about the component being ready to upload
         * and called just before upload
         *
         * @event
         */
        beforeUpload: '',

        //  this.sendAction('beforeUpload', this.get('filesToUpload'));

        /**
         * action name used to notify the controller
         * about the component finished the upload
         *
         * @event
         */
        afterUpload: '',

        /**
         * action name used to notify the controller
         * in case of error during upload
         *
         * @event
         */
        errorUpload: '',

        /**
         * @property
         *
         * endpoint to hit for upload documents, default: documents
         */
        endPoint: 'documents',

        /**
         * API upload url
         *
         * @property
         */
        uploadURL: Ember.computed('ENV', function () {
            var ENV = this.get('ENV');
            return ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/' + this.get('endPoint') + '?token=' + this.get('session.data.authenticated.token');
        }),

        isExtensionValid: function isExtensionValid(ext) {
            if (this.get('isOnlyImages')) {
                return imageExtensions.includes(ext);
            } else if (this.get('isReceipt')) {
                return receiptExtensions.includes(ext);
            } else {
                return allExtensions.includes(ext);
            }
        },
        isSizeValid: function isSizeValid(upload) {
            if (this.get('isOnlyImages')) {
                return upload <= MAX_IMAGE_SIZE;
            } else {
                return upload <= MAX_UPLOAD_SIZE;
            }
        },
        getFileExtension: function getFileExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },


        /** *************** ACTIONS of the Component ********************/
        actions: {
            cancelUpload: function cancelUpload() {
                if (this.isDestroyed || this.isDestroying) {
                    return;
                }
                this.set('filesToUpload', []);
                this.set('progress', 0);
                this.sendAction('cancelUpload');
            },
            deleteFile: function deleteFile(file) {
                this.get('filesToUpload').removeObject(file);
            },
            upload: function upload() {
                var _this3 = this;

                var self = this;
                var filesToUpload = this.get('filesToUpload');

                if (filesToUpload === null || filesToUpload.length === 0) {
                    return;
                }

                var uploadSize = filesToUpload.reduce(function (accumulator, file) {
                    return accumulator + file.size;
                }, 0);
                if (!this.isSizeValid(uploadSize)) {
                    this.get('notify').warning('Documents exceed the file size limit on the server, please reduce the number of files or the file size to under ' + (this.get('isOnlyImages') ? (0, _showFilesize.formatFilesize)(MAX_IMAGE_SIZE) : (0, _showFilesize.formatFilesize)(MAX_UPLOAD_SIZE)));
                    return;
                }
                // check if extension is valid
                var invalidExtensions = 0;

                filesToUpload.forEach(function (file) {
                    var ext = _this3.getFileExtension(file.file.name);
                    if (!ext || !_this3.isExtensionValid(ext.toLowerCase())) {
                        var message = 'File ' + file.file.name + ' has an invalid extension';
                        if (_this3.get('isReceipt')) {
                            message += ', only allowed: ' + receiptExtensions.join(', ');
                        }
                        if (_this3.get('isOnlyImages')) {
                            message += ', only allowed: ' + imageExtensions.join(', ');
                        }
                        _this3.get('notify').warning(message);
                        invalidExtensions++;
                    }
                });
                if (invalidExtensions > 0) {
                    this.get('notify').error(invalidExtensions + ' files have an invalid file extension.');
                    return;
                }

                if (!this.get('skipWarningCheck')) {
                    var acceptedWarnings = 0;
                    var pdfFiles = 0;
                    filesToUpload.forEach(function (file) {
                        var ext = _this3.getFileExtension(file.file.name);
                        if (ext !== 'pdf') {
                            return;
                        }
                        pdfFiles++;
                        if (file.acceptWarning) {
                            acceptedWarnings++;
                        }
                    });
                    if (pdfFiles > acceptedWarnings) {
                        this.get('notify').warning('Please check that all warnings are accepted');
                        return;
                    }
                }

                self.sendAction('beforeUpload', filesToUpload);

                var extraParameters = {
                    matters_id: this.get('matter.id')
                };
                filesToUpload.forEach(function (file, index) {
                    extraParameters['name_' + index] = file.get('name');
                    extraParameters['notes_' + index] = file.get('notes');
                    extraParameters['cant_bundle_' + index] = file.get('cantBundle');
                    extraParameters['description_' + index] = Ember.isPresent(file.get('description')) ? file.get('description') : '';
                    if (!self.get('askForSubject')) {
                        file.set('subject', self.get('subject'));
                    }

                    if (self.get('isClientPortal')) {
                        extraParameters['docType_' + index] = 'CLIENT_UPLOAD';
                        extraParameters['viewable_' + index] = 1;
                    } else {
                        extraParameters['docType_' + index] = file.get('docType');
                        extraParameters['viewable_' + index] = file.get('viewable');
                        extraParameters['do_not_use_' + index] = file.get('doNotUse') ? 1 : 0;
                        extraParameters['ocr_and_index_' + index] = file.get('ocrAndIndex') ? 1 : 0;
                        extraParameters['reportId_' + index] = Ember.isPresent(file.get('report.id')) ? file.get('report.id') : '';
                        extraParameters['tabNumber_' + index] = Ember.isPresent(file.get('tabNumber')) ? parseInt(file.get('tabNumber'), 10) : '';
                    }

                    var scrapeId = file.get('scrapeId');
                    if (scrapeId) {
                        extraParameters['scrape_id_' + index] = scrapeId;
                    }

                    extraParameters['subject_id_' + index] = file.get('subject.id');
                    extraParameters['additional_subjects_ids_' + index] = file.get('additionalSubjects').mapBy('id');
                    // TODO here we need to send an array of subject ids related to the document
                    extraParameters['overwrite_document_id_' + index] = file.get('overwriteDocumentId'); // This is now set using the document-edit-table beforeUpload

                    var tags = file.get('tags');
                    if ('tags' in file && Ember.isPresent(tags)) {
                        extraParameters['tag_ids_' + index] = tags.mapBy('id');
                    }
                    if (Ember.isPresent(file.get('extra'))) {
                        for (var key in file.get('extra')) {
                            extraParameters[key + '_' + index] = file.get('extra.' + key);
                        }
                    }
                });

                this.get('uploader').upload(filesToUpload.map(function (file) {
                    Ember.get(_this3, 'metrics').trackEvent({
                        category: 'Document',
                        action: 'upload',
                        label: file.get('name')
                    });
                    return file.file;
                }), extraParameters).then(function (response) {
                    if (Ember.typeOf(response) === 'object' && 'errors' in response && response.errors.length > 0) {
                        self.sendAction('errorUpload', response);
                    } else {
                        self.sendAction('afterUpload', response);
                    }
                }, function (error) {
                    self.sendAction('errorUpload', error);
                });
            },
            remove: function remove(fileToRemove) {
                this.get('filesToUpload').removeObject(fileToRemove);
            }
        }
    });
});