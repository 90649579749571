define('tm-common/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        title: attr('string'),
        body: attr('string'),
        read: attr('number', { defaultValue: 0 }),
        objectType: attr('string'),
        objectId: attr('number'),
        createdOn: attr('estdate'),
        seenOn: attr('estdate'),
        app: attr('string'),

        user: belongsTo('user', { async: true }),
        matter: belongsTo('matter', { async: true }),
        notificationType: belongsTo('notification-type'),

        metadata: attr('string'),

        jsonMetadata: Ember.computed('metadata', function () {
            return JSON.parse(this.get('metadata'));
        }),

        actionLabel: Ember.computed('objectType', function () {
            var objectType = this.get('objectType');
            var text = null;
            switch (objectType) {
                case 'task':
                    text = 'View Task';
                    break;
            }
            return text;
        })
    });
});