define('tm-common/components/subjects/analytics-modules/socnet-module', ['exports', 'tm-common/mixins/analytics-util', 'tm-common/templates/components/subjects/analytics-modules/socnet-module'], function (exports, _analyticsUtil, _socnetModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _socnetModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),
        isPortal: Ember.computed('ENV', function () {
            var ENV = this.get('ENV');

            return ENV.modulePrefix === 'sc-portal' || ENV.modulePrefix === 'js2-client';
        }),

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        noneAboveApplyOption: Ember.computed('data.socnetOptions.socnetOtherOptions.[]', function () {
            var socnetOther = this.get('data.socnetOptions.socnetOtherOptions');

            return socnetOther.findBy('shortName', 'none_above_apply');
        }),

        noThemesOption: Ember.computed('data.socnetOptions.socnetThemesOptions.[]', function () {
            var socnetThemes = this.get('data.socnetOptions.socnetThemesOptions');

            return socnetThemes.findBy('shortName', 'no_socnet_themes');
        }),

        otherThemesOption: Ember.computed('data.socnetOptions.socnetThemesOptions.[]', function () {
            var socnetThemes = this.get('data.socnetOptions.socnetThemesOptions');

            return socnetThemes.findBy('shortName', 'socnet_themes_other');
        }),

        noTonesOption: Ember.computed('data.socnetOptions.socnetTonesOptions.[]', function () {
            var socnetTones = this.get('data.socnetOptions.socnetTonesOptions');

            return socnetTones.findBy('shortName', 'no_socnet_tones');
        }),

        otherTonesOption: Ember.computed(
        // eslint-disable-next-line ember/use-brace-expansion
        'data.socnetOptions.socnetThemesOptions.[]', 'data.socnetOptions.socnetTonesOptions', function () {
            var socnetTones = this.get('data.socnetOptions.socnetTonesOptions');

            return socnetTones.findBy('shortName', 'socnet_tones_other');
        }),

        hiddenThemesGreaterThan3: Ember.computed('data.socnetOptions.socnetThemesHiddenOptions.@each.checked', function () {
            var socnetThemes = this.get('data.socnetOptions.socnetThemesHiddenOptions');

            return socnetThemes.filterBy('checked', true).length > 3;
        }),

        socnetIndividualNotes: Ember.computed('data.subject.socnetNote', function () {
            var socnetNote = this.get('data.subject.socnetNote');

            return socnetNote ? socnetNote.split('\n') : [];
        }),

        areAllLinkOfTypeNone: Ember.computed(
        // eslint-disable-next-line ember/use-brace-expansion
        'data.subject.hyperlinks.[]', 'data.subject.hyperlinks.@each.viewable', 'data.subject.hyperlinks.@each.type', function () {
            return this.calculateAllLinkOfTypeNone(this.get('data.subject.hyperlinks'));
        }),

        profileCount: Ember.computed(
        // eslint-disable-next-line ember/use-brace-expansion
        'data.subject.hyperlinks.[]', 'data.subject.hyperlinks.@each.viewable', 'data.subject.hyperlinks.@each.type', function () {
            return this.calculateProfileCount(this.get('data.subject.hyperlinks'));
        })
    });
});