define('tm-common/models/collection', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        description: attr('string'),
        budget: attr('number'),
        ownedBy: belongsTo('employee', { async: true }),
        reportComponent: belongsTo('report-component', { async: true }),
        collectionColumns: hasMany('collection-column'),

        getMatterItems: (0, _emberApiActions.memberAction)({
            path: 'matter_items',
            type: 'get',
            urlType: 'findRecord'
        }),
        hasAccess: (0, _emberApiActions.memberAction)({
            path: 'has_access',
            type: 'get',
            urlType: 'findRecord'
        }),
        moveMattersToCollection: (0, _emberApiActions.memberAction)({
            path: 'move-matters-to-collection',
            type: 'put'
        }),
        getCommonColumns: (0, _emberApiActions.collectionAction)({
            path: 'common_columns',
            type: 'get',
            urlType: 'findRecord'
        }),
        copyFrom: (0, _emberApiActions.memberAction)({
            path: 'copy-from',
            type: 'put'
        })
    });
});