define('tm-common/models/matter-has-workflow', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        enabled: attr('number'),
        weight: attr('number'),
        estHours: attr('number'),

        // relationships
        matter: belongsTo('matter', { async: false }),
        workflow: belongsTo('workflow', { async: true }),
        simpleWorkflows: hasMany('simple-workflow', { async: true }),

        /**
         * BEGIN BOOLEAN CALCS
         *
         * a series of booleans to describe if a workflow bitmask is enabled
         * for a particular case
         *
         * useful for template logic
         */
        status: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 1);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 1;
                } else {
                    enabled &= ~1;
                }
                this.set('enabled', enabled);
                return value;
            }
        }),
        assigned: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 2);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 2;
                } else {
                    enabled &= ~2;
                }
                this.set('enabled', enabled);
                return value;
            }
        }),
        comments: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 4);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 4;
                } else {
                    enabled &= ~4;
                }
                this.set('enabled', enabled);
                return value;
            }
        }),
        hours: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 8);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 8;
                } else {
                    enabled &= ~8;
                }
                this.set('enabled', enabled);
                return value;
            }
        }),
        deadline: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 16);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 16;
                } else {
                    enabled &= ~16;
                }
                this.set('enabled', enabled);
                return value;
            }
        })
        /**
         * END BOOLEAN CALCS
         */
    });
});