define('tm-common/components/subject-field-notes-value', ['exports', 'tm-common/templates/components/subject-field-notes-value'], function (exports, _subjectFieldNotesValue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _subjectFieldNotesValue.default,
    tagName: ''
  });
});