define('tm-common/services/permissions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service(),

        /**
         * @param requiredPermission array of strings with permissions to check
         */
        check: function check(requiredPermissions) {
            var userPermissions = this.get('session.data.authenticated.permissions');

            // For auth/login
            if (typeof userPermissions === 'undefined') {
                return true;
            }

            var satisfiedPermissions = 0;
            requiredPermissions.forEach(function (p) {
                if (Ember.isPresent(userPermissions.findBy('name', p))) {
                    satisfiedPermissions++;
                }
            });

            return satisfiedPermissions === requiredPermissions.length;
        },


        /**
         * @param permissionsList array of strings with some permissions to check
         *
         * @return boolean true if some of the permission on the list is satisfiable
         */
        checkAny: function checkAny(permissionsList) {
            var _this = this;

            return permissionsList.any(function (permission) {
                return _this.check([permission]);
            });
        }
    });
});