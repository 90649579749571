define('tm-common/components/subjects/profile/edit-modal', ['exports', 'tm-common/templates/components/subjects/profile/edit-modal'], function (exports, _editModal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _editModal.default,

        data: Ember.computed('analyticsFormsData', function () {
            var data = this.get('analyticsFormsData');
            if (!data.editMode) {
                data.editMode = true;
            }
            return data;
        })
    });
});