define('tm-common/components/subject-custom-field-edit', ['exports', 'tm-common/templates/components/subject-custom-field-edit'], function (exports, _subjectCustomFieldEdit) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _subjectCustomFieldEdit.default,
        state: 'isEditing' // can be 'isEditing'
    });
});