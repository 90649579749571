define('tm-common/models/receivable-note', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        note: attr('string'),
        createdOn: attr('estdate'),

        firm: belongsTo('firm'),
        createdBy: belongsTo('user'),
        receivableNoteHasInvoceOutputs: hasMany('receivable-note-has-invoice-output')
    });
});