define('tm-common/components/sc-radio-button', ['exports', 'tm-common/templates/components/sc-radio-button', 'ember-radio-button/components/radio-button'], function (exports, _scRadioButton, _radioButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _radioButton.default.extend({
        layout: _scRadioButton.default,
        checkedClass: 'checked'
    });
});