define('tm-common/components/task-button', ['exports', 'tm-common/templates/components/task-button'], function (exports, _taskButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _taskButton.default,

        tagName: 'button',

        attributeBindings: ['type', 'disabled'],
        classNameBindings: ['task.isRunning:running'],

        idleText: 'Save',
        runningText: 'Saving..',

        onInit: Ember.on('init', function () {
            if (this.get('disabled')) {
                this.set('disabled', 'disabled');
            }

            (false && !(this.get('task')) && Ember.assert('You have to provide a task to `task-button`', this.get('task')));


            var runningClass = this.get('runningClass');

            if (runningClass) {
                this.set('classNameBindings', ['task.isRunning:' + runningClass]);
            }
        }),

        text: Ember.computed('task.isRunning', function () {
            var idleText = this.get('idleText');
            var runningText = this.get('runningText');

            if (runningText && this.get('task.isRunning')) {
                return runningText;
            } else {
                return idleText;
            }
        })
    });
});