define('tm-common/models/matter-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),

        // relationships
        matterHasTag: hasMany('matter-has-tag', {
            async: false
        })
    });
});