define('tm-common/helpers/do-nothing', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        compute: function compute() {
            return function () {};
        }
    });
});