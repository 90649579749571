define('tm-common/components/bs-popover-singleton', ['exports', 'ember-bootstrap/components/bs-popover', 'tm-common/templates/components/bs-popover-singleton'], function (exports, _bsPopover, _bsPopoverSingleton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _bsPopover.default.extend({
        layout: _bsPopoverSingleton.default,
        popover: Ember.inject.service(),

        visible: Ember.computed('popover.currentComponent', function () {
            return this === this.get('popover.currentComponent');
        }),

        toggle: function toggle(e) {
            if (e) {
                this.get('inState').toggleProperty('click');
                if (this.get('inState.showHelp')) {
                    this.get('popover').active(this);
                } else {
                    this.get('popover').close();
                }
            } else {
                if (this.get('showHelp')) {
                    this.get('popover').close();
                } else {
                    this.get('popover').active(this);
                }
            }
        },


        _watchVisible: Ember.observer('visible', function () {
            if (this.get('visible')) {
                this.show();
                this.set('inState.click', true);
            } else {
                this.hide();
                this.set('inState.click', false);
            }
        }),

        actions: {
            closePopover: function closePopover() {
                this.get('popover').close();
            }
        }
    });
});