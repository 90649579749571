define('ember-cli-table-pagination/components/bs-table-pagination/table-pager', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-pager'], function (exports, _tablePager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tablePager.default,
    classNames: ['col-xs-3', 'col-md-3', 'col-lg-2'],
    actions: {
      changePage: function changePage(newPage) {
        this.attrs.changePage(newPage);
      }
    }
  });
});