define('tm-common/models/db-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        name: attr('string'),
        description: attr('string'),
        locked: attr('boolean'),

        parent: belongsTo('db-tag', { async: true, inverse: 'children' }),
        children: hasMany('db-tag', { async: true, inverse: 'parent' }),

        // Special attributes not part of the model added on the serializer
        parentId: attr('number'),
        grandParentId: attr('number'),
        tagCategory: attr('string'), // to hold the info passed to db-has-tag

        // computed to show or not on the list
        isCountry: Ember.computed('parentId', 'ENV', function () {
            var ENV = this.get('ENV');
            return parseInt(this.get('parentId'), 10) === ENV.APP.tags.countryCategory;
        }),
        isState: Ember.computed('parentId', 'ENV', function () {
            var ENV = this.get('ENV');
            return parseInt(this.get('parentId'), 10) === ENV.APP.tags.stateCategory;
        }),
        isCounty: Ember.computed('grandParentId', 'ENV', function () {
            var ENV = this.get('ENV');
            return parseInt(this.get('grandParentId'), 10) === ENV.APP.tags.stateCategory;
        }),
        isUSA: Ember.computed('id', 'parentId', 'ENV', function () {
            var ENV = this.get('ENV');
            return parseInt(this.get('id'), 10) === ENV.APP.tags.USACountry && parseInt(this.get('parentId'), 10) === ENV.APP.tags.countryCategory;
        }),
        isNotOnline: Ember.computed.equal('name', 'Not Online'),
        isNotPublic: Ember.computed.equal('name', 'Not Public'),
        isSubpoenaReleaseRequired: Ember.computed.equal('name', 'Subpoena/Release Required'),
        isFixedCost: Ember.computed.equal('name', 'Fixed Cost'),
        isHidden: Ember.computed('isNotOnline', 'isNotPublic', 'isSubpoenaReleaseRequired', 'isState', 'isCounty', 'isUSA', function () {
            return this.get('isNotOnline') || this.get('isNotPublic') || this.get('isSubpoenaReleaseRequired') || this.get('isState') || this.get('isCounty') || this.get('isUSA');
        })
    });
});