define('tm-common/models/invoice-has-firm-has-client', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        invoice: belongsTo('invoice', { async: false }),
        firm: belongsTo('firm', { async: false }),
        clients: hasMany('client', { async: false })
    });
});