define('tm-common/models/invoice', ['exports', 'ember-data', 'ember-api-actions', 'accounting/format-money', 'moment'], function (exports, _emberData, _emberApiActions, _formatMoney, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        status: attr('string'),
        tmNum: attr('number'),
        billDate: attr('string'),
        billedAmount: attr('number'),

        availableTransitions: attr(), // computed on the API to check what actions are available for the invoice

        // custom field
        isAdmin: attr('boolean'),
        budgets: attr('string'),
        truncatedBudgets: Ember.computed('budgets', function () {
            var budgets = this.get('budgets');
            return budgets.length > 25 ? budgets.substring(0, 25) + '...' : budgets;
        }),
        splitNr: attr('string'),
        caseOwners: attr('string'),
        outstandingAmount: attr('number'),

        // relationship
        matter: belongsTo('matter', {
            async: false
        }),
        invoiceGroup: belongsTo('invoice-group', { async: true }),
        invoiceBatch: belongsTo('invoice-batch', { async: true }),
        invoiceOutput: belongsTo('invoice-output', { async: true }),
        billables: hasMany('billable', { async: true }),

        serviceAmount: attr('number'),
        expenseAmount: attr('number'),
        taxAmount: attr('number'),
        discountSubAmount: attr('number'),
        adjustment: attr('number'),

        discountAmount: attr('number'),
        discountDescription: attr('string'),
        privateNotes: attr('string'),
        publicNotes: attr('string'),

        formattedAmount: Ember.computed('billedAmount', function () {
            return (0, _formatMoney.default)(this.get('billedAmount'));
        }),

        formattedBillDate: Ember.computed('billDate', function () {
            var bd = (0, _moment.default)(this.get('billDate'));
            if (bd.isValid()) {
                return (0, _moment.default)(this.get('billDate')).format('MM/DD/YYYY');
            }
            return '';
        }),

        canGoToLevel1: Ember.computed('availableTransitions.[]', function () {
            return this.get('availableTransitions').includes('to Level 1 Complete');
        }),
        canGoToLevel2: Ember.computed('availableTransitions.[]', function () {
            return this.get('availableTransitions').includes('to Level 2 Complete');
        }),
        canGoToBilled: Ember.computed('availableTransitions.[]', function () {
            return this.get('availableTransitions').includes('to Billed');
        }),
        canGoToUnbilled: Ember.computed('availableTransitions.[]', function () {
            return this.get('availableTransitions').includes('to Unbilled');
        }),
        isFullyPaid: Ember.computed('outstandingAmount', function () {
            return this.get('outstandingAmount') <= 0;
        }),

        change: (0, _emberApiActions.memberAction)({
            path: 'change',
            type: 'put'
        }),
        addNewBillables: (0, _emberApiActions.memberAction)({
            path: 'add_new_billables',
            type: 'post'
        }),
        recalcTimeRates: (0, _emberApiActions.memberAction)({
            path: 'recalc_time_rates',
            type: 'post'
        }),
        updateInvoiceGroup: (0, _emberApiActions.memberAction)({
            path: 'update_invoice_group',
            type: 'post'
        }),
        getIGMDetails: (0, _emberApiActions.memberAction)({
            path: 'igm_details',
            type: 'get'
        })
    });
});