define('tm-common/mixins/timeline-display-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        store: Ember.inject.service(),
        actions: {
            addCurrentDocumentToEvent: function addCurrentDocumentToEvent(event) {
                this.get('store').createRecord('resource_has_resource', {
                    parentId: this.get('documentId'),
                    childId: event.get('id')
                }).save();
                this.attrs.refresh();
            },
            removeEvent: function removeEvent(event) {
                this.removeEvent(event);
            },
            editEvent: function editEvent(event) {
                this.editEvent(event);
            },
            previewDocument: function previewDocument(docId, subjectId) {
                window.open(this.get('apiUrl') + '/preview/' + docId + '/by-subject/' + subjectId);
            }
        }
    });
});