define('tm-common/models/todo-category', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    var Handlebars = Ember.Handlebars;
    exports.default = Model.extend({
        name: attr('string'),
        color: attr('string'),
        styleColor: Ember.computed('color', function () {
            var color = Handlebars.Utils.escapeExpression(this.get('color'));
            return Ember.String.htmlSafe('background-color: ' + color);
        })
    });
});