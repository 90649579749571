define('tm-common/components/custom-checkbox', ['exports', 'tm-common/templates/components/custom-checkbox'], function (exports, _customCheckbox) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _customCheckbox.default,

        classNames: ['custom-checkbox-container'],
        disabled: false,

        id: Ember.computed(function () {
            return 'customCheckbox' + this.get('elementId');
        }),

        change: function change() /* event */{
            this._super.apply(this, arguments);
            if (typeof this.attrs['on-change'] === 'function') {
                this.attrs['on-change'](this.get('value'), this.get('checked'));
            }
        }
    });
});