define('tm-common/helpers/add-target-to-link', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.addTargetToLink = addTargetToLink;
    function addTargetToLink(params /*, hash*/) {
        if (params.length === 0) {
            return Ember.String.htmlSafe('');
        }
        var html = params[0];
        var result = '';
        if (Ember.String.isHTMLSafe(html)) {
            result = html.toString();
        } else {
            result = html;
        }

        var $res = Ember.$(result);
        $res.find('a').attr('target', '_blank');

        return Ember.String.htmlSafe($res.html());
    }

    exports.default = Ember.Helper.helper(addTargetToLink);
});