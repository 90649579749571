define('tm-common/components/monitor-scroll', ['exports', 'ember-component-inbound-actions/inbound-actions'], function (exports, _inboundActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inboundActions.default, {
        target: null,

        didInsertElement: function didInsertElement() {
            var _this = this;

            var self = this;
            var target = this.get('target');
            this.$(target).on('scroll', function () {
                var position = _this.$(target).scrollTop();
                self.get('scrollChanged')(position);
            });
        },
        willDestroyElement: function willDestroyElement() {
            var target = this.get('target');
            this.$(target).off('scroll');
        },


        actions: {
            goToPosition: function goToPosition(position) {
                var target = this.get('target');
                var container = this.$(target);
                if (container) {
                    container.scrollTop(position);
                }
            }
        }
    });
});