define('tm-common/components/charts/custom-bar-chart/horizontal-single-bar', ['exports', 'tm-common/templates/components/charts/custom-bar-chart/horizontal-single-bar'], function (exports, _horizontalSingleBar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _horizontalSingleBar.default,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.set('outerBarWidth', '0');

            Ember.run.later(function () {
                _this.set('outerBarWidth', '100%');
            }, 100);

            this._super.apply(this, arguments);
        },


        outerBarStyle: Ember.computed('outerBarWidth', function () {
            // return `height:${this.outerBarHeight};width: 35px;border-radius: 7px;`
            return 'height:35px;width:' + this.outerBarWidth + '; border-radius: 7px;';
        }),

        labels: Ember.computed('barData', function () {
            var labels = this.get('barData.labels');
            var datasets = this.get('barData.datasets');

            var dataset = datasets[0];

            var barItems = [];

            var progressSum = 0;

            for (var i = 0; i < labels.length; i++) {
                barItems.push({
                    display: labels[i],
                    data: dataset.data[i],
                    colorClass: this.getColorClass(i),
                    barStyle: 'height:35px;width:' + dataset.data[i] + '%;position:absolute;top:0;left:' + progressSum + '%;'
                });
                progressSum += parseInt(dataset.data[i]);
            }

            return barItems;
        }),

        barColor1: null,
        barColor2: null,
        barColor3: null,
        barColor4: null,
        barColor5: null,
        barColor6: null,

        getColorClass: function getColorClass(i) {
            var colorClass = void 0;

            var barColor1 = this.get('barColor1');
            var barColor2 = this.get('barColor2');
            var barColor3 = this.get('barColor3');
            var barColor4 = this.get('barColor4');
            var barColor5 = this.get('barColor5');
            var barColor6 = this.get('barColor6');

            switch (i) {
                case 0:
                    colorClass = barColor1 || 'sc-analytics-bg-color-1';
                    break;

                case 1:
                    colorClass = barColor2 || 'sc-analytics-bg-color-2';
                    break;

                case 2:
                    colorClass = barColor3 || 'sc-analytics-bg-color-3';
                    break;

                case 3:
                    colorClass = barColor4 || 'sc-analytics-bg-color-4';
                    break;

                case 4:
                    colorClass = barColor5 || 'sc-analytics-bg-color-5';
                    break;

                case 5:
                    colorClass = barColor6 || 'sc-analytics-bg-color-6';
                    break;

                default:
                    colorClass = barColor1 || 'sc-analytics-bg-color-1';
                    break;
            }

            return colorClass;
        }
    });
});