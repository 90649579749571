define('tm-common/mixins/analytics-util', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_error.default, {
        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),
        isPortal: Ember.computed('ENV', function () {
            var ENV = this.get('ENV');
            return ENV.modulePrefix === 'sc-portal' || ENV.modulePrefix === 'js2-client';
        }),

        notify: Ember.inject.service(),
        store: Ember.inject.service(),
        analyticsFormsData: Ember.inject.service(),

        showFieldLabel: Ember.computed('vitalsSection', 'overviewSection', 'isTable', 'isParent', 'isChild', function () {
            return this.get('isChild') || this.get('overviewSection');
        }),

        isParentVital: Ember.computed('vitalsSection', 'isParent', function () {
            return this.get('vitalsSection') && this.get('isParent');
        }),

        buildStatesOptions: function buildStatesOptions(subject, states) {
            var subjectStates = subject.get('subjectAnalyticsStates');

            return states.map(function (s) {
                return Ember.Object.create({
                    display: s.get('display'),
                    value: s.get('value'),
                    checked: subjectStates && subjectStates.includes(s.get('display'))
                });
            });
        },

        buildCivilOptions: function buildCivilOptions(subject, civilList) {
            var subjectCivil = subject.get('subjectAnalyticsCivil');
            var subjectPartytype = subject.get('subjectAnalyticsCivilPartytype');

            return civilList.map(function (c) {
                return Ember.Object.create({
                    display: c.get('display'),
                    value: c.get('value'),
                    shortName: c.get('shortName'),
                    order: c.get('order'),
                    checked: subjectCivil && subjectCivil.includes(c.get('display')),
                    plaintiffCount: subjectPartytype && subjectPartytype.findBy('display', c.get('shortName')) ? subjectPartytype.findBy('display', c.get('shortName')).get('plaintiff') : '',
                    defendantCount: subjectPartytype && subjectPartytype.findBy('display', c.get('shortName')) ? subjectPartytype.findBy('display', c.get('shortName')).get('defendant') : '',
                    otherCount: subjectPartytype && subjectPartytype.findBy('display', c.get('shortName')) ? subjectPartytype.findBy('display', c.get('shortName')).get('other') : ''
                });
            });
        },

        buildCriminalOptions: function buildCriminalOptions(subject, criminalList, criminalChargesList, criminalRightsList) {
            var subjectCriminal = subject.get('subjectAnalyticsCriminal');
            var subjectCriminalCharges = subject.get('subjectAnalyticsCriminalCharges');
            var subjectCriminalRights = subject.get('subjectAnalyticsCriminalRights');

            var options = Ember.Object.create({});
            options.set('criminalAnalysis', criminalList.map(function (c) {
                return Ember.Object.create({
                    display: c.get('display'),
                    value: c.get('value'),
                    order: c.get('order'),
                    shortName: c.get('shortName'),
                    checked: subjectCriminal && subjectCriminal.includes(c.get('display'))
                });
            }));

            options.set('criminalCharges', criminalChargesList.map(function (c) {
                return Ember.Object.create({
                    display: c.get('display'),
                    value: c.get('value'),
                    order: c.get('order'),
                    shortName: c.get('shortName'),
                    checked: subjectCriminalCharges && subjectCriminalCharges.includes(c.get('display'))
                });
            }));

            options.set('criminalRights', criminalRightsList.map(function (c) {
                return Ember.Object.create({
                    display: c.get('display'),
                    value: c.get('value'),
                    order: c.get('order'),
                    shortName: c.get('shortName'),
                    checked: subjectCriminalRights && subjectCriminalRights.includes(c.get('display'))
                });
            }));

            return options;
        },

        buildTrafficOptions: function buildTrafficOptions(subject, trafficList) {
            var subjectTraffic = subject.get('subjectAnalyticsTraffic');

            return trafficList.map(function (t) {
                return Ember.Object.create({
                    display: t.get('display'),
                    value: t.get('value'),
                    order: t.get('order'),
                    shortName: t.get('shortName'),
                    checked: subjectTraffic && subjectTraffic.includes(t.get('display'))
                });
            });
        },

        buildEmploymentOptions: function buildEmploymentOptions(subject, employmentStatusList, occupationsList, employmentAnalysisList, employmentAnalysisCategoryList) {
            var subjectEmploymentStatus = subject.get('subjectAnalyticsEmploymentStatus');
            var subjectOccupation = subject.get('occupation');
            var subjectEmploymentAnalysis = subject.get('employmentAnalysis');
            var subjectEmploymentAnalysisCategory = subject.get('subjectAnalyticsEmploymentAnalysisCategory');

            var options = Ember.Object.create({});

            options.set('employmentStatus', employmentStatusList.map(function (e) {
                return Ember.Object.create({
                    display: e.get('display'),
                    value: e.get('value'),
                    order: e.get('order'),
                    shortName: e.get('shortName'),
                    checked: subjectEmploymentStatus && subjectEmploymentStatus.includes(e.get('display'))
                });
            }));

            options.set('occupation', occupationsList.sortBy('order').map(function (o) {
                return Ember.Object.create({
                    display: o.get('display'),
                    value: o.get('value'),
                    order: o.get('order'),
                    shortName: o.get('shortName'),
                    checked: subjectOccupation && subjectOccupation.includes(o.get('display'))
                });
            }));

            options.set('employmentAnalysis', employmentAnalysisList.map(function (e) {
                return Ember.Object.create({
                    display: e.get('display'),
                    value: e.get('value'),
                    order: e.get('order'),
                    shortName: e.get('shortName'),
                    checked: subjectEmploymentAnalysis && subjectEmploymentAnalysis.includes(e.get('display'))
                });
            }));

            options.set('employmentAnalysisCategory', employmentAnalysisCategoryList.map(function (e) {
                return Ember.Object.create({
                    display: e.get('display'),
                    value: e.get('value'),
                    order: e.get('order'),
                    shortName: e.get('shortName'),
                    checked: subjectEmploymentAnalysisCategory && subjectEmploymentAnalysisCategory.includes(e.get('display'))
                });
            }));

            return options;
        },

        buildEducationOptions: function buildEducationOptions(subject, educationList) {
            var educationAnalysis = subject.get('educationAnalysis');

            return educationList.map(function (e) {
                return Ember.Object.create({
                    display: e.get('display'),
                    value: e.get('value'),
                    order: e.get('order'),
                    checked: educationAnalysis && educationAnalysis.includes(e.get('display'))
                });
            });
        },

        buildSocnetOptions: function buildSocnetOptions(subject, socnetOtherList, socnetThemesList, socnetThemesHiddenList, socnetTonesList) {
            var socnetOther = subject.get('subjectAnalyticsSocnetOther');
            var socnetThemes = subject.get('subjectAnalyticsSocnetThemes');
            var socnetThemesHidden = subject.get('subjectAnalyticsSocnetThemesHidden');
            var socnetTones = subject.get('subjectAnalyticsSocnetTones');

            var options = Ember.Object.create({});

            options.set('socnetOtherOptions', socnetOtherList.map(function (s) {
                return Ember.Object.create({
                    display: s.get('display'),
                    value: s.get('value'),
                    shortName: s.get('shortName'),
                    order: s.get('order'),
                    checked: socnetOther && socnetOther.includes(s.get('display'))
                });
            }));

            options.set('socnetThemesOptions', socnetThemesList.map(function (s) {
                return Ember.Object.create({
                    display: s.get('display'),
                    value: s.get('value'),
                    shortName: s.get('shortName'),
                    order: s.get('order'),
                    checked: socnetThemes && socnetThemes.includes(s.get('display'))
                });
            }));

            options.set('socnetThemesHiddenOptions', socnetThemesHiddenList.map(function (s) {
                return Ember.Object.create({
                    display: s.get('display'),
                    value: s.get('value'),
                    shortName: s.get('shortName'),
                    order: s.get('order'),
                    checked: socnetThemesHidden && socnetThemesHidden.includes(s.get('display'))
                });
            }));

            options.set('socnetTonesOptions', socnetTonesList.map(function (s) {
                return Ember.Object.create({
                    display: s.get('display'),
                    value: s.get('value'),
                    shortName: s.get('shortName'),
                    order: s.get('order'),
                    checked: socnetTones && socnetTones.includes(s.get('display'))
                });
            }));

            return options;
        },

        buildPossibleConnectionsOptions: function buildPossibleConnectionsOptions(subject, possibleConnectionsCategoriesList) {
            var possibleConnectionsCategory = subject.get('subjectAnalyticsPossibleConnectionsCategories');
            var options = Ember.Object.create({});
            options.set('possibleConnectionsCategoryOptions', possibleConnectionsCategoriesList.map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    order: p.get('order'),
                    shortName: p.get('shortName'),
                    checked: possibleConnectionsCategory && possibleConnectionsCategory.includes(p.get('display'))
                });
            }));

            return options;
        },

        buildMaritalStatusOptions: function buildMaritalStatusOptions(subject, maritalStatusList) {
            var maritalStatus = subject.get('maritalStatus');

            return maritalStatusList.map(function (m) {
                return Ember.Object.create({
                    display: m.get('display'),
                    value: m.get('value'),
                    order: m.get('order'),
                    shortName: m.get('shortName'),
                    checked: maritalStatus && maritalStatus.includes(m.get('display'))
                });
            });
        },

        buildChildrenOptions: function buildChildrenOptions(subject, childrenOptions, hasChildrenOptions) {
            var children = subject.get('children');
            var hasChildren = subject.get('hasChildren');

            var options = Ember.Object.create({});

            options.set('childrenOptions', childrenOptions.map(function (c) {
                return Ember.Object.create({
                    display: c.get('display'),
                    value: c.get('value'),
                    order: c.get('order'),
                    shortName: c.get('shortName'),
                    checked: children && children.includes(c.get('display'))
                });
            }));

            options.set('hasChildrenOptions', hasChildrenOptions.map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    order: h.get('order'),
                    shortName: h.get('shortName'),
                    checked: hasChildren && hasChildren.includes(h.get('display'))
                });
            }));

            return options;
        },

        buildEmploymentTwoOptions: function buildEmploymentTwoOptions(subject, employmentTypeList, employmentLengthList) {
            var employmentType = subject.get('subjectAnalyticsEmploymentType');
            var employmentRole = subject.get('subjectAnalyticsEmploymentRole');
            var employmentOther = subject.get('subjectAnalyticsEmploymentOther');
            var employmentCurrentLast = subject.get('employmentCurrentLast');

            var employmentDetailsOptions = employmentTypeList.map(function (e) {
                var indexOfType = employmentType ? employmentType.indexOf(e.get('display')) : -1;
                return Ember.Object.create({
                    display: e.get('display'),
                    value: e.get('value'),
                    order: e.get('order'),
                    shortName: e.get('shortName'),
                    checked: employmentType && employmentType.includes(e.get('display')),
                    role: employmentRole && indexOfType > -1 ? employmentRole[indexOfType] : '',
                    other: employmentOther && indexOfType > -1 ? employmentOther[indexOfType] : '',
                    currentLast: e.get('display') === employmentCurrentLast
                });
            });

            var employmentLengthOptions = this.buildOptionsList(employmentLengthList.sortBy('order'), subject, 'employmentLengthCurrent');
            return {
                employmentDetailsOptions: employmentDetailsOptions,
                employmentLengthOptions: employmentLengthOptions
            };
        },

        buildOptionsList: function buildOptionsList(list, subject, field) {
            var fieldValue = subject.get(field);
            return list.map(function (c) {
                return Ember.Object.create({
                    display: c.get('display'),
                    value: c.get('value'),
                    order: c.get('order'),
                    shortName: c.get('shortName'),
                    checked: fieldValue && fieldValue.includes(c.get('display'))
                });
            });
        },


        buildProductUseOptions: function buildProductUseOptions(subject, productUseList, productUseSecondariesList, addictionList, medicalList) {
            var productUse = subject.get('subjectAnalyticsProductUse');
            var productUseSecondaries = subject.get('subjectAnalyticsProductUseSecondaries');
            var addictionWithdrawalExperience = subject.get('subjectAnalyticsWithdrawalExperience');
            var medicalIssue = subject.get('subjectAnalyticsJurorMedicalIssues');

            var options = Ember.Object.create({});

            options.set('productUseOptions', productUseList.map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    order: p.get('order'),
                    shortName: p.get('shortName'),
                    checked: productUse && productUse.includes(p.get('display'))
                });
            }));

            options.set('productUseSecondariesOptions', productUseSecondariesList.map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    order: p.get('order'),
                    shortName: p.get('shortName'),
                    checked: productUseSecondaries && productUseSecondaries.includes(p.get('display'))
                });
            }));

            options.set('addictionOptions', addictionList.map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    order: p.get('order'),
                    shortName: p.get('shortName'),
                    checked: addictionWithdrawalExperience && addictionWithdrawalExperience.includes(p.get('display'))
                });
            }));

            options.set('medicalOptions', medicalList.map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    order: p.get('order'),
                    shortName: p.get('shortName'),
                    checked: medicalIssue && medicalIssue.includes(p.get('display'))
                });
            }));

            return options;
        },

        buildOtherOptions: function buildOtherOptions(subject, politicalViewsList, mediaOutletList) {
            var politicalViews = subject.get('politicalViews');
            var mediaOutlet = subject.get('mediaOutlet');

            var options = Ember.Object.create({});

            options.set('politicalViewsOptions', politicalViewsList.sortBy('order').map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    shortName: p.get('shortName'),
                    checked: politicalViews && politicalViews.includes(p.get('display'))
                });
            }));

            options.set('mediaOutletOptions', mediaOutletList.sortBy('order').map(function (p) {
                return Ember.Object.create({
                    display: p.get('display'),
                    value: p.get('value'),
                    shortName: p.get('shortName'),
                    checked: mediaOutlet && mediaOutlet.includes(p.get('display'))
                });
            }));

            return options;
        },

        buildHigherEducationOptions: function buildHigherEducationOptions(subject, higherEducationList, higherEducationMastersList, higherEducationPostGradList) {
            var higherEducation = subject.get('subjectAnalyticsHigherEducation');
            var higherEducationMasters = subject.get('higherEducationMasters');
            var higherEducationPostGrad = subject.get('higherEducationPostGrad');

            var options = Ember.Object.create({});

            options.set('higherEducationOptions', higherEducationList.map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    shortName: h.get('shortName'),
                    checked: higherEducation && higherEducation.includes(h.get('display'))
                });
            }));

            options.set('higherEducationMastersOptions', higherEducationMastersList.map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    shortName: h.get('shortName'),
                    checked: higherEducationMasters && higherEducationMasters.includes(h.get('display'))
                });
            }));

            options.set('higherEducationPostGradOptions', higherEducationPostGradList.map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    shortName: h.get('shortName'),
                    checked: higherEducationPostGrad && higherEducationPostGrad.includes(h.get('display'))
                });
            }));

            return options;
        },

        buildGovernmentSectorBranches: function buildGovernmentSectorBranches(subject) {
            var currentValue = subject.get('governmentSectorBranches');

            return ['Administrative', 'Courts', 'Education', 'Healthcare', 'Law Enforcement', 'Other'].map(function (p) {
                return Ember.Object.create({
                    display: p,
                    value: p,
                    shortName: p,
                    checked: currentValue && currentValue.includes(p)
                });
            });
        },

        buildMilitaryBranches: function buildMilitaryBranches(subject) {
            var currentValue = subject.get('militaryVeteranBranches');

            return ['Air Force', 'Army', 'Coast Guard', 'Marines', 'National Guard', 'Navy', 'Other'].map(function (p) {
                return Ember.Object.create({
                    display: p,
                    value: p,
                    shortName: p,
                    checked: currentValue && currentValue.includes(p)
                });
            });
        },

        buildMaritalAttributesOptions: function buildMaritalAttributesOptions(subject) {
            var currentValue = subject.get('maritalAttributes');

            return ['Same Sex'].map(function (p) {
                return Ember.Object.create({
                    display: p,
                    value: p,
                    shortName: p,
                    checked: currentValue && currentValue.includes(p)
                });
            });
        },

        buildReligionOptions: function buildReligionOptions(subject, religiousInterestsList, religiousAffiliationsList, religiousLevelsOfInterestList) {
            var religiousAffiliation = subject.get('subjectAnalyticsReligiousAffiliation');
            var religiousLevel = subject.get('subjectAnalyticsReligiousLevelOfInterest');

            var options = Ember.Object.create({});

            options.set('religiousInterestsOptions', religiousInterestsList.sortBy('order').map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    shortName: h.get('shortName')
                });
            }));

            options.set('religiousAffiliationOptions', religiousAffiliationsList.sortBy('order').map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    shortName: h.get('shortName'),
                    checked: religiousAffiliation && religiousAffiliation.includes(h.get('display'))
                });
            }));

            options.set('religiousLevelOfInterestOptions', religiousLevelsOfInterestList.sortBy('order').map(function (h) {
                return Ember.Object.create({
                    display: h.get('display'),
                    value: h.get('value'),
                    shortName: h.get('shortName'),
                    checked: religiousLevel && religiousLevel.includes(h.get('display'))
                });
            }));

            return options;
        },

        normalizeAndSetAnalytics: function normalizeAndSetAnalytics(subject, data) {
            if (Ember.typeOf(subject) !== 'instance') {
                return;
            }
            subject.set('subjectAnalyticsStates', data.statesOptions);
            subject.set('subjectAnalyticsCivil', data.civilData);
            subject.set('subjectAnalyticsCivilPartytype', data.civilOptions);
            subject.set('subjectAnalyticsCriminal', data.criminalOptions);
            subject.set('subjectAnalyticsCriminalCharges', data.criminalOptions);
            subject.set('subjectAnalyticsCriminalRights', data.criminalOptions);
            subject.set('subjectAnalyticsCriminalCases', data.criminalData);
            subject.set('subjectAnalyticsTraffic', data.trafficOptions);
            subject.set('subjectAnalyticsEmploymentStatus', data.employmentOptions);
            subject.set('subjectAnalyticsEmploymentAnalysisCategory', data.employmentOptions);
            this.updateSubjectAnalyticsSocnet(subject, data.socnetOptions);
            subject.set('subjectAnalyticsPossibleConnectionsCategories', data.possibleConnectionsOptions);
            subject.set('subjectAnalyticsYearsMarried', data.maritalStatusOptions);
            subject.set('subjectAnalyticsHasChildren', data.childrenOptions);
            this.updateSubjectEmploymentPhaseTwo(subject, data.employmentTwoOptions);
            subject.set('subjectAnalyticsProductUse', data.productUseOptions);
            subject.set('subjectAnalyticsHigherEducation', data.higherEducationOptions);
            subject.set('subjectAnalyticsMediaOutlet', data.otherOptions);
            // backport
            subject.set('subjectAnalyticsGovernmentSectorBranches', data.governmentBranchOptions);
            subject.set('subjectAnalyticsMilitaryVeteranBranches', data.militaryBranchOptions);
            subject.set('subjectAnalyticsMaritalAttributes', data.maritalAttributesOptions);
            subject.set('subjectAnalyticsJurorMedicalIssues', data.productUseOptions);
            subject.set('subjectAnalyticsReligiousAffiliation', data.religionOptions);
            subject.set('subjectAnalyticsReligiousLevelOfInterest', data.religionOptions);
        },

        /**
         * @param subject
         * @param data
         * When selecting industry in Phase 1, the industry is also selected in Phase 2
         */
        updateSubjectEmploymentPhaseTwo: function updateSubjectEmploymentPhaseTwo(subject, data) {
            var currentIndustry = subject.get('employmentTypeCurrent');
            if (currentIndustry) {
                var phaseTwoOptionToBeSelected = data.employmentDetailsOptions.findBy('display', currentIndustry);
                phaseTwoOptionToBeSelected.set('checked', true);
                subject.set('employmentCurrentLast', currentIndustry);
            }
            if (Ember.isPresent(subject.get('militaryVeteranCurrent'))) {
                subject.set('militaryVeteran', subject.get('militaryVeteranCurrent'));
            }
            if (Ember.isPresent(subject.get('governmentSectorCurrent'))) {
                subject.set('governmentSector', subject.get('governmentSectorCurrent'));
            }

            subject.set('subjectAnalyticsEmploymentType', data);
            subject.set('subjectAnalyticsEmploymentRole', data);
            subject.set('subjectAnalyticsEmploymentOther', data);
        },
        updateSubjectAnalyticsSocnet: function updateSubjectAnalyticsSocnet(subject, data) {
            var profileCount = this.calculateProfileCount(subject.get('hyperlinks'));
            if (!profileCount) {
                // reset options
                data.socnetOtherOptions.forEach(function (st) {
                    st.set('checked', null);
                });

                data.socnetThemesOptions.forEach(function (st) {
                    st.set('checked', null);
                });

                data.socnetThemesHiddenOptions.forEach(function (st) {
                    st.set('checked', null);
                });

                data.socnetTonesOptions.forEach(function (st) {
                    st.set('checked', null);
                });
            }
            subject.set('subjectAnalyticsSocnetOther', data);
            subject.set('subjectAnalyticsSocnetThemes', data);
            subject.set('subjectAnalyticsSocnetThemesHidden', data);
            subject.set('subjectAnalyticsSocnetTones', data);
        },
        calculateProfileCount: function calculateProfileCount(hyperlinks) {
            var _this = this;

            if (hyperlinks && hyperlinks.get('length') > 0) {
                var filteredHyperlinks = hyperlinks.filter(function (h) {
                    if (_this.get('isPortal')) {
                        return h.get('viewable') === 1;
                    } else {
                        return h.get('viewable') !== 0;
                    }
                });
                var EXCLUDED_HYPERLINK_TYPES = ['Note', 'None', 'Media', 'Non-Subject'];
                filteredHyperlinks = filteredHyperlinks.reject(function (h) {
                    return EXCLUDED_HYPERLINK_TYPES.includes(h.get('type'));
                });

                if (filteredHyperlinks && filteredHyperlinks.get('length') > 0) {
                    return filteredHyperlinks.get('length');
                }
            }

            return null;
        },
        calculateAllLinkOfTypeNone: function calculateAllLinkOfTypeNone(hyperlinks) {
            var EXCLUDED_HYPERLINK_TYPES = ['Note', 'Media', 'Non-Subject'];
            var filteredHyperlinks = this.get('isPortal') ? hyperlinks.filterBy('viewable', 1) : hyperlinks.rejectBy('viewable', 0);
            filteredHyperlinks = filteredHyperlinks.reject(function (h) {
                return EXCLUDED_HYPERLINK_TYPES.includes(h.get('type'));
            });
            return filteredHyperlinks.get('length') && filteredHyperlinks.isEvery('type', 'None');
        },


        actions: {
            saveAllAnalytics: function saveAllAnalytics() {
                var _this2 = this;

                var data = this.get('analyticsFormsData.dataForSidebar');
                var subject = data.get('subject');
                this.normalizeAndSetAnalytics(subject, data);

                subject.saveAnalytic({ subject: subject.serialize() }).then(function () {
                    _this2.store.query('subject', {
                        resource_id: subject.get('id'),
                        with: 'analytics'
                    }).then(function () {
                        _this2.get('notify').success('Analytics updated successfully');
                        _this2.set('analyticsFormsData.sidebarEditMode', false);
                        _this2.refresh();
                    });
                }, function (response) {
                    _this2.handleInvalidError(response);
                });
            },
            cancelSaveAllAnalytics: function cancelSaveAllAnalytics() {
                var subject = this.get('analyticsFormsData.dataForSidebar.subject');

                subject.rollbackAttributes();
                this.get('analyticsFormsData').refreshData({
                    profileEditMode: this.get('analyticsFormsData.profileEditMode'),
                    sidebarEditMode: false
                });

                this.set('analyticsFormsData.dataForSidebar.editMode', false);
                this.refresh();
            }
        }
    });
});