define('tm-common/models/phrase', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        name: attr('string'),
        phraseType: attr('string'),

        // field-phrase->weight
        order: attr('number'),

        // field-phrase->phrase
        content: attr('string'),

        created: attr('estdate'),
        updated: attr('estdate'),

        includeVariables: attr('number'),
        softDeleted: attr('number'),

        // field-phrase->field
        matterFieldDisplay: attr('string'),
        // field-phrase->breaks
        fieldBreaks: attr('number'),

        // Relationships

        billableTypeId: attr('number'),
        billableType: belongsTo('billable-type', { async: false }),

        caseTypeGroupId: attr('number'),
        caseTypeGroup: belongsTo('phrase-case-type-group', { async: true }),

        createdUserId: attr('number'),
        createdUser: belongsTo('user', { async: true }),

        updatedUserId: attr('number'),
        updatedUser: belongsTo('user', { async: true }),

        // Computed Properties

        // field-phrase->name
        fieldName: Ember.computed('name', function () {
            var parts = this.get('name').split(' | ');
            return parts.length === 2 ? parts[1] : this.get('name');
        }),

        contentLength: Ember.computed('content.length', function () {
            return this.get('content.length');
        })
    });
});