define('tm-common/components/charts/custom-bar-chart/vertical-single-bar', ['exports', 'tm-common/templates/components/charts/custom-bar-chart/vertical-single-bar'], function (exports, _verticalSingleBar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _verticalSingleBar.default,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.set('outerBarHeight', '0');

            Ember.run.later(function () {
                _this.set('outerBarHeight', '185px');
            }, 100);

            this._super.apply(this, arguments);
        },


        outerBarStyle: Ember.computed('outerBarHeight', function () {
            return 'height:' + this.outerBarHeight + ';width: 35px;border-radius: 7px;';
        }),

        labels: Ember.computed('barData', function () {
            var labels = this.get('barData.labels');
            var datasets = this.get('barData.datasets');

            var dataset = datasets[0];

            var barItems = [];

            for (var i = 0; i < labels.length; i++) {
                barItems.push({
                    display: labels[i],
                    data: dataset.data[i],
                    colorClass: this.getColorClass(i),
                    barStyle: 'height:' + dataset.data[i] + '%;width:35px;'
                });
            }

            return barItems;
        }),

        getColorClass: function getColorClass(i) {
            var colorClass = void 0;

            switch (i) {
                case 0:
                    colorClass = 'sc-analytics-bg-color-1';
                    break;

                case 1:
                    colorClass = 'sc-analytics-bg-color-2';
                    break;

                case 2:
                    colorClass = 'sc-analytics-bg-color-3';
                    break;

                case 3:
                    colorClass = 'sc-analytics-bg-color-4';
                    break;

                case 4:
                    colorClass = 'sc-analytics-bg-color-5';
                    break;

                case 5:
                    colorClass = 'sc-analytics-bg-color-6';
                    break;

                default:
                    colorClass = 'sc-analytics-bg-color-1';
                    break;
            }

            return colorClass;
        }
    });
});