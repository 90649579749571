define('ember-cli-table-pagination/components/light-table/columns/quick-filter-toggle', ['exports', 'ember-cli-table-pagination/templates/components/light-table/columns/quick-filter-toggle', 'ember-light-table/components/columns/base'], function (exports, _quickFilterToggle, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    layout: _quickFilterToggle.default,

    allChecked: false,

    actions: {
      toggleFilters: function toggleFilters() {
        this.get('table.visibleColumns').forEach(function (col) {
          var tpColumn = col.get('tpColumn');
          if (Ember.isPresent(tpColumn)) {
            tpColumn.toggleProperty('showFilter');
          }
        });
      }
    }
  });
});