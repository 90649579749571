define('tm-common/helpers/subject-field-content-is-null', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Helper.extend({
        displayedFields: {
            case_related_analytics: ['possibleConnectionsCategory'],
            children_analytics: ['children'],
            civil_analytics: ['civilAnalysis', 'civilPartytype'],
            criminal_analytics: ['criminalAnalysis', 'criminalCharge', 'criminalTotal', 'criminalRights'],
            current_city: ['currentCity'],
            education_analytics: ['educationAnalysis'],
            employment_analytics: ['employmentStatus', 'isGovSector', 'isMilitaryVet', 'occupations', 'subjectAnalyticsEmploymentAnalysisCategory'],
            employment_status_analytics: ['employmentStatus'],
            marital_analytics: ['maritalStatus'],
            primary_property_value: ['primaryPropertyValue'],
            property: ['property'],
            social_media_analytics: ['socnetOther', 'socnetThemes', 'socnetThemesOther', 'socnetTones', 'socnetTonesOther', 'socnetNote'],
            states_analytics: ['states'],
            traffic_analytics: ['subjectAnalyticsTraffic'],
            years_in_county_analytics: ['yearsInCounty']
        },

        isSubjectFieldContentNull: function isSubjectFieldContentNull(subject, matterField) {
            var key = Ember.String.camelize(matterField.get('value'));
            var content = subject.get(key);
            var displayedFields = this.get('displayedFields');
            switch (matterField.get('fieldClass')) {
                case 'widget':
                    var fields = displayedFields[matterField.get('value')];
                    if (!fields) {
                        Ember.Logger.debug('Incorrect subject field name');
                        return true;
                    }
                    var isNull = true;
                    fields.forEach(function (field) {
                        if (subject.get(field) && subject.get(field) !== '{}') {
                            isNull = false;
                        }
                    });
                    return isNull;

                default:
                    return Ember.isEmpty(content);
            }
        },


        /**
         * @param subject
         * @param matterField
         * @param childMatterFields
         * @param value ---> extra param used as temporary fix (on NON-bootstrap-4 branches) so subject.field observer is
         *  triggered when value changes (example: when adding first hyperlink, we want the right-side panel of the
         *  subject profile page to display it without refreshing)
         * @returns {*|boolean}
         */
        compute: function compute(_ref) {
            var _this = this;

            var _ref2 = _slicedToArray(_ref, 4),
                subject = _ref2[0],
                matterField = _ref2[1],
                childMatterFields = _ref2[2],
                value = _ref2[3];

            var parentIsNull = this.isSubjectFieldContentNull(subject, matterField);
            var childrenAreNull = false;
            var allChildrenAreNull = false;

            if (childMatterFields && childMatterFields.length) {
                childMatterFields.forEach(function (field) {
                    allChildrenAreNull = allChildrenAreNull || _this.isSubjectFieldContentNull(subject, field);
                });
                childrenAreNull = allChildrenAreNull;
            } else {
                childrenAreNull = true;
            }
            return parentIsNull && childrenAreNull;
        }
    });
});