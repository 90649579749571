define('tm-common/models/appl-lists-category', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        categoryName: attr('string'),
        displayName: attr('string'),
        private: attr('number'),

        applLists: hasMany('appl-list', { async: false })
    });
});