define('tm-common/models/collection-column', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;


    var ASSIGNEE = 1;
    var STATUS = 2;
    var COMMENT = 4;

    exports.default = Model.extend({
        lists: Ember.inject.service(),

        label: attr('string'),
        fieldName: attr('string'),
        apiName: attr('string'),
        weight: attr('number'),
        columnWidth: attr('number'),
        percentage: attr('number'),
        viewRenderType: attr('string'),
        editRenderType: attr('string'),
        editRenderList: attr('string'),
        dashboardViewable: attr('number'),
        workflowEnabled: attr('number'),
        subtractDays: attr('number'),
        collection: belongsTo('collection'),
        workflow: belongsTo('workflow'),

        valuePath: Ember.computed.alias('fieldName'),

        workflowAssigneeEnabled: Ember.computed('workflowEnabled', {
            get: function get(key) {
                return this.get('workflowEnabled') & ASSIGNEE;
            },
            set: function set(key, value) {
                var enabled = this.get('workflowEnabled');
                if (value) {
                    enabled |= ASSIGNEE;
                } else {
                    enabled &= ~ASSIGNEE;
                }
                this.set('workflowEnabled', enabled);
                return value;
            }
        }),
        workflowStatusEnabled: Ember.computed('workflowEnabled', {
            get: function get(key) {
                return this.get('workflowEnabled') & STATUS;
            },
            set: function set(key, value) {
                var enabled = this.get('workflowEnabled');
                if (value) {
                    enabled |= STATUS;
                } else {
                    enabled &= ~STATUS;
                }
                this.set('workflowEnabled', enabled);
                return value;
            }
        }),
        workflowCommentEnabled: Ember.computed('workflowEnabled', {
            get: function get(key) {
                return this.get('workflowEnabled') & COMMENT;
            },
            set: function set(key, value) {
                var enabled = this.get('workflowEnabled');
                if (value) {
                    enabled |= COMMENT;
                } else {
                    enabled &= ~COMMENT;
                }
                this.set('workflowEnabled', enabled);
                return value;
            }
        }),
        isLabelEditable: Ember.computed('field_name', function () {
            return this.get('field_name').indexOf('custom') >= 0;
        }),

        /**
         * END BOOLEAN CALCS
         */

        /** this property gets the list from the lists service with the right listName */
        options: Ember.computed('editRenderType', function () {
            if (this.get('editRenderType') === 'enum' || this.get('editRenderType') === 'radio' || this.get('editRenderType') === 'checkboxes') {
                return this.get('lists').getListForGroup(this.get('editRenderList'));
            }
            return [];
        })
    });
});