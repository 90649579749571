define('tm-common/models/user-number', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        type: attr('string'),
        primary: attr('number'),
        number: attr('string'),
        ext: attr('string'),

        // relationships
        user: belongsTo('user', {
            async: false
        }) // because the FK is user_id
    });
});