define('ember-cli-table-pagination/components/table-pagination', ['exports', 'ember-cli-table-pagination/templates/components/table-pagination'], function (exports, _tablePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // HTML
    layout: _tablePagination.default,

    // properties

    /**
     * @public
     * @type boolean
     */
    allowQuickSearch: true,
    /**
     * @public
     * @type Array
     */
    content: [],

    /**
     * @public
     * @type Field
     */
    fields: [],

    /**
     * @public
     * @type boolean
     */
    isRemoteHandled: false,
    /**
     * @public
     * @type boolean
     */
    loading: false,
    /**
     * @public
     * @type number
     */
    page: 1,
    /**
     * @public
     * @type number
     */
    perPage: 5,
    /**
     * @public
     * @type string
     */

    searchString: '',
    /**
     * what is this?
     *
     * @private
     * @type string
     */
    sortProperty: null,

    /**
     * asc || desc
     *
     * @private
     * @type string
     */
    sortDirection: null,

    /**
     * @public
     * @type string
     */
    tableTitle: 'title',

    noFiltering: false,

    // computed
    numberOfRecords: Ember.computed.reads('filteredContent.length'),
    filteredContent: Ember.computed('content', 'searchString', 'fields.@each.filterValue', 'noFiltering', function () {
      var content = this.get('content');
      if (this.get('noFiltering')) {
        return content;
      }
      var searchString = this.get('searchString');
      var filteredContent = content.filter(function (item) {
        var pattern = new RegExp(searchString, 'i');
        var found = false;
        item.eachAttribute(function (name, meta) {
          if (['number', 'string', 'date'].includes(meta.type)) {
            found = found || pattern.test(item.get(name));
          }
        });
        return found;
      });
      var fields = this.get('fields');
      fields.forEach(function (field) {
        var fieldName = field.fieldName,
            filterValue = field.filterValue;

        if (filterValue) {
          filteredContent = content.filter(function (item) {
            var pattern = new RegExp(filterValue, 'i');
            var value = item.get(fieldName);
            var test = pattern.test(value);
            return test;
          });
        }
      });

      return filteredContent;
    }),
    /**
     * @public
     * @type Array.<string>
     */
    _sorting: Ember.computed('sortProperty', 'sortDirection', function () {
      var sorting = this.get('sortProperty');
      var sortDirection = this.get('sortDirection');
      if (sortDirection === 'desc') {
        return [sorting + ':' + sortDirection];
      } else {
        return ['' + sorting];
      }
    }),
    sortedContent: Ember.computed.sort('filteredContent', '_sorting'),
    pagedContent: Ember.computed('filteredContent', 'sortedContent', 'page', 'perPage', function () {
      var page = this.get('page');
      var perPage = this.get('perPage');
      var content = this.get('sortedContent');

      // we are handling the data ourself so:
      // we should display only the items on the current page:
      // a.k.a. perPage items starting at perPageItems*page
      return content.slice(perPage * (page - 1), perPage * (page - 1) + perPage);
    }),
    currentContent: Ember.computed('pagedContent', 'content', 'isRemoteHandled', function () {
      var isRemoteHandled = this.get('isRemoteHandled');
      if (isRemoteHandled) {
        return this.get('content');
      } else {
        return this.get('pagedContent');
      }
    }),
    currentContentSize: Ember.computed.reads('currentContent.length'),
    totalPages: Ember.computed('filteredContent.length', 'perPage', function () {
      var contentLength = this.get('filteredContent.length');
      var perPage = this.get('perPage');
      return Math.ceil(contentLength / perPage);
    }),

    allColumns: Ember.computed('columns.[]', 'additionalColumnsForFilter.[]', function () {
      var tableColumns = Ember.A(this.get('columns')).filterBy('enableSearch', true);
      var additionalColumnsForFilter = this.get('additionalColumnsForFilter');
      var additionalColumns = [];
      if (Ember.isPresent(additionalColumnsForFilter)) {
        additionalColumns = additionalColumnsForFilter;
      }

      return additionalColumns.concat(tableColumns);
    }),
    // overwritable components
    bodyComponent: 'table-pagination.table-body',
    contentComponent: 'table-pagination.table-content',
    footerComponent: 'table-pagination.table-footer',
    pagerComponent: 'table-pagination.table-pager',
    titleComponent: 'table-pagination.table-title',
    toolbarComponent: 'table-pagination.table-toolbar',
    toolsComponent: 'table-pagination.table-tools',
    noDataComponent: 'table-pagination.table-no-data',

    /**
     * pass in various custom properties, notice the pre-defined and expected properties below
     */
    contentParams: {},

    actions: {
      changeSort: function changeSort(property, direction) {
        var isRemoteHandled = this.get('isRemoteHandled');
        if (isRemoteHandled) {
          this.attrs.changeSort(property, direction);
        } else {
          this.set('sortProperty', property);
          if (direction) {
            this.set('sortDirection', direction);
          } else {
            this.set('sortDirection', null);
          }
        }
      },
      doNothing: function doNothing() {},
      runAdvancedSearch: function runAdvancedSearch() {
        if (typeof this.attrs.runAdvancedSearch === 'function') {
          this.attrs.runAdvancedSearch();
        }
      }
    }
  });
});