define('tm-common/models/analytic-stat', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        name: attr('string'),
        label: attr('string'),
        type: attr('string'),
        statData: attr()
    });
});