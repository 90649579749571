define('ember-cli-table-pagination/components/light-table/filter-box', ['exports', 'ember-cli-table-pagination/templates/components/light-table/filter-box'], function (exports, _filterBox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _filterBox.default,

    click: function click(e) {
      e.preventDefault();
      e.stopPropagation();
    },


    actions: {
      openModal: function openModal() {
        Ember.$('.adv-filter-modal').click();
      }
    }
  });
});