define('tm-common/components/subjects/analytics-modules/civil-analytics-form-row', ['exports', 'tm-common/templates/components/subjects/analytics-modules/civil-analytics-form-row'], function (exports, _civilAnalyticsFormRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _civilAnalyticsFormRow.default,

        partyTypeAnalyticsOptions: ['plaintiff', 'defendant', 'other'],

        showDeleteModal: false,

        selectedCaseTypeAnalytics: Ember.computed('civilItem.case_type_analytics_key', function () {
            return this.get('civilItem').case_type_analytics_key ? this.get('civilTypeOptions').findBy('shortName', this.get('civilItem').case_type_analytics_key) : null;
        }),

        actions: {
            caseTypeAnalyticsChanged: function caseTypeAnalyticsChanged(newValue) {
                if (newValue) {
                    this.set('civilItem.case_type_analytics_label', newValue.get('display'));
                    this.set('civilItem.case_type_analytics_key', newValue.get('shortName'));
                } else {
                    this.set('civilItem.case_type_analytics_label', null);
                    this.set('civilItem.case_type_analytics_key', null);
                }
            }
        }
    });
});