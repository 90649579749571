define('tm-common/models/passive-event', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        description: attr('string'),
        eventType: attr('string'),
        eventAction: attr('string'),
        createdOn: attr('estdate'),
        tableName: attr('string'),
        tableId: attr('number'),
        diff: attr('string'),
        oldData: attr('string'),
        newData: attr('string'),
        createdBy: belongsTo('user', { async: true }),
        matter: belongsTo('matter', { async: false }),

        eventActionTitle: Ember.computed('eventAction', function () {
            switch (this.get('eventAction')) {
                case 'New':
                    return 'created';
                case 'Edit':
                    return 'edited';
                case 'Delete':
                    return 'deleted';
                default:
                    return 'unknown action';
            }
        }),

        createdDay: Ember.computed('createdOn', function () {
            return (0, _moment.default)(this.get('createdOn')).format('MMM DD');
        }),
        createdOnFormatted: Ember.computed('createdOn', function () {
            return (0, _moment.default)(this.get('createdOn')).format('MM-DD-YY');
        })
    });
});