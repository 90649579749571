define('tm-common/models/firm', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        modelName: 'firm', // before we were using constructor.modelName but is no longer reliable

        name: attr('string'),
        pwId: attr('number'),
        qbName: attr('string'),
        tmNum: attr('number'),
        phone: attr('string'),
        address: attr('string'),
        suite: attr('string'),
        city: attr('string'),
        state: attr('string'),
        zip: attr('string'),
        country: attr('string'),
        region: attr('string'),
        industry: attr('string'),
        rating: attr('string'),
        scBusiness: attr('string'),
        history: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        recentMatterName: attr('string'),
        matterCounts: attr('number'),

        // relationships
        firmInvoiceHistory: belongsTo('firm-invoice-history', {
            async: false
        }),
        invoiceGroupMembers: hasMany('invoice-group-member', {
            async: false
        }),
        firmHasMatters: hasMany('firm-has-matter', {
            async: false
        }),
        matters: hasMany('matters', {
            async: false
        }),
        clients: hasMany('client', {
            async: false
        }),

        location: Ember.computed('state', 'city', function () {
            var delimiter = '';
            var state = this.get('state');
            var city = Ember.String.w(('' + this.get('city')).toLowerCase()).map(Ember.String.capitalize).join(' ');

            if (state && city) {
                delimiter = ', ';
            }

            return city + delimiter + state;
        })
    });
});