define('tm-common/services/popover', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        currentComponent: null, // bs-popover that is currently opened

        active: function active(component) {
            this.set('currentComponent', component);
        },
        close: function close() {
            this.set('currentComponent', null);
        }
    });
});