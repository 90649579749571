define('tm-common/components/field-validation-errors', ['exports', 'tm-common/templates/components/field-validation-errors'], function (exports, _fieldValidationErrors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _fieldValidationErrors.default,
        propertyName: '', // Field to extract validations from the changeset
        changeset: null // The ember-changeset that will execute the validations
    });
});