define('tm-common/models/deal-has-file', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        // these values are stored in the relationship
        // dealId: attr('number'),
        // fileId: attr('number'),

        // relationships
        file: belongsTo('file', { async: true }),
        deal: belongsTo('deal', { async: true })
    });
});