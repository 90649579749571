define('tm-common/models/crm-note-has-client', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        // only relationships here
        crmNote: belongsTo('crm-note', {
            async: false
        }),
        client: belongsTo('client', { async: true })
    });
});