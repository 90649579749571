define('tm-common/models/user-viewed-matter', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        createdOn: _emberData.default.attr('estdate'),
        updatedOn: _emberData.default.attr('estdate'),

        // relationships
        matter: _emberData.default.belongsTo('matter', {
            async: true
        }),
        user: _emberData.default.belongsTo('user', {
            async: false
        })
    });
});