define('tm-common/models/group', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        description: attr('string'),
        groupType: attr('string'),
        apiName: attr('string'),

        permissions: hasMany('permission', { async: true }),
        groupHasPermissions: hasMany('group-has-permission', { async: true }),

        /**
         * @computed
         *
         * used to differentiate the groups with a class on the badge
         */
        labelColor: Ember.computed('apiName', function () {
            switch (this.get('apiName')) {
                case 'Portal - Basic Access':
                    return 'label-default';
                case 'Portal - Contact':
                    return 'label-success';
                case 'Portal - Dormant':
                    return 'label-danger';
                default:
                    return 'label-warning';
            }
        }),

        isByTeam: Ember.computed.equal('id', 'by-team')
    });
});