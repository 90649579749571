define('tm-common/components/subjects/profile/modules/field-content', ['exports', 'tm-common/templates/components/subjects/profile/modules/field-content'], function (exports, _fieldContent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _fieldContent.default,
        classNames: ['subject-profile-info'],
        isExpanded: true,
        expandedObserver: Ember.observer('isExpanded', 'field.value', function () {
            var fieldValue = this.get('field.value');
            if (this.get('isExpanded')) {
                Ember.$('#' + fieldValue + 'Collapse').collapse('show');
                this.set('allAreCollapsed', false);
            } else {
                Ember.$('#' + fieldValue + 'Collapse').collapse('hide');
                this.set('allAreExpanded', false);
            }
        }),

        allAreExpandedObserver: Ember.observer('allAreExpanded', function () {
            if (this.get('allAreExpanded')) {
                this.set('isExpanded', true);
            }
        }),
        allAreCollapsedObserver: Ember.observer('allAreCollapsed', function () {
            if (this.get('allAreCollapsed')) {
                this.set('isExpanded', false);
            }
        })
    });
});