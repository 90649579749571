define('tm-common/components/sc-icon', ['exports', 'tm-common/templates/components/sc-icon'], function (exports, _scIcon) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _scIcon.default,
        svgHeight: '24px',
        svgWidth: '24px',
        tagName: '',

        /* eslint-disable-next-line ember/require-super-in-lifecycle-hooks */
        didReceiveAttrs: function didReceiveAttrs() {
            var _getProperties = this.getProperties('height', 'size', 'width'),
                height = _getProperties.height,
                size = _getProperties.size,
                width = _getProperties.width;

            if (size) {
                this.setProperties({
                    svgWidth: size + 'px',
                    svgHeight: size + 'px'
                });
            } else {
                if (width) {
                    this.setProperties({
                        svgWidth: size + 'px'
                    });
                }

                if (height) {
                    this.setProperties({
                        svgHeight: size + 'px'
                    });
                }
            }
        },


        computedClass: Ember.computed('class', 'color', function () {
            var _getProperties2 = this.getProperties('class', 'color'),
                classNames = _getProperties2.class,
                color = _getProperties2.color;

            if (!classNames && !color) {
                return null;
            }

            if (!classNames) {
                return 'svg-' + color;
            }

            return classNames + ' svg-' + color;
        })
    });
});