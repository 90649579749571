define('tm-common/models/simple-workflow', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        status: attr('string'),
        comment: attr('string'),
        priority: attr('string'),
        timeLimit: attr('string'),
        // joined assignedTo
        firstName: attr('string'),
        lastName: attr('string'),
        dueBy: attr('estdate'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        fullName: Ember.computed('firstName', 'lastName', function () {
            var fullName = '';
            if (Ember.isPresent(this.get('lastName'))) {
                fullName += this.get('lastName');
            }
            if (Ember.isPresent(this.get('firstName'))) {
                fullName += ', ' + this.get('firstName');
            }
            return Ember.$('<div/>').html(fullName).text();
        }),

        // could be a relationship
        // done as string since number was giving trouble with select box
        createdBy: attr('string'),
        updatedBy: attr('string'),

        // relationships
        assignedTo: belongsTo('employee', { async: true }),
        workflow: belongsTo('workflow', { async: true }),
        subject: belongsTo('subject', { async: true }),
        matterHasWorkflow: belongsTo('matter-has-workflow', { async: true }),
        collectionHasWorkflow: belongsTo('collection-has-workflow', { async: true })
    });
});