define('tm-common/models/shared-file', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        code: _emberData.default.attr(),
        deleteFileAfterExpire: _emberData.default.attr(),
        downloadedOn: _emberData.default.attr(),
        expiresAfterDownloadMin: _emberData.default.attr(),
        expiresMin: _emberData.default.attr(),
        note: _emberData.default.attr(),
        public: _emberData.default.attr('number'),
        shareMethod: _emberData.default.attr(),
        recipientUserEmail: _emberData.default.attr(),
        updatedOn: _emberData.default.attr(),
        fileType: _emberData.default.attr(),
        downloadUrl: _emberData.default.attr(),
        groupToken: _emberData.default.attr(),

        senderUser: _emberData.default.belongsTo('user'),
        recipientUser: _emberData.default.belongsTo('user'),
        resource: _emberData.default.belongsTo('resource'),

        sendEmailToGroup: (0, _emberApiActions.memberAction)({
            path: 'send_email_group',
            type: 'POST'
        })
    });
});