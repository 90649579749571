define('tm-common/components/subjects/sidebar/phase-one', ['exports', 'tm-common/templates/components/subjects/sidebar/phase-one'], function (exports, _phaseOne) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _phaseOne.default,
        analyticsFormsData: Ember.inject.service()
    });
});