define('tm-common/helpers/trunc-text-by-lines', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.truncTextByLines = truncTextByLines;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    /**
     * truncate a string to a maximum number of line breaks
     *
     * @param params
     * first parameter is expected to be the number of characters to truncate the text by
     * 2nd param is the number of lines
     *
     * @returns {*}
     */
    function truncTextByLines(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            str = _ref2[0],
            lines = _ref2[1];

        if (!Ember.isPresent(str)) {
            return '';
        }
        if (Ember.typeOf(str) !== 'string') {
            Ember.debug('Invalid input supplied to trunc-text-by-lines : ', Ember.typeOf(str), str);
            return '';
        }

        // array of possible line break codings.
        var possibleLineBreaks = ['\r\n', '\n\r', '\r', '\n'];

        var lineBreak = '';

        // try to determine what kind of line breaks are being used in this text.
        for (var i = 0; i < possibleLineBreaks.length; i++) {
            if (str.indexOf(possibleLineBreaks[i]) !== -1) {
                lineBreak = possibleLineBreaks[i];
                break;
            }
        }

        // if not line breaks are found, return original string.  otherwise, get the first four lines
        if (lineBreak === '') {
            return str;
        } else {
            // split the string into an array delimited by the value of the lineBreak variable
            var arr = str.split(lineBreak);

            var truncatedStr = '';
            var count = 0;
            for (i = 0; i < arr.length; i++) {
                // use the number passed in from the template to determine how many lines to include and break when that number is reached
                if (count === lines) {
                    break;
                }

                // ignore empty strings that appear before first actual line of text in the string
                if (arr[i] === '' && count === 0) {
                    continue;
                }

                truncatedStr = truncatedStr + arr[i] + lineBreak;
                count++;
            }

            return truncatedStr;
        }
    }

    exports.default = Ember.Helper.helper(truncTextByLines);
});