define('tm-common/components/dashboard-case-note', ['exports', 'tm-common/templates/components/dashboard-case-note'], function (exports, _dashboardCaseNote) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _dashboardCaseNote.default,

        showFullText: false,

        actions: {
            toggleShowFullText: function toggleShowFullText() {
                this.set('showFullText', !this.get('showFullText'));
            }
        }
    });
});