define('ember-cli-table-pagination/components/bs-table-pagination/table-footer', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-footer'], function (exports, _tableFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tableFooter.default,
    classNames: ['row', 'bs-table-pagination-footer']
  });
});