define('tm-common/components/matter/dashboard/dashboard-analytics/widgets/years-in-county-comparison', ['exports', 'tm-common/templates/components/matter/dashboard/dashboard-analytics/widgets/years-in-county-comparison'], function (exports, _yearsInCountyComparison) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _yearsInCountyComparison.default,

        chartData: Ember.computed('stat.analyticStat', function () {
            var _this = this;

            return {
                labels: this.get('stat.analyticStat.statData.labels'),
                datasets: [{
                    data: this.get('stat.analyticStat.statData.data').map(function (item) {
                        return (parseFloat(item) * 100).toFixed(0);
                    }),
                    backgroundColor: ['#215060', '#ea5f1c', '#40b5af'],
                    borderColor: 'white',
                    borderWidth: 1
                }],

                options: {
                    legend: {
                        display: false
                    },
                    showCustomLegend: true,
                    customLegendElementId: 'years-in-county-comparison-legend',
                    legendCallback: function legendCallback(chart) {
                        return '\n            <div class="col-md-12">\n                ' + _this.get('customLegendItems').map(function (i) {
                            return '\n                  <div class="row m-b-">\n                     <div class="col-md-8" style="padding-top:6px;">\n                        <div class="custom-legend-dot ' + i.colorClass + '"></div>\n                        <div class="custom-legend-label">' + i.label + '</div>\n                     </div>\n                     <div class="col-md-4">\n                        <div class="custom-legend-percent sc-font sc-blue">' + i.percentage + '%</div>\n                     </div>\n                  </div>\n                  ';
                        }).join('') + '\n            </div>\n         \n        ';
                    },
                    segmentShowStroke: true,
                    segmentStrokeColor: '#fff',
                    segmentStrokeWidth: 2,
                    percentageInnerCutout: 50,
                    animationSteps: 100,
                    animationEasing: 'easeOutBounce',
                    animateRotate: true,
                    responsive: true,
                    maintainAspectRatio: false,
                    showScale: true,
                    animateScale: true
                }
            };
        }),

        customLegendItems: Ember.computed('stat.analyticStat.statData.labels', function () {
            var data = this.get('stat.analyticStat.statData.data');

            return this.get('stat.analyticStat.statData.labels').map(function (item, i) {
                var colorClass = 'sc-analytics-bg-color-1';

                switch (i) {
                    case 0:
                        colorClass = 'sc-analytics-bg-color-1';
                        break;

                    case 1:
                        colorClass = 'sc-analytics-bg-color-2';
                        break;

                    case 2:
                        colorClass = 'sc-analytics-bg-color-3';
                        break;
                }

                return Ember.Object.create({
                    label: item,
                    colorClass: colorClass,
                    percentage: (parseFloat(data[i]) * 100).toFixed(0)
                });
            });
        })
    });
});