define("tm-common/utils/inner-join", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = innerJoin;
    /**
     * @function
     *
     * This function simulates an inner join from SQL in
     * plain javascript arrays with a clause `select` that
     * should return true in order to create the record as join.
     */
    function innerJoin(a, b, select) {
        var m = a.length;
        var n = b.length;
        var c = [];

        for (var i = 0; i < m; i++) {
            var x = a[i];

            for (var j = 0; j < n; j++) {
                // cartesian product - all combinations
                var y = select(x, b[j]); // filter out the rows and columns you want
                if (y) {
                    c.push(y); // if a row is returned add it to the table
                }
            }
        }

        return c;
    }
});