define('tm-common/components/subject-custom-modules/criminal', ['exports', 'tm-common/helpers/nl-2br', 'tm-common/templates/components/subject-custom-modules/criminal'], function (exports, _nl2br, _criminal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isExpanded: true,
        layout: _criminal.default,
        reRender: true,
        state: 'defaultReadOnly', // must be one of the following: defaultReadOnly, isEditing

        expandedObserver: Ember.observer('isExpanded', 'field.value', function () {
            var fieldValue = this.get('field.value');

            if (this.get('isExpanded')) {
                Ember.$('#' + fieldValue + 'Collapse').collapse('show');
                this.set('allAreCollapsed', false);
            } else {
                Ember.$('#' + fieldValue + 'Collapse').collapse('hide');
                this.set('allAreExpanded', false);
            }
        }),

        allAreExpandedObserver: Ember.observer('allAreExpanded', function () {
            if (this.get('allAreExpanded')) {
                this.set('isExpanded', true);
            }
        }),

        allAreCollapsedObserver: Ember.observer('allAreCollapsed', function () {
            if (this.get('allAreCollapsed')) {
                this.set('isExpanded', false);
            }
        }),

        displayAnalytics: Ember.computed('field.fieldType', function () {
            return this.get('field.fieldType') === 'analytics';
        }),

        isReadModeNoCriminal: Ember.computed('criminalReadMode', 'data.subject.subjectAnalyticsCriminalCases', function () {
            var criminalReadMode = this.get('data.subject.subjectAnalyticsCriminalCases');

            return criminalReadMode === 'No Criminal Records';
        }),

        criminalItems: Ember.computed('data.subject.subjectAnalyticsCriminalCases', function () {
            var criminalData = this.get('data.subject.subjectAnalyticsCriminalCases');
            var items = [];

            if (criminalData !== 'No Criminal Records') {
                criminalData.sortBy('order').forEach(function (criminal) {
                    var item = '';

                    if (criminal.year) {
                        item += criminal.year;
                    }

                    if (item && criminal.case_type) {
                        item += ', ';
                    }

                    if (criminal.case_type) {
                        item += ' <strong><i>' + criminal.case_type + '</i></strong>';
                    }

                    if (criminal.qty && criminal.qty > 1) {
                        item += ' (' + criminal.qty + ' cases)';
                    }

                    if (item && criminal.case_notes && !criminal.is_markdown) {
                        item += '; ';
                    }

                    if (criminal.case_notes && !criminal.is_markdown) {
                        item += (0, _nl2br.nl2br)([criminal.case_notes]);
                    }

                    if (criminal.case_notes && criminal.is_markdown) {
                        item += SimpleMDE.prototype.markdown(criminal.case_notes);
                    }

                    if (item) {
                        item = item.replace(/  +/g, ' ');
                        items.push(item);
                    }
                });
            }

            return items;
        }),

        actions: {
            usePhrase: function usePhrase(phrase) {
                var fieldPath = 'data.subject.criminal';
                var breaks = '';

                for (var i = 0; i <= phrase.get('breaks'); i += 1) {
                    breaks += '\n';
                }

                var previousVal = this.get(fieldPath);

                this.set('reRender', false);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));

                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            }
        }
    });
});