define('tm-common/models/subject-workflow-column', ['exports', 'ember-data', 'ember-cli-table-pagination/utils/operators-list'], function (exports, _emberData, _operatorsList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        displayName: attr('string'),
        fieldName: attr('string'),
        mergeField: attr('string'),
        apiName: attr('string'),
        order: attr('number'),

        // these two are used for presentational purposes on the list page
        workflowAssignedColumn: _emberData.default.attr('boolean'),
        workflowStatusColumn: _emberData.default.attr('boolean'),
        workflowCommentColumn: _emberData.default.attr('boolean'),
        workflowTimeColumn: _emberData.default.attr('boolean'),
        workflowTimeOnlyColumn: _emberData.default.attr('boolean'),
        workflowDeadlineColumn: _emberData.default.attr('boolean'),

        workflowColumn: _emberData.default.attr('boolean'),

        // list this field in the search component?
        enableSearch: true,

        // list this field in the main listing?
        enableDisplay: true,

        // show the quick filter or not
        showingFilter: false,

        // value on the filter for this column
        filterValue: null,

        // To make a column not searchable/sortable
        disableServerInteractions: false,

        // Component used to display the cell in the table
        cellComponent: null,

        // Component used to capture input from the advanced filter
        advFilterComponent: null,

        // calculate to pull either the apiName or fieldName
        apiInteractionName: Ember.computed('fieldName', 'apiName', function () {
            if (Ember.isPresent(this.get('apiName'))) {
                return this.get('apiName');
            } else {
                // ie lastName >>  last_name
                return this.get('fieldName').underscore();
            }
        }),

        width: attr('string'),

        usesPowerSelect: attr('boolean'),
        valuePath: null,
        displayPath: null,

        // Advanced Filter fields
        advFilterOperator: Ember.Object.create({
            display: 'Contains',
            value: 'contains',
            input: 1
        }),
        advFilterValue: undefined,
        advFilterValue2: undefined,

        filterValueChanged: function filterValueChanged(col) {
            // overwrite with function that will be called on change
        },
        filterValueObserver: Ember.observer('filterValue', function () {
            this.get('filterValueChanged')(this);
        }),

        observeHeader: Ember.observer('filterValue', function () {
            if (this.get('filterValue') === null || this.get('filterValue') === '') {
                this.clearFilter(false);
            } else {
                this.set('advFilterValue', this.get('filterValue'));
            }
        }),

        getFilterString: function getFilterString() {
            var filter = '';
            var value = this.get('advFilterValue');
            var value2 = this.get('advFilterValue2');
            this.set('filterValue', value);
            if (value === null || value === undefined) return null;
            switch (this.get('advFilterOperator.value')) {
                case 'contains':
                    filter = '*' + value + '*';
                    break;
                case 'not_contains':
                    filter = '!' + value + '!';
                    break;
                case 'equal':
                    filter = value;
                    break;
                case 'not_equal':
                    filter = '!=' + value;
                    break;
                case 'blank':
                    filter = 'NULL||';
                    break;
                case 'not_blank':
                    filter = '!NULL||!=';
                    break;
                case 'between':
                    filter = '~' + value + '~' + value2;
                    break;
                case 'in':
                    filter = value;
                    break;
            }
            return filter;
        },
        clearFilter: function clearFilter() {
            var clearFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            if (clearFilter) {
                this.set('filterValue', null);
            }
            this.set('advFilterValue', null);
            this.set('advFilterValue2', null);
            this.set('advFilterOperator', (0, _operatorsList.getOperator)(this.get('acceptedOperators')));
        }
    });
});