define('tm-common/components/basic-modal', ['exports', 'tm-common/templates/components/basic-modal'], function (exports, _basicModal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _basicModal.default,

        // set a default style for the modal, can be customized during implementation
        modalStyle: 'display: block;',

        autoCloseOnOk: false,
        useFixedHeight: true,

        showActionButtonsAtTop: false,

        closeText: 'Close',
        okText: 'Ok',
        hideOk: false,
        hideCancel: false,

        hasForm: false,
        actions: {
            ok: function ok() {
                if (this.get('hasForm')) {
                    var form = this.$('.modal-body form');
                    if (form.length > 0) {
                        // trigger submit event on body form
                        form.trigger('submit');
                    }
                } else {
                    if (this.get('autoCloseOnOk')) {
                        this.$('.modal').modal('hide');
                    }
                    this.sendAction('ok', this.get('model'));
                }
            }
        },

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.$('.modal').modal().on('hidden.bs.modal', function () {
                _this.sendAction('close');
            });
        },
        willDestroyElement: function willDestroyElement() {
            this.$('.modal').modal('hide');
        },


        // place holder for supplied mode
        model: false
    });
});