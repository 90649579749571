define('tm-common/components/subjects/analytics-modules/employment-status-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/employment-status-module'], function (exports, _employmentStatusModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _employmentStatusModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        isGovernmentSector: Ember.computed('data.subject.governmentSectorCurrent', function () {
            var governmentSector = this.get('data.subject.governmentSectorCurrent');
            return governmentSector && governmentSector === '1';
        }),

        isMilitaryVeteranCurrent: Ember.computed('data.subject.militaryVeteranCurrent', function () {
            var militaryVeteranCurrent = this.get('data.subject.militaryVeteranCurrent');
            return militaryVeteranCurrent && militaryVeteranCurrent === '1';
        }),

        showLicenseTypes: Ember.computed('data.subject.employmentAnalysis', function () {
            var employmentAnalysis = this.get('data.subject.employmentAnalysis');
            return employmentAnalysis && employmentAnalysis === 'Holds / Held professional license';
        }),

        isEmployed: Ember.computed('data.employmentOptions.employmentStatus.@each.checked', function () {
            var employmentOptions = this.get('data.employmentOptions.employmentStatus');
            var employedOption = employmentOptions.findBy('shortName', 'is_employed');
            return employedOption.get('checked');
        }),

        noProfessionalLicense: Ember.computed('data.employmentOptions.employmentAnalysis.[]', function () {
            var employmentOptions = this.get('data.employmentOptions.employmentAnalysis');
            return employmentOptions.findBy('shortName', 'analytics_employment_none');
        }),

        hasProfessionalLicense: Ember.computed('data.employmentOptions.employmentAnalysis.[]', function () {
            var employmentOptions = this.get('data.employmentOptions.employmentAnalysis');
            return employmentOptions.findBy('shortName', 'holds_professional_license');
        }),

        unknownEmploymentStatusOption: Ember.computed('data.employmentOptions.employmentStatus', function () {
            var employmentStatus = this.get('data.employmentOptions.employmentStatus');
            return employmentStatus.findBy('shortName', 'employment_status_unknown');
        }),

        showEmploymentData: Ember.computed('data.subject.subjectAnalyticsEmploymentStatus', 'data.subject.governmentSectorCurrent', 'data.subject.militaryVeteranCurrent', 'data.subject.subjectAnalyticsEmploymentAnalysisCategory', function () {
            var subjectAnalyticsEmploymentStatus = this.get('data.subject.subjectAnalyticsEmploymentStatus');
            var governmentSectorCurrent = this.get('data.subject.governmentSectorCurrent');
            var militaryVeteranCurrent = this.get('data.subject.militaryVeteranCurrent');
            var subjectAnalyticsEmploymentAnalysisCategory = this.get('data.subject.subjectAnalyticsEmploymentAnalysisCategory');

            var rtn = false;

            if (subjectAnalyticsEmploymentStatus && subjectAnalyticsEmploymentStatus.length > 0) {
                rtn = true;
            }
            if (governmentSectorCurrent && governmentSectorCurrent !== '') {
                rtn = true;
            }
            if (militaryVeteranCurrent && militaryVeteranCurrent !== '') {
                rtn = true;
            }
            if (subjectAnalyticsEmploymentAnalysisCategory && subjectAnalyticsEmploymentAnalysisCategory.length > 0) {
                rtn = true;
            }

            return rtn;
        }),

        isEmployedChanged: Ember.observer('isEmployed', function () {
            if (!this.get('isEmployed')) {
                this.set('data.subject.employmentPositionCurrent', null);
                this.set('data.subject.governmentSectorCurrent', null);
                this.set('data.subject.militaryVeteranCurrent', null);
                this.set('data.subject.employmentTypeCurrent', null);
            }
        }),

        employmentValues: Ember.computed('data.subject.subjectAnalyticsEmploymentStatus.[]', 'data.subject.employmentPositionCurrent', function () {
            var statuses = this.get('data.subject.subjectAnalyticsEmploymentStatus');
            var position = this.get('data.subject.employmentPositionCurrent');
            var values = [];
            statuses.forEach(function (status) {
                if (status === 'Employed') {
                    var value = status;
                    if (position) {
                        value += ' - ' + position;
                    }
                    values.push(value);
                } else {
                    values.push(status);
                }
            });
            return values;
        }),

        allEmploymentValues: Ember.computed('employmentValues.[]', 'overviewSection', 'isGovernmentSector', 'isMilitaryVeteranCurrent', function () {
            var values = this.get('employmentValues');
            if (this.get('overviewSection')) {
                if (this.get('isGovernmentSector')) {
                    values.push('Government Sector');
                }

                if (this.get('isMilitaryVeteranCurrent')) {
                    values.push('Military');
                }
            }
            return values.join(', ');
        }),

        actions: {
            updateSubject: function updateSubject() {
                this.set('data.subject.subjectAnalyticsEmploymentStatus', this.get('data.employmentOptions'));
            }
        }
    });
});