define('tm-common/models/list-template', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        listTemplateGroup: belongsTo('list-template-group', { async: true }),
        taskTemplates: hasMany('task-template', { async: true }),

        listTemplateGroupWritable: Ember.computed('listTemplateGroup', {
            get: function get(key) {
                return this.get('listTemplateGroup.content');
            },
            set: function set(key, value) {
                this.set('listTemplateGroup', value);
                return value;
            }
        })
    });
});