define('tm-common/components/subjects/profile/accordion-module', ['exports', 'tm-common/templates/components/subjects/profile/accordion-module', 'tm-common/mixins/analytics-util'], function (exports, _accordionModule, _analyticsUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _accordionModule.default,

        analyticsFormsData: Ember.inject.service(),

        isJuryCase: false,
        isHighLevelCase: false,
        allAreExpanded: true,
        allAreCollapsed: false,

        reRender: true,
        showHighlightsField: Ember.computed('field', 'isJuryCase', 'isHighLevelCase', function () {
            if (this.get('isJuryCase') || this.get('isHighLevelCase')) {
                var fieldValue = this.get('field.value');
                switch (fieldValue) {
                    case 'marital_analytics':
                    case 'criminal':
                    case 'traffic_analytics':
                    case 'criminal_analytics':
                    case 'civil':
                    case 'civil_analytics':
                    case 'employment_status_analytics':
                    case 'employment_analytics':
                    case 'employment':
                    case 'education_analytics':
                    case 'case_related_analytics':
                    case 'social_media_analytics':
                    case 'social_media':
                    case 'counties':
                    case 'states_analytics':
                    case 'children_analytics':
                        return true;
                    default:
                        return false;
                }
            }
            return false;
        }),

        showMiscField: Ember.computed('field', 'isJuryCase', 'isHighLevelCase', function () {
            if (this.get('isJuryCase') || this.get('isHighLevelCase')) {
                var fieldValue = this.get('field.value');
                switch (fieldValue) {
                    case 'education_analytics':
                    case 'marital_analytics':
                    case 'case_related_analytics':
                        return true;
                    default:
                        return false;
                }
            }
            return false;
        }),

        actions: {
            singleFieldChange: function singleFieldChange() {
                if (this.get('isSingleAutoSaving')) {
                    this.debounceTask('saveData', 5000);
                }
            },
            save: function save() {
                var _this = this;

                this.normalizeAndSetAnalytics(this.get('data.subject'), this.get('analyticsFormsData.dataForProfile'));
                var promise = this.attrs.save(false, false);
                promise.then(function () {
                    _this.sendAction('openFieldChanged', null);
                    _this.toggleProperty('isSingleEdit');
                    _this.set('isSsanVisible', false);
                    _this.attrs.closeEditInPlace();
                });
            },
            expandAll: function expandAll() {
                this.set('allAreExpanded', true);
                this.set('allAreCollapsed', false);
                Ember.$('.analytics-accordion.subject-profile-panel .collapse').collapse('show');
            },
            collapseAll: function collapseAll() {
                this.set('allAreCollapsed', true);
                this.set('allAreExpanded', false);
                Ember.$('.analytics-accordion.subject-profile-panel .collapse').collapse('hide');
            },
            usePhrase: function usePhrase(phrase, field) {
                var fieldPath = 'model.' + field.get('name');
                var breaks = '';
                for (var i = 0; i <= phrase.get('breaks'); i++) {
                    breaks += '\n';
                }
                this.set('reRender', false);
                var previousVal = this.get(fieldPath);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));
                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            }
        }
    });
});