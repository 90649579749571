define('tm-common/helpers/get-date', ['exports', 'moment-timezone'], function (exports, _momentTimezone) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toArray(arr) {
        return Array.isArray(arr) ? arr : Array.from(arr);
    }

    exports.default = Ember.Helper.extend({
        compute: function compute(_ref, hash) {
            var _ref2 = _toArray(_ref),
                value = _ref2[0],
                rest = _ref2.slice(1);

            if (value) {
                if (Ember.testing) {
                    return (0, _momentTimezone.default)(value).toDate();
                }
                var result = (0, _momentTimezone.default)(_momentTimezone.default.tz(value, 'America/New_York').format('YYYY-MM-DD') + 'T00:00:00').toDate();
                return result;
            }
            return null;
        }
    });
});