define('tm-common/models/field-phrase', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        field: attr('string'),
        phrase: attr('string'),
        breaks: attr('number'),
        weight: attr('number'),
        hidden: attr('number'),

        // relationships
        matter: belongsTo('matter', { async: true }),

        name: Ember.computed('field', function () {
            var name = this.get('field');
            var parts = name.split(' | ');
            if (parts.length === 2) {
                return parts[1];
            } else {
                return name;
            }
        }),

        isSubjectNote: Ember.computed('field', function () {
            var parts = this.get('field').split(' | ');
            return parts.length === 2;
        })
    });
});