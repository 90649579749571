define('tm-common/models/team', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        mode: _emberData.default.attr('string'),
        employees: _emberData.default.attr('csv'),

        userHasTeam: _emberData.default.hasMany('user-has-team', {
            async: false
        })
    });
});