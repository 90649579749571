define('tm-common/components/matter/dashboard/dashboard-analytics/widgets/state-migration-comparison', ['exports', 'tm-common/templates/components/matter/dashboard/dashboard-analytics/widgets/state-migration-comparison'], function (exports, _stateMigrationComparison) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _stateMigrationComparison.default,

        chartData: Ember.computed('stat.analyticStat', function () {
            return {
                labels: this.get('stat.analyticStat.statData.labels'),
                datasets: [{
                    data: this.get('stat.analyticStat.statData.data').map(function (item) {
                        return (parseFloat(item) * 100).toFixed(0);
                    }),
                    backgroundColor: ['#F9CEB8']
                }]
            };
        })
    });
});