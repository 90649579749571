define('ember-cli-table-pagination/components/light-table-pagination/table-actions', ['exports', 'ember-cli-table-pagination/templates/components/light-table-pagination/table-actions'], function (exports, _tableActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tableActions.default,
    tagName: ''
  });
});