define('tm-common/components/subjects/profile/modules/non-jury/criminal-cases-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/non-jury/criminal-cases-module'], function (exports, _criminalCasesModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _criminalCasesModule.default,

        classNames: ['inplace-edit-profile-data'],

        showCriminalHistory: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');

            if (field.get('value') === 'criminal') {
                return true;
            }
        }),

        highlightsField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            var highlights = sortedMatterFields.findBy('value', 'highlights');
            if (highlights) {
                return highlights;
            } else {
                return false;
            }
        }),

        showCriminalAnalytics: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            if (field.get('value') === 'criminal_analytics') {
                return true;
            } else {
                var criminalAnalyticsField = sortedMatterFields.find(function (mf) {
                    if (mf.get('value') === 'criminal_analytics') {
                        return true;
                    }
                    return false;
                });

                if (criminalAnalyticsField && criminalAnalyticsField.get('mergeField') === 'criminal') {
                    return true;
                }
            }

            return false;
        }),

        criminalNotesField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'criminal_history');
        }),

        showCriminalNotes: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            if (field.get('value') === 'criminal_history') {
                return true;
            } else {
                var criminalNotesField = sortedMatterFields.find(function (mf) {
                    if (mf.get('value') === 'criminal_history') {
                        return true;
                    }
                    return false;
                });

                if (criminalNotesField && criminalNotesField.get('mergeField') === 'criminal') {
                    return true;
                }
            }

            return false;
        })
    });
});