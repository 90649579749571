define('tm-common/services/go-back', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Service.extend({
        /**
         * @property
         * @private
         * used to keep a stack of routes visited by the user
         */
        routesStack: Ember.A(),
        /**
         * @service
         * @private
         * used to move a user to a different route
         */
        routing: Ember.inject.service('-routing'),

        scrollPositions: {},

        goToPositionWaitTime: 1000, // in milliseconds

        /**
         * @method
         * @public
         * this receives a Transition.handlerInfos array and the queryParams of a transition
         * with this info we keep the stack up to date
         */
        trackOrigin: function trackOrigin(handlerInfos, queryParams) {
            var _this = this;

            var scrollbarPosition = Ember.$('html').scrollTop();
            // Contexts are extracted from each handlerInfo
            // and they are the dynamic segments of a route
            var contexts = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = handlerInfos[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var info = _step.value;
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = Object.values(info.params)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var value = _step2.value;

                            contexts.push(value);
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }

                // We get the route name from the last handler info which is the new route
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var lastHandler = Ember.A(handlerInfos).get('lastObject');
            var name = lastHandler ? lastHandler.name : '';

            // When a query param is changed this can be tracked, but if the name matches the previous route
            // we just delete the previous route, and add the new route
            var lastRoute = this.get('routesStack').popObject();
            if (Ember.isPresent(lastRoute) && lastRoute.name === name) {
                Ember.debug('This route was replaced during `track` because it is a duplicate from last one ' + name);
            } else {
                if (Ember.isPresent(lastRoute)) {
                    var existTable = Ember.$('.lt-scrollable table');
                    if (existTable.length) {
                        var positions = this.get('scrollPositions');
                        positions[lastRoute.name] = existTable.offset();
                        this.set('scrollPositions', positions);
                    }

                    lastRoute.scrollbarPosition = scrollbarPosition;
                    this.get('routesStack').pushObject(lastRoute);
                }
            }
            // Data needed to transition back
            var addRoute = {
                name: name,
                queryParams: queryParams,
                contexts: contexts,
                scrollbarPosition: scrollbarPosition
            };

            var currentPositions = this.get('scrollPositions');
            var waitTime = this.get('goToPositionWaitTime');
            if (Ember.isPresent(currentPositions[name])) {
                Ember.run.later(function () {
                    var ltScrollable = Ember.$('.lt-scrollable');
                    var scrollTable = Ember.$('.lt-scrollable table');
                    if (scrollTable.length) {
                        var fromTop = ltScrollable.offset().top;
                        var perc = Math.abs(currentPositions[name].top - fromTop) / scrollTable.height();
                        var componentScroll = Ember.getOwner(_this).lookup('-view-registry:main')[ltScrollable[0].id];
                        componentScroll.send('recalculate');
                        componentScroll.send('verticalDrag', perc);
                    }
                }, waitTime);
            }

            this.get('routesStack').pushObject(addRoute);
        },


        /**
         * @method
         * @public
         * used to redirect the user to the previous route
         */
        toPreviousOrigin: function toPreviousOrigin() {
            var previousRoute = this._getPreviousTransition();

            if (Ember.isPresent(previousRoute)) {
                var router = this.get('routing');
                var transition = router.transitionTo(previousRoute.name, previousRoute.contexts, {
                    queryParams: previousRoute.queryParams
                });
                transition.finally(function () {
                    Ember.run.later(function () {
                        Ember.$('html').scrollTop(previousRoute.scrollbarPosition);
                    }, 1000);
                });
            }
        },


        /**
         * @method
         * @public
         * used to get the name of the previous route
         */
        previousRouteName: function previousRouteName() {
            var stack = [].concat(_toConsumableArray(this.get('routesStack')));
            var currentRoute = stack.popObject();
            if (!Ember.isPresent(currentRoute)) {
                return null;
            }
            var previousRoute = void 0;

            do {
                previousRoute = stack.pop();
            } while (Ember.isPresent(previousRoute) && currentRoute.name === previousRoute.name);

            if (Ember.isPresent(previousRoute)) {
                return previousRoute.name;
            }

            return null;
        },


        /**
         * @method
         * @public
         *
         * check if the stack is empty
         */
        canGoToPreviousOrigin: function canGoToPreviousOrigin() {
            return this.get('routesStack').length > 1;
        },


        /**
         * An alternative implementation of toPreviousOrigin that doesn't require having previously
         * called canGoToPreviousOrigin.
         *
         * Accepts an optional skip option that allows users to pass routes they're not interested to land on
         *
         * Returns false if it wasn't possible to do the transition, or the route details if
         * a transition was initiated
         */
        back: function back() {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                skip = _ref.skip;

            var stack = [].concat(_toConsumableArray(this.get('routesStack')));
            var currentRoute = stack.pop();
            if (!currentRoute) {
                return false;
            }

            var remove = [currentRoute];
            var previousRoute = void 0;

            do {
                previousRoute = stack.pop();
                remove.push(previousRoute);
            } while (previousRoute && (currentRoute.name === previousRoute.name || skip === previousRoute.name));

            if (!previousRoute) {
                return false;
            }

            this.get('routesStack').removeObjects(remove);

            var router = this.get('routing');
            var transition = router.transitionTo(previousRoute.name, previousRoute.contexts, {
                queryParams: previousRoute.queryParams
            });
            transition.finally(function () {
                Ember.run.later(function () {
                    Ember.$('html').scrollTop(previousRoute.scrollbarPosition);
                }, 1000);
            });

            return previousRoute;
        },


        /**
         * @method
         * @private
         * used to get the previous route different that the current one
         */
        _getPreviousTransition: function _getPreviousTransition() {
            var currentRoute = this.get('routesStack').popObject();
            if (!Ember.isPresent(currentRoute)) {
                return;
            }
            var previousRoute = void 0;

            do {
                previousRoute = this.get('routesStack').pop();
            } while (Ember.isPresent(previousRoute) && currentRoute.name === previousRoute.name);

            return previousRoute;
        },
        _reset: function _reset() {
            this.set('routesStack', Ember.A());
        }
    });
});