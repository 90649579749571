define('tm-common/models/resource', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        childTable: attr('string'),
        syncId: attr('number'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        viewable: attr('number'),
        doNotUse: attr('number'),

        // should be a relationships
        // createdById: attr('number'),
        updatedById: attr('number'),

        // relationships
        createdBy: belongsTo('user', { async: true }),
        matter: belongsTo('matter', {
            async: false
        }),
        resourceHasTags: _emberData.default.hasMany('resource-has-tag', { async: false })
    });
});