define('tm-common/models/note', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        body: _emberData.default.attr('string'),
        sendEmail: _emberData.default.attr('boolean'),
        recipients: _emberData.default.attr('string'),
        noteType: _emberData.default.attr('string'), // type is a reserved word in the api
        monitorActivity: _emberData.default.attr('string'),
        hasDocuments: _emberData.default.attr('boolean'),
        documentCount: _emberData.default.attr('number'),
        documentId: _emberData.default.attr('number'), // the id of the only document that is attached to the note
        taskId: _emberData.default.attr('number'), // the id of the only document that is attached to the note

        // replies on case notes
        children: _emberData.default.hasMany('note', { async: false, inverse: 'parent' }),
        parent: _emberData.default.belongsTo('note', { async: false, inverse: 'children' }),

        // resource fields
        childTable: _emberData.default.attr('string'),
        syncId: _emberData.default.attr('number'),
        parentId: _emberData.default.attr('number'),
        subjectId: _emberData.default.attr('number'),
        createdOn: _emberData.default.attr('estdate'),
        updatedOn: _emberData.default.attr('estdate'),
        viewable: _emberData.default.attr('number'),
        doNotUse: _emberData.default.attr('number', { defaultValue: 0 }),

        // relationships
        createdBy: _emberData.default.belongsTo('user', { async: true }),
        updatedBy: _emberData.default.belongsTo('user', { async: true }),
        matter: _emberData.default.belongsTo('matter', { async: true }),

        vNoteParents: _emberData.default.hasMany('v-note-parent', { async: true, inverse: 'note' }),

        // computed
        isViewable: Ember.computed('viewable', function () {
            return parseInt(this.get('viewable'), 10) === 1;
        }),

        recipientList: Ember.computed('recipients', function () {
            var self = this;
            var employeeList = [];
            var rec = this.get('recipients');
            if (Ember.isEmpty(rec)) {
                return [];
            }
            rec.split('/ ').forEach(function (recipient) {
                var employeeParts = recipient.split(':');
                var id = employeeParts[0];
                var employee = self.store.findRecord('employee', id);
                employeeList.pushObject(employee);
            });
            return employeeList;
        }),

        hasRecipients: Ember.computed.notEmpty('recipients'),

        truncatedBody: Ember.computed('body', function () {
            var body = this.get('body');
            return Ember.$.trim(body).substring(0, 100);
        })
    });
});