define('tm-common/components/subjects/analytics-modules/property-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/property-module'], function (exports, _propertyModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _propertyModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        propertyOwnershipObserver: Ember.observer('data.subject.property', function () {
            if (this.get('data.subject.property') !== 'Owns Property') {
                this.set('data.subject.primaryPropertyValue', null);
            }
        }),

        showPropertyValueError: Ember.computed('data.subject.property', 'data.subject.primaryPropertyValue', function () {
            if (this.get('data.subject.property') === 'Owns Property') {
                var value = parseFloat(this.get('data.subject.primaryPropertyValue'));
                return value === 0 || isNaN(value);
            } else {
                return false;
            }
        })
    });
});