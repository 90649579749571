define('tm-common/components/subject-field-read', ['exports', 'tm-common/templates/components/subject-field-read'], function (exports, _subjectFieldRead) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _subjectFieldRead.default,

        isTable: false,
        excludeSpecialChildren: false,

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        isTmAdmin: Ember.computed('ENV', function () {
            return this.get('ENV.modulePrefix') === 'tm3';
        }),

        // these are used if the component is inside the trimmable-subject-field-read (to get the content and apply truncate on that text is needed)
        textContent: null,
        didRender: function didRender() {
            this._super.apply(this, arguments);
            this.set('textContent', this.get('element.textContent').trim());
        },


        fieldValue: Ember.computed('currentMatterFieldValue', function () {
            var currentMatterFieldValue = this.get('currentMatterFieldValue');
            if (currentMatterFieldValue.indexOf(':') > -1) {
                return currentMatterFieldValue.split(':')[1];
            }
            return currentMatterFieldValue;
        }),

        field: Ember.computed('fieldValue', 'sortedMatterFields.[]', function () {
            return this.get('sortedMatterFields').findBy('value', this.get('fieldValue'));
        }),

        allChildren: Ember.computed('matterFieldFamilies', 'field', function () {
            var field = this.get('field');
            var allChildren = [];

            // we need this check. workflow columns do not have a corresponding matterFields (field) nor any children
            if (field) {
                var currentFieldValue = field.get('value');
                var matterFieldFamilies = this.get('matterFieldFamilies');
                if (currentFieldValue && matterFieldFamilies) {
                    if (Object.keys(matterFieldFamilies).includes(currentFieldValue)) {
                        allChildren = matterFieldFamilies[currentFieldValue];
                    }
                }
            }
            return allChildren;
        }),

        children: Ember.computed('allChildren.[]', function () {
            var _this = this;

            // all child fields EXCEPT _note fields
            var allChildren = this.get('allChildren');
            return allChildren.filter(function (child) {
                if (child.get('isNoteField')) {
                    return false;
                }

                if (_this.get('excludeSpecialChildren') && ([1, 2].indexOf(_this.get('field.section')) === -1 && [1, 2].indexOf(child.get('section')) > -1 || // parent is regular & child is special
                [1, 2].indexOf(_this.get('field.section')) > -1 && [1, 2].indexOf(child.get('section')) > -1 && child.get('section') !== _this.get('field.section')) // parent and child are special, but in different sections
                ) {
                        return false;
                    }

                return true;
            }).map(function (field) {
                field.set('showLabel', field.get('mergeField') === 'misc' || ['Yes/No', 'bool'].includes(field.get('listName')));
                return field;
            });
        }),

        childNoteFields: Ember.computed('allChildren.[]', function () {
            var _this2 = this;

            var allChildren = this.get('allChildren');
            return allChildren.filter(function (child) {
                if (!child.get('isNoteField')) {
                    return false;
                }

                if (_this2.get('excludeSpecialChildren') && ([1, 2].indexOf(_this2.get('field.section')) === -1 && [1, 2].indexOf(child.get('section')) > -1 || // parent is regular & child is special
                [1, 2].indexOf(_this2.get('field.section')) > -1 && [1, 2].indexOf(child.get('section')) > -1 && child.get('section') !== _this2.get('field.section')) // parent and child are special, but in different sections
                ) {
                        return false;
                    }

                return true;
            });
        }),

        displayedFields: Ember.computed('field', 'children.[]', function () {
            var displayedFields = [this.get('field')];
            displayedFields.pushObjects(this.get('children'));
            return displayedFields.sortBy('weight');
        }),

        numberOfFields: Ember.computed('displayedFields.[]', function () {
            return this.get('displayedFields.length') - 1;
        }),

        hideNotes: Ember.computed('isTable', 'isTmAdmin', function () {
            return this.get('isTmAdmin') && !this.get('isTable');
        })
    });
});