define('tm-common/models/db-memo', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        body: attr('string'),
        tmNum: attr('string'),
        isPending: attr('boolean', { defaultValue: false }),
        isBlank: attr('boolean', { defaultValue: false }),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        db: belongsTo('db', { async: true }),
        subjects: hasMany('subject', { async: true }),

        createdBy: belongsTo('employee', { async: true }),
        updatedBy: belongsTo('employee', { async: true }),

        // resource fields
        matter: belongsTo('matter', { async: true }),

        // UI state
        isExpanded: attr('boolean', { defaultValue: false }),
        isEditing: attr('boolean', { defaultValue: false }),

        // has the logged in user viewed this resource recently
        hasBeenViewed: attr('number'),

        selectSubjects: (0, _emberApiActions.collectionAction)({ path: 'select_subjects', type: 'post' })
    });
});