define('tm-common/models/file-to-upload', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        file: null,
        name: '',
        size: '',
        fileType: '',
        documentType: '',
        subjectId: null,
        viewable: 0,

        /**
         * calculate a friendly file size for display
         */
        friendlySize: Ember.computed('size', function () {
            var bytes = this.get('size');
            var decimals = 0;

            if (bytes === 0) {
                return '0 Byte';
            }
            var k = 1024; // Or 1 kilo = 1000
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
        })
    });
});