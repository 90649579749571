define('tm-common/components/subjects/profile/modules/field-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/field-module'], function (exports, _fieldModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _fieldModule.default,
        analyticsFormsData: Ember.inject.service(),
        classNames: ['inplace-edit-profile-data'],
        // for current field get parent field and all children fields
        otherFields: Ember.computed('field', 'sortedMatterFields', function () {
            var fieldValue = this.get('field.value');
            var otherFields = [];
            var sortedMatterFields = this.get('sortedMatterFields');

            if (!this.get('field.isNoteField')) {
                // When the user clicks to edit the note child, then just the note field appears for edit
                // parent
                var parentField = sortedMatterFields.findBy('value', this.get('field.mergeField'));
                if (parentField) {
                    otherFields.push(parentField);
                }
            }

            // children
            sortedMatterFields.forEach(function (matterField) {
                if (matterField.get('mergeField') === fieldValue) {
                    otherFields.push(matterField);
                }
            });
            return otherFields;
        }),
        highlightsField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            var highlights = sortedMatterFields.findBy('value', 'highlights');
            if (highlights) {
                return highlights;
            } else {
                return false;
            }
        }),
        miscField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            var misc = sortedMatterFields.findBy('value', 'misc');
            if (misc) {
                return misc;
            } else {
                return false;
            }
        }),
        allFields: Ember.computed('field', 'otherFields.[]', 'highlightsField', 'miscField', 'showHighlightsField', 'showMiscField', function () {
            var _this = this;

            var allFields = [];
            var otherFields = this.get('otherFields');

            var highlightsField = this.get('highlightsField');
            if (highlightsField && this.get('showHighlightsField')) {
                allFields.push(Ember.Object.create({
                    matterField: highlightsField
                }));
            }
            var miscField = this.get('miscField');
            if (miscField && this.get('showMiscField')) {
                allFields.push(Ember.Object.create({
                    matterField: miscField
                }));
            }

            if (this.get('field.fieldClass') === 'widget') {
                if (this.get('widgetFieldModules').findBy('field', this.get('field.value'))) {
                    allFields.push(Ember.Object.create({
                        widgetFieldModule: this.get('widgetFieldModules').findBy('field', this.get('field.value')),
                        matterField: this.get('field')
                    }));
                } else if (this.get('fieldWithParentModules').findBy('childField', this.get('field.value'))) {
                    allFields.push(Ember.Object.create({
                        widgetFieldModule: this.get('fieldWithParentModules').findBy('childField', this.get('field.value')),
                        matterField: this.get('field')
                    }));
                }
            } else {
                allFields.push(Ember.Object.create({
                    matterField: this.get('field')
                }));
            }

            otherFields.forEach(function (of) {
                var widgetFieldModule = null;
                if (of.get('fieldClass') === 'widget') {
                    widgetFieldModule = _this.get('widgetFieldModules').findBy('field', of.get('value'));
                }
                allFields.push(Ember.Object.create({
                    matterField: of,
                    widgetFieldModule: widgetFieldModule
                }));
            });

            return allFields.sort(function (a, b) {
                return Math.sign(a.get('matterField.weight') - b.get('matterField.weight'));
            });
        }),

        allFieldsSort: ['matterField.weight'],
        allFieldsSorted: Ember.computed.sort('allFields', 'allFieldsSort'),

        widgetFieldModules: [{ module: 'possible-connections-module', field: 'case_related_analytics' }, { module: 'children-phase-one-module', field: 'children_analytics' }, { module: 'education-module', field: 'education_analytics' }, { module: 'employment-module', field: 'employment_analytics' }, {
            module: 'employment-status-module',
            field: 'employment_status_analytics'
        }, { module: 'marital-phase-one-module', field: 'marital_analytics' }, { module: 'socnet-module', field: 'social_media_analytics' }, { module: 'states-module', field: 'counties' }, { module: 'states-module', field: 'states_analytics' }, {
            module: 'residential-phase-one-module',
            field: 'years_in_county_analytics'
        }, { module: 'property-module', field: 'property' }, { module: 'current-city-module', field: 'current_city' }, { module: 'civil_module', field: 'civil_analytics' }, { module: 'criminal-module', field: 'criminal_analytics' }, { module: 'criminal-cases-module', field: 'criminal_cases' }, { module: 'traffic_module', field: 'traffic_analytics' }],

        // for these we display the analytics for parent fields also
        fieldWithParentModules: [{
            module: 'socnet-module',
            childField: 'social_media_analytics',
            parentField: 'social_media'
        }, {
            module: 'civil-module',
            childField: 'civil_analytics',
            parentField: 'civil'
        }, {
            module: 'criminal-cases-module',
            childField: 'criminal_cases',
            parentField: 'criminal'
        }, {
            module: 'criminal-module',
            childField: 'criminal_analytics',
            parentField: 'criminal'
        }, {
            module: 'traffic-module',
            childField: 'traffic_analytics',
            parentField: 'criminal'
        }],

        displayedAnalyticsFields: Ember.computed('field', 'sortedMatterFields', 'widgetFieldModules', 'fieldWithParentModules', function () {
            var fieldValue = this.get('field.value');
            var sortedMatterFields = this.get('sortedMatterFields');
            var displayedAnalyticsFields = [];

            /*
            -- Regarding the commented lines below this block comment --
            if field.value === any of the widgetFieldModules, then it will be handled in the otherFields loop because that
            means this is the primary field that is being edited and should be on top
            */
            // this.get('widgetFieldModules').forEach((analyticFieldObject) => {
            //   if (analyticFieldObject.field === fieldValue) {
            //     displayedAnalyticsFields.push(analyticFieldObject.module)
            //   }
            // })

            this.get('fieldWithParentModules').forEach(function (analyticFieldObject) {
                if (fieldValue === analyticFieldObject.childField) {
                    /*
                    -- Regarding the commented code below this block comment --
                    Do nothing because this field is the main field being edited so it will be handled in the otherFields loop
                    */
                    // displayedAnalyticsFields.push(analyticFieldObject.module)
                } else {
                    var correspondingField = sortedMatterFields.find(function (mf) {
                        if (mf.get('value') === analyticFieldObject.childField) {
                            return true;
                        }
                        return false;
                    });

                    if (correspondingField && correspondingField.get('mergeField') === analyticFieldObject.parentField && analyticFieldObject.parentField === fieldValue) {
                        displayedAnalyticsFields.push(analyticFieldObject.module);
                    }
                }
            });

            return displayedAnalyticsFields;
        })
    });
});