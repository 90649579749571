define('tm-common/models/collection-has-matter-column', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var belongsTo = _emberData.default.belongsTo,
        attr = _emberData.default.attr,
        Model = _emberData.default.Model;
    exports.default = Model.extend({
        weight: attr('number'),
        columnWidth: attr('number'),
        display: attr('string'),
        value: attr('string'),
        enabled: attr('boolean'),

        collection: belongsTo('collection', {
            async: true
        }),
        matterColumn: belongsTo('matter-column', {
            async: true
        })
    });
});