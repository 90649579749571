define('ember-cli-table-pagination/components/light-table-pagination', ['exports', 'ember-cli-table-pagination/templates/components/light-table-pagination', 'ember-cli-table-pagination/components/table-pagination', 'ember-light-table'], function (exports, _lightTablePagination, _tablePagination, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tablePagination.default.extend({
    layout: _lightTablePagination.default,
    extra: null,

    // boxClasses: 'box box-top',
    box: true,
    boxTop: true,
    classNames: ['ember-cli-table-pagination'],
    classNameBindings: ['boxSizeClass', 'box', 'boxTop', 'flex:ember-cli-table-pagination--flex'],

    enableExpandedRows: false,
    searchIsOpen: false,
    showRemoveTableSettings: false,

    // properties
    boxSize: 12,

    actionsCellWidth: '75px',

    height: '80vh',

    flex: false,

    useNoWrap: true,

    hideActionsColumn: false,

    customSelectAll: false,
    searchHoverText: null,

    hideHeader: false,

    breakpoints: {
      mobile: 3,
      tablet: 5,
      desktop: 10,
      jumbo: 20
    },

    // override inherited properties
    perPage: 50,

    isInfinite: Ember.computed(function () {
      return typeof this.attrs.loadNext === 'function';
    }),

    // override the components:
    bodyComponent: 'bs-table-pagination.table-body',
    contentComponent: 'bs-table-pagination.table-content', // NOT USED anymore
    footerComponent: 'bs-table-pagination.table-footer',
    pagerComponent: 'bs-table-pagination.table-pager',
    titleComponent: 'bs-table-pagination.table-title',
    toolbarComponent: 'bs-table-pagination.table-toolbar',
    toolsComponent: 'bs-table-pagination.table-tools',
    noDataComponent: 'bs-table-pagination.table-no-data',
    tableActionsComponent: 'light-table-pagination.table-actions',
    tfootComponent: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      // Reposition the scrollbar so that it's always in view
      var $lightTable = this.$('.ember-light-table');
      var $container = this.$('.ember-cli-table-content');

      $container.on('mouseenter scroll', function () {
        var diff = $lightTable.width() - $container.width();
        var leftDiff = $container.offset().left - $lightTable.offset().left;
        var right = diff - leftDiff;
        if (right < 0) {
          right = 0;
        }
        _this.$('.tse-scrollbar.vertical').css('right', right + 'px');
      });

      Ember.run.later(function () {
        if (_this.isDestroyed) {
          return;
        }
        var scrollbarHeight = _this.$('.tse-scrollbar.vertical .drag-handle').height();
        if (scrollbarHeight > 0) {
          $container.addClass('table-container--with-scrollbar');
        }

        $container.trigger('mouseenter');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var $container = this.$('.ember-cli-table-content');
      $container.off('mouseenter scroll');
    },


    /**
     * We let all the past individual actions be defined as always + user can define any number of custom actions via the extraActions parameter
     */
    allActions: Ember.computed('extraActions', 'customSelectAll', 'searchHoverText', 'selectAction', 'selectAllAction', 'linkPath', 'linkAction', 'linkCondition', 'editPath', 'editCondition', 'editAction', 'editIcon', 'deleteAction', 'deleteCondition', 'editFlag', 'allowQuickSearch', function () {
      var actions = {
        customSelectAll: this.get('customSelectAll'),
        selectAction: this.get('selectAction'),
        selectAllAction: this.get('selectAllAction'),
        searchHoverText: this.get('searchHoverText'),
        linkPath: this.get('linkPath'),
        linkAction: this.get('linkAction'),
        linkCondition: this.get('linkCondition'),
        editPath: this.get('editPath'),
        editCondition: this.get('editCondition'),
        editAction: this.get('editAction'),
        editIcon: this.get('editIcon'),
        deleteAction: this.get('deleteAction'),
        deleteCondition: this.get('deleteCondition'),
        editFlag: this.get('editFlag'),
        allowQuickSearch: this.get('allowQuickSearch')
      };

      var extraActions = this.get('extraActions');
      for (var key in extraActions) {
        actions[key] = extraActions[key];
      }

      return actions;
    }),

    /** light table columns derived from the columns property */
    ltColumns: Ember.computed('tableActionsComponent', 'columns.[]', 'searchIsOpen', function () {
      var searchIsOpen = this.get('searchIsOpen');
      var columns = Ember.A([]);
      if (!this.get('hideActionsColumn')) {
        columns.pushObject({
          label: 'Actions',
          sortable: false,
          width: this.get('actionsCellWidth'),
          cellComponent: this.get('tableActionsComponent'),
          cellClassNames: 'nowrap',
          type: 'quick-filter-toggle'
        });
      }
      columns.pushObjects(this.get('columns').map(function (column) {
        column.set('showFilter', searchIsOpen);
        return {
          label: column.get('displayName'),
          showIcon: column.get('showIcon') ? column.get('showIcon') : false,
          valuePath: column.get('fieldName'),
          sortable: !column.get('disableServerInteractions'),
          hoverText: column.get('hoverText'),
          width: column.get('width'),
          cellComponent: column.get('cellComponent'),
          cellType: column.get('cellType') ? column.get('cellType') : 'base',
          cellClassNames: 'nowrap',
          type: 'base-with-filter',
          tpColumn: column,
          align: column.get('align')
        };
      }));
      return columns;
    }),
    table: Ember.computed('ltColumns.[]', 'content.[]', function () {
      var content = void 0;
      var options = {};
      if (typeof this.attrs.loadNext === 'function') {
        content = this.get('content.content');
        options.enableSync = true;
      } else {
        content = this.get('content');
      }
      if (Ember.isEmpty(content)) {
        content = [];
      }
      var t = new _emberLightTable.default(this.get('ltColumns'), content, options);

      var tpColumn = this.get('columns').findBy('apiInteractionName', this.get('sortProperty'));
      if (tpColumn) {
        var sortColumn = t.get('allColumns').findBy('valuePath', tpColumn.get('fieldName'));

        if (sortColumn) {
          sortColumn.set('sorted', true);
          sortColumn.set('ascending', this.get('sortDirection') === 'asc');
        }
      }
      return t;
    }),

    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (typeof this.attrs.loadNext === 'function') {
          this.attrs.loadNext();
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          /** get the table pagination column */
          var tpColumn = this.get('columns').findBy('fieldName', column.get('valuePath'));
          this.sendAction('changeSort', tpColumn.get('apiInteractionName'), column.get('ascending') ? 'asc' : 'desc');
        }
      }
    }
  });
});