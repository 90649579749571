define('tm-common/components/subjects/analytics-modules/marital-phase-one-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/marital-phase-one-module'], function (exports, _maritalPhaseOneModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _maritalPhaseOneModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        marriedOption: Ember.computed('data.maritalStatusOptions.[]', function () {
            var maritalStatusOptions = this.get('data.maritalStatusOptions');
            return maritalStatusOptions.findBy('shortName', 'married');
        }),

        martialOptionsSort: ['order'],
        sortedMaritalOptions: Ember.computed.sort('data.maritalStatusOptions', 'martialOptionsSort')
    });
});