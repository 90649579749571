define('tm-common/helpers/formatted-subject-field-value', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formattedSubjectFieldValue = formattedSubjectFieldValue;


    /**
     *  Used to display regular subject field values
     * (NOT analytic fields)
     *
     * @param params
     * expects an array with the following elements, in this order: content, subject, matterField, matterFieldName (optional)
     * content = subject's field value that we want to format (we send this as a param so the helper is notified of any changes on the subject)
     * @returns {*}
     */
    function formattedSubjectFieldValue(params /*, hash */) {
        if (Ember.$.isArray(params)) {
            var subject = params[1];
            var matterField = params[2];
            var matterFieldName = params[3];

            if (subject && matterField) {
                if (!matterFieldName) {
                    matterFieldName = matterField.get('name');
                }

                var breakTag = '<br />';
                var content = subject.get(matterFieldName);
                if (Ember.isEmpty(content)) {
                    return '';
                }
                if (Ember.isPresent(content) && ['race', 'gender', 'affiliation', 'affiliationHistory'].indexOf(matterFieldName) >= 0) {
                    content = content.split(',').join(', ');
                }
                if (matterFieldName === 'affiliation') {
                    content = content.split(', ').map(function (x) {
                        if (x.indexOf('Unknown') < 0) {
                            if (matterField.get('fieldType') === 'radio') {
                                return x + ' (likely current)';
                            } else {
                                return x;
                            }
                        } else {
                            return x;
                        }
                    }).join(', ');
                }
                if (matterFieldName === 'affiliationHistory') {
                    content = content.split(', ').map(function (x) {
                        return x + ' (historical)';
                    }).join(', ');
                }
                var fieldType = matterField.get('fieldType');

                if (fieldType === 'date') {
                    var dateValue = (0, _moment.default)(content, 'YYYY-MM-DD');
                    if (dateValue.isValid()) {
                        return dateValue.format('MM-DD-YYYY').toString();
                    }
                }

                return Ember.String.htmlSafe((content + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
            }
            Ember.debug('Invalid parameters supplied to formatted-subject-field-value helper');
            return '';
        }
    }

    exports.default = Ember.Helper.helper(formattedSubjectFieldValue);
});