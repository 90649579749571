define('ember-cli-table-pagination/components/bs-table-pagination/table-title', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-title'], function (exports, _tableTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tableTitle.default,
    classNames: ['box-header', 'with-border']
  });
});