define('tm-common/utils/specific-subject-fields', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        jurorSubjectFieldNames: ['location', 'cases', 'locationOrder', 'foreperson', 'courtPanel', 'courtJuror'],
        plaintiffSubjectFieldNames: ['injured', 'broughtSuit', 'deceased'],
        otherSubjectFieldNames: [],
        witnessSubjectFieldNames: []
    };
});