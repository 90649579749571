define('tm-common/models/firm-invoice-history', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        mostRecentInvoice: attr('number'),
        mostRecentInvoiceBillDate: attr('estdate'),
        yearToDateRevenue: attr('number'),
        allRevenue: attr('number'),
        allInvoiceCount: attr('number'),
        yearToDateInvoiceCount: attr('number'),

        // relationships
        firm: belongsTo('firm', {
            async: false
        })
    });
});