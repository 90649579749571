define('tm-common/models/firm-has-client', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        relationship: attr('string'),

        firm: belongsTo('firm', { async: true }),
        user: belongsTo('user', { async: true }),
        client: belongsTo('client', { async: true }),

        classForRelationship: Ember.computed('relationship', function () {
            switch (this.get('relationship')) {
                case 'Current Employer':
                    return 'label-success';
                case 'Former Employer':
                    return 'label-danger';
                case 'Local Counsel':
                    return 'label-info';
                case 'National Counsel':
                    return 'label-warning';

                case 'Jury Consultant':
                    return 'label-primary';
                case 'Third Party Administrator':
                    return 'label-party';
                case 'Insurance Company':
                    return 'label-insurance';
                default:
                    return 'label-default';
            }
        })
    });
});