define('tm-common/services/report-builder', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_error.default, {
        ajax: Ember.inject.service(),
        metrics: Ember.inject.service(),
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        /**
         * Provides the API URL to POST to create the report
         *
         * @property url
         * @type {String}
         */
        url: Ember.computed(function () {
            var ENV = Ember.getOwner(this).resolveRegistration('config:environment');

            return ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/report_builder';
        }),

        /**
         * Using the json object for report_builder we send it to validate_report so
         * the server checks if all filters are in order so we can generate
         * correctly
         *
         * @method validateReportOnServer
         * @param {String} jsonPostObjectParamAsString
         * @param {Function} completionCallback
         * @return {Boolean}
         */
        validateReportOnServer: function validateReportOnServer(jsonPostObjectParamAsString, completionCallback) {
            var _this = this;

            /*
                We can't use the ajax service here b/c of the async: false (it
                always returns a promise).
             */
            var response = Ember.$.ajax({
                url: this.get('url') + '/validate_report',
                type: 'POST',
                data: { json_post: jsonPostObjectParamAsString },
                headers: {
                    'X-Authorization': 'Token: ' + this.get('session.data.authenticated.token')
                },
                async: false,
                error: function error(xhr) {
                    if (typeof completionCallback === 'function') {
                        completionCallback();
                    }

                    _this.handleXHR(xhr.responseJSON);
                }
            });

            return response.status >= 200 && response.status < 300;
        },


        /**
         * Executes the reports and returns the result as a promise.
         *
         * @method getReportResult
         * @returns {Promise}
         */
        getReportResult: function getReportResult(jsonPostObjectParam) {
            var jsonObjectAsString = JSON.stringify(jsonPostObjectParam);
            var url = this.get('url');

            return this.get('ajax').post(url, {
                data: {
                    json_post: jsonObjectAsString,
                    token: this.get('session.data.authenticated.token')
                }
            });
        },


        /**
         * Validates and executes a report in the API using the report_builder
         * endpoint
         *
         * @method executeReport
         * @param {Object} jsonPostObjectParam
         * @param {Function} completionCallback
         */
        executeReport: function executeReport(jsonPostObjectParam, completionCallback) {
            var jsonObjectAsString = JSON.stringify(jsonPostObjectParam);

            if (!this.validateReportOnServer(jsonObjectAsString, completionCallback)) {
                return;
            }

            var target = '_self';
            /**
                do if(true) to debug the report and the jQuery form target declaration below to debug
                reports (will actually open in new tab).
             */
            if ('report_builder' in jsonPostObjectParam && 'standard' in jsonPostObjectParam.report_builder && 'format' in jsonPostObjectParam.report_builder.standard && jsonPostObjectParam.report_builder.standard.format === 'html') {

                var reportNumber = Math.floor(Math.random() * 100000);
                var reportWindow = window.open('', 'reportWindow_' + reportNumber);

                if (!reportWindow) {
                    / * eslint-disable-next-line no-alert * /;
                    window.alert('Please allow popups on this domain');

                    return;
                }

                reportWindow.document.write('Please wait while the report is generated...');

                target = 'reportWindow_' + reportNumber;
            }

            // send analytics event
            var reportName = 'report_builder';

            if ('report_builder' in jsonPostObjectParam && 'standard' in jsonPostObjectParam.report_builder && 'type' in jsonPostObjectParam.report_builder.standard) {
                reportName = jsonPostObjectParam.report_builder.standard.type;
            }

            if ('report_builder' in jsonPostObjectParam && 'custom' in jsonPostObjectParam.report_builder && 'entity' in jsonPostObjectParam.report_builder.custom) {
                reportName += ':' + jsonPostObjectParam.report_builder.custom.entity;
            }

            Ember.get(this, 'metrics').trackEvent({
                category: 'Documents',
                action: 'generate_report',
                label: reportName
            });

            // Build form and post json object
            var form = Ember.$('<form>', {
                action: this.get('url'),
                enctype: 'application/json',
                id: 'reportForm',
                method: 'post',
                target: target
            });

            var jsonInput = Ember.$('<input>', {
                name: 'json_post',
                value: jsonObjectAsString
            });

            var tokenInput = Ember.$('<input>', {
                name: 'token',
                value: this.get('session.data.authenticated.token')
            });

            Ember.$(form).append([jsonInput, tokenInput]);
            Ember.$('body').append(form);
            Ember.$('#reportForm').submit();
            Ember.$(form).remove();

            this.get('notify').info('Preparing report...');

            if (typeof completionCallback === 'function') {
                completionCallback();
            }
        },
        enqueueReport: function enqueueReport(jsonPostObjectParam, callback) {
            var jsonObjectAsString = JSON.stringify(jsonPostObjectParam);

            (false && !(Ember.typeOf(callback) === 'function') && Ember.assert('Please you nee to specify a callback where the job id will be returned after enqueue', Ember.typeOf(callback) === 'function'));


            if (!this.validateReportOnServer(jsonObjectAsString)) {
                return;
            }

            // send analytics event
            var reportName = 'report_builder';

            if ('report_builder' in jsonPostObjectParam && 'standard' in jsonPostObjectParam.report_builder && 'type' in jsonPostObjectParam.report_builder.standard) {
                reportName = jsonPostObjectParam.report_builder.standard.type;
            }

            if ('report_builder' in jsonPostObjectParam && 'custom' in jsonPostObjectParam.report_builder && 'entity' in jsonPostObjectParam.report_builder.custom) {
                reportName += ':' + jsonPostObjectParam.report_builder.custom.entity;
            }

            Ember.get(this, 'metrics').trackEvent({
                category: 'Documents',
                action: 'generate_report',
                label: reportName
            });

            // build form and post json object to the new window opened
            this.get('ajax').post(this.get('url') + '/enqueue_report', {
                data: { json_post: jsonObjectAsString }
            }).then(function (response) {
                callback({ ok: true, job: response.job_id });
            }, function (xhr, status, error) {
                callback({ ok: false, error: error });
            });
        }
    });
});