define('tm-common/models/resource-meta', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        Model = _emberData.default.Model;

    var string = attr('string');

    exports.default = Model.extend({
        key: string,
        value: string,
        resource: belongsTo('timeline-event', { async: true })
    });
});