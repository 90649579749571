define('tm-common/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        name: attr('string'),
        type: attr('string'),
        publishedOn: attr('string'),
        dueOn: attr('string'),
        comment: attr('string'),
        initialDueOn: attr('string'),
        pushedFlag: attr('string'),

        // resource fields
        matter: belongsTo('matter', { async: true })
    });
});