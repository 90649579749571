define('tm-common/models/document-group', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        groupName: _emberData.default.attr('string'),
        documentsCount: _emberData.default.attr('number'),

        isSubject: _emberData.default.attr('boolean'),

        idOrName: Ember.computed('id', 'groupName', function () {
            return this.get('isSubject') ? this.get('id') : this.get('groupName');
        })
    });
});