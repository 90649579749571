define('tm-common/models/matter-has-stat', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        statName: _emberData.default.attr('string'),
        viewable: _emberData.default.attr('number'),
        jsonConfig: _emberData.default.attr('string'),

        matter: belongsTo('matter', { async: true }),

        isEnabled: Ember.computed('viewable', function () {
            return this.get('viewable') === 1;
        })
    });
});