define('tm-common/models/post', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        Model = _emberData.default.Model;


    var number = attr('number', { defaultValue: 0 });
    var estdate = attr('estdate');
    var string = attr('string');

    exports.default = Model.extend({
        title: string,
        datePosted: string,
        url: string,
        description: string,
        requestScrape: number,
        createdOn: estdate,
        updatedOn: estdate,
        isUrgent: number,
        isForensic: number,
        scrapeNotes: string,
        weight: number,
        viewable: number,
        dueDate: string,

        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),

        hyperlinkResource: belongsTo('hyperlink', { async: true }),
        hyperlinkResourceId: number,
        documentResource: belongsTo('document', { async: true }),

        updateCaptureRequest: (0, _emberApiActions.memberAction)({ path: 'update-scrape', type: 'put' })
    });
});