define('tm-common/components/sc-common-components', ['exports', 'tm-common/templates/components/sc-common-components'], function (exports, _scCommonComponents) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _scCommonComponents.default,
        cities: ['Barcelona', 'London', 'New York', 'Porto'],
        destination: 'London',
        radioButtonValue: true,
        showSubjects: true,
        actions: {
            chooseDestination: function chooseDestination(city) {
                this.set('destination', city);
            },
            openconfirm: function openconfirm() {
                this.set('openconfirm', true);
            }
        }
    });
});