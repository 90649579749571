define('tm-common/components/subject-profile/subject-type-row', ['exports', 'tm-common/templates/components/subject-profile/subject-type-row'], function (exports, _subjectTypeRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _subjectTypeRow.default,
        classNames: ['profile-data-row-component'],
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        reRender: true,
        showLockIconAtEnd: true,
        initialize: Ember.on('init', function () {
            this.set('index', this.attrs.getReadIndex());
            this.attrs.incrementReadIndex();

            var bgColor = this.get('determineBgColor');
            this.set('bgColor', bgColor);
        }),

        formattedSubjectType: Ember.computed('model.subjectType', function () {
            var subjectType = this.get('model.subjectType');
            return subjectType ? subjectType.split('_')[0] : '';
        }),

        determineBgColor: Ember.computed('index', function () {
            var index = this.get('index');
            return index % 2 === 0 ? '#f9f9f9' : '#ffffff';
        }),

        showEditModal: false,

        isSingleEdit: false,
        loading: false,

        mouseEnter: function mouseEnter() {
            this.set('bgColor', '#fefec8');
        },
        mouseLeave: function mouseLeave() {
            var bgColor = this.get('determineBgColor');
            this.set('bgColor', bgColor);
        },
        cancel: function cancel() {
            this.send('cancel');
            this.toggleProperty('isSingleEdit');
        },
        saveData: function saveData() {
            // save data and continue editing
            this.sendAction('save', true, false);
        },


        modelObserver: Ember.observer('model', function () {
            this.set('isSingleEdit', false);
        }),

        actions: {
            singleFieldChange: function singleFieldChange() {
                if (this.get('isSingleAutoSaving')) {
                    this.debounceTask('saveData', 5000);
                }
            },
            showEditModal: function showEditModal() {
                this.toggleProperty('showEditModal');
                this.toggleProperty('isSingleEdit');
            },
            hideModal: function hideModal() {
                this.toggleProperty('showEditModal');
                this.toggleProperty('isSingleEdit');
            },
            saveModal: function saveModal() {},
            focusOut: function focusOut() {},
            saveSingleEdit: function saveSingleEdit() {
                this.sendAction('save', false, false);
                this.sendAction('openFieldChanged', null);
                this.toggleProperty('isSingleEdit');
                this.set('isSsanVisible', false);
            },
            cancelSingleEdit: function cancelSingleEdit() {
                var _this = this;

                var subject = this.get('model');
                subject.rollbackAttributes();
                this.get('store').query('subject', { resource_id: this.get('model.id') }).then(function () {
                    _this.sendAction('openFieldChanged', null);
                    _this.toggleProperty('isSingleEdit');
                });
            },
            rowClicked: function rowClicked() {
                if (!this.get('isEditing')) {
                    this.setEditInPlace(this.get('item'));
                }
            }
        }
    });
});