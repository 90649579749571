define('tm-common/components/subjects/profile/modules/jury/years-in-county-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/jury/years-in-county-module'], function (exports, _yearsInCountyModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _yearsInCountyModule.default,
        analyticsFormsData: Ember.inject.service(),

        classNames: ['inplace-edit-profile-data']
    });
});