define('tm-common/models/subject-revision', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        memberId: attr('string'),
        // tmNum: attr('number'), // Not used in matter_fields
        firstName: attr('string'),
        lastName: attr('string'),
        middleName: attr('string'),
        gender: attr('string'),
        genderNotes: attr('string'),
        dob: attr('string'),
        dod: attr('string'),
        relationship: attr('string'),
        relationshipNotes: attr('string'),
        // salt not used in UI
        ssan: attr('string'),
        ssanNotes: attr('string'),
        alias: attr('string'),
        age: attr('string'),
        ageNotes: attr('string'),
        race: attr('string'),
        raceNotes: attr('string'),
        nationalOrigin: attr('string'),
        nationalOriginNotes: attr('string'),
        employment: attr('string'),
        employmentNotes: attr('string'),
        general: attr('string'),
        custom1: attr('string'),
        custom2: attr('string'),
        custom3: attr('string'),
        custom4: attr('string'),
        custom5: attr('string'),
        custom6: attr('string'),
        custom7: attr('string'),
        custom8: attr('string'),
        custom9: attr('string'),
        custom10: attr('string'),
        custom11: attr('string'),
        custom12: attr('string'),
        custom13: attr('string'),
        custom14: attr('string'),
        custom15: attr('string'),
        civil: attr('string'),
        civilNotes: attr('string'),
        civilFormPublicNotes: attr('string'),
        civilFormInternalNotes: attr('string'),
        criminal: attr('string'),
        criminalHistory: attr('string'),
        criminalFormPublicNotes: attr('string'),
        criminalFormInternalNotes: attr('string'),
        counties: attr('string'),
        countyNotes: attr('string'),
        affiliation: attr('string'),
        affiliationHistory: attr('string'),
        affiliationNotes: attr('string'),
        contributions: attr('string'),
        misc: attr('string'),
        miscNotes: attr('string'),
        socialMedia: attr('string'),
        socialMediaNotes: attr('string'),
        birth: attr('string'),
        education: attr('string'),
        educationNotes: attr('string'),
        medical: attr('string'),
        medicalNotes: attr('string'),
        marital: attr('string'),
        maritalNotes: attr('string'),
        bankruptcy: attr('string'),
        bankruptcyNotes: attr('string'),
        residential: attr('string'),
        residentialNotes: attr('string'),
        death: attr('string'),
        deathInformation: attr('string'),
        scope: attr('string'),
        traffic: attr('string'),
        trafficNotes: attr('string'),
        family: attr('string'),
        familyNotes: attr('string'),
        results: attr('string'),
        supplementalResearch: attr('string'),
        flagStatus: attr('string'),
        flagNote: attr('string'),
        identity: attr('string'),
        highlights: attr('string'),
        subjectType: attr('string'),
        smNotes: attr('string'),
        monitor: attr('string'),
        notes: attr('string'),
        identityStatus: attr('string'),
        socnetNotifiedOn: attr('estdate'),
        socnetNotifiedBy: attr('number'),
        vehicle: attr('string'),
        expertWitnessHistory: attr('string'),
        expertWitnessHistoryNotes: attr('string'),
        caseClassification: attr('string'),

        location: attr('string'),
        locationNotes: attr('string'),
        locationOrder: attr('number'),
        foreperson: attr('number'),
        seatNumber: attr('number'),
        courtPanel: attr('string'),
        courtJuror: attr('string'),
        injured: attr('string'),
        broughtSuit: attr('string'),
        deceased: attr('string'),
        forensicCapture: attr('string'),
        depDate: attr('string'),
        civilJudgmentsOrLiens: attr('string'),
        foreclosure: attr('string'),
        federalCivil: attr('string'),
        workersCompensation: attr('string'),
        captureStatus: attr('string'),

        published: attr('boolean'),
        pending: attr('boolean'),

        // resource fields
        childTable: attr('string', { defaultValue: 'subjects' }),
        syncId: attr('number'),
        createdOn: attr('utcdate'),
        updatedOn: attr('estdate'),
        fullProfileUpdatedOn: attr('estdate'),

        createdBy: belongsTo('user', { async: true }),
        // relationships
        matter: belongsTo('matter', {
            async: false
        }),

        // calculated
        fullName: Ember.computed('firstName', 'lastName', 'subjectType', function () {
            if (!this.get('lastName') && !this.get('firstName')) {
                return null;
            }

            switch (this.get('subjectType')) {
                case 'other_subjects':
                    return this.get('lastName');
                default:
                    return this.get('lastName') + ', ' + this.get('firstName');
            }
        })
    });
});