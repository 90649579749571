define('tm-common/components/subjects/sidebar/analytics-display', ['exports', 'tm-common/templates/components/subjects/sidebar/analytics-display'], function (exports, _analyticsDisplay) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _analyticsDisplay.default,
        currentComponent: Ember.computed('phase', function () {
            var phase = this.get('phase');
            var componentName = '';

            switch (phase) {
                case 'one':
                    componentName = 'subjects/sidebar/phase-one';
                    break;

                case 'two':
                    componentName = 'subjects/sidebar/phase-two';
                    break;
            }

            return componentName;
        })
    });
});