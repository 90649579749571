define('tm-common/services/counts', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * @property
         * @private
         *
         * ajax service to communicate with the servre
         */
        ajax: Ember.inject.service(),

        query: function query(modelName, options) {
            options['count'] = '';

            return this.get('ajax').request(Ember.String.underscore((0, _emberInflector.pluralize)(modelName)), { data: options }).then(function (response) {
                return response.meta.total_record_count;
            });
        }
    });
});