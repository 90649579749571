define('tm-common/components/subject-custom-modules/civil', ['exports', 'tm-common/helpers/nl-2br', 'tm-common/templates/components/subject-custom-modules/civil'], function (exports, _nl2br, _civil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isExpanded: true,
        layout: _civil.default,
        reRender: true,
        state: 'defaultReadOnly', // must be one of the following: defaultReadOnly, isEditing

        expandedObserver: Ember.observer('isExpanded', 'field.value', function () {
            var fieldValue = this.get('field.value');

            if (this.get('isExpanded')) {
                Ember.$('#' + fieldValue + 'Collapse').collapse('show');
                this.set('allAreCollapsed', false);
            } else {
                Ember.$('#' + fieldValue + 'Collapse').collapse('hide');
                this.set('allAreExpanded', false);
            }
        }),

        allAreExpandedObserver: Ember.observer('allAreExpanded', function () {
            if (this.get('allAreExpanded')) {
                this.set('isExpanded', true);
            }
        }),

        allAreCollapsedObserver: Ember.observer('allAreCollapsed', function () {
            if (this.get('allAreCollapsed')) {
                this.set('isExpanded', false);
            }
        }),

        displayAnalytics: Ember.computed.equal('field.fieldType', 'analytics'),

        isReadModeNoCivil: Ember.computed('civilReadMode', 'data.subject.subjectAnalyticsCivil', function () {
            var civilReadMode = this.get('data.subject.subjectAnalyticsCivil');

            return civilReadMode === 'No Civil Records';
        }),

        civilItems: Ember.computed('data.subject.subjectAnalyticsCivil', function () {
            var civilData = this.get('data.subject.subjectAnalyticsCivil');
            var items = [];

            if (civilData !== 'No Civil Records') {
                civilData.sortBy('order').forEach(function (civil) {
                    var item = '';

                    if (civil.year) {
                        item += civil.year;
                    }

                    if (item && civil.case_type) {
                        item += ', ';
                    }

                    if (civil.case_type) {
                        item += ' <strong><i>' + civil.case_type + '</i></strong>';
                    }

                    if (civil.party_type) {
                        item += ' (' + civil.party_type + ')';
                    }

                    if (civil.qty && civil.qty > 1) {
                        item += ' (' + civil.qty + ' cases)';
                    }

                    if (item && civil.case_notes && !civil.is_markdown) {
                        item += '; ';
                    }

                    if (civil.case_notes && !civil.is_markdown) {
                        item += (0, _nl2br.nl2br)([civil.case_notes]);
                    }

                    if (civil.case_notes && civil.is_markdown) {
                        item += SimpleMDE.prototype.markdown(civil.case_notes);
                    }

                    if (item) {
                        item = item.replace(/  +/g, ' ');
                        items.push(item);
                    }
                });
            }

            return items;
        }),

        actions: {
            usePhrase: function usePhrase(phrase) {
                var fieldPath = 'data.subject.civil';
                var breaks = '';

                for (var i = 0; i <= phrase.get('breaks'); i += 1) {
                    breaks += '\n';
                }

                var previousVal = this.get(fieldPath);

                this.set('reRender', false);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));

                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            }
        }
    });
});