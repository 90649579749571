define('tm-common/models/subject-addr', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        subject: belongsTo('subject', { async: false }),
        type: attr('string'),
        addr1: attr('string'),
        addr2: attr('string'),
        city: attr('string'),
        county: attr('string'),
        state: attr('string'),
        country: attr('string'),
        zip: attr('string'),
        description: attr('string')
    });
});