define('tm-common/components/subjects/analytics-modules/questionnaire-module', ['exports', 'tm-common/mixins/analytics-util', 'tm-common/templates/components/subjects/analytics-modules/questionnaire-module'], function (exports, _analyticsUtil, _questionnaireModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _questionnaireModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        checked: Ember.computed('data.subject.questionnaire', function () {
            var questionnaire = this.get('data.subject.questionnaire');
            return questionnaire === 'Significant discrepancies between questionnaire and research';
        }),

        questionnaireProxy: Ember.computed('checked', function () {
            var checked = this.get('checked');
            if (checked) {
                this.set('data.subject.questionnaire', 'Significant discrepancies between questionnaire and research');
            } else {
                this.set('data.subject.questionnaire', null);
            }
        })
    });
});