define('ember-cli-table-pagination/components/bs-table-pagination/table-content', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-content', 'ember-resize/mixins/resize-aware'], function (exports, _tableContent, _resizeAware) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resizeAware.default, {
    layout: _tableContent.default,
    resizeHeightSensitive: true,
    resizeWidthSensitive: true,

    resizeService: Ember.inject.service('resize'),

    classNames: ['ember-cli-table-content'],
    // tagName: '',

    showFilter: false,
    scrollMode: Ember.computed.reads('contentParams.scrollMode'),
    mainScroll: Ember.computed.reads('contentParams.mainScroll'),
    bodyHeight: Ember.computed.reads('contentParams.bodyHeight'),
    actionWidth: undefined,

    onInit: Ember.on('didInsertElement', function () {
      if (this.get('scrollMode')) {
        this.adjustTableDimensions();
      }
    }),
    showFilterObserver: Ember.observer('showFilter', function () {
      if (this.get('scrollMode')) {
        this.adjustTableDimensions();
      }
    }),
    currentContentSizeObserver: Ember.observer('currentContentSize', function () {
      // let size = this.get('currentContentSize');
      if (this.get('scrollMode')) {
        this.adjustTableDimensions();
      }
    }),

    //eslint-disable-next-line
    didResize: function didResize(width, height) {
      if (this.get('scrollMode')) {
        this.adjustTableDimensions();
      }
    },
    adjustTableDimensions: function adjustTableDimensions() {
      var _this = this;

      // if we need to resize the table's height
      if (this.get('mainScroll')) {
        this.adjustBodyHeight();
      }
      var columns = this.get('columns');
      Ember.run.later(function () {
        _this.set('actionWidth', 0);
        columns.forEach(function (col) {
          col.set('width', 0);
        });

        var self = _this;
        Ember.run.later(function () {
          // in anycase, we need to check the width of each cell in the table's body
          var showFilter = self.get('showFilter');
          var firstRow = self.$('tbody > tr:nth-of-type(2) > td');
          var headerRow = self.$('thead > tr:first-of-type > th');
          var footerRow = self.$('tfoot > tr:first-of-type > th');
          var columnWidths = [];
          var contentWidths = [];
          var headerWidths = [];
          var footerWidths = [];
          var actionWidth = void 0;
          firstRow.each(function (idx, cell) {
            contentWidths[idx] = self.$(cell).innerWidth();
            if (idx === columns.length) {
              actionWidth = self.$(cell).innerWidth();
            }
          });
          headerRow.each(function (idx, cell) {
            headerWidths[idx] = self.$(cell).innerWidth();
          });
          footerRow.each(function (idx, cell) {
            footerWidths[idx] = self.$(cell).innerWidth();
          });

          var maxWidth = void 0;
          var sum = 0;
          var delta = 0;
          for (var i = 0; i < columns.length; i++) {
            maxWidth = Math.max(headerWidths[i], contentWidths[i] - 0, footerWidths[i]);
            // console.log(`headerWidth[${i}] vs contentWidth[${i}] -> `, headerWidths[i], contentWidths[i]);
            // console.log(`footerWidths[${i}] -> `, footerWidths[i]);
            // console.log(`contentWidth[${i}] - delta -> `, contentWidths[i] - delta);
            // console.log(`maxWidth[${i}] -> `, maxWidth);
            if (showFilter) {
              // the delta business is only for when we show the filters
              if (maxWidth === contentWidths[i] - delta) {
                // if we applied the delta we need to reset it.
                delta = 0;
              } else if (maxWidth === headerWidths[i]) {
                // if we didn't we need to add him
                delta += headerWidths[i] - contentWidths[i];
                // console.log('delta -> ', delta);
              }
            }
            columnWidths[i] = maxWidth;
            sum += maxWidth;
          }
          maxWidth = Math.max(headerWidths[columns.length], contentWidths[columns.length], footerWidths[columns.length]);
          self.set('actionWidth', maxWidth);
          sum += maxWidth;

          // now check if there is some extra space
          var tableWidth = self.$().innerWidth();
          var extra = tableWidth - sum;
          extra = extra > 0 ? extra : 0;
          extra = extra / columns.length;
          // console.log('we have ', extra, ' extra px / col');
          for (var _i = 0; _i < columns.length; _i++) {
            columns[_i].set('width', columnWidths[_i] + extra);
            // columns[i].set('width', tableWidth / columns.length);
          }
          Ember.run.later(function () {
            // console.log('second pass');
            actionWidth = self.$('thead').outerWidth() + self.$('thead').offset().left - self.$('tbody > tr:nth-of-type(2) > td:last-of-type').offset().left;
            self.set('actionWidth', actionWidth);
          });
        });
      });
    },
    adjustBodyHeight: function adjustBodyHeight() {
      // let documentHeight = document.body.clientHeight;
      var above = this.$('tbody').offset().top;
      var height = this.$('tbody').outerHeight();
      var mainContent = this.$().parents('section.content');
      var under = mainContent.offset().top + mainContent.outerHeight() - (above + height);
      // let under = documentHeight - (above + height);

      var bodyHeight = window.innerHeight - above - under + 'px';
      this.set('bodyHeight', bodyHeight);
    }
  });
});