define('tm-common/components/string-len-greater', ['exports', 'tm-common/templates/components/string-len-greater'], function (exports, _stringLenGreater) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _stringLenGreater.default,

        isGreater: Ember.computed('str', 'length', function () {
            var str = this.get('str');
            var length = this.get('length');

            if (Ember.typeOf(str) !== 'string') {
                return false;
            }

            if (str.length > length) {
                return true;
            } else {
                return false;
            }
        })
    });
});