define('tm-common/components/x-preview-document/search-result-preview', ['exports', 'tm-common/templates/components/x-preview-document/search-result-preview'], function (exports, _searchResultPreview) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _searchResultPreview.default,
        tagName: 'li'
    });
});