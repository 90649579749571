define('tm-common/models/filtered-batch', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        status: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        tmNum: attr('number'),
        name: attr('string'),

        // relationship
        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        invoices: hasMany('invoice', {
            async: false
        })
    });
});