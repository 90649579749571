define('tm-common/mixins/token-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        initialTargetName: null,
        isTokenAuthenticating: null,
        model: function model(params) {
            var _this = this;

            var newtoken = params.newtoken,
                redirect = params.redirect;

            var self = this;
            if (this.get('session.isAuthenticated')) {
                this.get('raven').callRaven('setUserContext', {
                    id: self.get('session.data.authenticated.id'),
                    name: self.get('session.data.authenticated.user_name')
                });
            }

            if (newtoken) {
                this.set('isTokenAuthenticating', true);
                if (this.get('session.isAuthenticated')) {
                    var oldToken = this.get('session').get('data').authenticated.token;
                    // check if it's the same token to skip invalid reloads
                    if (oldToken === newtoken) {
                        return;
                    }
                }
                return this.get('session').authenticate('authenticator:token', newtoken).then(function () {
                    _this.sessionAuthenticated(redirect);
                }).catch(function () {
                    _this.transitionTo('auth.login');
                });
            }
        },
        sessionAuthenticated: function sessionAuthenticated() {
            var redirect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            var self = this;
            this.get('raven').callRaven('setUserContext', {
                id: self.get('session.data.authenticated.id'),
                name: self.get('session.data.authenticated.user_name')
            });
            if (!this.get('isTokenAuthenticating')) {
                // this will attempt to transition to previous transition or default url
                console.log({ redirect: redirect });
                if (redirect) {
                    this.transitionTo(redirect);
                } else {
                    this._super();
                }
            } else {
                this.set('isTokenAuthenticating', false);
            }
        },
        sessionInvalidated: function sessionInvalidated() {
            if (!this.get('isTokenAuthenticating')) {
                // this will reload the app to delete the old store and state
                this._super();
            }
            this.get('raven').callRaven('setUserContext', { id: null, name: null });
        }
    });
});