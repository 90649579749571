define('tm-common/transforms/estdate', ['exports', 'ember-data/transform', 'moment-timezone'], function (exports, _transform, _momentTimezone) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transform.default.extend({
        deserialize: function deserialize(serialized) {
            // read from API
            if (serialized) {
                if (serialized === '0000-00-00 00:00:00') {
                    return null;
                } else {
                    return _momentTimezone.default.tz(serialized, 'America/New_York').toDate();
                }
            }

            return serialized;
        },
        serialize: function serialize(deserialized) {
            // sent to API
            if (deserialized) {
                return (0, _momentTimezone.default)(deserialized).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
            }

            return deserialized;
        }
    });
});