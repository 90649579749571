define('tm-common/mixins/matter-timeline-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var colors = [{ code: '#10B4B0', name: 'teal' }, { code: '#FAA92C', name: 'honey' }, { code: '#EA601C', name: 'orange' }, { code: '#7D54A2', name: 'purple' }, { code: '#2C71B4', name: 'blue' }, { code: '#E93E97', name: 'pink' }];
    exports.default = Ember.Mixin.create({
        queryParams: {
            subjects: {
                refreshModel: true
            },
            showHidden: {
                refreshModel: true
            }
        },
        currentMatter: Ember.inject.service('current-matter'),
        lists: Ember.inject.service(),
        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),
        model: function model(queryParams) {
            var _this = this;

            var subjectIds = [];
            if (queryParams.subjects) {
                subjectIds = queryParams.subjects.split(',');
            }
            var showHidden = false;
            if (queryParams.showHidden) {
                showHidden = queryParams.showHidden;
            }

            var subjectPromise = this.store.query('subject', {
                matter_id: this.get('currentMatter.id'),
                with: 'timeline_events',
                sort: 'last_name,first_name',
                has_timeline_events: true
            });

            return subjectPromise.then(function (subjects) {
                var subjectProxies = subjects.map(function (subject, index) {
                    return Ember.ObjectProxy.create({
                        subject: subject,
                        color: _this.getColor(index),
                        isSelected: subjectIds.includes(subject.get('id')) || subjectIds.get('length') === 0
                    });
                });
                var allSubjectIds = subjects.map(function (s) {
                    return s.get('id');
                });

                var timelineEvents = [];
                if (subjectProxies.length > 0) {
                    timelineEvents = _this.store.query('timeline-event', {
                        resource_id: subjects.filter(function (s) {
                            return allSubjectIds.includes(s.get('id'));
                        }).map(function (s) {
                            return s.get('timelineEvents').map(function (e) {
                                return e.get('id');
                            });
                        }).filter(function (s) {
                            return s && s.length;
                        }).flat(),
                        with: 'documents',
                        viewable: showHidden ? [0, 1, 2] : [1, 2]
                    }).then(function (timelineEvents) {
                        return timelineEvents.sortBy('startDate');
                    });
                }
                var config = _this.get('ENV');
                return Ember.RSVP.hash({
                    matter: _this.store.findRecord('matter', _this.get('currentMatter.id')),
                    subjectsProxy: subjectProxies,
                    timelineEvents: timelineEvents,
                    timelineEventParents: _this.store.findAll('timeline-event-parent'),
                    subjectIds: subjectIds,
                    eventTypes: _this.store.findAll('timeline-event-type').then(function (eventTypes) {
                        return eventTypes.sortBy('name');
                    }),
                    emptyEvent: { viewable: 2 }, // by default a new event is "pending"
                    consolidatedTags: _this.store.query('db-tag', {
                        parent_id: [config.APP.tags.stateCategory, config.APP.tags.countryCategory, 'NULL']
                    }),
                    documentTypes: _this.get('lists').getListForGroup('jury_doc_type_tm')
                }).then(function (response) {
                    response.states = response.consolidatedTags.filterBy('parentId', config.APP.tags.stateCategory);
                    response.countries = response.consolidatedTags.filterBy('parentId', config.APP.tags.countryCategory);

                    delete response.consolidatedTags;

                    return response;
                });
            });
        },
        getColor: function getColor(index) {
            return colors[index];
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});