define('tm-common/mixins/scroll-to-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        scrollDuration: 1000,
        margin: 50,
        actions: {
            /**
             * for a given selector, scroll to it using the HTML BODY as reference
             * @param selector
             */
            scrollTo: function scrollTo(selector) {
                var offset = Ember.$(selector).offset();
                if (offset) {
                    Ember.$('html, body').animate({
                        scrollTop: offset.top - this.get('margin')
                    }, this.get('scrollDuration'));
                } else {
                    Ember.debug('trying to scroll to a non existing element');
                }
            },


            /**
             * for a given selector, scroll to it using the parent element as reference
             * this is used specifically when scrolling within a div whoose overflow-y is set to auto or scroll
             *
             * @param selector
             * @param parent
             */
            scrollToOverflow: function scrollToOverflow(selector, parent) {
                // relative element
                var offset = Ember.$(selector).offset();
                if (offset) {
                    Ember.$(parent).animate({
                        scrollTop: Ember.$(selector).offset().top - Ember.$(parent).offset().top + Ember.$(parent).scrollTop()
                    }, this.get('scrollDuration'));
                } else {
                    Ember.debug('trying to scroll to a non existing element');
                }
            }
        }
    });
});