define('tm-common/components/subjects/analytics-modules/product-use-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/product-use-module'], function (exports, _productUseModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _productUseModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        hasUsedProductsOption: Ember.computed('data.productUseOptions.productUseOptions.[]', function () {
            var productUseOptions = this.get('data.productUseOptions.productUseOptions');
            return productUseOptions.findBy('shortName', 'has_used_products');
        }),

        similarProductsOption: Ember.computed('data.productUseOptions.productUseOptions.[]', function () {
            var productUseOptions = this.get('data.productUseOptions.productUseOptions');
            return productUseOptions.findBy('shortName', 'similar_product_option');
        }),

        setMedicalIllnessAccordingToTheme: Ember.on('init', function () {
            if (this.get('data.editMode') && this.get('data.subject.socnetThemes') != null && this.get('data.subject.socnetThemes').indexOf('Medical / Illness') > 0) {
                var medicalIllnesOption = this.get('data.productUseOptions.medicalOptions').findBy('shortName', 'medical_illness');
                medicalIllnesOption.set('checked', true);
            }
        })
    });
});