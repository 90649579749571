define('tm-common/components/subject-profile/profile-data-row', ['exports', 'ember-lifeline/mixins/run', 'tm-common/templates/components/subject-profile/profile-data-row'], function (exports, _run, _profileDataRow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_run.default, {
        layout: _profileDataRow.default,
        classNames: ['profile-data-row-component'],
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        reRender: true,
        showLockIconAtEnd: true,
        initialize: Ember.on('init', function () {
            this.set('index', this.attrs.getReadIndex());
            this.attrs.incrementReadIndex();

            var bgColor = this.get('determineBgColor');
            this.set('bgColor', bgColor);
        }),

        determineBgColor: Ember.computed('index', function () {
            var index = this.get('index');
            return index % 2 === 0 ? '#f9f9f9' : '#ffffff';
        }),

        showEditModal: false,

        isSingleEdit: false,
        loading: false,

        mouseEnter: function mouseEnter() {
            this.set('bgColor', '#fefec8');
        },
        mouseLeave: function mouseLeave() {
            var bgColor = this.get('determineBgColor');
            this.set('bgColor', bgColor);
        },
        cancel: function cancel() {
            this.send('cancel');
            this.toggleProperty('isSingleEdit');
        },

        isSsanVisible: false,

        saveData: function saveData() {
            // save data and continue editing
            this.sendAction('save', true, false);
        },


        modelObserver: Ember.observer('model', function () {
            this.set('isSingleEdit', false);
        }),

        actions: {
            singleFieldChange: function singleFieldChange() {
                if (this.get('isSingleAutoSaving')) {
                    this.debounceTask('saveData', 5000);
                }
            },
            showEditModal: function showEditModal() {
                this.toggleProperty('showEditModal');
                this.toggleProperty('isSingleEdit');
            },
            hideModal: function hideModal() {
                this.toggleProperty('showEditModal');
                this.toggleProperty('isSingleEdit');
            },
            saveModal: function saveModal() {},
            showSsan: function showSsan() {
                var _this = this;

                this.set('loading', true);
                this.get('store').query('subject', { resource_id: this.get('model.id'), with: 'ssan' }).then(function () {
                    _this.set('loading', false);
                    _this.set('isSsanVisible', true);
                });
            },
            hideSsan: function hideSsan() {
                var _this2 = this;

                this.set('loading', true);
                this.get('store').query('subject', { resource_id: this.get('model.id') }).then(function () {
                    _this2.set('loading', false);
                    _this2.set('isSsanVisible', false);
                });
            },
            focusOut: function focusOut() {},
            saveSingleEdit: function saveSingleEdit() {
                this.sendAction('save', false, false);
                this.sendAction('openFieldChanged', null);
                this.toggleProperty('isSingleEdit');
                this.set('isSsanVisible', false);
            },
            cancelSingleEdit: function cancelSingleEdit() {
                var _this3 = this;

                var subject = this.get('model');
                subject.rollbackAttributes();
                this.get('store').query('subject', { resource_id: this.get('model.id') }).then(function () {
                    _this3.sendAction('openFieldChanged', null);
                    _this3.toggleProperty('isSingleEdit');
                });
            },
            usePhrase: function usePhrase(phrase) {
                var fieldPath = 'model.' + this.get('item.name');
                var breaks = '';
                for (var i = 0; i <= phrase.get('breaks'); i++) {
                    breaks += '\n';
                }
                this.set('reRender', false);
                var previousVal = this.get(fieldPath);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));
                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            },
            rowClicked: function rowClicked() {
                if (!this.get('isEditing')) {
                    this.setEditInPlace(this.get('item'));
                }
            }
        }
    });
});