define('tm-common/models/document-field', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        fieldName: attr('string'),
        weight: attr('number'),
        verticalPosition: attr('string'),
        horizontalPosition: attr('string'),

        // relationships
        documentTemplateHasFields: hasMany('document-template-has-field', {
            async: true
        })
    });
});