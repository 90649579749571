define('tm-common/transforms/csv', ['exports', 'ember-data/transform'], function (exports, _transform) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _transform.default.extend({
        deserialize: function deserialize(serialized) {
            if (serialized) {
                return Ember.typeOf(serialized) === 'array' ? serialized : serialized.split(',').map(function (item) {
                    return item.trim();
                });
            } else {
                return serialized; // Null or undefined
            }
        },
        serialize: function serialize(deserialized) {
            var type = Ember.typeOf(deserialized);
            if (type === 'array') {
                return deserialized.join(', ');
            } else if (type === 'string') {
                return deserialized;
            } else {
                return null;
            }
        }
    });
});