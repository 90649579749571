define('tm-common/models/task-has-event', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        task: belongsTo('task', { async: false, inverse: 'events' }),
        event: attr('string'),
        description: attr('string'),
        createdBy: belongsTo('employee', { async: true }),
        createdOn: attr('estdate')
    });
});