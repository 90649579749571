define('tm-common/models/billable-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        description: attr('string'),
        rate: attr('string'),
        time: attr('number'),
        expense: attr('number'),
        billing: attr('number'),
        database: attr('number'),
        report: attr('number'),

        // relationships
        billables: hasMany('billable', { async: true }),
        charges: hasMany('charge', { async: true }),
        deductions: hasMany('deduction', {
            async: true
        }),

        inputByQuantity: Ember.computed.equal('name', 'MILEAGE')
    });
});