define('tm-common/components/subjects/analytics-modules/possible-connections-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/possible-connections-module'], function (exports, _possibleConnectionsModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _possibleConnectionsModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        noneOption: Ember.computed('data.possibleConnectionsOptions.possibleConnectionsCategoryOptions.[]', function () {
            var possibleConnectionsCategoryOptions = this.get('data.possibleConnectionsOptions.possibleConnectionsCategoryOptions');
            return possibleConnectionsCategoryOptions ? possibleConnectionsCategoryOptions.findBy('shortName', 'no_possible_connections') : null;
        }),

        hasPossibleConnections: Ember.computed('data.possibleConnectionsOptions.possibleConnectionsOptions.[]', function () {
            var possibleConnectionsOptions = this.get('data.possibleConnectionsOptions.possibleConnectionsOptions');
            return possibleConnectionsOptions ? possibleConnectionsOptions.findBy('shortName', 'has_possible_connections') : null;
        }),

        hasConnectionsCategories: Ember.computed('data.subject.subjectAnalyticsPossibleConnectionsCategories.[]', function () {
            var connections = this.get('data.subject.subjectAnalyticsPossibleConnectionsCategories');
            return connections ? connections.length : false;
        })
    });
});