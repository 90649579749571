define('tm-common/helpers/remove-links', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.removeLinks = removeLinks;
    function removeLinks(params /*, hash*/) {
        if (!Ember.isPresent(params)) return null;
        if (!Ember.isPresent(params[0].string) || Ember.typeOf(params[0].string) !== 'string') {
            return params[0];
        }
        return Ember.String.htmlSafe(params[0].string.replace(/<\/?a[^>]*>/g, '').replace('<table>', '<table class="table table-bordered">'));
    }

    exports.default = Ember.Helper.helper(removeLinks);
});