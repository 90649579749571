define('tm-common/components/subjects/analytics-modules/education-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/education-module'], function (exports, _educationModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _educationModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        educationOptionsSort: ['order'],
        sortedEducationOptions: Ember.computed.sort('data.educationOptions', 'educationOptionsSort')
    });
});