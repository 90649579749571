define('tm-common/components/input-tooltip', ['exports', 'tm-common/templates/components/input-tooltip'], function (exports, _inputTooltip) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _inputTooltip.default,
        tagName: 'span',

        classNames: ['input-group-addon'],

        // define properties
        content: 'Help',

        position: 'top'
    });
});