define('tm-common/components/user-avatar', ['exports', 'tm-common/templates/components/user-avatar', 'tm-common/utils/get-app-url'], function (exports, _userAvatar, _getAppUrl) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),

        compAdditionClass: '',
        classNameBindings: ['compAdditionClass'],

        ENV: Ember.computed(function () {
            var applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
            return applicationConfig;
        }),

        layout: _userAvatar.default,

        /**
         * @property
         *
         * set the files.id number to show the avatar
         */
        fileId: null,

        /**
         * @computed
         *
         * check if fileId exists
         */
        hasAvatar: Ember.computed.notEmpty('fileId'),

        /**
         * size of the avatar
         */
        width: 100,
        height: 100,

        /**
         * if we want the avatar rounded
         */
        circle: false,

        /**
         * @computed
         *
         * return the url needed to preview the avatar
         */
        avatarUrl: Ember.computed('fileId', function () {
            var ENV = this.get('ENV');
            return (0, _getAppUrl.default)(ENV, '/files/' + this.get('fileId') + '/preview/1?token=' + this.get('session.data.authenticated.token'));
        }),

        classStr: Ember.computed('circle', 'pullLeft', 'pullRight', function () {
            var circle = this.get('circle');
            var pullLeft = this.get('pullLeft');
            var pullRight = this.get('pullRight');

            var classNameArr = [];
            if (circle) {
                classNameArr.push('img-circle');
            }
            if (pullLeft) {
                classNameArr.push('pull-left');
            }
            if (pullRight) {
                classNameArr.push('pull-right');
            }
            classNameArr.push(this.get('additionalClass'));

            return classNameArr.join(' ');
        })
    });
});