define('tm-common/models/v-note-parent', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        parentId: attr('number'),
        matter: belongsTo('matter', { async: true }),
        table: attr('string'),
        title: attr('string'),

        note: belongsTo('note', { async: true }),
        subject: belongsTo('subject', { async: true }),
        parentnote: belongsTo('note', { async: true })
    });
});