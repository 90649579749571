define('tm-common/models/collection-has-workflow', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        enabled: _emberData.default.attr('number'),
        weight: _emberData.default.attr('number'),
        columnWidth: _emberData.default.attr('number'),

        workflow: _emberData.default.belongsTo('workflow'),
        collection: _emberData.default.belongsTo('collection'),

        isStatusEnabled: Ember.computed('enabled', function () {
            if (this.get('enabled') & 1) {
                return true;
            }
            return false;
        }),

        isAssignedEnabled: Ember.computed('enabled', function () {
            if (this.get('enabled') & 2) {
                return true;
            }
            return false;
        }),

        isCommentsEnabled: Ember.computed('enabled', function () {
            if (this.get('enabled') & 4) {
                return true;
            }
            return false;
        }),

        /**
         * BEGIN BOOLEAN CALCS
         *
         * a series of booleans to describe if a workflow bitmask is enabled
         * for a particular case
         *
         * useful for template logic
         */
        status: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 1);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 1;
                } else {
                    enabled &= ~1;
                }
                this.set('enabled', enabled);
                return value;
            }
        }),
        assigned: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 2);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 2;
                } else {
                    enabled &= ~2;
                }
                this.set('enabled', enabled);
                return value;
            }
        }),
        comments: Ember.computed('enabled', {
            get: function get(key) {
                return !!(this.get('enabled') & 4);
            },
            set: function set(key, value) {
                var enabled = this.get('enabled');
                if (value) {
                    enabled |= 4;
                } else {
                    enabled &= ~4;
                }
                this.set('enabled', enabled);
                return value;
            }
        })
        /**
         * END BOOLEAN CALCS
         */
    });
});