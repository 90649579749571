define('tm-common/models/matter-has-user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        modelName: 'matter-has-user', // before we were using constructor.modelName but is no longer reliable

        bill: attr('boolean', { defaultValue: false }),
        billCopy: attr('boolean', { defaultValue: false }),
        report: attr('boolean', { defaultValue: false }),
        referredBy: attr('boolean', { defaultValue: false }),
        whoHired: attr('boolean', { defaultValue: false }),
        decisionMaker: attr('boolean', { defaultValue: false }),
        rate: attr('string'),
        contact: attr('string'),
        userType: attr('string'),
        isOrigin: attr('boolean', { defaultValue: false }),
        isNonSalesOrigin: attr('boolean', { defaultValue: false }),
        notes: attr('string'),
        currentEmployer: attr('string'),
        currentEmployerCity: attr('string'),

        // relationships
        matter: belongsTo('matter', { async: false }),
        user: belongsTo('user', { async: true }),
        employee: belongsTo('employee', { async: true }),
        group: belongsTo('group', { async: true }),
        team: belongsTo('team', { async: true }),

        // field to show the source of the permission
        source: attr('string'),

        // UI
        isSelected: attr('boolean', { defaultValue: false }),

        primary: Ember.computed('contact', function () {
            return this.get('contact') === 'Primary';
        }),

        sourceName: Ember.computed('source', function () {
            switch (this.get('source')) {
                case 'matter_has_users':
                    return 'Given to User';
                case 'matter_has_teams':
                    return 'Given to Team';
            }
            return '';
        })
    });
});