define("ember-scroll-to-target/components/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: "",
    tagName: null,
    target: null,
    offset: 0,
    duration: 500,
    didInsertElement: function didInsertElement() {
      var self = this;
      document.querySelector("#".concat(this.get("elementId"))).addEventListener("click", function () {
        self.scrollToTarget();
      });
    },
    scrollToTarget: function scrollToTarget() {
      var target = this.get("target");
      var offset = this.get("offset");
      var duration = this.get("duration");

      if (!target) {
        Ember.Logger.error("Target should be passed");
        return;
      }

      var targetPos = document.querySelector(target).offsetTop + offset;
      this.animateScroll(targetPos, duration);
    },
    animateScroll: function animateScroll(targetPos, duration) {
      var self = this;
      var startPos = window.scrollY;
      var speed = 20;
      var time = 0;

      var animate = function animate() {
        time += speed;
        window.scrollTo(0, self.getAnimationPos(time, startPos, targetPos - startPos, duration));

        if (time < duration) {
          setTimeout(animate, speed);
        }
      };

      animate();
    },
    getAnimationPos: function getAnimationPos(time, startPos, endPos, duration) {
      time /= duration / 2;

      if (time < 1) {
        return endPos / 2 * time * time + startPos;
      }

      time--;
      return -endPos / 2 * (time * (time - 2) - 1) + startPos;
    }
  });

  _exports.default = _default;
});