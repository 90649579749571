define('tm-common/initializers/auth-token', ['exports', 'tm-common/authenticators/token', 'tm-common/authenticators/custom'], function (exports, _token, _custom) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'auth-token',
        before: 'ember-simple-auth',
        initialize: function initialize(container) {
            container.register('authenticator:token', _token.default);
            container.register('authenticator:custom', _custom.default);
        }
    };
});