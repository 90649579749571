define('tm-common/models/scrape', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.autoAssignToUserId = undefined;

    var _Model$extend;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var autoAssignToUserId = 9355; // scrape@smithcarson.com

    exports.autoAssignToUserId = autoAssignToUserId;
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend((_Model$extend = {
        job: attr('string'),
        jobId: attr('number'),
        jobLog: attr('string'),
        automaticScrape: attr('number'),
        status: attr('string'),
        notes: attr('string'),
        args: attr('string'),

        totalSubJobs: attr('number'),
        currentSubJobs: attr('number'),
        subJobsComplete: attr('boolean'),

        url: attr('string'),
        isUrgent: attr('number', { defaultValue: 0 }),
        isForensic: attr('number', { defaultValue: 0 }),
        isUpdated: attr('number', { defaultValue: 0 }),
        dueDate: attr('string'),

        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        completedOn: attr('estdate'),

        assignedOn: attr('estdate'),
        assignedTo: belongsTo('employee', { async: true }),
        createdBy: belongsTo('user', { async: true }),
        matter: belongsTo('matter', { async: false }),
        subject: belongsTo('subject', { async: false }),
        hyperlinkResource: belongsTo('hyperlink', { async: false }),
        post: belongsTo('post', { async: false }),
        hyperlinkResourceId: attr('number'),
        documents: hasMany('document', { async: false }),

        // search_requests_id not sure what is this

        assignedAndDueLabel: Ember.computed('assignedTo.content.fullName', 'isUrgent', 'dueDate', function () {
            var assignedTo = this.get('assignedTo.content');
            var dueOn = this.get('dueDate');

            var title = [];

            if (Ember.isPresent(assignedTo)) {
                var name = assignedTo.get('fullName');
                if (name) {
                    title.push(name);
                }
            } else {
                title.push('Unassigned');
            }
            if (Ember.isPresent(dueOn)) {
                title.push((0, _moment.default)(dueOn).format('ddd, MMM D'));
            } else if (this.get('isUrgent')) {
                title.push('Urgent');
            }
            return title.join(' - ');
        }),

        assignedToWritable: Ember.computed({
            get: function get(key) {
                return this.get('assignedTo.content');
            },
            set: function set(key, value) {
                this.set('assignedTo', value);
                return value;
            }
        })

    }, _defineProperty(_Model$extend, 'notes', Ember.computed({
        get: function get(_key) {
            if (this.get('post')) {
                return this.get('post.scrapeNotes');
            } else {
                return this.get('hyperlinkResource.scrapeNotes');
            }
        },
        set: function set(_key, value) {
            if (this.get('post')) {
                this.set('post.scrapeNotes', value);
            } else {
                this.set('hyperlinkResource.scrapeNotes', value);
            }
            return value;
        }
    })), _defineProperty(_Model$extend, 'displayOrTitle', Ember.computed({
        get: function get(_key) {
            if (this.get('post')) {
                return this.get('post.title');
            } else {
                return this.get('hyperlinkResource.display');
            }
        }
    })), _defineProperty(_Model$extend, 'requestScrapeUrgentType', Ember.computed('isUrgent', {
        get: function get() {
            if (this.get('isUrgent') === 1) {
                return '1-day';
            }

            if (this.get('isUrgent') === 2) {
                return '2-day';
            }

            return 'no';
        },
        set: function set(key, value) {
            switch (value) {
                case '1-day':
                    this.set('isUrgent', 1);

                    break;
                case '2-day':
                    this.set('isUrgent', 2);

                    break;
                case 'no':
                    this.set('isUrgent', 0);

                    break;

                default:
            }

            return value;
        }
    })), _Model$extend));
});