define('tm-common/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'tm-common/mixins/crud/error'], function (exports, _base, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_error.default, {
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),
        metrics: Ember.inject.service(),
        raven: Ember.inject.service(),
        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        /**
         * @param data
         */
        restore: function restore(data) {
            this.get('raven').callRaven('setUserContext', {
                id: data.id,
                email: data.email
            });
            var metrics = this.get('metrics');
            metrics.identify('GoogleAnalyticsExtra', { distinctId: data.id });
            metrics.trackEvent({
                category: 'Users',
                action: 'login_restore',
                label: data.id
            });
            return Ember.RSVP.resolve(data);
        },


        /**
         * submit user supplied credentials to the API for authentication
         * for a good reference check here:
         * https://github.com/simplabs/ember-simple-auth/blob/master/examples/4-authenticated-account.html
         * @param credentials
         * @param options
         * @returns {Rx.Promise}
         */
        authenticate: function authenticate(identification, password) {
            var _this = this;

            var otp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
            var rememberDevice = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

            var ENV = this.get('ENV');
            return new Ember.RSVP.Promise(function (resolve, reject) {
                // make XHR request to api
                _this.get('ajax').post(ENV.auth.login, {
                    // This is set in order to the browser send the cookies with the request
                    xhrFields: {
                        withCredentials: true
                    },
                    data: {
                        username: identification,
                        password: password,
                        appname: ENV.appname,
                        otp_token: otp,
                        remember_my_device: rememberDevice ? window.navigator.userAgent : null
                    }
                }).then(function (response) {
                    if (response.verify_token) {
                        reject(response);
                        return;
                    }
                    // perform some validation to verify that we got a valid response from API
                    if (typeof response.profile.token === 'undefined' || typeof response.profile.id === 'undefined') {
                        var errorMessage = '<h4>Could not log you into the system: </h4> No valid user found';
                        _this.get('notify').alert({ raw: errorMessage, closeAfter: 10000 });
                        reject();
                    } else {
                        _this.get('raven').callRaven('setUserContext', {
                            id: response.profile.id,
                            email: response.profile.email
                        });

                        var metrics = _this.get('metrics');
                        metrics.identify('GoogleAnalyticsExtra', {
                            distinctId: response.profile.id
                        });
                        metrics.trackEvent({
                            category: 'Users',
                            action: 'login',
                            label: response.profile.id
                        });
                        resolve(response.profile);
                    }
                }, function (xhr, status, error) {
                    // use local error handling mixin
                    _this.handleXHR(xhr);
                    reject(error);
                });
            });
        },


        /**
         * logout
         * @returns {Rx.Promise}
         */
        invalidate: function invalidate(data) {
            var ENV = this.get('ENV');
            return this.get('ajax').request(ENV.auth.logout);
        }
    });
});