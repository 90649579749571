define('tm-common/models/task', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany,
        Model = _emberData.default.Model;
    exports.default = Model.extend({
        order: attr('number'),
        timeLimit: attr('number'),
        priority: attr('number'),
        status: attr('string', { defaultValue: 'I' }),
        comment: attr('string'),
        dueOn: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        hours: attr('number'),

        helpWanted: attr('boolean'),
        county: attr('string'),
        state: attr('string'),

        // make me a relationship
        createdBy: belongsTo('employee'),
        updatedBy: belongsTo('employee'),

        taskList: belongsTo('task-list'),
        assignedTo: belongsTo('employee', { async: true, inverse: 'tasks' }),

        // property only for UI not persisted
        isSelected: attr('boolean'),
        editAssignAndDueDate: attr('boolean'),
        notes: hasMany('task-has-note', {
            async: false
        }),
        events: hasMany('task-has-event', {
            async: false
        }),

        // computed properties
        isDone: Ember.computed('status', function () {
            return this.get('status') === 'C';
        }),

        assignedAndDueLabel: Ember.computed('assignedTo', 'dueOn', function () {
            var assignedTo = this.get('assignedTo.content');
            var dueOn = this.get('dueOn');

            var title = [];
            if (Ember.isPresent(assignedTo)) {
                title.push(assignedTo.get('fullName'));
            } else {
                title.push('Unassigned');
            }
            if (Ember.isPresent(dueOn)) {
                title.push((0, _moment.default)(dueOn).format('ddd, MMM D'));
            }
            return title.join(' - ');
        }),

        urgent: Ember.computed('priority', {
            get: function get(key) {
                return this.get('priority') === '1' || this.get('priority') === 1;
            },
            set: function set(key, value) {
                this.set('priority', value ? 1 : 0);
                return value;
            }
        }),

        assignedToWritable: Ember.computed({
            get: function get() {
                return this.get('assignedTo.content');
            },
            set: function set(key, value) {
                this.set('assignedTo', value);
                return value;
            }
        })
    });
});