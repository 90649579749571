define('tm-common/models/user-has-matter-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        rate: attr('number'),

        matterType: belongsTo('matter-type', { async: true }),
        employee: belongsTo('employee', { async: true })
    });
});