define('tm-common/components/subjects/analytics-modules/higher-education-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/higher-education-module'], function (exports, _higherEducationModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _higherEducationModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        bachelorsOption: Ember.computed('data.higherEducationOptions.[]', function () {
            var higherEducationOptions = this.get('data.higherEducationOptions.higherEducationOptions');
            return higherEducationOptions.findBy('shortName', 'bachelors');
        }),

        mastersOption: Ember.computed('data.higherEducationOptions.[]', function () {
            var higherEducationOptions = this.get('data.higherEducationOptions.higherEducationOptions');
            return higherEducationOptions.findBy('shortName', 'masters');
        }),

        postGradOption: Ember.computed('data.higherEducationOptions.[]', function () {
            var higherEducationOptions = this.get('data.higherEducationOptions.higherEducationOptions');
            return higherEducationOptions.findBy('shortName', 'post_grad');
        }),

        professionalDesignationOption: Ember.computed('data.higherEducationOptions.[]', function () {
            var higherEducationOptions = this.get('data.higherEducationOptions.higherEducationOptions');
            return higherEducationOptions.findBy('shortName', 'professional_designation');
        })
    });
});