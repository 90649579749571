define('tm-common/components/subject-profile/profile-data-parser', ['exports', 'tm-common/templates/components/subject-profile/profile-data-parser', 'tm-common/mixins/timeline-event-mixin', 'tm-common/mixins/data-entry-parser', 'ember-lifeline/mixins/run', 'moment'], function (exports, _profileDataParser, _timelineEventMixin, _dataEntryParser, _run, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_timelineEventMixin.default, _run.default, _dataEntryParser.default, {
        layout: _profileDataParser.default,
        noFormat: false,
        reRender: true,
        documentSourceName: '',
        copiedSubject: false,
        store: Ember.inject.service(),
        notify: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        classNames: ['row', 'form-group'],
        initialize: Ember.on('init', function () {
            var eventTypeParent = this.eventTypeParentName(this.get('item.value'));
            // Conditional Columns for Different Data Entry Fields
            if (eventTypeParent == 'Education') {
                this.set('isEducation', true);
            } else if (eventTypeParent == 'Residence') {
                this.set('isResidential', true);
            } else if (eventTypeParent == 'Employment') {
                this.set('isEmployment', true);
            } else if (eventTypeParent == 'Bankruptcy') {
                this.set('isShowBankruptcy', true);
            } else {
                this.set('isEducation', false);
                this.set('isResidential', false);
                this.set('isEmployment', false);
            }
        }),
        didReceiveAttrs: function didReceiveAttrs() {
            this.sendAction('loadData');
        },

        actions: {
            /*  Saving the Parsed Data as per the selected Format. All Format Parsing related code is in the mixin */
            saveParsedData: function saveParsedData() {
                var _this = this;

                var parsedData = this.get('parsedData');
                var fullTextAr = [];
                var saveTimelineEventPromises = [];
                var saveSubjectsPromises = [];
                var eventTypes = this.get('eventTypes');
                var fieldValue = this.get('item.value');
                var eventTypeParent = this.eventTypeParentName(fieldValue);
                if (!eventTypeParent) {
                    return this.get('notify').alert('Timeline Event Type Parent Not Found');
                }
                var timelineEventType = eventTypes.filterBy('timelineEventParent.name', eventTypeParent);
                var subject = this.get('model');
                parsedData.forEach(function (event) {
                    // If the checkbox for Timeline Save is checked
                    if (event.postToTimeline) {
                        var timeline_data = {};
                        if (event.state && event.state.trim().length == 2) {
                            var state = _this.get('stateAbbreviations').findBy('value', event.state.toUpperCase());
                            timeline_data.state = state.get('display');
                        } else {
                            timeline_data.state = event.state;
                        }
                        timeline_data.docType = _this.get('documentSourceName') === null ? 'this' : _this.get('documentSourceName');
                        timeline_data.county = event.county ? event.county : null;
                        timeline_data.city = event.city ? event.city : null;
                        timeline_data.viewable = 1;
                        timeline_data.matter = _this.get('currentMatter.matter');
                        timeline_data.subjectId = _this.get('model.id');
                        timeline_data.startYear = event.startYear ? event.startYear : event.startDate ? _moment.default.utc(event.startDate, 'YYYY-MM-DD').format('YYYY') : '2019';
                        timeline_data.startMonth = event.startMonth ? event.startMonth : event.startDate ? _moment.default.utc(event.startDate, 'YYYY-MM-DD').format('MM') : '01';
                        timeline_data.startDay = event.startDay ? event.startDay : event.startDate ? _moment.default.utc(event.startDate, 'YYYY-MM-DD').format('DD') : '01';
                        timeline_data.endYear = event.endYear ? event.endYear : event.endDate ? _moment.default.utc(event.endDate, 'YYYY-MM-DD').format('YYYY') : null;
                        timeline_data.endMonth = event.endDate ? _moment.default.utc(event.endDate, 'YYYY-MM-DD').format('MM') : event.endYear ? '01' : null;
                        timeline_data.endDay = event.endDate ? _moment.default.utc(event.endDate, 'YYYY-MM-DD').format('DD') : event.endYear ? '01' : null;
                        timeline_data.country = 'United States';
                        var eventType = _this.getEventType(timelineEventType, eventTypeParent, event.fullText).get('firstObject');
                        timeline_data.timelineEventType = eventType;
                        timeline_data.metaResources = event.metaResources;
                        var timeline_event = _this.get('store').createRecord('timeline_event', timeline_data);
                        saveTimelineEventPromises.push(timeline_event.save());
                    }
                    if (event.postToSubject) {
                        // If the checkbox for Subject Save is checked - Push all checked items into an array
                        fullTextAr.push(event.fullText);
                        if (eventTypeParent == 'Birth') {
                            subject.set('dob', event.startDate);
                        } else if (eventTypeParent == 'Death') {
                            subject.set('dod', event.startDate);
                        }
                    }
                });
                if (fullTextAr.length > 0) {
                    var newSubjectText = fullTextAr.join('\n');
                    var subjectFieldText = 'model.' + this.get('item.name');
                    if (this.get(subjectFieldText) != null) {
                        subject.set(this.get('item.name'), newSubjectText + '\n\n' + this.get(subjectFieldText));
                    } else {
                        subject.set(this.get('item.name'), newSubjectText);
                    }
                    saveSubjectsPromises.push(subject.save());
                }

                Ember.RSVP.Promise.all(saveTimelineEventPromises).then(function () {
                    if (saveTimelineEventPromises.length > 0) {
                        _this.get('notify').success('Event has been saved successfully');
                    }
                });

                Ember.RSVP.Promise.all(saveSubjectsPromises).then(function () {
                    if (saveSubjectsPromises.length > 0) {
                        _this.get('notify').success('Subject has been saved successfully');
                    }
                });
                return this.sendAction('cancel');
            },

            // Parse Data using the format ID
            parseData: function parseData() {
                this.set('dataGrid', true);
                this.set('noFormat', false);
                var selectedFormat = this.get('selectedParserFormat');
                var parserFormat = this.get('parserFormats').filterBy('id', selectedFormat);
                this.set('isShowFullDate', false);
                this.set('isShowState', false);
                this.set('isShowEnd', false);
                this.set('isShowCounty', false);
                this.set('isShowDetails', false);
                this.set('isShowCity', false);
                this.set('isShowMonthYear', false);
                this.set('isShowDistrict', false);
                this.set('isShowCourt', false);
                this.set('isMarital', false);
                this.set('isCharge', false);
                this.set('isChargeLevel', false);
                this.set('isArrestingAgency', false);
                this.set('isDisposition', false);

                if (selectedFormat === undefined) {
                    return this.set('noFormat', true);
                } else {
                    try {
                        this.set('parsedData', this.parseTextToTable(this.get('textToParse'), parserFormat, this.get('copiedSubject')));
                        if (!this.get('parsedData.firstObject.district')) {
                            this.set('isShowDistrict', false);
                        }
                        if (this.get('parsedData.firstObject.county')) {
                            this.set('isShowCounty', true);
                        }
                        if (this.get('parsedData.firstObject.spouseName')) {
                            this.set('isMarital', true);
                        }
                        if (this.get('parsedData.firstObject.endDate') || this.get('parsedData.firstObject.endYear')) {
                            this.set('isShowEnd', true);
                        }
                        if (this.get('parsedData.firstObject.details')) {
                            this.set('isShowDetails', true);
                        }
                        if (this.get('parsedData.firstObject.zipCode')) {
                            this.set('isShowZipCode', true);
                        }
                        if (this.get('parsedData.firstObject.startYear') && this.get('parsedData.firstObject.startMonth') && this.get('parsedData.firstObject.startDay')) {
                            this.set('isShowFullDate', true);
                        }
                        if (this.get('parsedData.firstObject.startYear') && this.get('parsedData.firstObject.startMonth')) {
                            this.set('isShowMonthYear', true);
                        }
                        if (this.get('parsedData.firstObject.city')) {
                            this.set('isShowCity', true);
                        }
                        if (this.get('parsedData.firstObject.state')) {
                            this.set('isShowState', true);
                        }
                        if (this.get('parsedData.firstObject.courtName')) {
                            this.set('isShowCourt', true);
                        }
                        if (this.get('parsedData.firstObject.chargeLevel')) {
                            this.set('isChargeLevel', true);
                        }
                        if (this.get('parsedData.firstObject.charge')) {
                            this.set('isCharge', true);
                        }
                        if (this.get('parsedData.firstObject.disposition')) {
                            this.set('isDisposition', true);
                        }
                        if (this.get('parsedData.firstObject.arrestingAgency')) {
                            this.set('isArrestingAgency', true);
                        }
                    } catch (e) {
                        this.get('notify').alert('Incorrect Data Format');
                    }
                }
            },

            setDocType: function setDocType(docType) {
                this.set('documentSourceName', docType.get('value'));
            },
            copyExisting: function copyExisting() {
                var fieldPath = 'model.' + this.get('item.value').camelize();
                this.set('textToParse', this.get(fieldPath));
                this.set('postToSubject', true);
                this.set('copiedSubject', true);
            },
            hideModal: function hideModal() {
                return this.sendAction('hideModal');
            }
        }
    });
});