define('tm-common/models/data-parser-format', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        description: attr('string'),
        parseFormat: attr('string'),
        fieldMap: attr('string'),
        activeInd: attr('number')
    });
});