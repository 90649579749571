define('tm-common/components/wizard-list', ['exports', 'tm-common/templates/components/wizard-list'], function (exports, _wizardList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _wizardList.default,
        panelClasses: null,
        panelTitle: null,
        buttonTitle: null,

        showSelectAll: false,
        showButton: false,
        showArrows: false,
        showFilter: false,
        showFieldsFilter: false,
        isDisabled: false,

        previousMarkedItem: null,

        selectedFilters: [],

        /**
         * Filters are expected to be objects with following fields
         *
         * display = what to display in placeholder
         * apiInteractionName = the field by which we filter
         * value = value to filter by
         */
        filters: null,

        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);

            /**
             * Effectively applies default filters on component insertion.
             * Not suitable to be called in init/didRender or didRecieveAttrs, since
             * these cause infinite render loops
             */
            if (this.get('filters')) {
                this.actions.applyFilter.apply(this);
            }
        },


        /**
         * @property
         * the internal properties of each item on the list array to sort by
         */
        sortPropertiesForList: ['order:asc'],
        orderProperties: Ember.computed('sortPropertiesForList.[]', function () {
            var properties = [];
            this.get('sortPropertiesForList').forEach(function (sortProperty) {
                properties.push(sortProperty.split(':')[0]);
            });
            return properties;
        }),
        /**
         * @computed
         * the list already sorted
         * to get selected first
         * then the order the user wants
         */
        sortedList: Ember.computed.sort('list', 'sortPropertiesForList'),
        isArrowDisabled: Ember.computed('sortedList.@each.isMarked', function () {
            return this.get('sortedList').filterBy('isMarked', true).length !== 1;
        }),

        allAreMarked: Ember.computed('sortedList.@each.isMarked', function () {
            return this.get('sortedList').isEvery('isMarked', true);
        }),

        setItemOrder: function setItemOrder(item, newOrder) {
            this.get('orderProperties').forEach(function (property) {
                item.set(property, newOrder);
            });
        },
        reorder: function reorder(sortedItems, moveItemId, movedItemNewOrder) {
            var _this = this;

            var order = 1;
            sortedItems.forEach(function (item) {
                _this.setItemOrder(item, order++);
            });
        },
        swap: function swap(item, direction, sortedList) {
            var oldPosition = item.get('order');
            var newPosition = oldPosition + direction;
            var neighbour = sortedList.findBy('order', newPosition);
            this.setItemOrder(item, newPosition);
            this.setItemOrder(neighbour, oldPosition);
        },


        /**
         * Used when user clicks one list item, scrolls, then shift + clicks another item
         * ==> we mark every list item between the two selected items
         */
        markItemsInInterval: function markItemsInInterval(prev, current) {
            var prevOrder = prev.get('order');
            var currOrder = current.get('order');
            var mark = !current.get('isMarked');
            var start = prevOrder <= currOrder ? prevOrder : currOrder;
            var end = prevOrder <= currOrder ? currOrder : prevOrder;

            this.get('sortedList').forEach(function (item) {
                if (item.get('order') >= start && item.get('order') <= end) {
                    item.set('isMarked', mark);
                }
            });
        },


        actions: {
            /**
             * Action called when the ember-sortable component
             * finished re-ordering a field inside the list of available fields
             * it just set a new order number based on the new order.
             *
             * @param sortedFields Array of Fields already ordered
             * @param draggedModel Field moved
             */
            reorderItems: function reorderItems(sortedItems /*, draggedModel */) {
                var _this2 = this;

                var order = 1;
                sortedItems.forEach(function (item) {
                    return _this2.setItemOrder(item, order++);
                });
            },

            toggleItemMark: function toggleItemMark(item) {
                item.set('isMarked', !item.get('isMarked'));
            },
            selectAll: function selectAll() {
                this.get('sortedList').forEach(function (item) {
                    item.set('isMarked', true);
                });
            },
            deselectAll: function deselectAll() {
                this.get('sortedList').forEach(function (item) {
                    item.set('isMarked', false);
                });
            },
            up: function up() {
                var sortedList = this.get('sortedList');
                var item = sortedList.findBy('isMarked', true);
                if (!this.get('isArrowDisabled') && item.get('order') > 1) {
                    this.swap(item, -1, sortedList);
                }
            },
            down: function down() {
                var sortedList = this.get('sortedList');
                var item = this.get('sortedList').findBy('isMarked', true);
                if (!this.get('isArrowDisabled') && item.get('order') < sortedList.length) {
                    this.swap(item, 1, sortedList);
                }
            },
            clearAllFilters: function clearAllFilters(makeRequest) {
                this.get('filters').setEach('value', null);

                if (makeRequest) {
                    this.sendAction('requestItemsWithFilterParams', {});
                }
            },
            clearFilter: function clearFilter(filter) {
                filter.set('value', null);
            },
            applyFilter: function applyFilter() {
                var params = {};
                this.get('filters').forEach(function (filter) {
                    if (filter.value) {
                        params[filter.apiInteractionName] = filter.value;
                    }
                });
                this.sendAction('requestItemsWithFilterParams', params);
            },
            itemSelected: function itemSelected(item, e) {
                if (e.shiftKey) {
                    var prevItem = this.get('previousMarkedItem');
                    this.markItemsInInterval(prevItem, item);
                }

                if (e.ctrlKey || e.metaKey) {
                    item.set('isMarked', !item.get('isMarked'));
                }

                if (!e.shiftKey && !e.ctrlKey & !e.metaKey) {
                    this.get('sortedList').forEach(function (listItem) {
                        listItem.set('isMarked', false);
                    });
                    item.set('isMarked', true);
                }

                this.set('previousMarkedItem', item);
            }
        }
    });
});