define('tm-common/components/charts/custom-bar-chart', ['exports', 'tm-common/templates/components/charts/custom-bar-chart'], function (exports, _customBarChart) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _customBarChart.default,

        hasBarLabel: false,

        barItems: Ember.computed('data.statData', function () {
            var hasBarLabel = this.get('hasBarLabel');
            var labels = this.get('data.labels');
            var barLabels = hasBarLabel ? this.get('data.barLabels') : [];
            var datasets = this.get('data.datasets');

            var dataset = datasets[0];

            var barItems = [];

            for (var i = 0; i < labels.length; i++) {
                barItems.push({
                    label: labels[i],
                    data: dataset.data[i],
                    barLabel: hasBarLabel ? barLabels[i] : null,
                    colorClass: this.getColorClass(i)
                });
            }

            return barItems;
        }),

        barColor1: null,
        barColor2: null,
        barColor3: null,
        barColor4: null,
        barColor5: null,
        barColor6: null,

        getColorClass: function getColorClass(i) {
            var colorClass = void 0;

            var barColor1 = this.get('barColor1');
            var barColor2 = this.get('barColor2');
            var barColor3 = this.get('barColor3');
            var barColor4 = this.get('barColor4');
            var barColor5 = this.get('barColor5');
            var barColor6 = this.get('barColor6');

            switch (i) {
                case 0:
                    colorClass = barColor1 || 'sc-analytics-bg-color-1';
                    break;

                case 1:
                    colorClass = barColor2 || 'sc-analytics-bg-color-2';
                    break;

                case 2:
                    colorClass = barColor3 || 'sc-analytics-bg-color-3';
                    break;

                case 3:
                    colorClass = barColor4 || 'sc-analytics-bg-color-4';
                    break;

                case 4:
                    colorClass = barColor5 || 'sc-analytics-bg-color-5';
                    break;

                case 5:
                    colorClass = barColor6 || 'sc-analytics-bg-color-6';
                    break;

                default:
                    colorClass = barColor1 || 'sc-analytics-bg-color-1';
                    break;
            }

            return colorClass;
        }
    });
});