define('tm-common/models/field-template', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        name: attr('string'),
        hideDashboard: attr('number'),
        hideSubjects: attr('number'),
        hideDocuments: attr('number'),
        typeOfReportTab: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        fromMatterId: attr('number'),
        updateFieldDefaults: (0, _emberApiActions.memberAction)({
            path: 'update-field-defaults',
            type: 'post'
        }),
        clone: (0, _emberApiActions.memberAction)({
            path: 'clone',
            type: 'post'
        }),
        copyFromCase: (0, _emberApiActions.memberAction)({
            path: 'copy-from-case',
            type: 'post'
        })
    });
});