define('tm-common/models/todo', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        body: attr('string'),
        dueOn: attr('string'),
        archived: attr('boolean'),
        priority: attr('string'),

        isSelected: attr('boolean'),

        // relationships
        createdBy: belongsTo('employee', {
            async: false
        }),
        assignedTo: belongsTo('employee', { async: true }),
        client: belongsTo('client'),
        todoCategory: belongsTo('todo-category', {
            async: false
        }),

        /**
         * @computed
         * writable to make priority a checkbox, if checked is high
         * if not is normal
         */
        isHigh: Ember.computed('priority', {
            get: function get(key) {
                return this.get('priority') === 'High';
            },
            set: function set(key, value) {
                if (value) {
                    this.set('priority', 'High');
                } else {
                    this.set('priority', 'Normal');
                }
                return value;
            }
        }),

        /**
         * this property return the group name
         * depending on the due date of the todo record
         */
        dueGroup: Ember.computed('dueOn', function () {
            var today = (0, _moment.default)();
            var tomorrow = (0, _moment.default)().add(1, 'd');
            var nextWeekStart = (0, _moment.default)().add(1, 'd');
            var nextWeekEnd = (0, _moment.default)().add(1, 'd').add(1, 'w');

            if ((0, _moment.default)(this.get('dueOn')).isBefore(today, 'day')) {
                return 'Overdue';
            } else if ((0, _moment.default)(this.get('dueOn')).isSame(today, 'day')) {
                return 'Today';
            } else if ((0, _moment.default)(this.get('dueOn')).isSame(tomorrow, 'day')) {
                return 'Tomorrow';
            } else if ((0, _moment.default)(this.get('dueOn')).isBetween(nextWeekStart, nextWeekEnd)) {
                return 'Next Few Days';
            } else if ((0, _moment.default)(this.get('dueOn')).isAfter(nextWeekEnd)) {
                return 'Future';
            } else {
                return 'None';
            }
        }),

        /**
         * this writable computed is used for the power-select value,
         * to read the content, and to write on the real property
         */
        assignedToWritable: Ember.computed({
            get: function get(key) {
                return this.get('assignedTo.content');
            },
            set: function set(key, value) {
                this.set('assignedTo', value);
                return value;
            }
        })
    });
});