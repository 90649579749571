define('tm-common/models/billable', ['exports', 'ember-data', 'accounting/format-money', 'ember-api-actions'], function (exports, _emberData, _formatMoney, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        qty: attr('number'),
        rate: attr('number'),
        cut: attr('number'),
        adjustment: attr('number'),
        total: attr('number'),
        description: attr('string'),
        cutDescription: attr('string'),
        cutReasons: attr('string'),
        trainee: belongsTo('employee', { async: true }),
        adjustmentReason: attr('string'),
        billed: attr('boolean', { defaultValue: false }),
        billType: attr('string'), // can't use billableType due to conflict w/ relationship
        reimbursementStatus: attr('string', { defaultValue: 'Company Card' }),
        reimbursedOn: attr('date'),
        autoCalculated: attr('boolean', { defaultValue: true }),

        employeeName: attr('string'),
        canSeeAmounts: attr('boolean', { defaultValue: true }),

        hoursSpent: attr('number'),
        expenses: [],

        // relationships
        invoiceGroup: belongsTo('invoice-group', {
            async: false
        }),
        billableGroup: belongsTo('billable-group', {
            async: false
        }),
        billableType: belongsTo('billable-type', {
            async: false
        }),
        invoice: belongsTo('invoice', { async: true }),
        matter: belongsTo('matter', { async: true }),
        budget: belongsTo('budget', { async: true }),
        matterWorkflow: belongsTo('matter-workflow', { async: true }),

        billableGroupId: Ember.computed.alias('billableGroup.id'),
        invoiceId: Ember.computed(function () {
            return this.belongsTo('invoice').id();
        }),
        billableTypeName: Ember.computed.alias('billableType.name'),

        reimbursementStatusName: Ember.computed('reimbursementStatus', function () {
            switch (this.get('reimbursementStatus')) {
                case 'Company':
                    return 'IGNORE';
                case 'Company Card':
                    return 'Company Credit Card';
                case 'Company Check':
                    return 'Company Check';
                case 'Employee':
                    return 'Employee';
                case 'Employee Reimbursed':
                    return 'Employee and Reimbursed';
                case 'Company Paid':
                    return 'Company Paid - A/P';
                default:
                    return 'N/A';
            }
        }),

        // make it easy to know what type of billable a template is dealing with
        isTime: Ember.computed.equal('billType', 'Time'),
        isExpense: Ember.computed('billType', function () {
            if (this.get('billType') === 'Time:Expense' || this.get('billType') === 'Piece:Expense') {
                return true;
            }
        }),
        isPiece: Ember.computed('billType', function () {
            if (this.get('billType') === 'Piece') {
                return true;
            }
        }),

        isForTraining: Ember.computed('cutReasons', function () {
            return Ember.isPresent(this.get('cutReasons')) && this.get('cutReasons').indexOf('training') >= 0;
        }),

        formattedRate: Ember.computed('rate', function () {
            return (0, _formatMoney.default)(this.get('rate'));
        }),
        formattedTotal: Ember.computed('total', function () {
            return (0, _formatMoney.default)(this.get('total'));
        }),

        computedTotal: Ember.computed('qty', 'rate', 'total', 'adjustment', function () {
            var qty = parseFloat(this.get('qty') || 0);
            var rate = parseFloat(this.get('rate') || 0);
            var tot = qty * rate;
            var adj = parseFloat(this.get('adjustment') || 0);

            return (0, _formatMoney.default)(tot + adj);
        }),

        reimburseEmployee: (0, _emberApiActions.collectionAction)({
            path: 'reimburse-employee',
            type: 'post'
        }),
        undoReimburseEmployee: (0, _emberApiActions.collectionAction)({
            path: 'undo-reimburse-employee',
            type: 'post'
        })
    });
});