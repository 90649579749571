define('ember-cli-table-pagination/utils/operators-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getOperator = getOperator;


  var operators = [Ember.Object.create({ display: 'Contains', value: 'contains', input: 1 }), Ember.Object.create({
    display: 'Does not contain:',
    value: 'not_contains',
    input: 1
  }), Ember.Object.create({ display: 'Is equal to:', value: 'equal', input: 1 }), Ember.Object.create({
    display: 'Is not equal to:',
    value: 'not_equal',
    input: 1
  }), Ember.Object.create({ display: 'Is blank:', value: 'blank', input: 0 }), Ember.Object.create({
    display: 'Is not blank:',
    value: 'not_blank',
    input: 0
  }), Ember.Object.create({ display: 'Between:', value: 'between', input: 2 }), Ember.Object.create({
    display: 'Not Between:',
    value: 'not_between',
    input: 2
  }), Ember.Object.create({ display: 'Is one of:', value: 'in', input: 1 }), Ember.Object.create({ display: 'Is NOT one of:', value: 'not_in', input: 1 }), Ember.Object.create({ display: 'All are:', value: 'all', input: 1 }), Ember.Object.create({ display: 'None are:', value: 'none', input: 1 })];

  function getOperator(accepted) {
    if (!accepted || accepted.length === 0) {
      return operators.get('firstObject');
    } else {
      return operators.findBy('value', accepted.get('firstObject'));
    }
  }

  exports.default = operators;
});