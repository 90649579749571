define('tm-common/models/resource-has-communication', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model;
    exports.default = Model.extend({
        createdOn: _emberData.default.attr('estdate'),
        createdOnDay: Ember.computed('createdOn', function () {
            return (0, _moment.default)(this.get('createdOn')).format('YYYY-MM-DD HH:mm:ss');
        }),
        sentBy: _emberData.default.belongsTo('user', { async: true }),
        recipient: _emberData.default.belongsTo('user', { async: true }),
        resource: _emberData.default.belongsTo('resource', { async: true })
    });
});