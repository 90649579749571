define('tm-common/components/bs-popover-singleton/element', ['exports', 'ember-bootstrap/components/bs-popover/element', 'tm-common/templates/components/bs-popover-singleton/element'], function (exports, _element, _element2) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _element.default.extend({
        layout: _element2.default,
        popover: Ember.inject.service(),
        actions: {
            close: function close() {
                this.get('popover').close();
            }
        }
    });
});