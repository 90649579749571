define('tm-common/components/subject-autosuggest', ['exports', 'tm-common/templates/components/subject-autosuggest'], function (exports, _subjectAutosuggest) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _subjectAutosuggest.default,
        store: Ember.inject.service(),
        placeholder: 'Please select an option',

        searchByNameAndNumbers: false,

        // current matter
        matter: null,

        // currently selected subject
        subject: null,

        initialSubjects: null,

        // event fired when selecting a subject
        selectSubject: '',

        actions: {
            // bubble up the action to the controller
            subjectSelected: function subjectSelected(subject) {
                this.sendAction('selectSubject', subject);
            },

            /** power-select for subject */
            searchSubjects: function searchSubjects(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchSubjects, term, resolve, reject, 600);
                });
            }
        },

        _performSearchSubjects: function _performSearchSubjects(term, resolve, reject) {
            var params = {
                'first_name||last_name||member_id||court_panel||court_juror': '*' + term + '*',
                matter_id: this.get('matter.id'),
                limit: 30,
                sortField: 'member_id,last_name'
            };
            if (this.get('searchByNameAndNumbers') && !isNaN(term)) {
                /**
                user typed in a number, so we need to the API to priorities the results
                ex: if i enter “1” in the search, I will see #1, then #10, then #100, 101, etc. but NOT 21, 301, etc.
                */
                params['use_special_priority'] = term;
            }

            return this.get('store').query('subject', params).then(function (items) {
                return resolve(items);
            });
        }
    });
});