define('tm-common/components/quick-print', ['exports', 'tm-common/templates/components/quick-print'], function (exports, _quickPrint) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _quickPrint.default,
        tagName: '',

        reportBuilder: Ember.inject.service(),
        notify: Ember.inject.service(),

        isAsync: false,
        enqueuedJobId: null,

        currentParams: {},
        columnFields: [],
        columnNames: [],
        columnWidths: [],

        includeFooter: false,
        fromTabular: false,
        headerText: null,
        customExtraParams: null,
        standardExtraParams: null,
        showOnlyPDF: false,
        showOnlyExcel: false,
        landscape: false,
        align: 'left',

        styleForJSI: false,

        reportName: 'export_entity_find',
        entity: '',

        addExtraParams: function addExtraParams(jsonObject, type) {
            var extraParams = this.get(type + 'ExtraParams');
            if (extraParams) {
                // allow passing functions as extraParams so that ehy can be evaluated on demand
                if (extraParams instanceof Function) {
                    extraParams = extraParams();
                }
                for (var key in extraParams) {
                    jsonObject['report_builder'][type][key] = extraParams[key];
                }
            }
        },


        actions: {
            exportList: function exportList(format) {
                var _this = this;

                var jsonObject = {
                    report_builder: {
                        standard: {
                            type: this.get('reportName'),
                            format: format,
                            style: 'misc'
                        },
                        custom: {
                            from_tabular: this.get('fromTabular'),
                            current_params: this.get('currentParams'),
                            column_fields: this.get('columnFields'),
                            column_names: this.get('columnNames'),
                            column_widths: this.get('columnWidths'),
                            entity: this.get('entity'),
                            header_text: this.get('headerText'),
                            landscape: this.get('landscape'),
                            include_footer: this.get('includeFooter'),
                            footer_center: 'Page [page] of [topage]'
                        }
                    }
                };

                this.addExtraParams(jsonObject, 'custom');
                this.addExtraParams(jsonObject, 'standard');

                if (this.get('isAsync')) {
                    this.get('reportBuilder').enqueueReport(jsonObject, function (response) {
                        if (response.ok) {
                            _this.set('enqueuedJobId', response.job);
                        } else {
                            _this.get('notify').error('There was a problem enqueueing the report for generation');
                        }
                    });
                } else {
                    this.get('reportBuilder').executeReport(jsonObject);
                }
            }
        }
    });
});