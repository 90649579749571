define('tm-common/models/prorate-subscription-cost-process', ['exports', 'ember-data', 'moment', 'ember-api-actions'], function (exports, _emberData, _moment, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        executionDate: attr('date'),
        createdOn: attr('estdate'),
        canBeReversed: attr('boolean'),

        formattedExecutionDate: Ember.computed('executionDate', function () {
            var bd = (0, _moment.default)(this.get('executionDate'));
            if (bd.isValid()) {
                return (0, _moment.default)(this.get('executionDate')).format('MM/DD/YYYY');
            }
            return '';
        }),

        createdBy: belongsTo('employee', { async: false }),
        billableGroup: belongsTo('billableGroup', { async: false }),

        reverse: (0, _emberApiActions.memberAction)({ path: 'reverse', type: 'PUT' })
    });
});