define('tm-common/helpers/margin-left', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.marginLeft = marginLeft;
    function marginLeft(params /*, hash */) {
        var pixels = params[0];
        return Ember.String.htmlSafe('margin-left: ' + pixels + 'px;');
    }

    exports.default = Ember.Helper.helper(marginLeft);
});