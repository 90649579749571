define('tm-common/models/receivable', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model;
    exports.default = Model.extend({
        getFirms: (0, _emberApiActions.collectionAction)({ path: 'firms', type: 'get' }),
        getInvoices: (0, _emberApiActions.collectionAction)({ path: 'invoices', type: 'get' })
    });
});