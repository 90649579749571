define('tm-common/mixins/authorized-route-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        /**
        The session service.
         @property session
        @readOnly
        @type SessionService
        @public
        */
        session: Ember.inject.service('session'),
        permissions: Ember.inject.service('permissions'),

        /**
        Checks if the currentUser has the permissions designated on the actual route where this mixin is added
         Actual route needs a property with an array of group names called `requiredPermissions`
         @method beforeModel
        @param {Transition} transition The transition that lead to this route
        @public
        */
        beforeModel: function beforeModel(transition) {
            // to allow show the unauthorized route
            if (transition.targetName === 'unauthorized') {
                return this._super.apply(this, arguments);
            }

            var allPermissions = this.get('permissions').check(this.get('requiredPermissions'));
            var somePermission = this.get('permissions').checkAny(this.get('requireAnyPermission'));

            if (allPermissions || somePermission) {
                return this._super.apply(this, arguments);
            } else {
                transition.abort();
                this.transitionTo('unauthorized');
            }
        }
    });
});