define('tm-common/models/charge', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        qty: attr('number'),
        rate: attr('number'),
        total: attr('number'),
        cut: attr('number'),
        status: attr('string'),
        type: attr('string'),
        description: attr('string'),
        date: attr('string'),

        billableGroup: belongsTo('billable-group', {
            async: false
        }),
        billableType: belongsTo('billable-type', {
            async: false
        }),

        effectiveRate: Ember.computed.or('rate', 'billableType.rate'),

        calculatedTotal: Ember.computed('effectiveRate', 'qty', function () {
            var total = this.get('effectiveRate') * this.get('qty');
            if (isNaN(total) || total === 0) {
                total = null;
            }
            return total;
        }),

        hasEffectiveValues: function hasEffectiveValues() {
            if (this.get('billableType.inputByQuantity')) {
                return this.get('qty') > 0;
            } else {
                return this.get('rate') > 0;
            }
        },

        finalizeTotal: function finalizeTotal() {
            if (this.get('billableType.inputByQuantity')) {
                // user has input quantity
                this.set('rate', this.get('effectiveRate'));
                this.set('total', this.get('calculatedTotal'));
            } else {
                // user has input rate
                this.set('qty', 1);
                this.set('total', this.get('rate'));
            }
        }
    });
});