define('tm-common/components/subjects/profile/accordion-subject-type', ['exports', 'tm-common/templates/components/subjects/profile/accordion-subject-type', 'tm-common/mixins/analytics-util'], function (exports, _accordionSubjectType, _analyticsUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _accordionSubjectType.default,

        allAreExpanded: true,
        allAreCollapsed: false,

        reRender: true,

        isExpanded: true,
        expandedObserver: Ember.observer('isExpanded', function () {
            var fieldValue = 'subject_type';
            if (this.get('isExpanded')) {
                Ember.$('#' + fieldValue + 'Collapse').collapse('show');
                this.set('allAreCollapsed', false);
            } else {
                Ember.$('#' + fieldValue + 'Collapse').collapse('hide');
                this.set('allAreExpanded', false);
            }
        }),

        allAreExpandedObserver: Ember.observer('allAreExpanded', function () {
            if (this.get('allAreExpanded')) {
                this.set('isExpanded', true);
            }
        }),
        allAreCollapsedObserver: Ember.observer('allAreCollapsed', function () {
            if (this.get('allAreCollapsed')) {
                this.set('isExpanded', false);
            }
        }),

        actions: {
            save: function save() {
                this.attrs.save(false, false);
                this.sendAction('openFieldChanged', null);
                this.toggleProperty('isSingleEdit');
                this.set('isSsanVisible', false);
                this.attrs.closeEditInPlace();
            },
            expandAll: function expandAll() {
                this.set('allAreExpanded', true);
                this.set('allAreCollapsed', false);
                Ember.$('.analytics-accordion.subject-profile-panel .collapse').collapse('show');
            },
            collapseAll: function collapseAll() {
                this.set('allAreCollapsed', true);
                this.set('allAreExpanded', false);
                Ember.$('.analytics-accordion.subject-profile-panel .collapse').collapse('hide');
            }
        }
    });
});