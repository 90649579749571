define('tm-common/models/notification-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        label: _emberData.default.attr('string'),
        group: _emberData.default.attr('string'),
        icon: _emberData.default.attr('string'),
        color: _emberData.default.attr('string'),
        locked: _emberData.default.attr('number'),
        sendUi: _emberData.default.attr('number'),
        sendEmail: _emberData.default.attr('number')
    });
});