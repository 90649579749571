define('tm-common/models/crm-note', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        modelName: 'crm-note', // before we were using constructor.modelname but is no longer reliable

        body: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        // relationships
        createdBy: belongsTo('employee', { async: true }),
        parent: belongsTo('crm-note', { async: true, inverse: 'comments' }),
        crmNoteCategory: belongsTo('crm-note-category', { async: true }),

        comments: hasMany('crm-note', { async: true, inverse: 'parent' }),
        crmNoteHasClients: hasMany('crm-note-has-client', { async: false }),
        crmNoteHasDeals: hasMany('crm-note-has-deal', { async: false })
    });
});