define('tm-common/helpers/target-blank-links', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.targetBlankLinks = targetBlankLinks;
    function targetBlankLinks(params) {
        if (!Ember.isPresent(params)) return null;
        if (!Ember.isPresent(params[0].string) || Ember.typeOf(params[0].string) !== 'string') {
            return params[0];
        }
        return Ember.String.htmlSafe(params[0].string.replace(/<a href/g, '<a target="_blank" href'));
    }

    exports.default = Ember.Helper.helper(targetBlankLinks);
});