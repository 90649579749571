define('tm-common/components/subjects/analytics-modules/criminal-cases-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/criminal-cases-module'], function (exports, _criminalCasesModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['subject-profile-info'],
        isExpanded: true,
        layout: _criminalCasesModule.default,

        collapseAll: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('listSort', ['order:asc']);
        },


        orderedCriminalItems: Ember.computed.sort('data.criminalData.[]', 'listSort'),
        isNoCriminalSelected: Ember.computed.equal('data.criminalData', 'No Criminal Records'),

        isReadModeNoCriminal: Ember.computed('data.subject.subjectAnalyticsCriminalCases', function () {
            var criminalReadMode = this.get('data.subject.subjectAnalyticsCriminalCases');

            return criminalReadMode === 'No Criminal Records';
        }),

        hasFelonyConvictions: Ember.computed('data.criminalOptions.criminalCharges', function () {
            var criminalOptionsCriminalCharges = this.get('data.criminalOptions.criminalCharges');
            return criminalOptionsCriminalCharges.findBy('shortName', 'has_felony_convictions');
        }),

        allCaseTypes: Ember.computed('data.subject.subjectAnalyticsCriminalCases.[]', function () {
            var criminalData = this.get('data.subject.subjectAnalyticsCriminalCases');
            var allTypes = [];

            criminalData.forEach(function (criminalItem) {
                var caseType = criminalItem.case_type || '';

                if (caseType && allTypes.indexOf(caseType) === -1) {
                    allTypes.push(caseType.trim());
                }
            });

            return allTypes.join(', ');
        }),

        caseTotal: Ember.computed('data.subject.subjectAnalyticsCriminalCases.[]', function () {
            var criminalData = Ember.typeOf(this.get('data.subject.subjectAnalyticsCriminalCases')) === 'array' ? this.get('data.subject.subjectAnalyticsCriminalCases') : [];
            return criminalData.reduce(function (acc, criminalItem) {
                return parseInt(criminalItem.qty) + acc;
            }, 0);
        }),

        actions: {
            addCriminal: function addCriminal() {
                if (this.get('isNoCriminalSelected')) {
                    this.set('data.criminalData', []);
                }
                this.get('data.criminalData').unshiftObject(Ember.Object.create({
                    order: 0,
                    year: null,
                    case_type: null,
                    case_type_analytics_key: null,
                    case_type_analytics_label: null,
                    case_notes: null,
                    qty: 1,
                    is_markdown: false
                }));
            },
            reorderCriminalItems: function reorderCriminalItems(sortedFields) {
                var index = 0;

                sortedFields.forEach(function (item) {
                    item.set('order', index);
                    index += 1;
                });
            },
            changeNoCriminalRecords: function changeNoCriminalRecords() {
                var check = this.get('data.criminalData') !== 'No Criminal Records';

                this.set('criminalCasesTemp', this.get('data.subject.subjectAnalyticsCriminalCases'));

                if (check) {
                    this.set('data.criminalData', 'No Criminal Records');
                } else {
                    // Fixes issue where initial value is No records and add criminal case button never becomes enabled
                    if (this.get('criminalCasesTemp') === 'No Criminal Records') {
                        this.set('criminalCasesTemp', []);
                    }
                    this.set('data.criminalData', this.get('criminalCasesTemp'));
                }
            },
            removeCriminalItem: function removeCriminalItem(item) {
                this.set('data.criminalData', this.get('data.criminalData').removeObject(item));
            }
        }
    });
});