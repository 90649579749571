define('tm-common/components/subjects/analytics-modules/civil-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/civil-module'], function (exports, _civilModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['subject-profile-info'],
        isExpanded: true,
        layout: _civilModule.default,

        collapseAll: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('listSort', ['order:asc']);
        },


        civilOptionsSorted: Ember.computed.sort('data.civilOptions.[]', 'listSort'),
        orderedCivilItems: Ember.computed.sort('data.civilData.[]', 'listSort'),
        isNoCivilSelected: Ember.computed.equal('data.civilData', 'No Civil Records'),

        isReadModeNoCivil: Ember.computed('data.subject.subjectAnalyticsCivil', function () {
            var civilReadMode = this.get('data.subject.subjectAnalyticsCivil');

            return civilReadMode === 'No Civil Records';
        }),

        plaintiffTotal: Ember.computed('data.subject.subjectAnalyticsCivil.[]', function () {
            return this.getTotalForType('plaintiff');
        }),

        defendantTotal: Ember.computed('data.subject.subjectAnalyticsCivil.[]', function () {
            return this.getTotalForType('defendant');
        }),

        otherTotal: Ember.computed('data.subject.subjectAnalyticsCivil.[]', function () {
            return this.getTotalForType('other');
        }),

        getTotalForType: function getTotalForType(type) {
            var civilData = this.get('data.subject.subjectAnalyticsCivil');
            var total = 0;

            civilData.forEach(function (civilItem) {
                if (civilItem.party_type_analytics === type) {
                    total += civilItem.qty ? +civilItem.qty : 1;
                }
            });

            return total;
        },


        totalsString: Ember.computed('plaintiffTotal', 'defendantTotal', 'otherTotal', function () {
            var parts = [];

            if (this.get('plaintiffTotal')) {
                parts.push(this.get('plaintiffTotal') + ' plaintiff');
            }

            if (this.get('defendantTotal')) {
                parts.push(this.get('defendantTotal') + ' defendant');
            }

            if (this.get('otherTotal')) {
                parts.push(this.get('otherTotal') + ' other');
            }

            return parts.join(', ');
        }),

        caseTotal: Ember.computed('plaintiffTotal', 'defendantTotal', 'otherTotal', function () {
            return this.get('plaintiffTotal') + this.get('defendantTotal') + this.get('otherTotal');
        }),

        allCaseTypes: Ember.computed('data.subject.subjectAnalyticsCivil.[]', function () {
            var civilData = this.get('data.subject.subjectAnalyticsCivil');
            var allTypes = [];

            civilData.forEach(function (civilItem) {
                var caseType = civilItem.case_type || '';

                if (caseType && allTypes.indexOf(caseType) === -1) {
                    allTypes.push(caseType.trim());
                }
            });

            return allTypes.join(', ');
        }),

        actions: {
            addCivil: function addCivil() {
                if (this.get('isNoCivilSelected')) {
                    this.set('data.civilData', []);
                }
                this.get('data.civilData').unshiftObject(Ember.Object.create({
                    order: 0,
                    year: null,
                    case_type: null,
                    case_type_analytics_key: null,
                    case_type_analytics_label: null,
                    party_type: null,
                    party_type_analytics: null,
                    case_notes: null,
                    qty: 1,
                    is_markdown: false
                }));
            },
            reorderCivilItems: function reorderCivilItems(sortedFields) {
                var index = 0;

                sortedFields.forEach(function (item) {
                    item.set('order', index);
                    index += 1;
                });
            },
            changeNoCivilRecords: function changeNoCivilRecords() {
                var check = this.get('data.civilData') !== 'No Civil Records';

                this.set('civilCasesTemp', this.get('data.subject.subjectAnalyticsCivil'));

                if (check) {
                    this.set('data.civilData', 'No Civil Records');
                } else {
                    // Fixes issue where initial value is No records and add civil case button never becomes enabled
                    if (this.get('civilCasesTemp') === 'No Civil Records') {
                        this.set('civilCasesTemp', []);
                    }
                    this.set('data.civilData', this.get('civilCasesTemp'));
                }
            },
            removeCivilItem: function removeCivilItem(item) {
                this.set('data.civilData', this.get('data.civilData').removeObject(item));
            }
        }
    });
});