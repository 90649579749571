define('tm-common/models/timeline-event-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        pointOrDuration: attr('string'),
        iconFile: attr('string'),
        default: attr('number'),
        timelineEvents: hasMany('timeline-event', {
            async: true,
            inverse: 'timelineEventType'
        }),
        timelineEventParent: belongsTo('timeline-event-parent', { async: true })
    });
});