define('tm-common/models/tag', ['exports', 'ember-data', 'tm-common/mixins/random-color-generator'], function (exports, _emberData, _randomColorGenerator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        Model = _emberData.default.Model;
    exports.default = Model.extend(_randomColorGenerator.default, {
        name: attr('string'),
        type: attr('string'),
        isInternal: attr('number', { defaultValue: 0 }),

        resourceHasTag: _emberData.default.hasMany('resource-has-tag', {
            async: false
        }),
        resource: _emberData.default.belongsTo('resource', { async: false }),

        color: Ember.computed('name', function () {
            var id = parseInt(this.get('id'));
            return this.getRandomColor(Math.abs(Math.sin(id) + id) * 1547 % 1);
        })
    });
});