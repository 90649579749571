define('tm-common/components/subjects/analytics-modules/states-module', ['exports', 'tm-common/mixins/analytics-util', 'tm-common/templates/components/subjects/analytics-modules/states-module'], function (exports, _analyticsUtil, _statesModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _statesModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        states: Ember.computed('data.subject.subjectAnalyticsStates.[]', function () {
            var subject = this.get('data.subject');
            return subject.get('subjectAnalyticsStates');
        }),

        statesSelected: Ember.computed('data.subject.subjectAnalyticsStates.[]', function () {
            var subjectStates = this.get('data.subject.subjectAnalyticsStates');
            return !!(subjectStates && subjectStates.length > 0);
        }),

        shouldShowStatesOther: Ember.computed('data.statesOptions.@each.checked', function () {
            return !!this.get('data.statesOptions').filterBy('checked').find(function (x) {
                return x.get('display') === 'Other';
            });
        }),

        shouldShowNationalOriginOther: Ember.computed('data.subject.nationalOrigin', 'data.subject.nationalOriginNotes', function () {
            var nationalOrigin = this.get('data.subject.nationalOrigin');
            return nationalOrigin && ['United States', 'Unknown'].indexOf(nationalOrigin) === -1;
        }),

        statesString: Ember.computed('shouldShowNationalOriginOther', 'states', function () {
            var _this = this;

            var shouldShowNationalOriginOther = this.get('shouldShowNationalOriginOther');
            var states = this.get('states');

            var rtn = [];

            if (shouldShowNationalOriginOther) {
                rtn.push(this.get('data.subject.nationalOrigin'));
            }

            if (states) {
                states.forEach(function (s) {
                    if (s === 'Other') {
                        rtn.push(_this.get('data.subject.statesOther'));
                    } else {
                        rtn.push(s);
                    }
                });
            }

            if (this.get('vitalsSection') || this.get('overviewSection') || this.get('isChild')) {
                return rtn.join(', ');
            } else {
                return Ember.String.htmlSafe(rtn.join('<br/>'));
            }
        })
    });
});