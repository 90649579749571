define('tm-common/mixins/token-controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        // token param for url authentication
        queryParams: ['newtoken', 'redirect'],
        newtoken: null,
        redirect: null,

        unsetToken: Ember.observer('newtoken', function () {
            if (this.get('newtoken')) {
                Ember.run.next(this, function () {
                    this.set('newtoken', null);
                });
            }
        })
    });
});