define('tm-common/models/hyperlink-metric', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        Model = _emberData.default.Model;

    var string = attr('string');
    var number = attr('number');

    exports.default = Model.extend({
        key: string,
        value: string,
        weight: number,

        resource: belongsTo('hyperlink', { async: true })
    });
});