define('tm-common/components/subject-custom-modules/relevant-content', ['exports', 'tm-common/templates/components/subject-custom-modules/relevant-content'], function (exports, _relevantContent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _relevantContent.default,

        store: Ember.inject.service(),
        relevantContentUpdater: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);

            // get data for posts
            this.get('relevantContentUpdater').setFunction(this.getData.bind(this));
            this.get('relevantContentUpdater').refresh();
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.get('relevantContentUpdater').setFunction(this.getData.bind(this));
            this.get('relevantContentUpdater').refresh();
        },


        getData: function getData() {
            var _this = this;

            var subject = this.get('data.subject');
            var hyperlinks = subject.get('hyperlinks');
            var resourcesIds = hyperlinks.map(function (link) {
                return link.get('resourceId');
            });
            if (resourcesIds.length > 0) {
                this.get('store').query('post', {
                    hyperlink_resource_id: resourcesIds,
                    sortField: 'weight'
                }).then(function (posts) {
                    _this.set('posts', posts.sortBy('weight'));
                });
            }
        },

        actions: {
            reload: function reload() {
                this.get('relevantContentUpdater').refresh();
            }
        }
    });
});