define('tm-common/components/form-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        fieldType: null,
        fieldName: null,
        layoutName: Ember.computed('fieldType', function () {
            var type = 'undefined';
            switch (this.get('fieldType')) {
                case 'text':
                case 'textWithCaseNote':
                    type = 'text';
                    break;
                case 'longtext':
                case 'longtextWithCaseNote':
                    type = 'textarea';
                    break;
                case 'longtext+markdown':
                    type = 'markdown';
                    break;
                case 'hyperlink':
                    type = 'hyperlink';
                    break;
                case 'enum':
                    type = 'select';
                    break;
                case 'enum-multiple':
                    type = 'multiple-select';
                    break;
                case 'radio':
                    type = 'radio';
                    break;
                case 'checkboxes':
                    type = 'checkboxes';
                    break;
                case 'checkbox':
                    type = 'checkbox';
                    break;
                case 'date':
                    type = 'date';
                    break;
                case 'readonly':
                    type = 'readonly';
                    break;
            }
            return 'components/dynamic-edit-field/' + type + '-field';
        }),

        model: {},
        field: {},
        optionDisplayField: 'display',

        // input for hyperlink type
        display: null,
        url: null,

        // sort options from app-lists by weight
        sortedOptions: Ember.computed.sort('options', 'sortDefinition'),
        sortDefinition: ['weight', 'display'],

        onInit: Ember.on('init', function () {
            var propName = 'model.' + this.get('fieldName');
            var self = this;
            Ember.defineProperty(this, 'value', Ember.computed(propName, function () {
                return self.get(propName);
            }));
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('fieldType') === 'hyperlink') {
                var value = this.get('model.' + this.get('fieldName'));
                if (Ember.isPresent(value)) {
                    var regexp = /\[(.*)\]\((.*)\)/g;
                    var matches = [].concat(_toConsumableArray(value.matchAll(regexp)));
                    if (matches.length > 0 && matches[0].length === 3) {
                        this.set('display', matches[0][1]);
                        this.set('url', matches[0][2]);
                    }
                }
            }
        },


        selectedValue: Ember.computed('value', 'options.@each.value', function () {
            if (this.get('options') === undefined) {
                return null;
            }
            if (typeof this.get('value') === 'string') {
                return this.get('options').findBy('value', this.get('value'));
            }
            return this.get('options').findBy(this.get('optionDisplayField'), this.get('value.' + this.get('optionDisplayField')));
        }),

        selectedMultiValues: Ember.computed('value', 'options.@each.value', function () {
            var _this = this;

            var valueList = this.get('value');
            return valueList.map(function (val) {
                return _this.get('options').findBy('value', val);
            });
        }),

        bind: Ember.observer('value', function () {
            // update model property with new value
            this.set('model.' + this.get('fieldName'), this.get('value'));
            // optional event to be fired when data changes
            this.sendAction('change', this.get('model'), this.get('field'), this.get('value'));
        }),

        checkedValues: Ember.computed('value', 'options.@each.value', function () {
            var checked = [];
            var currentValue = this.get('value');
            if (currentValue && Ember.isPresent(currentValue) && Ember.typeOf(currentValue) === 'string') {
                checked = currentValue.split(',');
            }
            return checked;
        }),

        actions: {
            changeValue: function changeValue(newValue) {
                // this.sendAction('selectAction', newValue)
                this.set('selectedValue', newValue);
                if (newValue) {
                    this.set('value', newValue.get('value'));
                }
            },
            updateCheckedValues: function updateCheckedValues(values, value, operation) {
                this.set('value', values.join(','));
            },
            updateMainValueFromHyperlink: function updateMainValueFromHyperlink() {
                var display = this.get('display') || 'Hyperlink';
                var url = this.get('url') || 'https://empty.com';
                this.set('value', '[' + display + '](' + url + ')');
            }
        }
    });
});