define('tm-common/models/matter-workflow', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var alias = Ember.computed.alias;
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        matter: belongsTo('matter', { async: false }),
        workflow: belongsTo('workflow', { async: true }),

        workflowName: attr('string'),
        workflowEnabled: attr('number'),
        percentage: attr('number'),
        weight: attr('number'),
        assignedTo: _emberData.default.belongsTo('employee', { async: true }),
        comment: _emberData.default.attr('string'),
        status: _emberData.default.attr('string'),
        various: _emberData.default.attr('boolean', { defaultValue: false }),
        hours: _emberData.default.attr('number'),
        deadline: _emberData.default.attr('string'),

        // fields needed for matter-new where we display a matter-workflow as a matter for matter-list/editable-cell/workflow
        customA: alias('assignedTo'),
        customC: alias('comment'),
        customS: alias('status'),
        customVarious: alias('various'),
        customHours: alias('hours'),
        customDeadline: alias('deadline')
    });
});