define('tm-common/helpers/subject-field', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.subjectField = subjectField;


    /**
     * take in a subject and matterField
     * return the subject value for the provided matterField "value"
     *
     * @param params
     * @returns {*}
     */
    function subjectField(params /*, hash */) {
        if (Ember.$.isArray(params)) {
            var subject = params[0];
            var matterField = params[1];
            var breakTag = '<br />';
            var content = subject.get(matterField.get('name'));
            var fieldType = matterField.get('fieldType');
            // do not return null or undefined values, just a blank space
            if (Ember.isEmpty(content)) {
                return '';
            }

            if (fieldType === 'date') {
                var dateValue = (0, _moment.default)(content, 'YYYY-MM-DD');
                if (dateValue.isValid()) {
                    return dateValue.format('MM-DD-YYYY').toString();
                }
            }
            return Ember.String.htmlSafe((content + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
        }
        // invalid params
        Ember.debug('Invalid parameters supplied to subject-field');
        return '';
    }

    exports.default = Ember.Helper.helper(subjectField);
});