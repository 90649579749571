define('tm-common/mixins/route-from-table-settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Mixin.create({
        /**
         * @property
         * @private
         *
         * generated route to be used as key on the backend
         */
        routeKey: null,

        appName: 'tm-admin',

        /**
         * @property
         * @public
         *
         * current route name
         */
        currentRoute: null,
        /**
         * @property
         * @private
         *
         * table settings record from the db already loaded from ember-data
         */
        currentTableSetting: null,
        /**
         * @property
         * @private
         *
         * state field from currentTableSetting
         */
        currentControllerState: null,

        /**
         * @property
         * @private
         *
         * To check if the table already loaded from table settings
         * to avoid restoring and saving immediatly
         */
        loadedFromTableSettings: false,

        isFirstLoad: false,

        /**
         * @method
         * @public
         *
         *
         * This method needs to be overrided if the current route
         * requires params to be generated
         */
        getCurrentRouteParams: function getCurrentRouteParams() {
            return [];
        },


        /**
         * @method
         * @private
         *
         * On this method this mixin checks if it's coming from another route
         * to a persisted in table settings route
         * if it's the first time it will query the db to get
         * the user settings for this table according to the generated routeKey
         * once it gets the data it saves to currentControllerState
         */
        beforeModel: function beforeModel(transition) {
            var _router,
                _this = this;

            this._super(transition);

            this.set('routeKey', (_router = this.router).generate.apply(_router, [this.get('currentRoute')].concat(_toConsumableArray(this.getCurrentRouteParams()))));
            if (!this.get('loadedFromTableSettings')) {
                // Only load from the api once
                var previousRoutes = this.router._routerMicrolib.currentHandlerInfos;
                var previousRoute = previousRoutes && previousRoutes.pop();
                var lastVisitedRoute = null;
                if (previousRoute) {
                    lastVisitedRoute = previousRoute.name;
                }
                return this.store.queryRecord('table-setting', {
                    route: this.get('routeKey'),
                    app_name: this.get('appName')
                }).then(function (ts) {
                    _this.set('currentTableSetting', ts);
                    _this.set('currentControllerState', null);
                    if (Ember.isPresent(ts) && lastVisitedRoute !== _this.get('currentRoute')) {
                        // comes from another route, check saved params in db
                        _this.set('currentControllerState', JSON.parse(ts.get('state')));
                    }
                    // just continue to save params in afterModel
                    return Ember.RSVP.resolve();
                }, function (e) {
                    return Ember.RSVP.reject(e);
                });
            } else {
                return Ember.RSVP.resolve();
            }
        },
        model: function model(params) {
            if (!this.get('loadedFromTableSettings')) {
                return {};
            } else {
                return this._super(params);
            }
        },


        /**
         * @param controller
         * @param resolved
         * On this method if is the first load from table settings
         * it will invoke fromTableSettingsState on the controller
         * to re-hydrate the controller state
         */

        setupController: function setupController(controller, resolved) {
            var _this2 = this;

            if (!this.get('loadedFromTableSettings')) {
                this.set('loadedFromTableSettings', true);
                Ember.Logger.info('From currentControllerState', this.get('currentControllerState'));
                Ember.run.later(function () {
                    controller.fromTableSettingsState(_this2.get('currentControllerState'));
                    _this2.set('isFirstLoad', true);
                    _this2.refresh();
                });
            } else {
                this._super(controller, resolved);
                Ember.run.later(function () {
                    if (_this2.get('isFirstLoad')) {
                        controller.updateColumnsFromTableSettingState(_this2.get('currentControllerState'));
                        _this2.set('isFirstLoad', false);
                    }
                    _this2.afterSetupController(controller);
                });
            }
        },


        /**
         * @method
         * @private
         * if it's not the first load, it will persist changes to the table
         * settings
         */
        afterSetupController: function afterSetupController(controller) {
            var _this3 = this;

            this.set('currentControllerState', controller.toTableSettingsState());
            Ember.Logger.info('To currentControllerState', this.get('currentControllerState'));
            if (this.get('currentTableSetting')) {
                if (this.get('currentTableSetting.state') !== JSON.stringify(this.get('currentControllerState'))) {
                    this.set('currentTableSetting.state', JSON.stringify(this.get('currentControllerState')));
                    this.get('currentTableSetting').save();
                }
            } else {
                this.store.createRecord('table-setting', {
                    route: this.get('routeKey'),
                    appName: this.get('appName'),
                    state: JSON.stringify(this.get('currentControllerState'))
                }).save().then(function (tableSetting) {
                    _this3.set('currentTableSetting', tableSetting);
                });
            }
        },


        /**
         * when a user goes to another case to show the same table
         * it should reset the data because controllers are singleton
         * so if the new case doesn't have a table-setting record
         * it will start in a pristine state
         */
        resetController: function resetController(controller, isExiting, transition) {
            this._super.apply(this, arguments);
            if (isExiting) {
                Ember.Logger.info('exiting controller');
                this.resetControllerFields(controller, '');
            }
        },
        resetControllerFields: function resetControllerFields(controller, sortField) {
            controller.set('page', 1);
            controller.set('perPage', 50);
            controller.set('quickSearch', '');
            controller.set('sortField', sortField);
            controller.set('extraParams', {});
            controller.get('columns').forEach(function (col) {
                col.setProperties({
                    filterValue: null,
                    showFilter: false,
                    advFilterOperator: undefined,
                    advFilterValue: undefined,
                    advFilterValue2: undefined
                });
            });
            controller.get('additionalColumnsForFilter').forEach(function (col) {
                col.setProperties({
                    filterValue: null,
                    showFilter: false,
                    advFilterOperator: undefined,
                    advFilterValue: undefined,
                    advFilterValue2: undefined
                });
            });
        },
        setDefaultFilters: function setDefaultFilters(controller, filter) {
            controller.set('extraParams', filter['extraParams']);
            controller.get('additionalColumnsForFilter').forEach(function (col) {
                filter['additionalColumnsForFilter'].forEach(function (acff) {
                    if (acff['fieldName'] === col['fieldName']) {
                        col.setProperties({
                            filterValue: acff['filterValue'],
                            showFilter: true,
                            advFilterOperator: acff['advFilterOperator'],
                            advFilterValue: acff['advFilterValue'],
                            advFilterValue2: acff['advFilterValue2']
                        });
                    }
                });
            });
        },


        actions: {
            /**
             * @method
             * @private
             *
             * checks if user is going out of the route to re-init loadedFromTableSettings
             */
            willTransition: function willTransition(transition) {
                var target = transition.targetName;
                if (target !== this.get('currentRoute')) {
                    Ember.Logger.info('Reset loadedFromTableSettings in willTransition');
                    this.set('loadedFromTableSettings', false);
                }
            },
            resetSearchAndFilters: function resetSearchAndFilters(sortField) {
                var defaultFilter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

                this.resetControllerFields(this.controller, sortField);
                if (defaultFilter) {
                    this.setDefaultFilters(this.controller, defaultFilter);
                }
                this.set('currentControllerState', this.controller.toTableSettingsState());
                this.set('currentTableSetting.state', JSON.stringify(this.get('currentControllerState')));
                this.get('currentTableSetting').save();
                this.refresh();
            }
        }
    });
});