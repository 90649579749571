define('tm-common/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = _emberInflector.default.inflector;

  // Tell the inflector that the plural of "meta" is "metas"
  inflector.irregular('meta', 'metas');

  // Modules must have an export, so we just export an empty object here
  exports.default = {};
});