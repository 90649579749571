define('tm-common/components/charts/custom-bar-chart/bar-chart-bar-vert', ['exports', 'tm-common/templates/components/charts/custom-bar-chart/bar-chart-bar-vert'], function (exports, _barChartBarVert) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _barChartBarVert.default,

        barHeight: '0',

        barStyle: Ember.computed('barHeight', function () {
            return 'height:' + this.barHeight;
        }),

        didInsertElement: function didInsertElement() {
            var _this = this;

            Ember.run.later(function () {
                _this.set('barHeight', _this.get('barData.data') + '%');
            }, 100);

            this._super.apply(this, arguments);
        },


        customWidthStyle: Ember.computed('customBarWidth', function () {
            var customBarWidth = this.get('customBarWidth');
            if (customBarWidth) {
                return 'width:' + customBarWidth + '% !important;';
            } else {
                return '';
            }
        })
    });
});