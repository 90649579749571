define('tm-common/models/analytic-list', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        display: attr('string'),
        value: attr('string'),
        shortName: attr('string'),
        groupName: attr('string'),
        private: attr('string'),

        order: attr('number'),
        description: attr('string'),
        categoryName: attr('string')
    });
});