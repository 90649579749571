define('tm-common/models/appl-list', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        display: attr('string'),
        value: attr('string'),
        weight: attr('number'),
        shortName: attr('string'),

        applListsCategory: belongsTo('appl-lists-category')
    });
});