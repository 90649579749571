define('tm-common/models/invoice-batch', ['exports', 'ember-data', 'ember-api-actions', 'moment'], function (exports, _emberData, _emberApiActions, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        status: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        tmNum: attr('number'),

        // pending relationship

        // relationship
        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        invoices: hasMany('invoice', {
            async: false
        }),
        moveInvoices: (0, _emberApiActions.memberAction)({
            path: 'move_invoices',
            type: 'post'
        }),

        formattedCreatedOn: Ember.computed('createdOn', function () {
            return (0, _moment.default)(this.get('createdOn')).format('MM-DD-YYYY');
        })
    });
});