define('tm-common/helpers/subject-field-is-null', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Helper.extend({
        observingKey: false,

        compute: function compute(_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                subject = _ref2[0],
                matterField = _ref2[1];

            var key = Ember.String.camelize(matterField.get('value'));
            var content = subject.get(key);

            if (key !== this.observingKey) {
                // if we were observing something else, cleanup
                if (this.observingKey) {
                    subject.removeObserver(this.observingKey, this, 'recompute');
                }

                this.observingKey = key;
                subject.addObserver(key, this, 'recompute');
            }

            switch (matterField.get('fieldClass')) {
                case 'widget':
                    if (matterField.get('mergeField') && matterField.get('mergeField') !== '') {
                        return true;
                    } else {
                        return false;
                    }

                default:
                    return Ember.isEmpty(content);
            }
        }
    });
});