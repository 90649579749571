define('tm-common/models/invoice-detail', ['exports', 'ember-data', 'tm-common/models/invoice'], function (exports, _emberData, _invoice) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr;
    exports.default = _invoice.default.extend({
        timeEntries: attr(),
        manualEntries: attr(),
        subscriptionEntries: attr(),
        travelEntries: attr(),
        pieceEntries: attr(),
        canSeeAmounts: attr('boolean')
    });
});