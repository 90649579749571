define('tm-common/components/if-equal', ['exports', 'tm-common/templates/components/if-equal'], function (exports, _ifEqual) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _ifEqual.default,

        classNames: ['ifEqual'],

        isEqual: Ember.computed('param1', 'param2', function () {
            return this.get('param1') === this.get('param2');
        })
    });
});