define('tm-common/models/user-event-log', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        code: attr('string'),
        description: attr('string'),
        createdOn: attr('estdate'),

        createdBy: belongsTo('user')
    });
});