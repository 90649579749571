define('ember-cli-table-pagination/components/bs-table-pagination/table-row', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-row'], function (exports, _tableRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tableRow.default,
    tagName: 'tr'
  });
});