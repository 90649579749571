define('tm-common/models/matter-has-team', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        matter: belongsTo('matter', {
            async: false
        }),

        team: belongsTo('team', {
            async: false
        }),

        group: belongsTo('group', {
            async: false
        })
    });
});