define('tm-common/components/charts/custom-bar-chart/bar-chart-bar', ['exports', 'tm-common/templates/components/charts/custom-bar-chart/bar-chart-bar'], function (exports, _barChartBar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _barChartBar.default,

        barWidth: '0',

        barStyle: Ember.computed('barWidth', function () {
            return 'width:' + this.barWidth;
        }),

        didInsertElement: function didInsertElement() {
            var _this = this;

            Ember.run.later(function () {
                _this.set('barWidth', _this.get('barData.data') + '%');
            }, 100);

            this._super.apply(this, arguments);
        }
    });
});