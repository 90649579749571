define('tm-common/models/invoice-output-payment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        amount: attr('number'),
        paidOn: attr('string'),
        paymentType: attr('string'),
        writeOff: Ember.computed('paymentType', {
            get: function get(key) {
                return this.get('paymentType') !== 'payment';
            },
            set: function set(key, value) {
                this.set('paymentType', value ? 'write-off' : 'payment');
                return value;
            }
        }),

        // relationships
        invoiceOutput: belongsTo('invoice-output', {
            async: false
        })
    });
});