define('tm-common/components/use-field-phrase', ['exports', 'tm-common/templates/components/use-field-phrase'], function (exports, _useFieldPhrase) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _useFieldPhrase.default,
        useAllPhrases: false,
        fieldPhrases: Ember.computed('phrases.length', 'field.display', 'useAllPhrases', function () {
            var phrases = (this.get('phrases') || []).filterBy('matter.content', null) || [];
            return this.get('useAllPhrases') ? phrases.sortBy('weight') : phrases.filterBy('field', this.get('field.display')).sortBy('weight');
        }),
        caseFieldPhrases: Ember.computed('phrases.length', 'field.display', 'useAllPhrases', function () {
            var phrases = (this.get('phrases') || []).rejectBy('matter.content', null) || [];
            return this.get('useAllPhrases') ? phrases.sortBy('weight') : phrases.filterBy('field', this.get('field.display')).sortBy('weight');
        })
    });
});