define('tm-common/helpers/comma-2br', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.comma2br = comma2br;
    function comma2br(params /*, hash*/) {
        if (Ember.$.isArray(params)) {
            var breakTag = '<br />';
            var str = params[0];
            if (str) {
                return Ember.String.htmlSafe((str + '').replace(/,/g, breakTag));
            }
            return '';
        }
    }

    exports.default = Ember.Helper.helper(comma2br);
});