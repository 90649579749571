define('tm-common/components/x-upload/body-list', ['exports', 'tm-common/templates/components/x-upload/body-list'], function (exports, _bodyList) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _bodyList.default,
        filesToUpload: [],

        actions: {
            changeType: function changeType(fileToUpload, newType) {
                fileToUpload.set('docTypeInitial', newType);
                if (newType) {
                    fileToUpload.set('documentType', newType.get('value'));
                } else {
                    fileToUpload.set('documentType', null);
                }
            },
            changeSubject: function changeSubject(fileToUpload, newSubject) {
                fileToUpload.set('subject', newSubject);
                if (newSubject) {
                    fileToUpload.set('subjectId', newSubject.get('id'));
                } else {
                    fileToUpload.set('subjectId', null);
                }
            }
        }
    });
});