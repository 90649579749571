define('tm-common/models/matter-field-default', ['exports', 'tm-common/mixins/matter-field-bitmasks', 'ember-data'], function (exports, _matterFieldBitmasks, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend(_matterFieldBitmasks.default, {
        fieldTemplateId: attr('number'),
        fieldName: attr('string'),
        fieldDisplay: attr('string'),
        enabled: attr('number'),
        viewable: attr('number'),
        weight: attr('number'),
        locked: attr('number'),
        iconFile: attr('string'), // pulled from appl_list: icon_file_names

        section: attr('number'),
        mergeField: attr('string'),

        fieldType: attr('string'), // From the appl_list: matter_field_input_types
        listName: attr('string'), // One group_name from the appl_list

        columnWidth: attr('number'),

        fieldClass: attr('string'),
        parsable: attr('number'), // Tells whether the field is enabled for Data Entry Parsing
        isPortalFilter: attr('number'),
        showAboveOverview: attr('number'),

        fieldTemplate: belongsTo('field-template', {
            async: true
        }),

        isVital: Ember.computed('section', function () {
            return this.get('section') === 1;
        }),
        isOverview: Ember.computed('section', function () {
            return this.get('section') === 2;
        }),

        isLabelEditable: Ember.computed('fieldName', 'isEnabledCase', function () {
            return this.get('fieldName').indexOf('custom') >= 0 && this.get('isEnabledCase');
        }),

        /**
         * Mare sure some fields cannot be customized.
         * Examples:
         * first & last name must always be text input field
         * dob & dod must always be calendar dates
         * foreperson & monitor must always be booleans
         */
        isUncustomizable: Ember.computed('value', function () {
            return ['first_name', 'last_name', 'dob', 'dod', 'monitor', 'foreperson'].indexOf(this.get('fieldName')) > -1;
        }),

        // used to prevent users from changing some fields
        disabledChangeLock: Ember.computed('fieldName', 'isDisabledCase', function () {
            return this.get('fieldName') === 'first_name' || this.get('fieldName') === 'last_name' || this.get('fieldName') === 'dob' || this.get('fieldName') === 'dod' ||
            // this.get('fieldName') === 'monitor' ||
            // this.get('fieldName') === 'foreperson' ||
            this.get('isDisabledCase');
        })
    });
});