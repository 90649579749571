define('tm-common/models/db', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        tmNum: attr('string'),
        url: attr('string'),
        name: attr('string'),
        description: attr('string'),
        instructions: attr('string'),
        status: attr('number', { defaultValue: 1 }),
        national: attr('number', { defaultValue: 0 }),
        type: attr('string'),
        invoiceDescription: attr('string'),

        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        // UI state
        isExpanded: attr('boolean', { defaultValue: false }),

        createdBy: belongsTo('employee', { async: true }),
        updatedBy: belongsTo('employee', { async: true }),

        dbHasTags: hasMany('db-has-tag', { async: false, inverse: 'db' }),
        dbHasFiles: hasMany('db-has-file', { async: false, inverse: 'db' }),

        dbMemos: hasMany('db-memo', { async: false, inverse: 'db' }),

        memoCount: attr('number'),

        /** for sorting purposes */
        fullName: Ember.computed('name', 'state', 'county', function () {
            var fname = '';
            if (this.get('state')) {
                fname += this.get('state.name');
            }
            if (this.get('county')) {
                fname += this.get('county.name') + ' County';
            }
            fname += this.get('name');
            return fname;
        }),

        primaryTag: Ember.computed('dbHasTags.[]', function () {
            return this.get('dbHasTags').filterBy('tagCategory', 'primary').mapBy('dbTag').get('firstObject');
        }),
        secondaryTag: Ember.computed('dbHasTags.[]', function () {
            return this.get('dbHasTags').filterBy('tagCategory', 'secondary').mapBy('dbTag').get('firstObject');
        }),
        state: Ember.computed('dbHasTags.[]', function () {
            return this.get('dbHasTags').mapBy('dbTag').filterBy('isState').get('firstObject');
        }),
        county: Ember.computed('dbHasTags.[]', function () {
            return this.get('dbHasTags').mapBy('dbTag').filterBy('isCounty').get('firstObject');
        }),
        country: Ember.computed('dbHasTags.[]', function () {
            // NOTE Do we even need the @each?
            return this.get('dbHasTags').mapBy('dbTag').filterBy('isCountry').get('firstObject');
        }),
        notOnline: Ember.computed('dbHasTags.[]', function () {
            return !!this.get('dbHasTags').mapBy('dbTag').filterBy('isNotOnline').get('firstObject');
        }),
        notPublic: Ember.computed('dbHasTags.[]', function () {
            return !!this.get('dbHasTags').mapBy('dbTag').filterBy('isNotPublic').get('firstObject');
        }),
        subpoenaReleaseRequired: Ember.computed('dbHasTags.[]', function () {
            return !!this.get('dbHasTags').mapBy('dbTag').filterBy('isSubpoenaReleaseRequired').get('firstObject');
        }),
        fixedCost: Ember.computed('dbHasTags.[]', function () {
            return !!this.get('dbHasTags').mapBy('dbTag').filterBy('isFixedCost').get('firstObject');
        }),
        allMemosViewed: Ember.computed('dbMemos.[]', 'dbMemos.@each.hasBeenViewed', function () {
            return this.get('dbMemos').filterBy('hasBeenViewed', 1).length === this.get('dbMemos.length');
        }),

        notify: (0, _emberApiActions.memberAction)({
            type: 'put',
            path: 'notify'
        })
    });
});