define('tm-common/components/tui-editor', ['exports', '@toast-ui/editor'], function (exports, _editor) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);

            // here we use a syntax like <property>:<method>:<tui option> to update such property (optional)>
            this.set('tuiOptions', ['previewStyle:changePreviewStyle', 'editType:changeMode:initialEditType', 'height:height', 'minHeight:minHeight', 'language', 'useDefaultHTMLSanitizer', 'useCommandShortcut', 'usageStatistics', 'toolbarItems', 'hideModeSwitch', 'viewer', 'value:setMarkdown:initialValue', 'hooks', 'plugins']);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            this.send('setupEditor');
        },
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);

            this.send('destroyEditor');
        },


        // gathers all the options to initialize TUI editor, respecting tuiOptions syntax
        options: Ember.computed('tuiOptions.[]', function () {
            var options = {};

            /*
                TODO: Fix this eslint violation
                 ```
                    error
                    iterators/generators require regenerator-runtime, which is
                    too heavyweight for this guide to allow them. Separately,
                    loops should be avoided in favor of array iterations
                    no-restricted-syntax
                ```
             */
            // eslint-disable-next-line no-restricted-syntax
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('tuiOptions')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var o = _step.value;

                    // eslint-disable-next-line prefer-const
                    var _o$split = o.split(':'),
                        _o$split2 = _slicedToArray(_o$split, 3),
                        optionName = _o$split2[0],
                        tuiOption = _o$split2[2];

                    tuiOption = tuiOption || optionName;
                    var value = this.get(optionName);

                    if (value !== undefined) {
                        options[tuiOption] = value;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return options;
        }),

        actions: {
            destroyEditor: function destroyEditor() {
                this.removeObservers();
            },
            setupEditor: function setupEditor() {
                var _this = this;

                var editor = _editor.default.factory(Ember.assign(this.get('options'), {
                    el: this.element,
                    events: {
                        load: function load() {
                            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                                args[_key] = arguments[_key];
                            }

                            return _this.eventInvoked.apply(_this, ['onLoad'].concat(args));
                        },
                        change: function change() {
                            for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
                                args[_key2] = arguments[_key2];
                            }

                            return _this.eventInvoked.apply(_this, ['onChange', _this.editor.getMarkdown()].concat(args));
                        },
                        stateChange: function stateChange() {
                            for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
                                args[_key3] = arguments[_key3];
                            }

                            return _this.eventInvoked.apply(_this, ['onStateChange'].concat(args));
                        },
                        focus: function focus() {
                            for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
                                args[_key4] = arguments[_key4];
                            }

                            return _this.eventInvoked.apply(_this, ['onFocus'].concat(args));
                        },
                        blur: function blur() {
                            for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
                                args[_key5] = arguments[_key5];
                            }

                            return _this.eventInvoked.apply(_this, ['onBlur'].concat(args));
                        }
                    }
                }));

                if (editor.getCodeMirror) {
                    var cm = editor.getCodeMirror();

                    cm.on('inputRead', function (doc, event) {
                        if (event.origin !== 'paste') {
                            return;
                        }

                        var firstText = event.text[0];
                        var lineNum = event.from.line;
                        var chNum = event.from.ch;
                        var newTexts = event.text.map(function (_element) {
                            return _element.replace('●', '-');
                        });

                        newTexts.forEach(function (text, i) {
                            if (i === 0) {
                                doc.replaceRange(text, { line: lineNum, ch: chNum }, { line: lineNum, ch: chNum + firstText.length });
                            } else {
                                doc.replaceRange(text, { line: lineNum + i, ch: 0 }, { line: lineNum + i, ch: event.text[i].length });
                            }
                        });
                    });
                }

                this.set('editor', editor);

                this.addObservers();
            }
        },

        // tests if an `actionName` function exists and calls it with the arguments if so
        eventInvoked: function eventInvoked(actionName) {
            if (this.get(actionName)) {
                for (var _len6 = arguments.length, args = Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
                    args[_key6 - 1] = arguments[_key6];
                }

                this.get(actionName).apply(undefined, _toConsumableArray(args));
            }
        },
        addObservers: function addObservers() {
            var _this2 = this;

            this._observers = {};

            /*
                TODO: Fix this eslint violation
                 ```
                    error
                    iterators/generators require regenerator-runtime, which is
                    too heavyweight for this guide to allow them. Separately,
                    loops should be avoided in favor of array iterations
                    no-restricted-syntax
                ```
             */
            // eslint-disable-next-line no-restricted-syntax

            var _loop = function _loop(o) {
                var _o$split3 = o.split(':'),
                    _o$split4 = _slicedToArray(_o$split3, 2),
                    optionName = _o$split4[0],
                    tuiMethod = _o$split4[1];

                if (tuiMethod) {
                    _this2._observers[optionName] = function () {
                        var value = this.get(optionName);

                        /* `value` is a special case because using `setValue`
                           moves the current cursor position so we need to avoid calling it
                           only call it when the editor value is different from the new value we got */
                        if (optionName === 'value' && this.editor.getMarkdown) {
                            var editorValue = this.editor.getMarkdown();

                            if (editorValue !== value) {
                                this.editor.setMarkdown(value, false);
                            }
                        } else {
                            (false && !(!!this.editor[tuiMethod]) && Ember.assert('Editor instance should be have a function \'' + tuiMethod + '\' but found ' + this.editor[tuiMethod] + ' instead.', !!this.editor[tuiMethod]));

                            this.editor[tuiMethod].call(this.editor, value);
                        }
                    };

                    // eslint-disable-next-line ember/no-observers
                    _this2.addObserver(optionName, _this2, _this2._observers[optionName]);
                }
            };

            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = this.tuiOptions[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var o = _step2.value;

                    _loop(o);
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }
        },
        removeObservers: function removeObservers() {
            if (this._observers) {
                /*
                    TODO: Fix this eslint violation
                     ```
                        error
                        iterators/generators require regenerator-runtime, which is
                        too heavyweight for this guide to allow them. Separately,
                        loops should be avoided in favor of array iterations
                        no-restricted-syntax
                    ```
                 */
                // eslint-disable-next-line no-restricted-syntax
                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = this.tuiOptions[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var o = _step3.value;

                        var _o$split5 = o.split(':'),
                            _o$split6 = _slicedToArray(_o$split5, 1),
                            _optionName = _o$split6[0];

                        if (this._observers[_optionName]) {
                            this.removeObserver(_optionName, this, this._observers[_optionName]);
                            delete this._observers[_optionName];
                        }
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }
            }
        }
    });
});