define('tm-common/components/subjects/analytics-modules/employment-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/employment-module'], function (exports, _employmentModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _employmentModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        isGovernmentSector: Ember.computed('data.subject.governmentSectorCurrent', function () {
            var governmentSector = this.get('data.subject.governmentSectorCurrent');
            return governmentSector && governmentSector === '1';
        }),

        isMilitaryVeteranCurrent: Ember.computed('data.subject.militaryVeteranCurrent', function () {
            var militaryVeteranCurrent = this.get('data.subject.militaryVeteranCurrent');
            return militaryVeteranCurrent && militaryVeteranCurrent === '1';
        }),

        showLicenseTypes: Ember.computed('data.subject.employmentAnalysis', function () {
            var employmentAnalysis = this.get('data.subject.employmentAnalysis');
            return employmentAnalysis === 'Holds / Held professional license';
        }),

        // used when editing
        isEmployed: Ember.computed('data.employmentOptions.employmentStatus.@each.checked', function () {
            var employmentOptions = this.get('data.employmentOptions.employmentStatus');
            var employedOption = employmentOptions.findBy('shortName', 'is_employed');
            return employedOption.get('checked');
        }),

        noProfessionalLicense: Ember.computed('data.employmentOptions.employmentAnalysis.[]', function () {
            var employmentOptions = this.get('data.employmentOptions.employmentAnalysis');
            return employmentOptions.findBy('shortName', 'analytics_employment_none');
        }),

        hasProfessionalLicense: Ember.computed('data.employmentOptions.employmentAnalysis.[]', function () {
            var employmentOptions = this.get('data.employmentOptions.employmentAnalysis');
            return employmentOptions.findBy('shortName', 'holds_professional_license');
        }),

        unknownEmploymentStatusOption: Ember.computed('data.employmentOptions.employmentStatus', function () {
            var employmentStatus = this.get('data.employmentOptions.employmentStatus');
            return employmentStatus.findBy('shortName', 'employment_status_unknown');
        }),

        showEmploymentData: Ember.computed('data.subject.subjectAnalyticsEmploymentStatus', 'data.subject.governmentSectorCurrent', 'data.subject.militaryVeteranCurrent', 'data.subject.subjectAnalyticsEmploymentAnalysisCategory', function () {
            var subjectAnalyticsEmploymentStatus = this.get('data.subject.subjectAnalyticsEmploymentStatus');
            var governmentSectorCurrent = this.get('data.subject.governmentSectorCurrent');
            var militaryVeteranCurrent = this.get('data.subject.militaryVeteranCurrent');
            var subjectAnalyticsEmploymentAnalysisCategory = this.get('data.subject.subjectAnalyticsEmploymentAnalysisCategory');

            var rtn = false;

            if (subjectAnalyticsEmploymentStatus && subjectAnalyticsEmploymentStatus.length > 0) {
                rtn = true;
            }
            if (governmentSectorCurrent && governmentSectorCurrent !== '') {
                rtn = true;
            }
            if (militaryVeteranCurrent && militaryVeteranCurrent !== '') {
                rtn = true;
            }
            if (subjectAnalyticsEmploymentAnalysisCategory && subjectAnalyticsEmploymentAnalysisCategory.length > 0) {
                rtn = true;
            }

            return rtn;
        }),

        // used in readOnly mode
        statusContainsEmployed: Ember.computed('data.subject.subjectAnalyticsEmploymentStatus', function () {
            var subjectAnalyticsEmploymentStatus = this.get('data.subject.subjectAnalyticsEmploymentStatus');
            var rtn = false;
            if (subjectAnalyticsEmploymentStatus && subjectAnalyticsEmploymentStatus.length > 0 && subjectAnalyticsEmploymentStatus.includes('Employed')) {
                rtn = true;
            }
            return rtn;
        }),

        employementCategories: Ember.computed('data.subject.subjectAnalyticsEmploymentAnalysisCategory', function () {
            var categories = this.get('data.subject.subjectAnalyticsEmploymentAnalysisCategory');
            return categories ? categories.join(', ') : null;
        }),

        employmentContentExists: Ember.computed('data.subject.subjectAnalyticsEmploymentStatus', 'isGovernmentSector', 'isMilitaryVeteranCurrent', 'data.subject.occupations', 'data.subject.employmentAnalysis', function () {
            return this.get('data.subject.subjectAnalyticsEmploymentStatus') || this.get('isGovernmentSector') || this.get('isMilitaryVeteranCurrent') || this.get('data.subject.occupations') || this.get('data.subject.employmentAnalysis');
        }),

        noProfessionalLicenseChanged: Ember.observer('data.subject.employmentAnalysis', function () {
            if (this.get('data.subject.employmentAnalysis') === 'No Professional License') {
                this.get('data.employmentOptions.employmentAnalysisCategory').forEach(function (c) {
                    c.set('checked', false);
                });
            }
        }),

        /*
        when subject is not employed, gray out & deselect following fields: government, military, industry
        */
        isEmployedObserver: Ember.observer('isEmployed', function () {
            if (!this.get('isEmployed')) {
                this.set('data.subject.employmentPositionCurrent', null);
                this.set('data.subject.governmentSectorCurrent', null);
                this.set('data.subject.militaryVeteranCurrent', null);
                this.set('data.subject.employmentTypeCurrent', null);
            }
        })
    });
});