define('tm-common/components/subjects/analytics-modules/religious-interests', ['exports', 'tm-common/templates/components/subjects/analytics-modules/religious-interests'], function (exports, _religiousInterests) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _religiousInterests.default,
        tagName: '',

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        religiousOption: Ember.computed('data.religionOptions.[]', function () {
            return this.get('data.religionOptions.religiousInterestsOptions').findBy('shortName', 'religious');
        }),

        affiliationOption: Ember.computed('data.religionOptions.[]', function () {
            return this.get('data.religionOptions.religiousAffiliationOptions').rejectBy('shortName', 'no_affiliation');
        }),

        noAffiliationOption: Ember.computed('data.religionOptions.[]', function () {
            return this.get('data.religionOptions.religiousAffiliationOptions').findBy('shortName', 'no_affiliation');
        }),

        noAffiliationChanged: Ember.observer('noAffiliationOption.checked', function () {
            if (this.get('noAffiliationOption.checked')) {
                var affiliationOptions = this.get('data.religionOptions.religiousAffiliationOptions').rejectBy('shortName', 'no_affiliation');
                affiliationOptions.forEach(function (option) {
                    option.set('checked', false);
                });
            }
        }),

        setLevelOfInterestAccordingToTheme: Ember.on('init', function () {
            if (this.get('data.editMode') && this.get('data.subject.socnetThemes') != null && this.get('data.subject.socnetThemes').indexOf('Religion / Spirituality') > 0) {
                var socialMediaLevelOfInterest = this.get('data.religionOptions.religiousLevelOfInterestOptions').findBy('shortName', 'social_media_posts');
                socialMediaLevelOfInterest.set('checked', true);
            }
        })
    });
});