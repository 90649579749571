define('tm-common/services/current-matter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * the currently "open" matter
     */
    matter: null,

    /**
     * quick access to the matter_id
     */
    id: null,

    /**
     * store the current route so menus can be updated
     */
    currentRoute: null,

    /**
     * the matter fields related to the matter
     */
    matterFields: null,

    /**
     * Reset state of the matter service
     */
    reset: function reset() {
      this.set('id', null);
      this.set('matter', null);
      this.set('matterFields', null);
    }
  });
});