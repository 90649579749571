define('tm-common/models/workflow', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        type: attr('string'),

        backendName: Ember.computed('name', function () {
            return this.get('name').toLowerCase().underscore();
        }),
        frontendName: Ember.computed('name', function () {
            return Ember.String.camelize(this.get('name').toLowerCase());
        }),

        // relationships
        matterHasWorkflow: hasMany('matter-has-workflow', { async: true }),
        vResourceTask: hasMany('v-resource-task', { async: true })
    });
});