define('tm-common/models/case-note', ['exports', 'tm-common/models/note', 'ember-data'], function (exports, _note, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var hasMany = _emberData.default.hasMany,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _note.default.extend({
        children: hasMany('case-note', { async: false, inverse: 'parent' }),
        parent: belongsTo('case-note', { async: false, inverse: 'children' })
    });
});