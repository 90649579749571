define('tm-common/components/subjects/analytics-modules/residential-phase-one-module', ['exports', 'tm-common/mixins/analytics-util', 'tm-common/templates/components/subjects/analytics-modules/residential-phase-one-module'], function (exports, _analyticsUtil, _residentialPhaseOneModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _residentialPhaseOneModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        })
    });
});