define('tm-common/components/empty-component', ['exports', 'tm-common/templates/components/empty-component'], function (exports, _emptyComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _emptyComponent.default,
        tagName: ''
    });
});