define('tm-common/models/announcement', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        description: attr('string'),
        showFrom: attr('string'),
        showTo: attr('string'),
        viewable: attr('boolean', { defaultValue: true }),
        matters: hasMany('matter', { async: false }),
        announcementHasMatters: hasMany('announcement-has-matter', { async: false }),

        relatedCases: attr('string'),
        relatedCasesComputed: Ember.computed('matters.@each.unboundTitle', function () {
            var matters = this.get('matters');
            this.set('relatedCases', matters.map(function (matter) {
                return matter.get('unboundTitle');
            }).join('\n'));
        })
    });
});