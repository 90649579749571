define('tm-common/models/matter-has-private-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        // only relationships here
        matterPrivateTag: belongsTo('matter-private-tag', {
            async: false
        }),
        matter: belongsTo('matter', { async: true })
    });
});