define('tm-common/components/subject-custom-field-read', ['exports', 'tm-common/templates/components/subject-custom-field-read'], function (exports, _subjectCustomFieldRead) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _subjectCustomFieldRead.default,

        state: 'defaultReadOnly', // can be either 'defaultReadOnly'

        vitalsSection: false,

        isChild: false,

        isParent: true,

        isTable: false
    });
});