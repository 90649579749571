define('tm-common/components/subjects/profile/modules/non-jury/civil-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/non-jury/civil-module'], function (exports, _civilModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _civilModule.default,

        classNames: ['inplace-edit-profile-data'],

        showCivilHistory: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');

            if (field.get('value') === 'civil') {
                return true;
            }
        }),

        highlightsField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            var highlights = sortedMatterFields.findBy('value', 'highlights');
            if (highlights) {
                return highlights;
            } else {
                return false;
            }
        }),

        showCivilAnalytics: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            if (field.get('value') === 'civil_analytics') {
                return true;
            } else {
                var civilAnalyticsField = sortedMatterFields.find(function (mf) {
                    if (mf.get('value') === 'civil_analytics') {
                        return true;
                    }
                    return false;
                });

                if (civilAnalyticsField && civilAnalyticsField.get('mergeField') === 'civil') {
                    return true;
                }
            }

            return false;
        }),

        civilNotesField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'civil_notes');
        }),

        showCivilNotes: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            if (field.get('value') === 'civil_notes') {
                return true;
            } else {
                var civilNotesField = sortedMatterFields.find(function (mf) {
                    if (mf.get('value') === 'civil_notes') {
                        return true;
                    }
                    return false;
                });

                if (civilNotesField && civilNotesField.get('mergeField') === 'civil') {
                    return true;
                }
            }

            return false;
        })
    });
});