define('tm-common/helpers/min-date', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.minDate = minDate;
    function minDate(params) {
        return params.every(function (p) {
            return p.toString().includes('Jan 01 1000');
        });
    }

    exports.default = Ember.Helper.helper(minDate);
});