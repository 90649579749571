define('ember-cli-table-pagination/helpers/pager-loop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pagerLoop = pagerLoop;


  /**
   * for a given model and pager object
   * return the matching field value
   * @param model
   * @param field
   * @returns {*}
   */
  function pagerLoop(params, namedArgs) {
    return Ember.get(namedArgs.model, namedArgs.field.fieldName);
  }
  exports.default = Ember.Helper.helper(pagerLoop);
});