define('tm-common/models/matter-workflow-assignee', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        matterWorkflowId: attr('number'),
        userId: attr('number'),
        status: attr('string'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate')
    });
});