define('tm-common/components/subjects/analytics-modules/criminal-module', ['exports', 'tm-common/mixins/analytics-util', 'tm-common/templates/components/subjects/analytics-modules/criminal-module'], function (exports, _analyticsUtil, _criminalModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _criminalModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        noneOption: Ember.computed('data.criminalOptions.criminalAnalysis', function () {
            var criminalOptionsCriminalAnalysis = this.get('data.criminalOptions.criminalAnalysis');
            return criminalOptionsCriminalAnalysis.findBy('shortName', 'no_criminal');
        }),

        isReadModeNoCriminal: Ember.computed('data.subject.subjectAnalyticsCriminal', function () {
            var subjectAnalyticsCriminal = this.get('data.subject.subjectAnalyticsCriminal');

            var noCriminalFound = false;
            if (subjectAnalyticsCriminal) {
                subjectAnalyticsCriminal.forEach(function (c) {
                    if (c) {
                        if (c.indexOf('No Criminal Records') >= 0) {
                            noCriminalFound = true;
                        }
                    }
                });
            }

            return noCriminalFound;
        }),

        showCriminalTotalError: Ember.computed('data.subject.criminalTotal', function () {
            var criminalTotal = this.get('data.subject.criminalTotal');
            return criminalTotal === '0' || criminalTotal && !/^(0|[1-9]\d*)$/.test(criminalTotal);
        }),

        noCriminalRecordsChanged: Ember.observer('noneOption.checked', function () {
            if (this.get('noneOption.checked')) {
                this.set('data.subject.criminal', 'None Identified');
                this.set('data.subject.criminalTotal', null);
                var charges = this.get('data.criminalOptions.criminalAnalysis').rejectBy('shortName', 'no_criminal').rejectBy('shortName', 'traffic');
                charges.forEach(function (option) {
                    option.set('checked', false);
                });
            } else {
                this.set('data.subject.criminal', null);
            }
        }),

        noneEnteredForSubjectCriminalAnalysis: Ember.observer('data.subject.criminal', 'data.criminalOptions.criminalAnalysis.@each.checked', function () {
            var criminalAnalysis = this.get('data.subject.criminal');
            var selectedCriminalAnalysisOptions = this.get('data.criminalOptions.criminalAnalysis').rejectBy('shortName', 'no_criminal').filterBy('checked', true);
            if ((!Ember.isPresent(criminalAnalysis) || criminalAnalysis.toLowerCase() === 'none') && !selectedCriminalAnalysisOptions) {
                this.set('noneOption.checked', true);
            }
        }),

        hasFelonyConvictions: Ember.computed('data.criminalOptions.criminalCharges', function () {
            var criminalOptionsCriminalCharges = this.get('data.criminalOptions.criminalCharges');
            return criminalOptionsCriminalCharges.findBy('shortName', 'has_felony_convictions');
        }),

        showCriminalData: Ember.computed('data.subject.subjectAnalyticsCriminal', 'data.subject.subjectAnalyticsCriminalCharges', 'data.subject.criminalTotal', 'data.subject.subjectAnalyticsCriminalRights', function () {
            var subjectAnalyticsCriminal = this.get('data.subject.subjectAnalyticsCriminal');
            var subjectAnalyticsCriminalCharges = this.get('data.subject.subjectAnalyticsCriminalCharges');
            var criminalTotal = this.get('data.subject.criminalTotal');
            var subjectAnalyticsCriminalRights = this.get('data.subject.subjectAnalyticsCriminalRights');

            var rtn = false;

            if (subjectAnalyticsCriminal && subjectAnalyticsCriminal.length > 0) {
                rtn = true;
            }
            if (subjectAnalyticsCriminalCharges && subjectAnalyticsCriminalCharges.length > 0) {
                rtn = true;
            }
            if (criminalTotal && criminalTotal !== '') {
                rtn = true;
            }
            if (subjectAnalyticsCriminalRights && subjectAnalyticsCriminalRights.length) {
                rtn = true;
            }

            return rtn;
        })
    });
});