define('tm-common/models/report-component-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr(),
        reportComponentTemplates: hasMany('report-component-templates', {
            async: true
        }),
        reportComponents: hasMany('report-component', { async: true })
    });
});