define('tm-common/models/group-has-permission', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        relationship: attr('string'),

        group: belongsTo('group', { async: true }),
        permission: belongsTo('permission', { async: true })
    });
});