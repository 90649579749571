define('tm-common/models/announcement-has-matter', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        matter: belongsTo('matter', { async: false }),
        announcement: belongsTo('announcement')
    });
});