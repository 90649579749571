define('tm-common/services/analytics-forms-data', ['exports', 'tm-common/mixins/analytics-util'], function (exports, _analyticsUtil) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(_analyticsUtil.default, {
        analyticsLists: null,
        currentSubject: null,
        baseData: null,
        profileEditMode: false,
        sidebarEditMode: false,

        refreshData: function refreshData() {
            var kwargs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            if (typeof kwargs.sidebarEditMode !== 'undefined') {
                this.set('sidebarEditMode', kwargs.sidebarEditMode);
            }

            if (typeof kwargs.profileEditMode !== 'undefined') {
                this.set('profileEditMode', kwargs.profileEditMode);
            }

            this._buildData(this.get('currentSubject'), this.get('analyticsLists'));
        },
        buildData: function buildData(subject, analyticsLists) {
            this.set('currentSubject', subject);
            this.set('analyticsLists', analyticsLists);
            this._buildData(subject, analyticsLists);
        },
        _buildData: function _buildData(subject, analyticsLists) {
            var kwargs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

            if (typeof kwargs.sidebarEditMode !== 'undefined') {
                this.set('sidebarEditMode', kwargs.sidebarEditMode);
            }

            if (typeof kwargs.profileEditMode !== 'undefined') {
                this.set('profileEditMode', kwargs.profileEditMode);
            }

            this.set('baseData', Ember.Object.create({
                statesOptions: this.buildStatesOptions(subject, analyticsLists.states),
                propertyOptions: analyticsLists.property,
                civilOptions: this.buildCivilOptions(subject, analyticsLists.civil_analysis),
                civilData: subject.get('subjectAnalyticsCivil'),
                criminalData: subject.get('subjectAnalyticsCriminalCases'),
                criminalOptions: this.buildCriminalOptions(subject, analyticsLists.criminal_analysis, analyticsLists.criminal_charges, analyticsLists.criminal_rights),
                trafficOptions: this.buildTrafficOptions(subject, analyticsLists.traffic_analysis),
                employmentOptions: this.buildEmploymentOptions(subject, analyticsLists.employment_status, analyticsLists.occupations, analyticsLists.employment_analysis, analyticsLists.employment_analysis_category),
                educationOptions: this.buildEducationOptions(subject, analyticsLists.education_analysis),
                socnetOptions: this.buildSocnetOptions(subject, analyticsLists.socnet_other, analyticsLists.socnet_themes, analyticsLists.socnet_themes_hidden, analyticsLists.socnet_tones),
                possibleConnectionsOptions: this.buildPossibleConnectionsOptions(subject, analyticsLists.possible_connections_category),
                maritalStatusOptions: this.buildMaritalStatusOptions(subject, analyticsLists.marital_status),
                childrenOptions: this.buildChildrenOptions(subject, analyticsLists.has_children, analyticsLists.children),
                employmentTwoOptions: this.buildEmploymentTwoOptions(subject, analyticsLists.employment_type, analyticsLists.employment_length),
                productUseOptions: this.buildProductUseOptions(subject, analyticsLists.product_use, analyticsLists.product_use_tobacco, analyticsLists.addiction_withdrawal_experience, analyticsLists.juror_medical_issues),
                otherOptions: this.buildOtherOptions(subject, analyticsLists.political_views, analyticsLists.media_outlet),
                higherEducationOptions: this.buildHigherEducationOptions(subject, analyticsLists.higher_education, analyticsLists.higher_education_masters, analyticsLists.higher_education_post_grad),
                governmentBranchOptions: this.buildGovernmentSectorBranches(subject),
                militaryBranchOptions: this.buildMilitaryBranches(subject),
                maritalAttributesOptions: this.buildMaritalAttributesOptions(subject),
                religionOptions: this.buildReligionOptions(subject, analyticsLists.religious_interests, analyticsLists.religious_affiliation, analyticsLists.religious_level_of_interest)
            }));
        },


        dataForProfile: Ember.computed('baseData', 'currentSubject', 'profileEditMode', function () {
            return Ember.Object.create({
                subject: this.get('currentSubject'),
                editMode: this.get('profileEditMode'),
                statesOptions: this.get('baseData.statesOptions'),
                propertyOptions: this.get('baseData.propertyOptions'),
                civilOptions: this.get('baseData.civilOptions'),
                civilData: this.get('baseData.civilData'),
                criminalData: this.get('baseData.criminalData'),
                criminalOptions: this.get('baseData.criminalOptions'),
                trafficOptions: this.get('baseData.trafficOptions'),
                employmentOptions: this.get('baseData.employmentOptions'),
                educationOptions: this.get('baseData.educationOptions'),
                socnetOptions: this.get('baseData.socnetOptions'),
                possibleConnectionsOptions: this.get('baseData.possibleConnectionsOptions'),
                maritalStatusOptions: this.get('baseData.maritalStatusOptions'),
                childrenOptions: this.get('baseData.childrenOptions'),
                employmentTwoOptions: this.get('baseData.employmentTwoOptions'),
                productUseOptions: this.get('baseData.productUseOptions'),
                otherOptions: this.get('baseData.otherOptions'),
                higherEducationOptions: this.get('baseData.higherEducationOptions'),
                governmentBranchOptions: this.get('baseData.governmentBranchOptions'),
                militaryBranchOptions: this.get('baseData.militaryBranchOptions'),
                maritalAttributesOptions: this.get('baseData.maritalAttributesOptions'),
                religionOptions: this.get('baseData.religionOptions')
            });
        }),

        dataForSidebar: Ember.computed('baseData', 'currentSubject', 'sidebarEditMode', function () {
            return Ember.Object.create({
                subject: this.get('currentSubject'),
                editMode: this.get('sidebarEditMode'),
                statesOptions: this.get('baseData.statesOptions'),
                propertyOptions: this.get('baseData.propertyOptions'),
                civilOptions: this.get('baseData.civilOptions'),
                civilData: this.get('baseData.civilData'),
                criminalData: this.get('baseData.criminalData'),
                criminalOptions: this.get('baseData.criminalOptions'),
                trafficOptions: this.get('baseData.trafficOptions'),
                employmentOptions: this.get('baseData.employmentOptions'),
                educationOptions: this.get('baseData.educationOptions'),
                socnetOptions: this.get('baseData.socnetOptions'),
                possibleConnectionsOptions: this.get('baseData.possibleConnectionsOptions'),
                maritalStatusOptions: this.get('baseData.maritalStatusOptions'),
                childrenOptions: this.get('baseData.childrenOptions'),
                employmentTwoOptions: this.get('baseData.employmentTwoOptions'),
                productUseOptions: this.get('baseData.productUseOptions'),
                otherOptions: this.get('baseData.otherOptions'),
                higherEducationOptions: this.get('baseData.higherEducationOptions'),
                governmentBranchOptions: this.get('baseData.governmentBranchOptions'),
                militaryBranchOptions: this.get('baseData.militaryBranchOptions'),
                maritalAttributesOptions: this.get('baseData.maritalAttributesOptions'),
                religionOptions: this.get('baseData.religionOptions')
            });
        })
    });
});