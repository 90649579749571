define('tm-common/components/subjects/analytics-modules/other-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/other-module'], function (exports, _otherModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _otherModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        mediaOutletOtherOption: Ember.computed('data.otherOptions.mediaOutletOptions.[]', function () {
            var mediaOutletOptions = this.get('data.otherOptions.mediaOutletOptions');
            return mediaOutletOptions.findBy('shortName', 'other');
        })
    });
});