define('ember-cli-table-pagination/components/light-table/columns/base-with-filter', ['exports', 'ember-cli-table-pagination/templates/components/light-table/columns/base-with-filter', 'ember-light-table/components/columns/base'], function (exports, _baseWithFilter, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    layout: _baseWithFilter.default,
    classNames: ['lt-column', 'nowrap']
  });
});