define('tm-common/models/resource-has-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model;
    exports.default = Model.extend({
        tag: _emberData.default.belongsTo('tag', { async: false }),
        resource: _emberData.default.belongsTo('resource', { async: true, polymorphic: true }),

        resourceId: Ember.computed.reads('resource.id')
    });
});