define('tm-common/models/db-has-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        tagCategory: attr('string', { defaultValue: 'special' }),

        db: belongsTo('db', { async: true }),
        dbTag: belongsTo('db-tag', { async: false })
    });
});