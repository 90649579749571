define('tm-common/components/subjects/analytics-modules/marital-status-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/marital-status-module'], function (exports, _maritalStatusModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _maritalStatusModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        marriedOption: Ember.computed('data.maritalStatusOptions.[]', function () {
            var maritalStatusOptions = this.get('data.maritalStatusOptions');
            return maritalStatusOptions.findBy('shortName', 'married');
        })
    });
});