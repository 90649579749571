define('tm-common/helpers/dates-begin-and-end-month', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.datesBeginAndEndMonth = datesBeginAndEndMonth;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function datesBeginAndEndMonth(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            date1 = _ref2[0],
            date2 = _ref2[1];

        var startingStartEarliestDate = new Date(date1 + ' 00:00:00');
        var startEarliestYear = startingStartEarliestDate.getFullYear();
        var startEarliestMonth = startingStartEarliestDate.getMonth() + 1;
        var startEarliestDay = startingStartEarliestDate.getDate();

        var startingStartLatestDate = new Date(date2 + ' 00:00:00');
        var startLatestYear = startingStartLatestDate.getFullYear();
        var startLatestMonth = startingStartLatestDate.getMonth() + 1;
        var startLatestDay = startingStartLatestDate.getDate();

        var lastDayOfStartLatestDateMonth = new Date(startLatestYear, startLatestMonth, 0).getDate();

        return startEarliestYear === startLatestYear && startEarliestMonth === startLatestMonth && startEarliestDay === 1 && startLatestDay === lastDayOfStartLatestDateMonth;
    }

    exports.default = Ember.Helper.helper(datesBeginAndEndMonth);
});