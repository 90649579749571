define('tm-common/models/firm-has-matter-has-tax', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        firmHasMatter: belongsTo('firm-has-matter', { async: true }),
        taxRule: belongsTo('tax-rule', { async: true }),
        split: attr('number'),

        formattedSplit: Ember.computed('split', function () {
            var perc = Number(this.get('split')).toFixed(2);
            return perc + ' %';
        })
    });
});