define('tm-common/components/subjects/analytics-modules/employment-two-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/employment-two-module'], function (exports, _employmentTwoModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _employmentTwoModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        jobLevel: ['Low-level', 'Mid-level', 'High-level / Exec.', 'Small Business Owner', 'Unknown'],

        isGovernmentSector: Ember.computed('data.subject.governmentSector', function () {
            var governmentSector = this.get('data.subject.governmentSector');
            return governmentSector && governmentSector === '1' ? 'Yes' : 'No';
        }),

        isMilitaryVeteran: Ember.computed('data.subject.militaryVeteran', function () {
            var militaryVeteran = this.get('data.subject.militaryVeteran');
            return militaryVeteran && militaryVeteran === '1' ? 'Yes' : 'No';
        }),

        removeIndustryValuesForUncheckedOptions: Ember.observer('data.employmentTwoOptions.employmentDetailsOptions.@each.checked', function () {
            var uncheckedOptions = this.get('data.employmentTwoOptions.employmentDetailsOptions').filterBy('checked', false);
            uncheckedOptions.forEach(function (option) {
                option.set('other', null);
                option.set('role', null);
            });
            if (uncheckedOptions.mapBy('display').indexOf(this.get('data.subject.employmentCurrentLast')) > -1) {
                this.set('data.subject.employmentCurrentLast', null);
            }
        })
    });
});