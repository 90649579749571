define('tm-common/components/subjects/profile/modules/jury/criminal-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/jury/criminal-module'], function (exports, _criminalModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _criminalModule.default,
        analyticsFormsData: Ember.inject.service(),

        classNames: ['inplace-edit-profile-data'],

        showCriminalHistory: Ember.computed('field', function () {
            var field = this.get('field');

            if (field.get('value') === 'criminal') {
                return true;
            }
        }),

        highlightsField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'highlights');
        }),

        showCriminalAnalytics: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            if (field.get('value') === 'criminal_analytics') {
                return true;
            } else {
                var criminalAnalyticsField = sortedMatterFields.find(function (mf) {
                    if (mf.get('value') === 'criminal_analytics') {
                        return true;
                    }
                    return false;
                });

                if (criminalAnalyticsField && criminalAnalyticsField.get('mergeField') === 'criminal') {
                    return true;
                }
            }

            return false;
        }),

        showTrafficAnalytics: Ember.computed('sortedMatterFields', 'field', function () {
            var field = this.get('field');
            var sortedMatterFields = this.get('sortedMatterFields');

            if (field.get('value') === 'traffic_analytics') {
                return true;
            } else {
                var trafficAnalyticsField = sortedMatterFields.find(function (mf) {
                    if (mf.get('value') === 'traffic_analytics') {
                        return true;
                    }
                    return false;
                });

                if (trafficAnalyticsField && trafficAnalyticsField.get('mergeField') === 'criminal') {
                    return true;
                }
            }

            return false;
        })
    });
});