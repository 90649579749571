define('tm-common/models/matter-has-recognition', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        matter: belongsTo('matter', { async: true }),
        state: attr('string'),
        split: attr('number'),

        formattedSplit: Ember.computed('split', function () {
            var perc = Number(this.get('split')).toFixed(2);
            return perc + ' %';
        })
    });
});