define('tm-common/models/time-allocation-matter', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        entryId: attr('number'),
        matterId: attr('number'),

        matter: belongsTo('matter', { async: false }),
        timeAllocation: belongsTo('time_allocation', { async: false })
    });
});