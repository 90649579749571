define('tm-common/mixins/authorize-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        session: Ember.inject.service(),
        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),
        authorize: function authorize(xhr) {
            var ENV = this.get('ENV');
            var accessToken = this.get('session.data.authenticated.token');
            if (Ember.isPresent(accessToken)) {
                xhr.setRequestHeader('X-Authorization', 'Token: ' + accessToken);
                if (ENV.environment === 'test') {
                    xhr.setRequestHeader('X-CI-KEY', ENV.cikey);
                }
            }
        }
    });
});