define('ember-cli-table-pagination/components/bs-table-pagination/table-body', ['exports', 'ember-cli-table-pagination/templates/components/bs-table-pagination/table-body'], function (exports, _tableBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tableBody.default,
    classNames: ['box-body']
  });
});