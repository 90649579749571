define('tm-common/models/phrase-case-type-group', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),

        created: attr('estdate'),
        updated: attr('estdate'),

        softDeleted: attr('number'),

        caseTypeGroupMatterTypes: hasMany('phrase-case-type-group-matter-type', {
            async: false
        }),

        createdUserId: attr('number'),
        createdUser: belongsTo('user', { async: true }),

        updatedUserId: attr('number'),
        updatedUser: belongsTo('user', { async: true })

        //caseTypeGroupMatterTypes: belongsTo('phrase-case-type-group-matter-type', {async: true}),
    });
});