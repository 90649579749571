define("ember-router-scroll/services/router-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isFastBoot: Ember.computed(function () {
      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),
    scrollElement: 'window',
    targetElement: null,
    delayScrollTop: false,
    init: function init() {
      this._super.apply(this, arguments);

      this._loadConfig();

      Ember.set(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      });
      Ember.set(this, 'key', null);
    },
    update: function update() {
      var scrollElement = Ember.get(this, 'scrollElement');
      var targetElement = Ember.get(this, 'targetElement');
      var scrollMap = Ember.get(this, 'scrollMap');
      var key = Ember.get(this, 'key');
      var x;
      var y;

      if (targetElement) {
        if (Ember.get(this, 'isFastBoot')) {
          return;
        }

        var element = document.querySelector(targetElement);

        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop; // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen

          Ember.set(scrollMap, 'default', {
            x: x,
            y: y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        if (Ember.get(this, 'isFastBoot')) {
          return;
        }

        var _element = document.getElementById(scrollElement.substring(1));

        if (_element) {
          x = _element.scrollLeft;
          y = _element.scrollTop;
        }
      }

      if (key && 'number' === Ember.typeOf(x) && 'number' === Ember.typeOf(y)) {
        Ember.set(scrollMap, key, {
          x: x,
          y: y
        });
      }
    },
    position: Ember.computed(function position() {
      var scrollMap = Ember.get(this, 'scrollMap');
      var stateUuid = Ember.get(window, 'history.state.uuid');
      Ember.set(this, 'key', stateUuid); // eslint-disable-line ember/no-side-effects

      var key = Ember.getWithDefault(this, 'key', '-1');
      return Ember.getWithDefault(scrollMap, key, scrollMap.default);
    }).volatile(),
    _loadConfig: function _loadConfig() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.routerScroll) {
        var scrollElement = config.routerScroll.scrollElement;
        var targetElement = config.routerScroll.targetElement;
        (false && !(!(scrollElement && targetElement)) && Ember.assert('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));

        if ('string' === Ember.typeOf(scrollElement)) {
          Ember.set(this, 'scrollElement', scrollElement);
        }

        if ('string' === Ember.typeOf(targetElement)) {
          Ember.set(this, 'targetElement', targetElement);
        }

        var delayScrollTop = config.routerScroll.delayScrollTop;

        if (delayScrollTop === true) {
          Ember.set(this, 'delayScrollTop', true);
        }
      }
    }
  });

  _exports.default = _default;
});