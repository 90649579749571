define('tm-common/models/permission-has-endpoint', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        endpoint: _emberData.default.attr('string'),
        mode: _emberData.default.attr('number'),
        permission: _emberData.default.belongsTo('permission', { async: false })
    });
});