define('tm-common/components/display-tags', ['exports', 'tm-common/templates/components/display-tags'], function (exports, _displayTags) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _displayTags.default,
        tagName: 'span'
    });
});