define('tm-common/components/matter/dashboard/dashboard-analytics/widgets/gender-comparison', ['exports', 'tm-common/templates/components/matter/dashboard/dashboard-analytics/widgets/gender-comparison'], function (exports, _genderComparison) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _genderComparison.default,

        uppercaseLabel: Ember.computed('stat.analyticStat.label', function () {
            if (this.get('stat.analyticStat')) {
                return this.get('stat.analyticStat.label').toUpperCase();
            }
        }),

        percentMale: Ember.computed('stat.analyticStat.statData.gender_male', function () {
            if (this.get('stat.analyticStat')) {
                return (parseFloat(this.get('stat.analyticStat.statData.gender_male')) * 100).toFixed(0);
            }
        }),

        percentFemale: Ember.computed('stat.analyticStat.statData.gender_female', function () {
            if (this.get('stat.analyticStat')) {
                return (parseFloat(this.get('stat.analyticStat.statData.gender_female')) * 100).toFixed(0);
            }
        })
    });
});