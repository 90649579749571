define('tm-common/components/subjects/profile/modules/jury/states-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/jury/states-module'], function (exports, _statesModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _statesModule.default,
        analyticsFormsData: Ember.inject.service(),

        classNames: ['inplace-edit-profile-data'],

        highlightsField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'highlights');
        }),

        residentialField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'counties');
        }),

        shouldShowNationalOriginOther: Ember.computed('subject.nationalOrigin', 'subject.nationalOriginOther', function () {
            var nationalOrigin = this.get('subject.nationalOrigin');
            return nationalOrigin === 'Other';
        })
    });
});