define('tm-common/models/user-has-skill', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        skill: belongsTo('skill', { async: true }),
        user: belongsTo('user', { async: true })
    });
});