define('tm-common/components/ember-chart', ['exports', 'tm-common/templates/components/ember-chart'], function (exports, _emberChart) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * Sets a property to a default value if the property is
     * not already set.
     *
     * @private
     * @method setDefault
     * @param {object} object The object to set the value on
     * @param {string} key The key to the value in the object
     * @param {mixed} The default value to set
     */
    function setDefault(object, key, value) {
        if (Ember.isNone(Ember.get(object, key))) {
            Ember.set(object, key, value);
        }
    }

    /**
     * `EmberChart`
     *
     */
    exports.default = Ember.Component.extend({
        layout: _emberChart.default,

        // data properties

        /**
         * Chartjs data object or an ember model
         *
         * @public
         * @property model
         * @type {object}
         */
        model: null,

        /**
         * The path of the property in the model to use
         * as the label for a data point.
         *
         * @public
         * @property labelPath
         * @type {string}
         */
        labelPath: null,

        /**
         * The path of the property in the model to use
         * as the data for a data point
         *
         * @public
         * @property dataPath
         * @type {string|array}
         */
        dataPath: null,

        /**
         * The path of each model in the model property to
         * use for each dataset.
         *
         * This property is optional and defaults to ['model'].
         *
         * @example
         *  if model === {somedata: [models], someotherdata: [models]}
         *  then modelPath === ["somedata", "someotherdata"]
         *  so dataset1 is somedata and dataset2 is someotherdata.
         *
         * @public
         * @property modelPath
         * @type {string|array}
         */
        modelPath: '',

        /**
         * Chart js options object.
         *
         * @public
         * @property options
         * @type object
         */
        options: null,

        /**
         * Options object for setting options on
         * a specific chart type.
         *
         * @public
         * @property chartOptions
         * @type object
         */
        chartOptions: null,

        // paging results properties

        /**
         * The text to display when the back button is showing.
         *
         * @public
         * @property backText
         * @type string
         */
        backText: 'Back',

        /**
         * Text to display for the label of the other result when paging.
         *
         * @public
         * @property otherText
         * @type string
         */
        otherText: 'Other',

        /**
         * The size of the results to show.
         *
         * This will show one less than the pageSize plus a result
         * that is other. The other result can be clicked to show more results.
         *
         * @public
         * @property pageSize
         * @default null - all results.
         * @type number
         */
        pageSize: null,

        // chart html properties

        /**
         * width of the chart.
         *
         * @public
         * @property width
         * @default 600
         * @type number
         */
        width: 600,

        /**
         * height of the chart.
         *
         * @public
         * @property height
         * @default 600
         * @type number
         */
        height: 600,

        _page: 0,
        _chartObject: null,
        isModel: false,

        /**
         * Ember init function gets called when the component
         * has rendered the element to the DOM
         *
         * @public
         * @method didInsertElement
         */
        didInsertElement: function didInsertElement() {
            var _this2 = this;

            // call the super funtion to handle any parent rendering
            this._super.apply(this, arguments);

            // create a chart object
            var chart = this.createChart();

            // save the chart object for later use
            this.set('chart', chart);

            if (this.get('isModel')) {
                // add oberserver methods for the model
                ['model', 'model.[]', '_page', 'colors.[]'].forEach(function (observable) {
                    _this2.addObserver(observable, _this2, _this2.updateChart);
                });
            } else {
                // add observer methods for chart data
                this.addObserver('data', this, this.updateChart);
                this.addObserver('data.[]', this, this.updateChart);
            }

            // add observers for options and type
            this.addObserver('options', this, this.redrawChart);
            this.addObserver('type', this, this.redrawChart);
        },


        /**
         * Creates a chart object with the type and values
         * passed in.
         *
         * @public
         * @method createChart
         * @returns {Chart} chartjs object class
         */
        createChart: function createChart() {
            var isModel = !Ember.isNone(this.get('model'));
            this.set('isModel', isModel);

            var _chartObject = this.initializeChartDataObject(isModel);

            var context = this.$().find('canvas').get(0);
            var type = this.get('type');
            var options = this.setDefaultOptions(this.get('data.options'));

            var chart = new Chart(context, {
                type: type,
                data: _chartObject,
                options: options
            });

            // add the chart to the chartObject if
            // this is a model chartObject.
            if (isModel) {
                _chartObject.set('__chart', chart);
            }

            // store the ember model _chartObject
            this.set('_chartObject', _chartObject);

            if (this.get('data.options.showCustomLegend')) {
                document.getElementById(this.get('data.options.customLegendElementId')).innerHTML = chart.generateLegend();
            }

            // return the chartjs class
            return chart;
        },


        /**
         * Creates a chart data object with the type and values
         * passed in.
         *
         * @public
         * @method initializeChartDataObject
         * @returns {ChartObject}
         */
        initializeChartDataObject: function initializeChartDataObject(isModel) {
            var _chartObject = void 0;
            // if and ember model is passed in then
            // setup the chart object to handle the model data
            if (isModel) {
                // create a ChartObject that converts an ember model
                // to a chartjs data structure.
                _chartObject = ChartObject.create({
                    model: this.get('model'), // model object
                    labelPath: this.get('labelPath'), // path of the label to display in the provided model.
                    dataPath: this.getTypeAsArray('dataPath'), // path of the data to display in the provided model.
                    modelPath: this.getTypeAsArray('modelPath'), // path of each model in the model object.
                    otherTitle: this.get('otherText'), // title to display for last result when paging.
                    page: this.get('_page'), // internal paging for limiting chart results size.
                    pageSize: this.get('pageSize'), // page size for limiting chart result szie.
                    type: this.get('type'), // chart type
                    options: this.get('options'), // chartjs options
                    chartOptions: this.get('chartOptions') // chartjs chart specific options
                });
            } else {
                // set isModel to false and use
                // the chartjs style data array passed in
                _chartObject = this.get('data');
            }

            return _chartObject;
        },
        getTypeAsArray: function getTypeAsArray(type, defaultValue) {
            var arr = this.get(type);
            if (Ember.isNone(arr)) {
                if (!Ember.isNone(defaultValue)) {
                    arr = defaultValue;
                } else {
                    arr = [];
                }
            }

            if (typeof arr === 'string') {
                arr = arr.split(',');
            }
            return arr;
        },


        /**
         * Ember callback gets called when the component is getting destroyed
         *
         * @public
         * @method willDestroyElement
         */
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            // destroy the chartjs object class
            this.get('chart').destroy();

            // remove the observers
            this.addObserver('data', this, this.updateChart);
            this.addObserver('data.[]', this, this.updateChart);
            this.removeObserver('model', this, this.updateChart);
            this.removeObserver('model.[]', this, this.updateChart);
            this.removeObserver('_page', this, this.updateChart);
            this.removeObserver('colors.[]', this, this.updateChart);
            this.removeObserver('options', this, this.redrawChart);
            this.removeObserver('type', this, this.redrawChart);
        },


        redrawChart: function redrawChart() {
            var existingChart = this.get('chart');
            if (existingChart) {
                existingChart.destroy();
            }
            this.set('chart', this.createChart());
        },

        setDefaultOptions: function setDefaultOptions(options) {
            var _this = this;

            // set options
            options = options || {};

            // set onClick options
            var oldOnClick = function oldOnClick() {};
            if (options.onClick) {
                oldOnClick = options.onClick;
            }

            options.onClick = function () {
                _this.clickAction.apply(_this, arguments);
                oldOnClick.apply(this, arguments);
            };

            // add responsive options
            setDefault(options, 'responsive', true);
            setDefault(options, 'maintainAspectRatio', false);

            // set legend options
            options.legend = options.legend || {};

            setDefault(options.legend, 'display', true);
            setDefault(options.legend, 'position', 'bottom');
            setDefault(options.legend, 'fullWidth', true);

            // set tooltip options
            options.tooltips = options.tooltips || {};

            setDefault(options.tooltips, 'enabled', true);
            setDefault(options.tooltips, 'mode', 'single');
            setDefault(options.tooltips, 'backgroundColor', 'rgba(240,240,240,1)');
            setDefault(options.tooltips, 'titleFontColor', '#444');
            setDefault(options.tooltips, 'bodyFontColor', '#444');
            setDefault(options.tooltips, 'bodySpacing', 0);
            setDefault(options.tooltips, 'bodyFontStyle', 'italic');
            setDefault(options.tooltips, 'footerFontColor', '#444');
            setDefault(options.tooltips, 'xPadding', 10);
            setDefault(options.tooltips, 'yPadding', 15);
            setDefault(options.tooltips, 'caretSize', 10);
            setDefault(options.tooltips, 'cornerRadius', 3);
            setDefault(options.tooltips, 'multiKeybackground', '#999');

            // set tooltip callbacks
            options.tooltips.callbacks = options.tooltips.callbacks || {};

            setDefault(options.tooltips.callbacks, 'label', function (tooltip, data) {
                var label = data.labels[tooltip.index];
                var dataType = data.datasets[tooltip.datasetIndex].dataType;
                var value = data.datasets[tooltip.datasetIndex].data[tooltip.index];

                // hack to make all zero charts show up.
                value = value === 0.01 ? 0 : value;

                if (Ember.isEmpty(label)) {
                    if (Ember.isEmpty(value)) {
                        return;
                    }
                    return value;
                } else if (Ember.isEmpty(value)) {
                    return label;
                }

                return _this.tooltip(label, value, dataType);
            });

            // return options with defaults
            return options;
        },
        clickAction: function clickAction(evt, segment) {
            if (this.get('isModel')) {
                segment = segment[0] || {};
                var segmentModel = segment._model;
                if (segmentModel && segmentModel.label === 'Other') {
                    this.set('_page', this.get('_page') + 1);
                    this.set('showBackButton', true);
                } else if (segmentModel && segmentModel.label) {
                    var index = (this.get('colors.length') - 2) * this.get('_page') + segment._index;
                    var model = this.get('_chartObject').getModel(index);
                    if (!Ember.isNone(model)) {
                        this.sendAction('onClick', model);
                    }
                } else {
                    // expiremental
                    // this.getClickedLabel(evt)
                }
            } else {
                this.sendAction('onClick', evt, segment);
            }
        },
        getClickedLabel: function getClickedLabel(evt) {
            var _chart = this.get('chart');
            var helpers = Chart.helpers;

            // hack to fix error in helper method
            _chart.currentDevicePixelRatio = 1;
            var pos = helpers.getRelativePosition(evt, _chart);

            var scale = _chart.scale;
            if (Ember.isNone(scale)) {
                for (var i in _chart.scales) {
                    if (_chart.scales.hasOwnProperty(i) && Ember.isNone(scale)) {
                        scale = _chart.scales[i];
                    }
                }
            }

            var closestPixel = null;
            var closestPixelDistance = 10000000;
            var tickLabel = '';
            // loop through all the labels
            helpers.each(scale.ticks, function (label, index) {
                var pixel = this.getPixelForTick(index, true);
                var distance = Math.abs(pos.x - pixel);
                if (distance < closestPixelDistance) {
                    closestPixel = index;
                    closestPixelDistance = distance;
                    tickLabel = label;
                }
            }, scale);

            if (tickLabel === 'Other') {
                this.set('_page', this.get('_page') + 1);
                this.set('showBackButton', true);
            } else {
                var model = this.get('_chartObject').getModel(closestPixel);
                if (!Ember.isNone(model)) {
                    this.sendAction('onClick', model);
                }
            }
        },
        tooltip: function tooltip(label, value) {
            return label + ': ' + value;
        },
        updateChart: function updateChart() {
            var data = void 0;
            if (this.get('isModel')) {
                data = this.get('_chartObject');
                if (this.get('model.length') !== data.get('model.length')) {
                    data.set('model', this.get('model'));
                }

                if (this.get('colors.length') !== data.get('colors.length')) {
                    data.set('colors', this.get('colors'));
                }

                if (this.get('_page') !== data.get('page')) {
                    data.set('page', this.get('_page'));
                }
            } else {
                data = this.get('data');
                var chart = this.get('chart');
                chart.config.data = data;
                chart.update();
            }
        },


        buttonDisplay: Ember.computed('showBackButton', function () {
            if (this.get('showBackButton')) {
                return Ember.String.htmlSafe('display:block; position:absolute; top:0; left:0');
            }

            return Ember.String.htmlSafe('display:none; position:absolute;');
        }),

        actions: {
            backAction: function backAction() {
                var segments = this.get('_page') - 1;
                if (segments <= 0) {
                    segments = 0;
                    this.set('showBackButton', false);
                }

                this.set('_page', segments);
            }
        }
    });


    var ChartObject = Ember.Object.extend({
        model: null,
        labelPath: null,
        dataPath: null,
        modelPath: '',
        otherTitle: '',
        page: 0,
        pageSize: null,
        type: '',
        options: null,
        chartOptions: null,

        datasets: null,
        labels: null,

        _init: Ember.on('init', function () {
            this.setup();
        }),

        _buildData: Ember.observer('model', 'page', function () {
            this.setup();
            this.get('__chart').update();
        }),

        setup: function setup() {
            (false && !(!Ember.isEmpty(this.get('labelPath'))) && Ember.assert('labelPath must be set to parse the model objects for labels <ember-chart::labelPath>', !Ember.isEmpty(this.get('labelPath'))));
            (false && !(!Ember.isEmpty(this.get('dataPath'))) && Ember.assert('dataPath must be set to parse the model objects for data values <ember-chart::dataPath>', !Ember.isEmpty(this.get('dataPath'))));


            this.buildLabels();
            this.generateDatasets();
        },

        generateDatasets: function generateDatasets() {
            var _this3 = this;

            var datasets = Ember.A();
            var dataPaths = this.get('dataPath');

            var modelPath = this.get('modelPath') || [];
            modelPath.forEach(function (path, index) {
                var models = _this3.getModels(path);
                var dataPath = dataPaths[index];

                // make suer models were found at the path provided.
                (false && !(!Ember.isNone(models)) && Ember.assert('The path provided returned no models', !Ember.isNone(models)));
                (false && !(Ember.isArray(models)) && Ember.assert('The path provided did not return an array', Ember.isArray(models)));


                var data = Ember.A();
                var hasOther = false;
                var otherTotal = 0;

                _this3.eachModel(models, function (item, index, isActive, isOther) {
                    if (isActive) {
                        // 0.01 is a hack to make all zero charts show up.
                        data.push(Ember.get(item, dataPath) || 0.01);
                    } else if (isOther) {
                        hasOther = true;
                        otherTotal = otherTotal + (Ember.get(item, dataPath) || 0);
                    }
                });

                if (otherTotal > 0 || hasOther) {
                    data.push(otherTotal || 0.01);
                }

                var dataset = _this3.createDataset(data, index);
                datasets.set('path', path);
                datasets.push(dataset);
            });

            this.set('datasets', datasets);
        },
        createDataset: function createDataset(data, index) {
            var chartOptions = this.get('chartOptions') || {};
            var dataset = Ember.Object.create({ data: data });

            for (var i in chartOptions) {
                if (chartOptions.hasOwnProperty(i)) {
                    var key = i;
                    if (/^_/.test(i)) {
                        key = i.replace(/^_/, '');
                        chartOptions[key] = chartOptions[i][index];
                    }
                    this.setOption(dataset, chartOptions, key);
                }
            }

            return dataset;
        },
        buildLabels: function buildLabels() {
            var _this4 = this;

            // add the ability to pass a static set of labels for multiple datasets.
            var staticLabels = this.get('chartOptions.staticLabels');
            if (!Ember.isNone(staticLabels) && Ember.isArray(staticLabels)) {
                this.get('labels', staticLabels);
            } else {
                var hasOther = false;
                var labels = Ember.A();
                var modelPath = this.get('modelPath') || [];
                modelPath.forEach(function (path) {
                    var models = _this4.getModels(path);

                    // make suer models were found at the path provided.
                    (false && !(!Ember.isNone(models)) && Ember.assert('The path provided returned no models', !Ember.isNone(models)));
                    (false && !(Ember.isArray(models)) && Ember.assert('The path provided did not return an array', Ember.isArray(models)));


                    _this4.eachModel(models, function (item, idx, isActive, isOther) {
                        if (isActive) {
                            var _label = Ember.get(item, _this4.get('labelPath')) || '';
                            if (labels.indexOf(_label) === -1) {
                                labels.push(_label);
                            }
                        } else if (isOther) {
                            hasOther = true;
                        }
                    });
                });

                if (hasOther) {
                    labels.push(this.get('otherTitle'));
                }
                this.set('labels', labels);
            }
        },
        setOption: function setOption(data, object, key) {
            var defaultValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

            var value = Ember.get(object, key);
            if (!Ember.isNone(value) || !Ember.isNone(defaultValue)) {
                Ember.set(data, key, value || defaultValue);
            }
            return this;
        },
        getModels: function getModels(path) {
            path = ('model.' + path).replace(/\.$/, '');
            return this.get(path);
        },
        eachModel: function eachModel(items, callback) {
            var page = this.get('page');

            var pageSize = this.get('pageSize');
            if (Ember.isNone(pageSize)) {
                pageSize = items.get ? items.get('length') : items.length;
            }

            var min = page * pageSize;
            var max = min + (pageSize - 1);

            items.forEach(function (item, index) {
                if (index >= min && index <= max) {
                    callback(item, index, true, false);
                } else if (index > max) {
                    callback(item, index, false, true);
                } else {
                    callback(item, index, false, false);
                }
            });

            return this;
        },


        _dataset: Ember.computed(function () {
            return this.get('datasets').objectAt(0);
        }),

        getModel: function getModel(index) {
            var models = this.getModels(this.get('modelPath')[0]);
            if (models && models.objectAt && models.objectAt(index)) {
                return models.objectAt(index);
            } else if (models && models[index]) {
                return models[index];
            }
            return null;
        }
    });
});