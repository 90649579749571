define('tm-common/components/subject-profile/profile-data-row-edit', ['exports', 'tm-common/templates/components/subject-profile/profile-data-row-edit'], function (exports, _profileDataRowEdit) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _profileDataRowEdit.default,
        reRender: true,
        classNames: ['row', 'form-group'],
        store: Ember.inject.service(),
        timelineFields: null,
        showAddTimelineEvent: Ember.computed('timelineFields.[]', 'item.value', function () {
            var timelineFields = this.get('timelineFields');
            if (timelineFields) {
                var fieldName = this.get('item.value');
                var item = timelineFields.findBy('field', fieldName);
                return item ? true : false;
            }
            return false;
        }),
        actions: {
            usePhrase: function usePhrase(phrase) {
                var fieldPath = 'model.' + this.get('item.name');
                var breaks = '';
                for (var i = 0; i <= phrase.get('breaks'); i++) {
                    breaks += '\n';
                }
                this.set('reRender', false);
                var previousVal = this.get(fieldPath);
                this.set(fieldPath, (Ember.isPresent(previousVal) ? previousVal + breaks : '') + phrase.get('phrase'));
                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            },
            showSsan: function showSsan() {
                var _this = this;

                this.set('reRender', false);
                this.get('store').query('subject', { resource_id: this.get('model.id'), with: 'ssan' }).then(function () {
                    Ember.run.next(_this, function () {
                        this.set('reRender', true);
                    });
                });
                this.set('isSsanVisible', true);
            },
            hideSsan: function hideSsan() {
                var _this2 = this;

                this.set('reRender', false);
                this.get('store').query('subject', { resource_id: this.get('model.id') }).then(function () {
                    Ember.run.next(_this2, function () {
                        this.set('reRender', true);
                    });
                });
                this.set('isSsanVisible', false);
            },

            // Show Parse Modal with the list of the Formats from the Database
            showParseModal: function showParseModal() {
                var _this3 = this;

                this.toggleProperty('showDataEntry');
                this.get('store').query('data-parser-format', { field_map: this.get('item.value') }).then(function (items) {
                    _this3.set('parserFormats', items);
                });
            },
            cancel: function cancel() {
                this.set('isDataParsing', false);
                this.set('reRender', true);
                this.sendAction('cancel');
            },
            hideModal: function hideModal() {
                if (!this.isDestroyed) {
                    this.toggleProperty('showDataEntry');
                    this.set('reRender', true);
                }
            }
        }
    });
});