define('tm-common/helpers/highlight-result', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.highlightResult = highlightResult;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    /**
     * @public
     * this helper function replace the hash.query parameter
     * with a sanitized <i class='highlight'>hash.query</i>
     * tag so it can be styled to show as highlighted
     */
    function highlightResult(params, hash) {
        var _params = _slicedToArray(params, 1),
            phrase = _params[0];

        var q = Ember.get(hash, 'query.lastSearchedText');
        var multiTerms = Ember.get(hash, 'multiTerms') || false;
        if (!Ember.isPresent(q)) {
            var query = Ember.get(hash, 'query'); // second try at getting the query
            if (typeof query === 'string') {
                q = query;
            }
        }
        if (!Ember.isPresent(q)) {
            return phrase;
        }
        if (Ember.isEmpty(phrase)) {
            return phrase;
        }
        var highlighted = void 0;
        var escapeRegexp = /[-/\\^$*+?.()|[\]{}]/g;
        if (multiTerms) {
            highlighted = q.split(' ').reduce(function (phrase, term) {
                return phrase.replace(new RegExp(term.toLowerCase().replace(escapeRegexp, '\\$&'), 'gi'), function (match) {
                    return '<i class="highlight">' + match + '</i>';
                });
            }, phrase);
        } else {
            phrase = phrase.toString(); // in case phrase is not a string. eg: number
            highlighted = phrase.replace(new RegExp(q.toLowerCase().replace(escapeRegexp, '\\$&'), 'gi'), function (match) {
                return '<i class="highlight">' + match + '</i>';
            });
        }
        return Ember.String.htmlSafe(highlighted.trim());
    }

    exports.default = Ember.Helper.helper(highlightResult);
});