define('tm-common/models/receivable-note-has-invoice-output', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        belongsTo = _emberData.default.belongsTo,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        pastDue: attr('number'),
        billDate: attr('string'),

        receivableNote: belongsTo('receivable-note'),
        invoiceOutput: belongsTo('invoice-output')
    });
});