define('tm-common/components/timeline-list-display', ['exports', 'tm-common/templates/components/timeline-list-display'], function (exports, _timelineListDisplay) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _timelineListDisplay.default,
        matter: null
    });
});