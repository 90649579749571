define('tm-common/components/x-filefield', ['exports', 'ember-uploader/components/file-field', 'tm-common/models/file-to-upload', 'ember-component-inbound-actions/inbound-actions'], function (exports, _fileField, _fileToUpload, _inboundActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var testing = Ember.testing;
    exports.default = _fileField.default.extend(_inboundActions.default, {
        /**
         * allow select multiple files when the file open
         * dialog is shown
         *
         * @attribute
         */
        multiple: true,

        /**
         * list of files selected
         *
         * @property
         */
        selectedFiles: [],

        /**
         * Action to perform when files are selected
         * Default implementation is to add them to selectedFiles
         * or reset selectedFiles to empty array if nothing was selected
         *
         * @property
         */
        onSelect: function onSelect(files) {
            if (files == null) {
                Ember.set(this, 'selectedFiles', []);
            } else {
                if (this.get('multiple')) {
                    this.get('selectedFiles').pushObjects(files);
                } else {
                    var newList = [];
                    newList.pushObjects(files);
                    this.set('selectedFiles', newList);
                }
            }
        },


        /**
         * override change method from FileField to
         * support set empty list when input.files is empty
         */
        change: function change(e) {
            var files = testing ? e.testingFiles : e.target.files;
            var selectedFiles = null;

            if (!Ember.isEmpty(files)) {
                var totalFiles = files.length;
                selectedFiles = [];

                for (var i = 0; i < totalFiles; i++) {
                    var file = files[i];
                    var fileName = file.name;
                    if (this.get('defaultFileName')) {
                        fileName = this.get('defaultFileName') + '  ' + (i + 1) + ' - ' + fileName;
                    }
                    selectedFiles.push(_fileToUpload.default.create({
                        file: file,
                        name: fileName,
                        size: file.size,
                        type: file.type,
                        docType: this.get('defaultDocType'),
                        subject: null,
                        additionalSubjects: [],
                        viewable: 0
                    }));
                }
            }

            this.onSelect(selectedFiles);
        },


        actions: {
            newFiles: function newFiles(e) {
                this.change(e);
            }
        }
    });
});