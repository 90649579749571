define('tm-common/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Store.extend({
        queryRecord: function queryRecord(modelName, query) {
            // workaround for TM-2414
            if (modelName === 'invoice-detail' || modelName === 'summary-case-statistic') {
                return this._super(modelName, query);
            }
            // Our api usually returns an array of records so
            // instead do a query and return the first result
            // This deals with the ds.serializer.rest.queryRecord-array-response deprecations
            return this.query(modelName, query).then(function (records) {
                return records && Ember.get(records, 'firstObject');
            });
        }
    });
});