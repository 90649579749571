define('tm-common/routes/shared-file', ['exports', 'tm-common/mixins/open-route-mixin'], function (exports, _openRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_openRouteMixin.default, {
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        model: function model(params) {
            return { code: params.code };
        },
        setupController: function setupController(controller, resolved) {
            this._super.apply(this, arguments);
            controller.set('code', resolved.code);
            controller.get('retrieveReport').perform();
        },


        actions: {
            error: function error(_error) {
                this.get('notify').error(_error.message);
            }
        }
    });
});