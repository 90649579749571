define('tm-common/models/crm-note-category', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr;
    exports.default = Model.extend({
        name: attr('string'),
        color: attr('string'),

        bgColor: Ember.computed('color', function () {
            return Ember.String.htmlSafe('background-color: ' + this.get('color'));
        })
    });
});