define('tm-common/models/invoice-summary', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        status: attr('string'),
        billDate: attr('string'),
        billedAmount: attr('number'),
        tmNum: attr('string'),

        invoiceGroup: belongsTo('invoice-group', { async: true }),
        invoiceBatch: belongsTo('invoice-batch', { async: true }),
        matter: belongsTo('matter', { async: true }),
        firms: hasMany('firm', { async: false }),
        invoiceSubs: hasMany('invoice-sub', { async: true }),
        invoiceOutputs: hasMany('invoice-output', { async: true }),
        clients: hasMany('client', { async: false }),

        discountAmount: attr('number'),
        discountDescription: attr('string'),

        invoiceHasFirmHasClients: hasMany('invoice-has-firm-has-client', {
            async: true
        }),

        modifiedRecordsForRender: Ember.computed(function () {
            var item = this;

            item.set('includeInExport', false);

            var invoiceHasFirmHasClients = this.get('invoiceHasFirmHasClients');
            var outputs = this.get('invoiceOutputs');

            var modifiedFirms = [];
            outputs.forEach(function (output) {
                var firm = output.get('firmHasMatter.firm');
                var modifiedFirm = Ember.Object.create({
                    id: firm.get('id'),
                    name: firm.get('name'),
                    outputId: output.get('id')
                });

                var ifc = invoiceHasFirmHasClients.findBy('firm.id', firm.get('id'));

                var clients = ifc ? ifc.get('clients') : [];

                var modifiedClients = [];
                if (clients) {
                    clients.forEach(function (client) {
                        var clientObj = Ember.Object.create({
                            id: client.get('id'),
                            firstName: client.get('firstName'),
                            lastName: client.get('lastName'),
                            currentEmployerId: client.get('currentEmployerId'),
                            currentEmployerName: client.get('currentEmployerName'),
                            remittance: false,
                            summaryAndDetail: false,
                            manualExpenses: false,
                            receipts: false
                        });

                        modifiedClients.push(clientObj);
                    });
                }
                modifiedFirm.set('modifiedClients', modifiedClients);
                modifiedFirms.push(modifiedFirm);
            });

            item.set('modifiedFirms', modifiedFirms);
            return item;
        }).volatile()
    });
});