define('tm-common/mixins/date-helper', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        isValidYear: function isValidYear(year) {
            return year >= 1000 && year < 3000;
        },
        isValidMonth: function isValidMonth(month) {
            return month > 0 && month <= 12;
        },
        isValidDay: function isValidDay(year, month, day) {
            if (!this.isValidMonth(month)) {
                return false;
            }
            if (!this.isValidYear(year)) {
                return false;
            }

            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            if (year % 400 === 0 || year % 100 !== 0 && year % 4 === 0) {
                monthLength[1] = 29;
            }

            return day > 0 && day <= monthLength[month - 1];
        },
        calculateDateRange: function calculateDateRange(earliestDate, latestDate, rangeFlag) {
            var earliestDay = earliestDate[0];
            var earliestMonth = earliestDate[1];
            var earliestYear = earliestDate[2];
            var latestDay = latestDate[0];
            var latestMonth = latestDate[1];
            var latestYear = latestDate[2];
            var newEarliestDate = void 0;
            var newLatestDate = void 0;
            if (rangeFlag) {
                if (this.isEmpty(latestDay, latestMonth, latestYear)) {
                    newLatestDate = '2999-12-31';
                }
                if (newLatestDate !== '2999-12-31') {
                    newLatestDate = this.getNewLatestDate(latestDay, latestMonth, latestYear);
                }
                if (!newLatestDate) {
                    newLatestDate = this.fillDateFieldsForUncertainLatestDateBasedOnEarliestDate(latestDay, latestMonth, latestYear);
                }
                if (this.isEmpty(earliestDay, earliestMonth, earliestYear)) {
                    newEarliestDate = '1000-01-01';
                    return [newEarliestDate, newLatestDate];
                }
                newEarliestDate = this.fillDateFieldsForUncertainEarliestDate(earliestDay, earliestMonth, earliestYear);
                return [newEarliestDate, newLatestDate];
            }
            if (this.isEmpty(earliestDay, earliestMonth, earliestYear)) {
                newEarliestDate = '1000-01-01';
                newLatestDate = '1000-01-01';
            } else {
                newEarliestDate = this.fillDateFieldsForUncertainEarliestDate(earliestDay, earliestMonth, earliestYear);
                newLatestDate = this.fillDateFieldsForUncertainLatestDateBasedOnEarliestDate(earliestDay, earliestMonth, earliestYear);
            }
            return [newEarliestDate, newLatestDate];
        },
        fillDateFieldsForUncertainEarliestDate: function fillDateFieldsForUncertainEarliestDate(earliestDay, earliestMonth, earliestYear) {
            var newEarliestDateArr = [earliestYear, '1', '1'];
            if (earliestMonth) {
                newEarliestDateArr[1] = earliestMonth;
            }
            if (earliestDay) {
                newEarliestDateArr[2] = earliestDay;
            }
            return newEarliestDateArr.join('-');
        },
        fillDateFieldsForUncertainLatestDateBasedOnEarliestDate: function fillDateFieldsForUncertainLatestDateBasedOnEarliestDate(earliestDay, earliestMonth, earliestYear) {
            var newLatestDateArr = [earliestYear, '12', '31'];
            if (earliestMonth) {
                newLatestDateArr[1] = earliestMonth;
                newLatestDateArr[2] = new Date(earliestYear, earliestMonth, 0).getDate();
            }
            if (earliestDay) {
                newLatestDateArr[2] = earliestDay;
            }
            return newLatestDateArr.join('-');
        },
        getNewLatestDate: function getNewLatestDate(latestDay, latestMonth, latestYear) {
            var newLatestDate = [latestYear, '12', '31'];
            if (latestMonth) {
                newLatestDate[1] = latestMonth;
                newLatestDate[2] = new Date(latestYear, latestMonth, 0).getDate();
            }
            if (latestDay) {
                newLatestDate[2] = latestDay;
            }
            return newLatestDate.join('-');
        },
        isEmpty: function isEmpty(day, month, year) {
            return !day && !month && !year;
        }
    });
});