define('tm-common/models/crm-tag', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),

        // relationships
        crmTagHasClient: hasMany('crm-tag-has-client', {
            async: false
        }),
        crmTagHasFirm: hasMany('crm-tag-has-firm', {
            async: false
        }),
        crmTagHasDeal: hasMany('crm-tag-has-deal', {
            async: false
        })
    });
});