define('tm-common/helpers/permissions-check-any', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        permissions: Ember.inject.service(),

        compute: function compute(params) {
            var argc = params.length;
            (false && !(argc === 1) && Ember.assert('permissions-check-any helper needs 1 parameter', argc === 1));


            var requiredPermissions = params[0];
            (false && !(Ember.isArray(requiredPermissions) || typeof requiredPermissions === 'string') && Ember.assert('Parameter of permissions-check-any helper should be an array of required permission names', Ember.isArray(requiredPermissions) || typeof requiredPermissions === 'string'));


            if (typeof requiredPermissions === 'string') {
                requiredPermissions = [requiredPermissions];
            }

            return this.get('permissions').checkAny(requiredPermissions);
        }
    });
});