define('tm-common/initializers/raven', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize() {
        var application = arguments[1] || arguments[0];

        application.inject('route', 'raven', 'service:raven');
        application.inject('component', 'raven', 'service:raven');
        application.inject('controller', 'raven', 'service:raven');
        application.inject('model', 'raven', 'service:raven');
    }

    exports.default = {
        initialize: initialize,
        name: 'raven'
    };
});