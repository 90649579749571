define('tm-common/models/v-resource-task', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        status: attr('string'),
        comment: attr('string'),
        priority: attr('string'),
        timeLimit: attr('string'),
        firstName: attr('string'),
        lastName: attr('string'),

        // relationships
        assignedTo: belongsTo('employee', { async: true }),
        workflow: belongsTo('workflow', { async: true }),
        matter: belongsTo('matter', { async: true }),
        matterHasWorkflow: belongsTo('matter-has-workflow', { async: true })
    });
});