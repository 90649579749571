define('tm-common/components/subjects/profile/modules/jury/education-module', ['exports', 'tm-common/templates/components/subjects/profile/modules/jury/education-module'], function (exports, _educationModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _educationModule.default,
        analyticsFormsData: Ember.inject.service(),

        classNames: ['inplace-edit-profile-data'],

        highlightsField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'highlights');
        }),

        miscField: Ember.computed('sortedMatterFields', function () {
            var sortedMatterFields = this.get('sortedMatterFields');
            return sortedMatterFields.findBy('value', 'misc');
        })
    });
});