define('tm-common/models/time-allocation', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        allocationTypeId: attr('number'),
        userId: attr('number'),
        groupKey: attr('string'),
        created: attr('estdate'),
        updated: attr('estdate'),
        startDate: attr('string'),
        endDate: attr('string'),
        duration: attr('number'),
        description: attr('string'),
        isNonBillable: attr('number'),
        softDeleted: attr('number'),

        allocationType: belongsTo('time_allocation_type', { async: false }),
        user: belongsTo('user', { async: false }),

        timeAllocationMatters: hasMany('time-allocation-matter', { async: false }),
        matters: hasMany('matters', { async: false }),

        postGroupAllocation: (0, _emberApiActions.collectionAction)({
            path: 'group_allocation',
            type: 'post'
        }),
        deleteGroupAllocation: (0, _emberApiActions.memberAction)({
            path: 'delete_group',
            type: 'delete'
        }),
        updateGroupAllocation: (0, _emberApiActions.memberAction)({
            path: 'update_group',
            type: 'put'
        })
    });
});