define('tm-common/components/trimmable-subject-field-read', ['exports', 'tm-common/templates/components/trimmable-subject-field-read'], function (exports, _trimmableSubjectFieldRead) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _trimmableSubjectFieldRead.default,
    trimSize: 200,
    showAll: true,
    textContent: null
  });
});