define('tm-common/components/subjects/analytics-modules/traffic-module', ['exports', 'tm-common/mixins/analytics-util', 'tm-common/templates/components/subjects/analytics-modules/traffic-module'], function (exports, _analyticsUtil, _trafficModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_analyticsUtil.default, {
        layout: _trafficModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        listSort: ['order:asc'],
        trafficOptionsSorted: Ember.computed.sort('data.trafficOptions', 'listSort'),

        traffic: Ember.computed('data.subject.trafficAnalysis', 'data.subject', 'subject.subjectAnalyticsTraffic.[]', function () {
            var subject = this.get('data.subject');
            return subject.get('subjectAnalyticsTraffic');
        }),

        noTrafficOption: Ember.computed('data.trafficOptions.[]', function () {
            var trafficOptions = this.get('data.trafficOptions');
            return trafficOptions.findBy('shortName', 'no_traffic');
        })
    });
});