define('tm-common/components/document-preview-url', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        icon: 'file-text-o',
        target: '_blank',

        actions: {
            clicked: function clicked() {
                if (this.get('markDocumentAsViewed')) {
                    this.get('markDocumentAsViewed')();
                }
            }
        }
    });
});