define('tm-common/mixins/profile-analytics-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        currentMatter: Ember.inject.service(),

        componentName: Ember.computed('field.display', function () {
            // let baseDir = (this.get('currentMatter.matter.isJuryCase') || this.get('currentMatter.matter.isHighLevelCase')) ? 'subjects/profile/modules/jury' : 'subjects/profile/modules/non-jury'
            var baseDir = 'subjects/profile/modules/jury';

            var field = this.get('field.value');

            // if (this.get('currentMatter.matter.isJuryCase') || this.get('currentMatter.matter.isHighLevelCase')) {
            return this.getJuryModule(baseDir, field);
            // } else {
            // return this.getNonJuryModule(baseDir, field)
            // }
        }),

        getJuryModule: function getJuryModule(baseDir, field) {
            var componentName = '';

            switch (field) {
                case 'affiliation':
                    componentName = baseDir + '/voter-module';
                    break;

                case 'age':
                case 'age_notes':
                    componentName = baseDir + '/age-module';
                    break;

                case 'case_related_analytics':
                    componentName = baseDir + '/case-related-module';
                    break;

                case 'children_analytics':
                    componentName = baseDir + '/children-module';
                    break;

                case 'civil':
                case 'civil_analytics':
                    componentName = baseDir + '/civil-module';
                    break;

                case 'criminal':
                case 'traffic_analytics':
                case 'criminal_analytics':
                    componentName = baseDir + '/criminal-module';
                    break;

                case 'criminal_cases':
                    componentName = baseDir + '/criminal-cases-module';
                    break;

                case 'education_analytics':
                    componentName = baseDir + '/education-module';
                    break;

                case 'employment_analytics':
                    componentName = baseDir + '/employment-module';
                    break;

                case 'employment_status_analytics':
                    componentName = baseDir + '/employment-status-module';
                    break;

                case 'gender':
                case 'gender_notes':
                    componentName = baseDir + '/gender-module';
                    break;

                case 'marital_analytics':
                    componentName = baseDir + '/marital-module';
                    break;

                case 'national_origin':
                case 'national_origin_notes':
                    componentName = baseDir + '/national-origin-module';
                    break;

                case 'race':
                case 'race_notes':
                    componentName = baseDir + '/race-module';
                    break;

                case 'counties':
                case 'states_analytics':
                    componentName = baseDir + '/states-module';
                    break;

                case 'social_media_analytics':
                    componentName = baseDir + '/social-media-module';
                    break;

                case 'years_in_county_analytics':
                    componentName = baseDir + '/years-in-county-module';
                    break;

                case 'current_city':
                    componentName = baseDir + '/current-city-module';
                    break;

                case 'property':
                    componentName = baseDir + '/property-module';
                    break;

                default:
                    componentName = baseDir + '/default-module';
                    break;
            }

            return componentName;
        },

        getNonJuryModule: function getNonJuryModule(baseDir, field) {
            var componentName = '';

            switch (field) {
                case 'bankruptcy':
                case 'dob':
                case 'civil':
                case 'counties':
                case 'criminal':
                case 'death':
                case 'education':
                case 'employment':
                case 'family':
                case 'marital':
                case 'medical':
                case 'relationship':
                case 'residential':
                case 'social_media':
                case 'ssan':
                case 'traffic':
                    componentName = baseDir + '/default-with-notes-module';
                    break;

                default:
                    componentName = baseDir + '/default-module';
                    break;
            }

            return componentName;
        }
    });
});