define('tm-common/components/subjects/analytics-modules/civil-module-before', ['exports', 'tm-common/templates/components/subjects/analytics-modules/civil-module'], function (exports, _civilModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _civilModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        }),

        listSort: ['order'],
        civilOptionsSorted: Ember.computed.sort('data.civilOptions.[]', 'listSort'),

        civil: Ember.computed('subject.subjectAnalyticsCivil.[]', 'data.civilOptions.[]', 'data.subject', function () {
            var subject = this.get('data.subject');
            var civil = subject.get('subjectAnalyticsCivil');
            var civilOptions = this.get('data.civilOptions');

            if (civil) {
                return civil.map(function (c) {
                    return civilOptions.findBy('display', c);
                });
            } else {
                return [];
            }
        }),

        civilReadMode: Ember.computed('subject.subjectAnalyticsCivil.[]', 'data.civilOptions.[]', 'data.subject', function () {
            var subject = this.get('data.subject');
            var civil = subject.get('subjectAnalyticsCivil');
            var civilOptions = this.get('data.civilOptions');

            if (civil) {
                return civil.map(function (c) {
                    var option = civilOptions.findBy('display', c);
                    if (option && option.get('checked')) {
                        var rtn = option.get('display');
                        var counts = [];
                        if (option.get('plaintiffCount') && option.get('plaintiffCount') !== '') {
                            counts.push(option.get('plaintiffCount') + ' plaintiff');
                        }
                        if (option.get('defendantCount') && option.get('defendantCountv') !== '') {
                            counts.push(option.get('defendantCount') + ' defendant');
                        }
                        if (option.get('otherCount') && option.get('otherCount') !== '') {
                            counts.push(option.get('otherCount') + ' unknown');
                        }

                        return rtn + ' - ' + counts.join(', ');
                    } else {
                        return null;
                    }
                });
            } else {
                return [];
            }
        }),

        isReadModeNoCivil: Ember.computed('civilReadMode', function () {
            var civilReadMode = this.get('civilReadMode');
            var noCivilRecords = false;
            civilReadMode.forEach(function (c) {
                if (c) {
                    if (c.indexOf('No Civil Records') >= 0) {
                        noCivilRecords = true;
                    }
                }
            });
            return noCivilRecords;
        }),

        noneOption: Ember.computed('data.civilOptionsSorted.[]', function () {
            var civilOptionsSorted = this.get('civilOptionsSorted');
            return civilOptionsSorted.findBy('shortName', 'no_civil_records');
        }),

        noneEnteredForSubjectCivil: Ember.observer('data.subject.civil', 'civilOptionsSorted.@each.checked', function () {
            var civil = this.get('data.subject.civil');
            var selectedCivilOptions = this.get('civilOptionsSorted').rejectBy('shortName', 'no_civil_records').filterBy('checked', true);
            if ((!Ember.isPresent(civil) || civil.toLowerCase() === 'none') && !selectedCivilOptions) {
                this.set('noneOption.checked', true);
            }
        }),

        plaintiffTotal: Ember.computed('civilOptionsSorted.@each.plaintiffCount', 'civilOptionsSorted.@each.checked', function () {
            var civilOptionsSorted = this.get('civilOptionsSorted');
            var plaintiffTotal = 0;

            civilOptionsSorted.forEach(function (c) {
                if (c.get('checked')) {
                    plaintiffTotal += c.get('plaintiffCount') === '' ? 0 : parseInt(c.get('plaintiffCount'));
                }
            });

            return plaintiffTotal;
        }),

        defendantTotal: Ember.computed('civilOptionsSorted.@each.defendantCount', 'civilOptionsSorted.@each.checked', function () {
            var civilOptionsSorted = this.get('civilOptionsSorted');
            var defendantTotal = 0;

            civilOptionsSorted.forEach(function (c) {
                if (c.get('checked')) {
                    defendantTotal += c.get('defendantCount') === '' ? 0 : parseInt(c.get('defendantCount'));
                }
            });

            return defendantTotal;
        }),

        otherTotal: Ember.computed('civilOptionsSorted.@each.otherCount', 'civilOptionsSorted.@each.checked', function () {
            var civilOptionsSorted = this.get('civilOptionsSorted');
            var otherTotal = 0;

            civilOptionsSorted.forEach(function (c) {
                if (c.get('checked')) {
                    otherTotal += c.get('otherCount') === '' ? 0 : parseInt(c.get('otherCount'));
                }
            });

            return otherTotal;
        }),

        caseTotal: Ember.computed('subject.subjectAnalyticsCivil.[]', 'data.civilOptions.[]', 'data.subject', function () {
            var subject = this.get('data.subject');
            var civil = subject.get('subjectAnalyticsCivil');
            var civilOptions = this.get('data.civilOptions');
            var total = 0;
            if (civil) {
                var civilMap = civil.map(function (c) {
                    var option = civilOptions.findBy('display', c);
                    if (option && option.get('checked')) {
                        var counts = [];
                        if (option.get('plaintiffCount') && option.get('plaintiffCount') !== '') {
                            counts.push(option.get('plaintiffCount'));
                        }
                        if (option.get('defendantCount') && option.get('defendantCountv') !== '') {
                            counts.push(option.get('defendantCount'));
                        }
                        if (option.get('otherCount') && option.get('otherCount') !== '') {
                            counts.push(option.get('otherCount'));
                        }

                        var countTotal = 0;
                        counts.forEach(function (c) {
                            countTotal += parseInt(c);
                        });

                        return countTotal;
                    } else {
                        return null;
                    }
                });

                if (civilMap) {
                    civilMap.forEach(function (cm) {
                        if (cm) {
                            total += parseInt(cm);
                        }
                    });
                }
            }
            return total;
        })
    });
});