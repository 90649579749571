define('tm-common/models/subscription-cost', ['exports', 'ember-data', 'accounting/format-money'], function (exports, _emberData, _formatMoney) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        db: attr('string'),
        type: attr('string'),
        renews: attr('string'),
        method: attr('string'),
        monthly: attr('number'),
        annual: attr('number'),

        employee: belongsTo('employee', { async: true }),
        monthlyFormatted: Ember.computed('type', 'monthly', function () {
            if (this.get('type') !== 'Monthly') {
                return '';
            }
            return (0, _formatMoney.default)(this.get('monthly'));
        }),
        annualFormatted: Ember.computed('type', 'annual', function () {
            if (this.get('type') !== 'Annually') {
                return '';
            }
            return (0, _formatMoney.default)(this.get('annual'));
        })
    });
});