define('tm-common/components/matter/dashboard/dashboard-analytics/widgets/simple-percentage', ['exports', 'tm-common/templates/components/matter/dashboard/dashboard-analytics/widgets/simple-percentage'], function (exports, _simplePercentage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _simplePercentage.default,

        uppercaseLabel: Ember.computed('stat.analyticStat.label', function () {
            if (this.get('stat.analyticStat')) {
                return this.get('stat.analyticStat.label').toUpperCase();
            }
        }),

        formattedPercentage: Ember.computed('stat.analyticStat.statData', function () {
            if (this.get('stat.analyticStat')) {
                return (parseFloat(this.get('stat.analyticStat.statData')) * 100).toFixed(0);
            }
        })
    });
});