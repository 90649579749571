define('tm-common/models/invoice-output', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        hasMany = _emberData.default.hasMany,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        billSplit: attr('number'),
        format: attr('string'),
        createdOn: attr('estdate'),
        qbInvoiceId: attr('number'),
        amount: attr('number'),

        lastPaidOn: attr('estdate'),
        amountPaid: attr('number'),
        arNote: attr(),

        // relationships
        invoice: belongsTo('invoice', {
            async: false
        }),
        firmHasMatter: belongsTo('firm-has-matter', {
            async: false
        }),
        invoiceSubs: hasMany('invoice-sub', {
            async: false
        }),
        invoiceOutputPayments: hasMany('invoice-output-payment', {
            async: false
        }),

        updatePayment: (0, _emberApiActions.memberAction)({
            type: 'PUT',
            path: 'update_payment'
        })
    });
});