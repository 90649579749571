define('tm-common/helpers/nl-2br', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.nl2br = nl2br;


    /**
     * convert line breaks to BR tags
     *
     * @param params
     * expects an array with a single element as string
     * @returns {*}
     */
    function nl2br(params /*, hash */) {
        if (Ember.$.isArray(params)) {
            var breakTag = '<br />';
            var str = params[0];
            if (str) {
                return Ember.String.htmlSafe((str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
            }
            return '';
        }
    }

    exports.default = Ember.Helper.helper(nl2br);
});