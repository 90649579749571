define('tm-common/models/email-log', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        result: attr('string'),
        senderName: attr('string'),
        recipients: attr('string'),
        subject: attr('string'),
        content: attr('string'),
        createdOn: attr('estdate'),
        type: attr('string'),

        code: attr('string', { defaultValue: 'Other' }),
        format: attr('string', { defaultValue: 'Plain Text' }),

        // relationships
        createdBy: belongsTo('user', { async: true })
    });
});