define('tm-common/components/x-checkbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Checkbox.extend({
        change: function change() /* event */{
            this._super.apply(this, arguments);
            if (typeof this.attrs['on-change'] === 'function') {
                this.attrs['on-change'](this.get('value'), this.get('checked'));
            }
        }
    });
});