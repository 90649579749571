define('tm-common/components/matter/dashboard/dashboard-analytics/widgets/property-comparison', ['exports', 'tm-common/templates/components/matter/dashboard/dashboard-analytics/widgets/property-comparison'], function (exports, _propertyComparison) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _propertyComparison.default,

        uppercaseLabel: Ember.computed('stat.analyticStat.label', function () {
            if (this.get('stat.analyticStat')) {
                return this.get('stat.analyticStat.label').toUpperCase();
            }
        }),

        propertyYes: Ember.computed('stat.analyticStat.statData.property_yes', function () {
            if (this.get('stat.analyticStat')) {
                return (parseFloat(this.get('stat.analyticStat.statData.property_yes')) * 100).toFixed(0);
            }
        }),

        propertyAvg: Ember.computed('stat.analyticStat.statData.property_avg', function () {
            if (this.get('stat.analyticStat')) {
                return parseFloat(this.get('stat.analyticStat.statData.property_avg')).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
            }
        })
    });
});