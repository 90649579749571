define('tm-common/components/x-upload-modal', ['exports', 'tm-common/templates/components/x-upload-modal', 'tm-common/components/x-upload'], function (exports, _xUploadModal, _xUpload) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _xUpload.default.extend({
        /**
         * set the right layout to be rendered with this component
         */
        layout: _xUploadModal.default,

        actions: {
            saveOrCancel: function saveOrCancel() {
                if (this.get('showUploadButton')) {
                    this.send('upload');
                } else {
                    this.send('cancelUpload');
                }
            }
        }
    });
});