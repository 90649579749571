define('tm-common/components/subjects/analytics-modules/children-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/children-module'], function (exports, _childrenModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _childrenModule.default,

        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        })
    });
});