define('tm-common/components/subject-formatted-field-value', ['exports', 'tm-common/templates/components/subject-formatted-field-value', 'moment'], function (exports, _subjectFormattedFieldValue, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _subjectFormattedFieldValue.default,
        tagName: '',

        subject: null,
        matterField: null,
        allMatterFields: null,
        matterFieldName: null,
        childMatterFieldKey: null,

        parentContent: '',
        childContent: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);

            var subject = this.get('subject');
            var matterField = this.get('matterField');
            var allMatterFields = this.get('allMatterFields');
            if (subject && matterField && allMatterFields) {
                var fieldName = this.get('matterFieldName') ? this.get('matterFieldName') : matterField.get('name');
                var fieldValue = matterField.get('value');
                this.addObserver('subject.' + fieldName, this, this.setParentContent);

                var childMatterFieldKey = this.get('childMatterFieldKey') ? this.get('childMatterFieldKey') : 'name';
                var childField = allMatterFields.findBy('mergeField', fieldValue);
                if (childField) {
                    this.addObserver('subject.' + childField.get(childMatterFieldKey), this, this.setParentContent);
                }
            }
        },
        setParentContent: function setParentContent() {
            var fieldName = this.get('matterFieldName') ? this.get('matterFieldName') : this.get('matterField').get('name');
            this.set('parentContent', this.get('subject').get(fieldName));
        },
        setChildContent: function setChildContent() {
            var matterField = this.get('matterField');
            var fieldValue = matterField.get('value');
            var allMatterFields = this.get('allMatterFields');
            var childMatterFieldKey = this.get('childMatterFieldKey') ? this.get('childMatterFieldKey') : 'name';
            var childField = allMatterFields.findBy('mergeField', fieldValue);
            this.set('childContent', childField ? this.get('subject').get(childField.get(childMatterFieldKey)) : '');
        },


        result: Ember.computed('subject', 'matterField', 'allMatterFields', 'matterFieldName', 'childMatterFieldKey', 'parentContent', 'childContent', function () {
            var subject = this.get('subject');
            var matterField = this.get('matterField');
            var allMatterFields = this.get('allMatterFields');

            if (subject && matterField && allMatterFields) {
                var matterFieldName = this.get('matterFieldName') ? this.get('matterFieldName') : matterField.get('name');
                var childMatterFieldKey = this.get('childMatterFieldKey') ? this.get('childMatterFieldKey') : 'name';

                var mergedValue = '';

                var breakTag = '<br />';
                var parentContent = subject.get(matterFieldName);
                if (Ember.isPresent(parentContent) && ['race', 'gender', 'affiliation'].indexOf(matterFieldName) >= 0) {
                    parentContent = parentContent.split(',').join(', ');
                }
                var parentFieldType = matterField.get('fieldType');

                // only note children are separated using '-' from the parent
                var childFields = allMatterFields.filterBy('mergeField', matterFieldName.underscore()).filterBy('fieldClass', 'notes');

                if (Ember.isEmpty(parentContent)) {
                    return '';
                }
                if (parentFieldType === 'date') {
                    var dateValue = (0, _moment.default)(parentContent, 'YYYY-MM-DD');
                    if (dateValue.isValid()) {
                        mergedValue = dateValue.format('MM-DD-YYYY').toString();
                    }
                } else {
                    mergedValue = parentContent;
                }

                childFields.forEach(function (childField) {
                    var childContent = subject.get(childField.get(childMatterFieldKey));

                    if (Ember.isPresent(childContent)) {
                        mergedValue += ' - ';
                    }

                    var childFieldType = childField.get('fieldType');
                    if (childFieldType === 'date') {
                        var _dateValue = (0, _moment.default)(childContent, 'YYYY-MM-DD');
                        if (_dateValue.isValid()) {
                            mergedValue += _dateValue.format('MM-DD-YYYY').toString();
                        }
                    } else {
                        if (Ember.isPresent(childContent)) {
                            mergedValue += childContent;
                        }
                    }
                });

                return Ember.String.htmlSafe((mergedValue + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
            }
            // invalid params
            Ember.debug('Invalid parameters supplied to subject-formatted-field');
            return '';
        }),

        // some of the child fields are merged together on one line. these are NOTES fields
        // we also need to handle the other child fields as well.
        // leaving the logic above intact and gathering the other child fields here
        otherChildFields: Ember.computed('subject', 'matterField', 'allMatterFields', function () {
            var subject = this.get('subject');
            var matterField = this.get('matterField');
            var allMatterFields = this.get('allMatterFields');
            var childFields = allMatterFields.filterBy('mergeField', matterField.get('value'));
            var filteredChildFields = childFields.rejectBy('fieldClass', 'notes');
            return filteredChildFields.map(function (cf) {
                var componentName = '';
                if (cf.get('fieldClass') === 'widget') {
                    switch (cf.get('name')) {
                        case 'caseRelatedAnalytics':
                            componentName = 'possible-connections-module';
                            break;
                        case 'civilAnalytics':
                            componentName = 'civil-module';
                            break;
                        case 'criminalAnalytics':
                            componentName = 'criminal-module';
                            break;
                        case 'criminalCases':
                            componentName = 'criminal-cases-module';
                            break;
                        case 'educationAnalytics':
                            componentName = 'education-module';
                            break;
                        case 'employmentAnalytics':
                            componentName = 'employment-module';
                            break;
                        case 'employmentStatusAnalytics':
                            componentName = 'employment-status-module';
                            break;
                        case 'maritalAnalytics':
                            componentName = 'marital-phase-one-module';
                            break;
                        case 'statesAnalytics':
                            componentName = 'states-module';
                            break;
                        case 'socialMediaAnalytics':
                            componentName = 'socnet_module';
                            break;
                        case 'trafficAnalytics':
                            componentName = 'traffic-module';
                            break;
                        case 'yearsInCountyAnalytics':
                            componentName = 'residential-phase-one-module';
                            break;
                        case 'currentCity':
                            componentName = 'current-city-module';
                            break;
                        case 'property':
                            componentName = 'property-module';
                            break;
                    }

                    if (componentName === '') {
                        (false && !(false) && Ember.assert('No widget is configured for ' + cf.get('name')));
                    }

                    return Ember.Object.create({
                        type: 'component',
                        label: cf.get('display'),
                        weight: cf.get('weight'),
                        componentName: componentName,
                        value: cf.get('value'),
                        name: cf.get('name'),
                        fieldClass: cf.get('fieldClass'),
                        isChildField: true
                    });
                } else {
                    return Ember.Object.create({
                        type: 'subjectField',
                        label: cf.get('display'),
                        weight: cf.get('weight'),
                        value: cf.get('value'),
                        name: cf.get('name'),
                        fieldClass: cf.get('fieldClass'),
                        isChildField: true
                    });
                }
            });
        }),

        // we need to order all fields (parent + children) and display them accordingly
        displayedFields: Ember.computed('otherChildFields.[]', 'matterField', function () {
            var allDisplayedFields = [this.get('matterField')];
            allDisplayedFields.pushObjects(this.get('otherChildFields'));
            return allDisplayedFields.sortBy('weight');
        }),

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            var matterField = this.get('matterField');
            if (matterField) {
                var fieldName = this.get('matterFieldName') ? this.get('matterFieldName') : matterField.get('name');
                var fieldValue = matterField.get('value');
                this.removeObserver('subject.' + fieldName, this, this.setParentContent);

                var allMatterFields = this.get('allMatterFields');
                var childMatterFieldKey = this.get('childMatterFieldKey') ? this.get('childMatterFieldKey') : 'name';
                var childField = allMatterFields.findBy('mergeField', fieldValue);
                if (childField) {
                    this.removeObserver('subject.' + childField.get(childMatterFieldKey), this, this.setParentContent);
                }
            }
        }
    });
});