define('tm-common/components/subjects/analytics-modules/current-city-module', ['exports', 'tm-common/templates/components/subjects/analytics-modules/current-city-module'], function (exports, _currentCityModule) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _currentCityModule.default,
        classNames: ['subject-profile-info'],

        expanded: true,

        accordionStyle: Ember.computed('expanded', function () {
            return this.get('expanded') ? 'in' : '';
        })
    });
});