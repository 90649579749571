define('tm-common/models/quick-link', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        matter: _emberData.default.belongsTo('matter', { async: true }),
        name: _emberData.default.attr(),
        url: _emberData.default.attr(),
        createdOn: _emberData.default.attr('estdate'),
        updatedOn: _emberData.default.attr('estdate')
    });
});